import { Reducer } from "redux";
import {
  FETCH_STATISTICS_FAILED,
  FETCH_STATISTICS_SUCCEEDED,
  START_FETCHING_STATISTICS,
  StatisticsActionTypes,
  StatisticsState,
  STOP_FETCHING_STATISTICS
} from "./types";

const initialState: StatisticsState = {
  subsidiaries: [],
  productsStatistics: null,
  failuresStatistics: null,
  importerFailuresCount: 0,
  updateTime: null,
  loading: false,
  error: null
};

export const statisticsReducer: Reducer<StatisticsState, StatisticsActionTypes> = (state: StatisticsState = initialState, action: StatisticsActionTypes): StatisticsState => {
  switch (action.type) {

    case START_FETCHING_STATISTICS: {
      return {
        ... state,
        subsidiaries: action.subsidiaries,
        loading: true,
        error: null
      }
    }

    case STOP_FETCHING_STATISTICS: {
      return {
        ... state,
        loading: false,
        error: null
      }
    }

    case FETCH_STATISTICS_SUCCEEDED: {
      return {
        ...state,
        productsStatistics: action.productsStatistics,
        failuresStatistics: action.failuresStatistics,
        importerFailuresCount: action.importerFailuresCount,
        updateTime: new Date().getTime(),
        loading: false,
        error: null
      }
    }

    case FETCH_STATISTICS_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.error
      }
    }

    default:
      neverReached(action);
  }

  return state;
};

const neverReached = (never: never) => {};
