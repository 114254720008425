export const FETCH_GROUPS_LIST = 'FETCH_GROUPS_LIST'
export const FETCH_GROUPS_LIST_SUCCEEDED = 'FETCH_GROUPS_LIST_SUCCEEDED'
export const FETCH_GROUPS_LIST_FAILED = 'FETCH_GROUPS_LIST_FAILED'

export const FETCH_PERMISSIONS_LIST = 'FETCH_PERMISSIONS_LIST'
export const FETCH_PERMISSIONS_LIST_SUCCEEDED = 'FETCH_PERMISSIONS_LIST_SUCCEEDED'
export const FETCH_PERMISSIONS_LIST_FAILED = 'FETCH_PERMISSIONS_LIST_FAILED'

export const FETCH_USERS_LIST = 'FETCH_USERS_LIST'
export const FETCH_USERS_LIST_SUCCEEDED = 'FETCH_USERS_LIST_SUCCEEDED'
export const FETCH_USERS_LIST_FAILED = 'FETCH_USERS_LIST_FAILED'

export const ADD_USER = 'ADD_USER'
export const ADD_USER_SUCCEEDED = 'ADD_USER_SUCCEEDED'
export const ADD_USER_FAILED = 'ADD_USER_FAILED'

export const CLOSE_USERS_TAB_ERROR_MESSAGE = 'CLOSE_USERS_TAB_ERROR_MESSAGE'

export const FETCH_USER = 'FETCH_USER'
export const FETCH_USER_SUCCEEDED = 'FETCH_USER_SUCCEEDED'
export const FETCH_USER_FAILED = 'FETCH_USER_FAILED'

export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_SUCCEEDED = 'UPDATE_USER_SUCCEEDED'
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED'

export const REFRESH_USER = 'REFRESH_USER'
export const REFRESH_USER_SUCCEEDED = 'REFRESH_USER_SUCCEEDED'
export const REFRESH_USER_FAILED = 'REFRESH_USER_FAILED'

export const DELETE_USER = 'DELETE_USER'
export const DELETE_USER_SUCCEEDED = 'DELETE_USER_SUCCEEDED'
export const DELETE_USER_FAILED = 'DELETE_USER_FAILED'

export const FETCH_GROUP = 'FETCH_GROUP'
export const FETCH_GROUP_SUCCEEDED = 'FETCH_GROUP_SUCCEEDED'
export const FETCH_GROUP_FAILED = 'FETCH_GROUP_FAILED'

export const UPDATE_GROUP = 'UPDATE_GROUP'
export const UPDATE_GROUP_SUCCEEDED = 'UPDATE_GROUP_SUCCEEDED'
export const UPDATE_GROUP_FAILED = 'UPDATE_GROUP_FAILED'

export const CREATE_GROUP = 'CREATE_GROUP'
export const CREATE_GROUP_SUCCEEDED = 'CREATE_GROUP_SUCCEEDED'
export const CREATE_GROUP_FAILED = 'CREATE_GROUP_FAILED'

export const FETCH_PERMISSION = 'FETCH_PERMISSION'
export const FETCH_PERMISSION_SUCCEEDED = 'FETCH_PERMISSION_SUCCEEDED'
export const FETCH_PERMISSION_FAILED = 'FETCH_PERMISSION_FAILED'

export const UPDATE_PERMISSION = 'UPDATE_PERMISSION'
export const UPDATE_PERMISSION_SUCCEEDED = 'UPDATE_PERMISSION_SUCCEEDED'
export const UPDATE_PERMISSION_FAILED = 'UPDATE_PERMISSION_FAILED'

export const CREATE_PERMISSION = 'CREATE_PERMISSION'
export const CREATE_PERMISSION_SUCCEEDED = 'CREATE_PERMISSION_SUCCEEDED'
export const CREATE_PERMISSION_FAILED = 'CREATE_PERMISSION_FAILED'

export interface PermissionsManagementState {
  groups: Group[];
  permissions: Permission[];
  users: User[];

  groupsLoading: boolean;
  groupsError: string;

  permissionsLoading: boolean;
  permissionsError: string;

  usersLoading: boolean;
  usersError: string;

  userId: number;
  userDetails: User;
  userDetailsLoading: boolean;
  userDetailsError: string;

  groupId: number;
  groupDetails: Group;
  groupDetailsLoading: boolean;
  groupDetailsError: string;

  permissionId: number;
  permissionDetails: Permission;
  permissionDetailsLoading: boolean;
  permissionDetailsError: string;
}

export interface AddUserRequest {
  email: string;
}

export interface AddUserResponse {
  content: User;
}

export interface FetchGroupsListResponse {
  content: Group[];
}

export interface FetchUsersListResponse {
  content: User[];
}

export interface FetchPermissionsListResponse {
  content: Permission[];
}

export interface FetchUserRequest {
  id: number;
}

export interface FetchUserResponse {
  user: User;
}


export interface FetchGroupRequest {
  id: number;
}

export interface FetchGroupResponse {
  group: Group;
}

export interface FetchPermissionRequest {
  id: number;
}

export interface FetchPermissionResponse {
  permission: Permission;
}

export interface UpdateUserRequest {
  id: number;
  groupIds: number[];
  permissionIds: number[];
  active: boolean;
}

export interface UpdateUserResponse {
  user: User;
}

export interface RefreshUserRequest {
  id: number;
}

export interface RefreshUserResponse {
  user: User;
}

export interface DeleteUserRequest {
  id: number;
}

export interface DeleteUserResponse extends Response{ }

export interface UpdateGroupRequest extends CreateGroupRequest {
  id: number;
}

export interface UpdateGroupResponse {
  group: Group;
}

export interface CreateGroupRequest {
  name: string;
  description: string;
  permissionIds: number[];
  active: boolean;
}

export interface CreateGroupResponse {
  group: Group;
}

export interface CreatePermissionRequest {
  description: string;
  operation: string;
  domain: string;
  domainId: string;
  active: boolean;
}

export interface CreatePermissionResponse {
  permission: Permission;
}

export interface UpdatePermissionRequest extends CreatePermissionRequest {
  id: number;
}

export interface UpdatePermissionResponse {
  permission: Permission;
}

export interface Group {
  id: number;
  name: string;
  description: string;
  permissions: Permission[];
  users: User[];
  active: boolean;

  createdAt: Date;
  updatedAt: Date;
  createdByFullName: string;
  updatedByFullName: string;
}

export interface Permission {
  id: number;
  description: string;
  domain: string;
  domainId: string;
  operation: string;
  active: boolean;

  createdAt: Date;
  updatedAt: Date;
  createdByFullName: string;
  updatedByFullName: string;
}

export interface User {
  id: number;
  fullName: string;
  email: string;
  externalId: string;
  externalSystem: string;
  groups: Group[];
  permissions: Permission[];
  active: boolean;

  createdAt: Date;
  updatedAt: Date;
  createdByFullName: string;
  updatedByFullName: string;
}

export interface FetchGroupsListAction {
  type: typeof FETCH_GROUPS_LIST
}

export interface FetchGroupsListSucceededAction {
  type: typeof FETCH_GROUPS_LIST_SUCCEEDED
  payload: FetchGroupsListResponse
}

export interface FetchGroupsListFailedAction {
  type: typeof FETCH_GROUPS_LIST_FAILED
  error: string
}


export interface FetchPermissionsListAction {
  type: typeof FETCH_PERMISSIONS_LIST
}

export interface FetchPermissionsListSucceededAction {
  type: typeof FETCH_PERMISSIONS_LIST_SUCCEEDED
  payload: FetchPermissionsListResponse
}

export interface FetchPermissionsListFailedAction {
  type: typeof FETCH_PERMISSIONS_LIST_FAILED
  error: string
}


export interface FetchUsersListAction {
  type: typeof FETCH_USERS_LIST
}

export interface FetchUsersListSucceededAction {
  type: typeof FETCH_USERS_LIST_SUCCEEDED
  payload: FetchUsersListResponse
}

export interface FetchUsersListFailedAction {
  type: typeof FETCH_USERS_LIST_FAILED
  error: string
}


export interface AddUserAction {
  type: typeof ADD_USER,
  payload: AddUserRequest
}

export interface AddUserSucceededAction {
  type: typeof ADD_USER_SUCCEEDED
  payload: AddUserResponse
}

export interface AddUserFailedAction {
  type: typeof ADD_USER_FAILED
  error: string
}

export interface CloseUsersTabErrorMessageAction {
  type: typeof CLOSE_USERS_TAB_ERROR_MESSAGE
}

export interface FetchUserAction {
  type: typeof FETCH_USER
  payload: FetchUserRequest
}

export interface FetchUserSucceededAction {
  type: typeof FETCH_USER_SUCCEEDED
  payload: FetchUserResponse
}

export interface FetchUserFailedAction {
  type: typeof FETCH_USER_FAILED
  error: string
}

export interface UpdateUserAction {
  type: typeof UPDATE_USER
  payload: UpdateUserRequest
}

export interface UpdateUserSucceededAction {
  type: typeof UPDATE_USER_SUCCEEDED
  payload: UpdateUserResponse
}

export interface UpdateUserFailedAction {
  type: typeof UPDATE_USER_FAILED
  error: string
}

export interface RefreshUserAction {
  type: typeof REFRESH_USER
  payload: RefreshUserRequest
}

export interface RefreshUserSucceededAction {
  type: typeof REFRESH_USER_SUCCEEDED
  payload: RefreshUserResponse
}

export interface RefreshUserFailedAction {
  type: typeof REFRESH_USER_FAILED
  error: string
}

export interface DeleteUserAction {
  type: typeof DELETE_USER
  payload: DeleteUserRequest
}

export interface DeleteUserSucceededAction {
  type: typeof DELETE_USER_SUCCEEDED
  payload: DeleteUserResponse
}

export interface DeleteUserFailedAction {
  type: typeof DELETE_USER_FAILED
  error: string
}

export interface FetchGroupAction {
  type: typeof FETCH_GROUP
  payload: FetchGroupRequest
}

export interface FetchGroupSucceededAction {
  type: typeof FETCH_GROUP_SUCCEEDED
  payload: FetchGroupResponse
}

export interface FetchGroupFailedAction {
  type: typeof FETCH_GROUP_FAILED
  error: string
}

export interface UpdateGroupAction {
  type: typeof UPDATE_GROUP
  payload: UpdateGroupRequest
}

export interface UpdateGroupSucceededAction {
  type: typeof UPDATE_GROUP_SUCCEEDED
  payload: UpdateGroupResponse
}

export interface UpdateGroupFailedAction {
  type: typeof UPDATE_GROUP_FAILED
  error: string
}

export interface CreateGroupAction {
  type: typeof CREATE_GROUP
  payload: CreateGroupRequest
}

export interface CreateGroupSucceededAction {
  type: typeof CREATE_GROUP_SUCCEEDED
  payload: CreateGroupResponse
}

export interface CreateGroupFailedAction {
  type: typeof CREATE_GROUP_FAILED
  error: string
}

export interface FetchPermissionAction {
  type: typeof FETCH_PERMISSION
  payload: FetchPermissionRequest
}

export interface FetchPermissionSucceededAction {
  type: typeof FETCH_PERMISSION_SUCCEEDED
  payload: FetchPermissionResponse
}

export interface FetchPermissionFailedAction {
  type: typeof FETCH_PERMISSION_FAILED
  error: string
}

export interface CreatePermissionAction {
  type: typeof CREATE_PERMISSION
  payload: CreatePermissionRequest
}

export interface CreatePermissionSucceededAction {
  type: typeof CREATE_PERMISSION_SUCCEEDED
  payload: CreatePermissionResponse
}

export interface CreatePermissionFailedAction {
  type: typeof CREATE_PERMISSION_FAILED
  error: string
}

export interface UpdatePermissionAction {
  type: typeof UPDATE_PERMISSION
  payload: UpdatePermissionRequest
}

export interface UpdatePermissionSucceededAction {
  type: typeof UPDATE_PERMISSION_SUCCEEDED
  payload: UpdatePermissionResponse
}

export interface UpdatePermissionFailedAction {
  type: typeof UPDATE_PERMISSION_FAILED
  error: string
}

export type PermissionsManagementActionTypes = FetchGroupsListAction | FetchGroupsListSucceededAction | FetchGroupsListFailedAction
  | FetchPermissionsListAction | FetchPermissionsListSucceededAction | FetchPermissionsListFailedAction
  | FetchUsersListAction | FetchUsersListSucceededAction | FetchUsersListFailedAction
  | AddUserAction | AddUserSucceededAction | AddUserFailedAction
  | CloseUsersTabErrorMessageAction
  | FetchUserAction | FetchUserSucceededAction | FetchUserFailedAction
  | UpdateUserAction | UpdateUserSucceededAction | UpdateUserFailedAction
  | FetchGroupAction | FetchGroupSucceededAction | FetchGroupFailedAction
  | UpdateGroupAction | UpdateGroupSucceededAction | UpdateGroupFailedAction
  | CreateGroupAction | CreateGroupSucceededAction | CreateGroupFailedAction
  | FetchPermissionAction | FetchPermissionSucceededAction | FetchPermissionFailedAction
  | CreatePermissionAction | CreatePermissionSucceededAction | CreatePermissionFailedAction
  | UpdatePermissionAction | UpdatePermissionSucceededAction | UpdatePermissionFailedAction
  | RefreshUserAction | RefreshUserSucceededAction | RefreshUserFailedAction
  | DeleteUserAction | DeleteUserSucceededAction | DeleteUserFailedAction;
