import { Reducer } from "redux";
import {
  CLOSE_ERROR_MESSAGE,
  CREATE_TASK,
  CREATE_TASK_FAILED,
  CREATE_TASK_SUCCEEDED,
  FETCH_TASKS,
  FETCH_TASKS_FAILED,
  FETCH_TASKS_SUCCEEDED,
  TasksActionTypes,
  TasksState,
  UPDATE_TASK,
  UPDATE_TASK_FAILED,
  UPDATE_TASK_SUCCEEDED
} from "./types";
import { pageResponse } from "../common/types";

const initialState: TasksState = {
  createImportTask: {
    subsidiary: null,
    sourceSystem: null,
    fromDate: null,
    toDate: null,
    intervalMinutes: null,
    enabled: false,
    batchSize: null,
    queryCondition: null
  },
  updateImportTask: null,
  pageRequest: {
    page: 0,
    size: 10,
    sort: [
      {
        field: "fromDate",
        direction: 'DESC'
      },
      {
        field: "id",
        direction: 'DESC'
      }
    ],
    subsidiary: null,
    enabled: null,
  },
  pageResponse: {
    ...pageResponse(),
    content: []
  },
  loading: false,
  error: null
};

export const tasksReducer: Reducer<TasksState, TasksActionTypes> = (state: TasksState = initialState, action: TasksActionTypes): TasksState => {
  switch (action.type) {

    case FETCH_TASKS: {
      return {
        ... state,
        pageRequest: {
          ... action.pageRequest
        },
        loading: true,
        error: null
      }
    }

    case FETCH_TASKS_SUCCEEDED: {
      return {
        ...state,
        pageResponse: action.payload,
        loading: false,
        error: null
      }
    }

    case FETCH_TASKS_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.error
      }
    }

    case CREATE_TASK: {
      return {
        ... state,
        createImportTask: action.request,
        loading: true,
        error: null
      }
    }

    case CREATE_TASK_SUCCEEDED: {
      return {
        ...state,
        loading: false,
        error: null
      }
    }

    case CREATE_TASK_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.error
      }
    }

    case UPDATE_TASK: {
      return {
        ... state,
        updateImportTask: action.request,
        loading: true,
        error: null
      }
    }

    case UPDATE_TASK_SUCCEEDED: {
      return {
        ...state,
        loading: false,
        error: null
      }
    }

    case UPDATE_TASK_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.error
      }
    }

    case CLOSE_ERROR_MESSAGE: {
      return {
        ...state,
        loading: false,
        error: null
      }
    }

    default:
      neverReached(action);
  }

  return state;
};

const neverReached = (never: never) => {};
