import * as React from "react";
import "./MappingKOPToMVsView.css"
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import {Alert, Button, Divider, Form, message, Select, Table} from 'antd';
import MappingSourceTypeSelect from "../inputs/MappingSourceTypeSelect/MappingSourceTypeSelect";
import {useState} from "react";
import KindOfProductLoadableSelect from "../LoadableSelect/KindOfProdactLoadableSelect";
import KindOfProductService from "../../services/KindOfProductService";
import MappingService from "../../services/MappingService";
import {MappingSourceType} from "../../store/common/types";

const {Option} = Select;

export interface Props {
    loading: boolean;
    error: string;
}

export const MappingKOPToMVsView: React.FunctionComponent<Props> = (props) => {

    const {loading} = props;
    const [mappingSource, setMappingSource] = useState(null);
    const [kindOfProduct, setKindOfProduct] = useState(null);
    const [error, setError] = React.useState(false);
    const [selectedMappedValues, setSelectedMappedValues] = React.useState([]);
    const [mappedValues, setMappedValues] = React.useState([]);
    const [unmappedKOP, setUnmappedKOP] = React.useState([]);

    const [form] = Form.useForm();

    const resetForm = () => {
        setUnmappedKOP([]);
        setSelectedMappedValues([]);
        form.resetFields();
    }

    const formItemLayout = {
        labelCol: {span: 3},
        wrapperCol: {span: 10},
    };

    const buttonItemLayout = {
        wrapperCol: {span: 10, offset: 3},
    };

    const onKindOfProductChange = (code: string) => {
        setKindOfProduct(code);
    };

    const onMappedValueChange = (mappedValues: []) => {
        setSelectedMappedValues(mappedValues);
    };

    const onMappingSourceChange = (mappingSource: MappingSourceType) => {
        setMappingSource(mappingSource);
    }

    const onSubmit = (data: any) => {
        MappingService.createKindOfProductMapping({
            mappingSource: data.mappingSource,
            kindOfProductCode: data.kindOfProduct,
            mappedValues: selectedMappedValues
        }).then(() => {
            message.success((<span>Mapping Created Successfully</span>), 5);
            resetForm();
        }).catch((e) => {
            message.error((<span>Create Mapping Failed: {e.message}</span>), 5);
        });
    }

    const columns = [
        {
            title: 'Code',
            dataIndex: 'code',
            key: 'code'
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
    ];

    React.useEffect(() => {

        if (!!kindOfProduct && !!mappingSource) {

            KindOfProductService.getKindOfProductMapping(kindOfProduct, mappingSource)
                .then(values => {
                    setSelectedMappedValues(values.content.map(value => value.id))
                })
                .catch(error => {
                    console.log(error)
                    setError(true);
                    setSelectedMappedValues(null);
                });
        } else {
            setSelectedMappedValues([]);
        }

    }, [!!kindOfProduct ? kindOfProduct : null, !!mappingSource ? mappingSource : null]);

    React.useEffect(() => {

        if (!!mappingSource) {

            MappingService.fetchMappedValuesList({mappingSource})
                .then(values => {
                    setMappedValues(values.content)
                })
                .catch(error => {
                    console.log(error)
                    setError(true);
                    setMappedValues(null);
                });
        } else {
            setMappedValues([]);
        }

    }, [!!mappingSource ? mappingSource : null]);

    React.useEffect(() => {

        if (!!mappingSource) {

            MappingService.fetchUnmappedKindOfProductList(mappingSource)
                .then(values => {
                    setUnmappedKOP(values.content)
                })
                .catch(error => {
                    console.log(error)
                    setError(true);
                    setUnmappedKOP(null);
                });
        } else {
            setUnmappedKOP([]);
        }

    }, [!!mappingSource ? mappingSource : null]);

    return <React.Fragment>

        {
            loading && (<div>
                <div className="view-loading"/>
                <CircularProgress className="view-loading-spinner"/>
            </div>)
        }

        {
            error && (<React.Fragment>
                <Alert
                    message="Error"
                    description={error}
                    type="error"
                />
                <br/>
            </React.Fragment>)
        }

        <br/>


        <Divider orientation={"left"}>
            Map Kind Of Product to Available Mapped Values
        </Divider>
        <br/>

        <Form
            {...formItemLayout}
            layout={'horizontal'}
            form={form}
            onFinish={onSubmit}
        >

            <Form.Item label="Mapping Source"
                       name={"mappingSource"}
                       rules={[{required: true, message: 'Please choose Mapping Source'}]}>

                <MappingSourceTypeSelect id={"mapping-source-select"}
                                         value={mappingSource}
                                         onChange={onMappingSourceChange}/>
            </Form.Item>

            <Form.Item label="Kind Of Product"
                       name={"kindOfProduct"}
                       rules={[{required: true, message: 'Please select Kind Of Product'}]}>

                <KindOfProductLoadableSelect onChange={onKindOfProductChange}/>
            </Form.Item>

            <Form.Item label="Mapped Values">
                <Select
                    mode="multiple"
                    size={"large"}
                    placeholder="Please select mappedValues..."
                    value={selectedMappedValues}
                    onChange={onMappedValueChange}
                    style={{width: '100%'}}
                >
                    {mappedValues.map(mappedValue => {
                        return (<Option key={mappedValue.id}
                                        value={mappedValue.id}>{mappedValue.id + "  -  " + mappedValue.name}</Option>)
                    })}
                </Select>
            </Form.Item>

            <Form.Item {...buttonItemLayout}>
                <Button type="primary" htmlType="submit">
                    Update
                </Button>
                <span> </span>
                <Button danger onClick={() => resetForm()}>Cancel</Button>
            </Form.Item>
        </Form>

        <br/>
        <Divider orientation={"left"}>
            Unmapped Kind Of Products
        </Divider>
        <br/>

        <Table dataSource={unmappedKOP} columns={columns} />

    </React.Fragment>
};

export default MappingKOPToMVsView;
