import { PageResponse, ProductSourceSystem, Subsidiary } from "../common/types";

export const CREATE_TASK = 'CREATE_TASK'
export const CREATE_TASK_SUCCEEDED = 'CREATE_TASK_SUCCEEDED'
export const CREATE_TASK_FAILED = 'CREATE_TASK_FAILED'

export const UPDATE_TASK = 'UPDATE_TASK'
export const UPDATE_TASK_SUCCEEDED = 'UPDATE_TASK_SUCCEEDED'
export const UPDATE_TASK_FAILED = 'UPDATE_TASK_FAILED'

export const FETCH_TASKS = 'FETCH_TASKS'
export const FETCH_TASKS_SUCCEEDED = 'FETCH_TASKS_SUCCEEDED'
export const FETCH_TASKS_FAILED = 'FETCH_TASKS_FAILED'

export const CLOSE_ERROR_MESSAGE = 'CLOSE_ERROR_MESSAGE'

export interface FetchTasksPageRequest {
  page: number;
  size: number;
  sort: {field: string, direction: 'ASC' | 'DESC'}[];
  subsidiary: Subsidiary;
  enabled: boolean;
}

export interface FetchTasksPageResponse extends PageResponse {
  content: ImportTask[];
}

export interface CreateImportTask {
  subsidiary: Subsidiary;
  sourceSystem: ProductSourceSystem
  fromDate: Date;
  toDate: Date;
  intervalMinutes: number;
  enabled: boolean;
  queryCondition: string;
  batchSize: number;
}

export interface UpdateImportTask {
  id: string;
  enabled: boolean;
}

export interface ImportTask extends CreateImportTask {
  id: string;
  syncDate: Date;
  runDate: Date;
  running: boolean;
  completed: boolean;
  createdAt: Date;
  updatedAt: Date;
  createdByFullName: string;
  updatedByFullName: string;
}

export interface TasksState {
  createImportTask: CreateImportTask;
  updateImportTask: UpdateImportTask;
  pageRequest: FetchTasksPageRequest;
  pageResponse: FetchTasksPageResponse;
  loading: boolean;
  error: string;
}

export interface FetchTasksAction {
  type: typeof FETCH_TASKS
  pageRequest: FetchTasksPageRequest
}

export interface FetchTasksSucceededAction {
  type: typeof FETCH_TASKS_SUCCEEDED
  payload: FetchTasksPageResponse
}

export interface FetchTasksFailedAction {
  type: typeof FETCH_TASKS_FAILED
  error: string
}

export interface CreateTaskAction {
  type: typeof CREATE_TASK
  request: CreateImportTask
}

export interface CreateTaskSucceededAction {
  type: typeof CREATE_TASK_SUCCEEDED
  payload: ImportTask
}

export interface CreateTaskFailedAction {
  type: typeof CREATE_TASK_FAILED
  error: string
}

export interface UpdateTaskAction {
  type: typeof UPDATE_TASK
  request: UpdateImportTask
}

export interface UpdateTaskSucceededAction {
  type: typeof UPDATE_TASK_SUCCEEDED
  payload: ImportTask
}

export interface UpdateTaskFailedAction {
  type: typeof UPDATE_TASK_FAILED
  error: string
}

export interface CloseErrorMessageAction {
  type: typeof CLOSE_ERROR_MESSAGE
}

export type TasksActionTypes = FetchTasksAction | FetchTasksSucceededAction | FetchTasksFailedAction
  | CreateTaskAction | CreateTaskSucceededAction | CreateTaskFailedAction
  | UpdateTaskAction | UpdateTaskSucceededAction | UpdateTaskFailedAction
  | CloseErrorMessageAction;


