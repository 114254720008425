import * as React from "react";
import {RouteComponentProps, withRouter} from "react-router-dom";
import "./DeprecatedKindOfProductListPage.css";
import {Dispatch} from "redux";
import {AppState} from "../../store";
import {flowRight} from "lodash";
import {connect} from 'react-redux';
import {UserSession} from "../../store/userSession/types";
import {Order} from "../../components/EnhancedTable/EnhancedTable";
import PageWrapper from "../../components/Drawer/PageWrapper";
import {PageHeader} from 'antd';
import DeprecatedKindOfProductList from "../../components/DeprecatedKindOfProductList/DeprecatedKindOfProductList";
import {
    FetchKindOfLevelItemsPageRequest, FetchKindOfLevelItemsPageResponse,
} from "../../store/kindOf/types";
import {fetchDeprecatedKindOfProductItems} from "../../store/kindOf/actions";

interface Props extends RouteComponentProps {
    userSession: UserSession;
    pageRequest: FetchKindOfLevelItemsPageRequest;
    pageResponse: FetchKindOfLevelItemsPageResponse;
    fetchDeprecatedKindOfProductItems: (pageRequest: FetchKindOfLevelItemsPageRequest) => void;
    loading?: boolean;
    error?: string;
}

interface State {
}

class DeprecatedKindOfProductListPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.onPaginationChanged = this.onPaginationChanged.bind(this);
        this.onSortingChanged = this.onSortingChanged.bind(this);
        this.onFilterChanged = this.onFilterChanged.bind(this);
    }

    componentDidMount() {
        this.props.fetchDeprecatedKindOfProductItems(this.props.pageRequest)
    }

    private onPaginationChanged(page: number, size: number) {
        console.log("Page: " + page + ", size: " + size);

        this.props.fetchDeprecatedKindOfProductItems({
            ...this.props.pageRequest,
            page,
            size
        })
    }

    private onFilterChanged(filterField: string, value: string) {
        console.log("Filter: " + filterField + ", value: " + value);

        this.props.fetchDeprecatedKindOfProductItems({
            ...this.props.pageRequest,
            [filterField]: value
        })
    }

    private onSortingChanged(orderBy: string, order: Order) {
        console.log("Order by: " + orderBy + ", order: " + order);

        this.props.fetchDeprecatedKindOfProductItems({
            ...this.props.pageRequest,
            sort: [{
                field: orderBy,
                direction: order
            }]
        });
    }

    public render() {
        return (
            <div className="import-tasks-page">
                <PageWrapper userSession={this.props.userSession}>
                    <PageHeader
                        backIcon={null}
                        ghost={false}
                        onBack={() => window.history.back()}
                        title="Deprecated Kind Of Product"
                        subTitle="Displays list of items of Deprecated Kind Of Product"
                    >
                    </PageHeader>
                    <DeprecatedKindOfProductList
                        pageRequest={this.props.pageRequest}
                        pageResponse={this.props.pageResponse}
                        onPaginationChanged={this.onPaginationChanged}
                        onSortingChanged={this.onSortingChanged}
                        onFilterChanged={this.onFilterChanged}
                        loading={this.props.loading}
                        error={this.props.error}
                    />
                </PageWrapper>
            </div>
        );
    }
}

const mapStateToProps = (state: AppState) => ({
    userSession: state.userSession.userSession,
    pageRequest: state.kindOf.pageRequest,
    pageResponse: state.kindOf.pageResponse,

    loading: state.kindOf.loading,
    error: state.kindOf.error,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchDeprecatedKindOfProductItems: (pageRequest: FetchKindOfLevelItemsPageRequest) => {
        dispatch(fetchDeprecatedKindOfProductItems(pageRequest))
    }
});

const enhance = flowRight(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(DeprecatedKindOfProductListPage);
