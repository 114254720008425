import * as React from "react";
import {useEffect, useState} from "react";
import "./ProductFailureView.css"
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import {
    Button,
    Col,
    Collapse,
    Descriptions,
    Dropdown,
    Menu,
    Modal,
    Row,
    Space,
    Tag,
    Typography,
    Radio,
    Spin
} from 'antd';
import { FailureGrantType, FailureStatus, ProductFailure } from "../../store/failures/types";
import Highlight from "../Highlight/Highlight";
import { ExclamationCircleOutlined } from "@ant-design/icons/lib";
import LoadableCoreProductView from "../CoreProduct/LoadableCoreProductView";
import { DownOutlined } from '@ant-design/icons';
import KindOfProductLoadableSelect from "../LoadableSelect/KindOfProdactLoadableSelect";
import KindOfProductPredictorService from "../../services/KindOfProductPredictorService";
import {Prediction} from "../../store/KindOfProductPredictor/types";

const { Text } = Typography;
const { Panel } = Collapse;
const { confirm } = Modal;

export interface ProductFailureViewProps {
  productFailure: ProductFailure;
  onFailureRetry?: (id: number) => void;
  onFailureReject?: (id: number) => void;
  onFailureResolve?: (id: number, grant: FailureGrantType, errorTypeDetails: {}) => void;
  retryLoading?: boolean;
  rejectLoading?: boolean;
  resolveLoading?: boolean;
  loading: boolean;
  error: string;
}

const ProductFailureView: React.FunctionComponent<ProductFailureViewProps> = (props) => {

  const { productFailure, onFailureRetry, onFailureReject, onFailureResolve, loading, resolveLoading, retryLoading, rejectLoading } = props;

  const [confirmResolveModalVisible, setConfirmResolveModalVisible] = useState(false);

  const [primaryProductId, setPrimaryProductId] = useState(null);
  const [kindOfProduct, setKindOfProduct] = useState(null);
  const [kindOfProductPrediction, setKindOfProductPrediction] = React.useState([]);
  const [error, setError] = useState(null as string);

  const statusTag = (status: FailureStatus) => {
    return (status == 'UNRESOLVED' ?
        (<Tag color="error">NOT RESOLVED</Tag>)
        : (status == 'RESOLVED'
          ? (<Tag color="success">RESOLVED</Tag>)
          : ((status == 'REJECTED' || status == 'RUNNING') ? <Tag color="warning">{status}</Tag> : null))
    );
  };

  const productActiveTag = (failure: ProductFailure) => {
    return (
      failure.activeProduct === true
        ? <Tag color="success">ACTIVE</Tag>
        : <Tag color="error">NOT ACTIVE</Tag>
    );
  };

  const onFailureRejectOpenConfirmDialog = (id: number) => {

    confirm({
      title: 'Are you sure to reject this failure ' + id + '?',
      icon: <ExclamationCircleOutlined />,
      content: 'Rejected failures are treated as non-important, and thus ignored during statistics calculation.',
      okText: 'Yes, reject!',
      okType: 'danger',
      cancelText: 'No, wait!',
      onOk() {
        !!onFailureReject && onFailureReject(id);
      },
      onCancel() {
      },
    });
  };

  const resolvableErrorTypes = [
      'GLOBAL_ID_CONFLICT',
      'DUPLICATE_COUNTRY_FOUND',
      'KIND_OF_PRODUCT_CONFLICT',
      'KIND_OF_PRODUCT_NOT_FOUND',
      'MULTIPLE_KIND_OF_PRODUCT_FOUND'
  ];

  const onFailureResolveOpenConfirmDialog = () => {
    setConfirmResolveModalVisible(true);
  };

    useEffect(() => {

        if (productFailure && productFailure.status === 'UNRESOLVED' &&
            (productFailure.errorType === 'KIND_OF_PRODUCT_CONFLICT' ||
                productFailure.errorType === 'MULTIPLE_KIND_OF_PRODUCT_FOUND' ||
                productFailure.errorType === 'KIND_OF_PRODUCT_NOT_FOUND')) {

            const globalProduct = JSON.parse(productFailure.productDetails.toString())

            KindOfProductPredictorService
                .getKindOfProductPrediction({
                    products: [
                        {
                            source_id: productFailure.globalId,
                            product_name: globalProduct.V1.name,
                            manufacturer_id: globalProduct.V1.manufacturer?.id,
                            manufacturer_name: globalProduct.V1.manufacturer?.name,
                            country: productFailure.subsidiary
                        }
                    ]
                })
                .then(coreProduct => {
                    setKindOfProductPrediction(coreProduct.results)
                })
                .catch(error => {
                    setError(error.response.data.detail);
                });
        }
    }, [props.productFailure]);

  const mergeGlobalProductsConfirmModal = (

    productFailure && productFailure.errorTypeDetails && productFailure.errorTypeDetails.mergeId
    && <Modal
        title={'Are you sure to merge the following Global Products: ' + (productFailure.errorTypeDetails.proposedGlobalIds) + '?'}
        visible={confirmResolveModalVisible}
        onOk={() => {
          !!onFailureResolve && onFailureResolve(productFailure.id, 'MERGE_GLOBAL_PRODUCTS_GRANT', productFailure.errorTypeDetails);
          setConfirmResolveModalVisible(false)
        }}
        onCancel={() => {
          setConfirmResolveModalVisible(false)
        }}
        okText={'Yes, merge!'}
        cancelText={'No, wait!'}
        okButtonProps={{ className: "bg-success" }}>
        <p>If you merge the Global Products, next actions will be performed:</p>
        <ul>
            <li><strong>NEW</strong> Global Product will be <strong>created</strong></li>

            <li>Newly created Global Product will connect to all subsidiary products from the merged global
                products <strong>{productFailure.errorTypeDetails.proposedGlobalIds.join(', ')}</strong>
            </li>

            <li>Newly created Global Product will become a <strong>Successor</strong> of merged global
                products <strong>{productFailure.errorTypeDetails.proposedGlobalIds.join(', ')}</strong>
            </li>

            <li>Changes to subsidiary products will update <strong>Successor</strong> Global Product</li>

            <li>Merged Global Products will be updated correspondingly when <strong>Successor</strong> Global Product is updated</li>
        </ul>
    </Modal>
  );

  const globalIdConflictConfirmModal = (

    productFailure && productFailure.errorTypeDetails && productFailure.errorTypeDetails.proposedGlobalProductId
    && <Modal
        title={'Are you sure to resolve this failure ' + (productFailure.id) + '?'}
        visible={confirmResolveModalVisible}
        onOk={() => {
          !!onFailureResolve && onFailureResolve(productFailure.id, 'GLOBAL_ID_CONFLICT_RESOLVE_GRANT', productFailure.errorTypeDetails);
          setConfirmResolveModalVisible(false)
        }}
        onCancel={() => {
          setConfirmResolveModalVisible(false)
        }}
        okText={'Yes, resolve!'}
        cancelText={'No, wait!'}
        okButtonProps={{ className: "bg-success" }}>
        <p>If you resolve the failure, next actions will be performed:</p>
        <ul>
            <li>Global Product <strong>{productFailure.errorTypeDetails.proposedGlobalProductId}</strong> will
                be <strong>created if not yet exists</strong></li>

            <li>Product <strong>{productFailure.productId} ({productFailure.subsidiary}, {productFailure.sourceSystem})</strong>
                &nbsp;will be <strong>disconnected from</strong> Global
                Product <strong>{productFailure.errorTypeDetails.globalProductId}</strong></li>

            <li>Product <strong>{productFailure.productId} ({productFailure.subsidiary}, {productFailure.sourceSystem})</strong>
                &nbsp;will be <strong>connected to</strong> Global
                Product <strong>{productFailure.errorTypeDetails.proposedGlobalProductId}</strong></li>

            <li>Product <strong>{productFailure.productId} ({productFailure.subsidiary}, {productFailure.sourceSystem})</strong>
                &nbsp;data fields (including GTINs) will be <strong>updated</strong> accordingly.
            </li>
        </ul>
    </Modal>
  );

    const duplicateCountryConfirmModal = (

        productFailure && productFailure.errorTypeDetails
        && <Modal
            title={'Are you sure to resolve this failure ' + (productFailure.id) + '?'}
            visible={confirmResolveModalVisible}
            onOk={() => {
                !!primaryProductId && !!onFailureResolve && onFailureResolve(productFailure.id, 'DUPLICATE_COUNTRY_RESOLVE_GRANT', {...productFailure.errorTypeDetails, resolvedPrimaryProductId: primaryProductId});
                setConfirmResolveModalVisible(false)
            }}
            onCancel={() => {
                setConfirmResolveModalVisible(false)
            }}
            okText={'Yes, resolve!'}
            cancelText={'No, wait!'}
            okButtonProps={{ className: "bg-success" }}>
            <p>Choose the primary product id and resolve the country duplication conflict:</p>

            <Radio.Group onChange={(e) => {
                setPrimaryProductId(e.target.value)
            }} value={primaryProductId}>
                <Radio value={productFailure.errorTypeDetails.importedProductId}>{productFailure.errorTypeDetails.importedProductId}</Radio>
                {productFailure.errorTypeDetails.duplicatedProductIds && productFailure.errorTypeDetails.duplicatedProductIds.map(it => {
                    return <Radio value={it}>{it}</Radio>
                })}
            </Radio.Group>

        </Modal>
    );

    const onKindOfProductChange = (code: string) => {
        setKindOfProduct(code);
    };

    const renderPredictionResultRadios = () => {
        return <div>
            <Typography style={{padding: "8px 0"}}>from prediction service</Typography>
            {!kindOfProductPrediction || kindOfProductPrediction.length <= 0 && <Spin/>}
            <div>
            {kindOfProductPrediction && kindOfProductPrediction.length > 0 &&
            kindOfProductPrediction[0].predictions.map((prediction: Prediction, index: Number) =>
                <Radio style={{width: "100%"}} key={`prediction-${index}`} value={prediction.kind_of_product}>
                    <span>{prediction.kind_of_product} </span>
                    <span style={{color: KindOfProductPredictorService.getColor(prediction.probability)}}>{Number((prediction.probability * 100).toFixed(2))}%</span>
                </Radio>)}
            </div>
        </div>
    }

    const sendKindOfProductPredictionFeedback = () => {
        if (kindOfProduct && kindOfProductPrediction && kindOfProductPrediction.length > 0) {
            KindOfProductPredictorService.putKindOfProductPredictionFeedback(kindOfProductPrediction[0].prediction_id, {kind_of_product: kindOfProduct})
        }
    }

    const kindOfProductConfirmModal = (

        productFailure && productFailure.errorTypeDetails
        && <Modal
            title={'Are you sure to resolve this failure ' + (productFailure.id) + '?'}
            visible={confirmResolveModalVisible}
            onOk={() => {
                !!kindOfProduct && !!onFailureResolve && onFailureResolve(productFailure.id, 'KIND_OF_PRODUCT_RESOLVE_GRANT', {...productFailure.errorTypeDetails, resolvedKindOfProduct: kindOfProduct});
                sendKindOfProductPredictionFeedback()
                setConfirmResolveModalVisible(false)
            }}
            onCancel={() => {
                setConfirmResolveModalVisible(false)
            }}
            okText={'Yes, resolve!'}
            cancelText={'No, wait!'}
            okButtonProps={{ className: "bg-success" }}>
            <Typography style={{padding: "8px 0", fontSize: 16}}>In order to resolve the failure please select a Kind Of Product</Typography>
            <Typography style={{padding: "8px 0"}}>from the proposed list</Typography>

            <Radio.Group onChange={(e) => {
                setKindOfProduct(e.target.value)
            }} value={kindOfProduct}>
                {productFailure.errorTypeDetails.kindOfProducts?.map(it => {
                    return <Radio value={it}>
                        {it}
                    </Radio>
                })}
                {!!productFailure.errorTypeDetails.kindOfProduct &&
                !productFailure.errorTypeDetails.kindOfProducts?.includes(productFailure.errorTypeDetails.kindOfProduct) &&
                <Radio value={productFailure.errorTypeDetails.kindOfProduct}>
                    {productFailure.errorTypeDetails.kindOfProduct}
                </Radio>}
                {!!productFailure.errorTypeDetails.proposedKindOfProduct &&
                !productFailure.errorTypeDetails.kindOfProducts?.includes(productFailure.errorTypeDetails.proposedKindOfProduct) &&
                <Radio value={productFailure.errorTypeDetails.proposedKindOfProduct}>
                    {productFailure.errorTypeDetails.proposedKindOfProduct}
                </Radio>}
                <Typography style={{padding: "8px 0"}}>from the existing list </Typography>
                <KindOfProductLoadableSelect onChange={onKindOfProductChange}/>
                {renderPredictionResultRadios()}
            </Radio.Group>
        </Modal>
    );

    const multipleKindOfProductConfirmModal = (

        productFailure && productFailure.errorTypeDetails
        && <Modal
            title={'Are you sure to resolve this failure ' + (productFailure.id) + '?'}
            visible={confirmResolveModalVisible}
            onOk={() => {
                !!kindOfProduct && !!onFailureResolve && onFailureResolve(productFailure.id, 'KIND_OF_PRODUCT_RESOLVE_GRANT', {...productFailure.errorTypeDetails, resolvedKindOfProduct: kindOfProduct});
                sendKindOfProductPredictionFeedback()
                setConfirmResolveModalVisible(false)
            }}
            onCancel={() => {
                setConfirmResolveModalVisible(false)
            }}
            okText={'Yes, resolve!'}
            cancelText={'No, wait!'}
            okButtonProps={{ className: "bg-success" }}>
            <Typography style={{padding: "8px 0", fontSize: 16}}>In order to resolve the failure please select a Kind Of Product</Typography>
            <Typography style={{padding: "8px 0"}}>from the proposed list</Typography>

            <Radio.Group onChange={(e) => {
                setKindOfProduct(e.target.value)
            }} value={kindOfProduct}>
                {productFailure.errorTypeDetails.kindOfProducts?.map(it => {
                    return <Radio value={it}>
                        {it}
                    </Radio>
                })}
                <Typography style={{padding: "8px 0"}}>from the existing list</Typography>
                <KindOfProductLoadableSelect onChange={onKindOfProductChange}/>
                {renderPredictionResultRadios()}
            </Radio.Group>
        </Modal>
    );

    const kindOfProductNotFoundConfirmModal = (

        productFailure && productFailure.errorTypeDetails
        && <Modal
            title={'Are you sure to resolve this failure ' + (productFailure.id) + '?'}
            visible={confirmResolveModalVisible}
            onOk={() => {
                !!kindOfProduct && !!onFailureResolve && onFailureResolve(productFailure.id, 'KIND_OF_PRODUCT_RESOLVE_GRANT', {...productFailure.errorTypeDetails, resolvedKindOfProduct: kindOfProduct});
                sendKindOfProductPredictionFeedback()
                setConfirmResolveModalVisible(false)
            }}
            onCancel={() => {
                setConfirmResolveModalVisible(false)
            }}
            okText={'Yes, resolve!'}
            cancelText={'No, wait!'}
            okButtonProps={{ className: "bg-success" }}>
            <Typography style={{padding: "8px 0", fontSize: 16}}>In order to resolve the failure please select a Kind Of Product</Typography>

            <Radio.Group onChange={(e) => {
                setKindOfProduct(e.target.value)
            }} value={kindOfProduct}>
                <Typography style={{padding: "8px 0"}}>from the existing list</Typography>
                <KindOfProductLoadableSelect onChange={onKindOfProductChange}/>
                {renderPredictionResultRadios()}
            </Radio.Group>
        </Modal>
    );

  const menu = (
    productFailure && productFailure.status == 'UNRESOLVED' &&
    <Menu>
      {
        <Menu.Item key="1">
            <Button type="primary" loading={props.retryLoading} size={"small"}
                    onClick={() => onFailureRetry(productFailure.id)}>Retry</Button>
        </Menu.Item>
      }
      {
        <Menu.Item key="2">
            <Button type="primary" danger loading={props.rejectLoading} size={"small"}
                    onClick={() => onFailureRejectOpenConfirmDialog(productFailure.id)}>Reject</Button>
        </Menu.Item>
      }
      {
        resolvableErrorTypes.indexOf(productFailure.errorType) > -1 &&
        <Menu.Item key="3">
        <Button type="primary" className={"bg-success"} loading={props.resolveLoading} size={"small"}
                onClick={() => onFailureResolveOpenConfirmDialog()}>Resolve</Button>
        </Menu.Item>
      }
    </Menu>
  );

  return props.error ?
    (
      <div className="view-error">{props.error}</div>
    )
    :
    (
      <React.Fragment>
        {(resolveLoading || loading || retryLoading || rejectLoading) && <React.Fragment>
            <div className="view-loading"/>
            <CircularProgress className="view-loading-spinner"/>
          </React.Fragment>
        }

        {productFailure && productFailure.errorType === 'GLOBAL_ID_CONFLICT' && globalIdConflictConfirmModal}

        {productFailure && productFailure.errorType === 'MORE_THAN_1_GLOBAL_ID_RESOLVED' && mergeGlobalProductsConfirmModal}

        {productFailure && productFailure.errorType === 'DUPLICATE_COUNTRY_FOUND' && duplicateCountryConfirmModal}

        {productFailure && productFailure.errorType === 'KIND_OF_PRODUCT_CONFLICT' && kindOfProductConfirmModal}

        {productFailure && productFailure.errorType === 'MULTIPLE_KIND_OF_PRODUCT_FOUND' && multipleKindOfProductConfirmModal}

        {productFailure && productFailure.errorType === 'KIND_OF_PRODUCT_NOT_FOUND' && kindOfProductNotFoundConfirmModal}

        {productFailure && productFailure.id && <Space/>}

        {productFailure && productFailure.id &&
        <React.Fragment>
            <Descriptions size="small" column={2} bordered>
                <Descriptions.Item label="ID">
                    <a>{productFailure.id}</a>
                </Descriptions.Item>
                <Descriptions.Item label="Status">
                    <Space>
                      {statusTag((productFailure.status))}
                      {
                        productFailure && productFailure.status == 'UNRESOLVED' &&
                        <Dropdown overlay={menu} trigger={['click']}>
                            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                Actions <DownOutlined/>
                            </a>
                        </Dropdown>
                      }
                    </Space>
                </Descriptions.Item>
              {
                productFailure.globalId && (
                  <Descriptions.Item span={2} label="Global Product Id">{(<a target={"_parent"}
                                                                     href={"/app/products/" + productFailure.globalId}>{productFailure.globalId}</a>)}</Descriptions.Item>
                )
              }
                <Descriptions.Item label="Subsidiary">{productFailure.subsidiary}</Descriptions.Item>
                <Descriptions.Item label="Source system">{productFailure.sourceSystem}</Descriptions.Item>
                <Descriptions.Item label="Error type">{productFailure.errorType && (
                  <Text code>{productFailure.errorType}</Text>)}</Descriptions.Item>
                <Descriptions.Item label="Product Id">{productFailure.productId && (
                  <a>{productFailure.productId}</a>)}</Descriptions.Item>
                <Descriptions.Item
                    label="Create time">{new Date(productFailure.createdAt).toLocaleString()}</Descriptions.Item>
                <Descriptions.Item label="Created by">
                    <a>{productFailure.createdByFullName}</a>
                </Descriptions.Item>
                <Descriptions.Item
                    label="Update time">{new Date(productFailure.updatedAt).toLocaleString()}</Descriptions.Item>
                <Descriptions.Item label="Updated by">
                    <a>{productFailure.updatedByFullName}</a>
                </Descriptions.Item>
                <Descriptions.Item span={2} label="Message">
                    <Text strong>{productFailure.message}<br/></Text>
                  {
                    productFailure && productFailure.errorType === 'GLOBAL_ID_CONFLICT' &&
                    <a href="#GLOBAL_ID_CONFLICT_details">Details</a>
                  }
                  {
                    productFailure && productFailure.errorType === 'MORE_THAN_1_GLOBAL_ID_RESOLVED' &&
                    <a href="#MORE_THAN_1_GLOBAL_ID_RESOLVED_details">Details</a>
                  }
                </Descriptions.Item>
            </Descriptions>
            <br/>
        </React.Fragment>
        }

        {productFailure && productFailure.errorType !== 'GLOBAL_ID_CONFLICT' && productFailure.errorType !== 'PRIMARY_GTIN_CONFLICT' && productFailure.errorType !== 'MORE_THAN_1_GLOBAL_ID_RESOLVED' &&

        <Collapse defaultActiveKey={[]}>
            <Panel header={<span>{'Imported product ' + (productFailure.productId) + ' (' + (productFailure.subsidiary) + ', ' + (productFailure.sourceSystem) + ')'}&nbsp;&nbsp;{productActiveTag(productFailure)}</span>}
                   key={productFailure.productId}>
                <Highlight language="json">
                  {productFailure.productDetails}
                </Highlight>
            </Panel>
        </Collapse>
        }

        {productFailure && productFailure.errorType === 'PRIMARY_GTIN_CONFLICT' &&

        <Row gutter={16} id={"PRIMARY_GTIN_CONFLICT_details"}>
            <Col className="gutter-row" span={12}>
                <Collapse defaultActiveKey={[productFailure.productId]}>
                    <Panel
                        header={<span>{'Imported product ' + (productFailure.productId) + ' (' + (productFailure.subsidiary) + ', ' + (productFailure.sourceSystem) + ')'}&nbsp;&nbsp;{productActiveTag(productFailure)}</span>}
                        key={productFailure.productId}>
                        <Highlight language="json">
                          {productFailure.productDetails}
                        </Highlight>
                    </Panel>
                </Collapse>
            </Col>
            <Col className="gutter-row" span={12}>
                <Collapse defaultActiveKey={[productFailure.globalId]}>
                    <Panel
                        header={'Global Product ' + (!!productFailure.globalId ? productFailure.globalId : '')}
                        key={productFailure.globalId}>
                      {!!productFailure.globalId && <LoadableCoreProductView coreProductId={productFailure.globalId.toString()}/>}
                    </Panel>
                </Collapse>
            </Col>
        </Row>
        }

        {productFailure && productFailure.errorType === 'MORE_THAN_1_GLOBAL_ID_RESOLVED' &&

        <Row gutter={16} id={"MORE_THAN_1_GLOBAL_ID_RESOLVED_details"}>
            <Col className="gutter-row" span={24 / (productFailure.errorTypeDetails.proposedGlobalIds.length + 1)}>
                <Collapse defaultActiveKey={[productFailure.productId]}>
                    <Panel
                        header={<span>{'Imported product ' + (productFailure.productId) + ' (' + (productFailure.subsidiary) + ', ' + (productFailure.sourceSystem) + ')'}&nbsp;&nbsp;{productActiveTag(productFailure)}</span>}
                        key={productFailure.productId}>
                        <Highlight language="json">
                          {productFailure.productDetails}
                        </Highlight>
                    </Panel>
                </Collapse>
            </Col>

          {productFailure.errorTypeDetails.proposedGlobalIds.map((proposedGlobalId, index) => {
            return (
              <Col className="gutter-row" span={24 / (productFailure.errorTypeDetails.proposedGlobalIds.length + 1)}>
                <Collapse defaultActiveKey={[proposedGlobalId]}>
                  <Panel
                    header={(index + 1) + '. Proposed Global Product ' + (proposedGlobalId)}
                    key={proposedGlobalId}>
                    <LoadableCoreProductView coreProductId={proposedGlobalId.toString()}/>
                  </Panel>
                </Collapse>
              </Col>
            )
          })}
        </Row>
        }

        {productFailure && productFailure.errorType === 'GLOBAL_ID_CONFLICT' &&

        <Row gutter={16} id={"GLOBAL_ID_CONFLICT_details"}>
            <Col className="gutter-row" span={8}>
                <Collapse defaultActiveKey={[productFailure.productId]}>
                    <Panel
                        header={<span>{'Imported product ' + (productFailure.productId) + ' (' + (productFailure.subsidiary) + ', ' + (productFailure.sourceSystem) + ')'}&nbsp;&nbsp;{productActiveTag(productFailure)}</span>}
                        key={productFailure.productId}>
                        <Highlight language="json">
                          {productFailure.productDetails}
                        </Highlight>
                    </Panel>
                </Collapse>
            </Col>
            <Col className="gutter-row" span={8}>
                <Collapse defaultActiveKey={[productFailure.errorTypeDetails.globalProductId]}>
                    <Panel
                        header={'Existing Global Product ' + (productFailure.errorTypeDetails.globalProductId)}
                        key={productFailure.errorTypeDetails.globalProductId}>
                        <LoadableCoreProductView coreProductId={productFailure.errorTypeDetails.globalProductId}/>
                    </Panel>
                </Collapse>
            </Col>
            <Col className="gutter-row" span={8}>
                <Collapse defaultActiveKey={[productFailure.errorTypeDetails.proposedGlobalProductId]}>
                    <Panel
                        header={'Proposed Global Product ' + (productFailure.errorTypeDetails.proposedGlobalProductId)}
                        key={productFailure.errorTypeDetails.proposedGlobalProductId}>
                        <LoadableCoreProductView coreProductId={productFailure.errorTypeDetails.proposedGlobalProductId}/>
                    </Panel>
                </Collapse>
            </Col>
        </Row>
        }

      </React.Fragment>
    );
};

export default ProductFailureView;
