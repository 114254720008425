import { Reducer } from "redux";
import {
    FETCH_IMPORT_CLASSIFICATION_PROGRESS,
    FETCH_IMPORT_CLASSIFICATION_PROGRESS_CONTINUES,
    FETCH_IMPORT_CLASSIFICATION_PROGRESS_FAILED,
    FETCH_IMPORT_CLASSIFICATION_PROGRESS_SUCCEEDED,
    ImportProgressActionTypes,
    ImportProgressState
} from "./types";

const initialState: ImportProgressState = {
    productGroups: {
        request: null,
        response: null,
        loading: false,
        error: null
    },
    gpc: {
        request: null,
        response: null,
        loading: false,
        error: null
    }
};

export const importProgressReducer: Reducer<ImportProgressState, ImportProgressActionTypes> = (state: ImportProgressState = initialState, action: ImportProgressActionTypes): ImportProgressState => {
    switch (action.type) {

        case FETCH_IMPORT_CLASSIFICATION_PROGRESS: {
            switch (action.request.importType) {

                case "GPC": {
                    return {
                        ...state,
                        gpc: {
                            request: action.request,
                            response: null,
                            loading: false,
                            error: null
                        }
                    }
                }

                case "PRODUCT_GROUP": {
                    return {
                        ...state,
                        productGroups: {
                            request: action.request,
                            response: null,
                            loading: false,
                            error: null
                        }
                    }
                }

                default:
                    neverReached(action.request.importType);
            }

            throw new Error("Not implemented");
        }

        case FETCH_IMPORT_CLASSIFICATION_PROGRESS_CONTINUES: {
            switch (action.payload.importType) {

                case "GPC": {
                    return {
                        ...state,
                        gpc: {
                            request: state.gpc.request,
                            response: action.payload,
                            loading: false,
                            error: null
                        }
                    }
                }

                case "PRODUCT_GROUP": {
                    return {
                        ...state,
                        productGroups: {
                            request: state.productGroups.request,
                            response: action.payload,
                            loading: false,
                            error: null
                        }
                    }
                }

                default:
                    neverReached(action.payload.importType);
            }

            throw new Error("Not implemented");
        }

        case FETCH_IMPORT_CLASSIFICATION_PROGRESS_SUCCEEDED: {
            switch (action.payload.importType) {

                case "GPC": {
                    return {
                        ...state,
                        gpc: {
                            request: state.gpc.request,
                            response: action.payload,
                            loading: false,
                            error: null
                        }
                    }
                }

                case "PRODUCT_GROUP": {
                    return {
                        ...state,
                        productGroups: {
                            request: state.productGroups.request,
                            response: action.payload,
                            loading: false,
                            error: null
                        }
                    }
                }

                default:
                    neverReached(action.payload.importType);
            }

            throw new Error("Not implemented");
        }

        case FETCH_IMPORT_CLASSIFICATION_PROGRESS_FAILED: {
            switch (action.importType) {

                case "GPC": {
                    return {
                        ...state,
                        gpc: {
                            request: state.gpc.request,
                            response: state.gpc.response,
                            loading: false,
                            error: action.error
                        }
                    }
                }

                case "PRODUCT_GROUP": {
                    return {
                        ...state,
                        productGroups: {
                            request: state.productGroups.request,
                            response: state.productGroups.response,
                            loading: false,
                            error: action.error
                        }
                    }
                }

                default:
                    neverReached(action.importType);
            }

            throw new Error("Not implemented");
        }

        default:
            neverReached(action);
    }

    return state;
};

const neverReached = (never: never) => {
};
