import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import "./PermissionsManagementPage.css";
import { Dispatch } from "redux";
import { AppState, store } from "../../store";
import {flowRight} from "lodash";
import { connect } from 'react-redux';
import { UserSession } from "../../store/userSession/types";
import PageWrapper from "../../components/Drawer/PageWrapper";
import { PageHeader, Tabs } from 'antd';
import * as qs from "qs";
import {
  addUser,
  closeUsersTabErrorMessage,
  fetchGroupsList,
  fetchPermissionsList,
  fetchUsersList
} from "../../store/permissionsManagement/actions";
import { Group, Permission, User } from "../../store/permissionsManagement/types";
import GroupsTabView from "../../components/GroupsTab/GroupsTabView";
import PermissionsTabView from "../../components/PermissionsTab/PermissionsTabView";
import UsersTabView from "../../components/UsersTab/UsersTabView";

const { TabPane } = Tabs;

interface Props extends RouteComponentProps {
  userSession: UserSession;

  fetchGroupsList: () => void;
  fetchPermissionsList: () => void;
  fetchUsersList: () => void;
  addUser: (email: string) => void;

  groups: Group[];
  permissions: Permission[];
  users: User[];

  groupsLoading?: boolean;
  groupsError?: string;

  permissionsLoading?: boolean;
  permissionsError?: string;

  usersLoading?: boolean;
  usersError?: string;
}

interface State {
}

class PermissionsManagementPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.onUsersTabMessageClose = this.onUsersTabMessageClose.bind(this);
    this.onUserDetailsClick = this.onUserDetailsClick.bind(this);
    this.onGroupDetailsClick = this.onGroupDetailsClick.bind(this);
    this.onPermissionDetailsClick = this.onPermissionDetailsClick.bind(this);
    this.onAddGroupClick = this.onAddGroupClick.bind(this);
    this.onAddPermissionClick = this.onAddPermissionClick.bind(this);
  }

  componentDidMount() {

    this.props.fetchGroupsList();
    this.props.fetchPermissionsList();
    this.props.fetchUsersList();
  }

  private onUsersTabMessageClose() {
    store.dispatch(closeUsersTabErrorMessage());
  }

  private onUserDetailsClick(id: number) {
    this.props.history.push("/app/settings/permissions-management/users/" + id);
  }

  private onGroupDetailsClick(id: number) {
    this.props.history.push("/app/settings/permissions-management/groups/" + id);
  }

  private onPermissionDetailsClick(id: number) {
    this.props.history.push("/app/settings/permissions-management/permissions/" + id);
  }

  private onAddGroupClick() {
    this.props.history.push("/app/settings/permissions-management/groups/create");
  }

  private onAddPermissionClick() {
    this.props.history.push("/app/settings/permissions-management/permissions/create");
  }

  public render() {
    const { activeTab } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

    return (
      <div className="permissions-management-page">
        <PageWrapper userSession={this.props.userSession}>
          <PageHeader
            backIcon={null}
            ghost={false}
            onBack={() => window.history.back()}
            title="Permissions Management"
            subTitle="Tool to manage users, groups and permissions"
          >
          </PageHeader>
          <Tabs defaultActiveKey={activeTab} type="card" size={"default"} onChange={(activeKey) => {this.props.history.push("/app/settings/permissions-management?activeTab=" + activeKey);}}>
            <TabPane tab="Users" key="1">
              <UsersTabView
                users={this.props.users}
                onEdit={this.onUserDetailsClick}
                onAdd={this.props.addUser}
                onMessageClose={this.onUsersTabMessageClose}
                loading={this.props.usersLoading}
                error={this.props.usersError}
              />
            </TabPane>
            <TabPane tab="Groups" key="2">
              <GroupsTabView
                groups={this.props.groups}
                onEdit={this.onGroupDetailsClick}
                onAdd={this.onAddGroupClick}
                loading={this.props.groupsLoading}
                error={this.props.groupsError}
              />
            </TabPane>
            <TabPane tab="Permissions" key="3">
              <PermissionsTabView
                permissions={this.props.permissions}
                onEdit={this.onPermissionDetailsClick}
                onAdd={this.onAddPermissionClick}
                loading={this.props.permissionsLoading}
                error={this.props.permissionsError}
              />
            </TabPane>
          </Tabs>
        </PageWrapper>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  userSession: state.userSession.userSession,

  groups: state.permissionsManagement.groups,
  permissions: state.permissionsManagement.permissions,
  users: state.permissionsManagement.users,

  groupsLoading: state.permissionsManagement.groupsLoading,
  groupsError: state.permissionsManagement.groupsError,

  permissionsLoading: state.permissionsManagement.permissionsLoading,
  permissionsError: state.permissionsManagement.permissionsError,

  usersLoading: state.permissionsManagement.usersLoading,
  usersError: state.permissionsManagement.usersError,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchGroupsList: () => {
    dispatch(fetchGroupsList())
  },
  fetchPermissionsList: () => {
    dispatch(fetchPermissionsList())
  },
  fetchUsersList: () => {
    dispatch(fetchUsersList())
  },
  addUser: (email: string) => {
    dispatch(addUser({email}))
  }
});

const enhance = flowRight(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(PermissionsManagementPage);
