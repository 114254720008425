import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import "./FailureListPage.css";
import { Dispatch } from "redux";
import { AppState } from "../../store";
import {flowRight} from "lodash";
import { connect } from 'react-redux';
import { UserSession } from "../../store/userSession/types";
import { Order } from "../../components/EnhancedTable/EnhancedTable";
import PageWrapper from "../../components/Drawer/PageWrapper";
import { PageHeader, Tabs } from 'antd';
import ImporterFailureListView from "../../components/ImporterFailureList/ImporterFailureListView";
import {
  fetchImporterFailureList,
  fetchProductFailureList,
  generateReport,
  retryImporterFailureList,
  retryProductFailureList,
  rejectImporterFailureList,
  rejectProductFailureList
} from "../../store/failures/actions";
import {
  FetchImporterFailuresPageRequest,
  FetchImporterFailuresPageResponse,
  FetchProductFailuresPageRequest,
  FetchProductFailuresPageResponse,
  RetryImporterFailureListRequest,
  RetryProductFailureListRequest,
  RejectImporterFailureListRequest,
  RejectProductFailureListRequest,
} from "../../store/failures/types";
import * as qs from "qs";
import ProductFailureListView from "../../components/ProductFailureList/ProductFailureListView";

const { TabPane } = Tabs;

interface Props extends RouteComponentProps {
  userSession: UserSession;

  importerFailuresPageRequest: FetchImporterFailuresPageRequest;
  importerFailuresPageResponse: FetchImporterFailuresPageResponse;

  productFailuresPageRequest: FetchProductFailuresPageRequest;
  productFailuresPageResponse: FetchProductFailuresPageResponse;

  fetchImporterFailureList: (pageRequest: FetchImporterFailuresPageRequest) => void;
  fetchProductFailureList: (pageRequest: FetchProductFailuresPageRequest) => void;

  generateReport: (pageRequest: FetchProductFailuresPageRequest) => void;
  generateReportLoading?: boolean;

  loading?: boolean;
  error?: string;

  retryImporterFailureList: (retryRequest: RetryImporterFailureListRequest) => void;
  retryProductFailureList: (retryRequest: RetryProductFailureListRequest) => void;

  rejectImporterFailureList: (rejectRequest: RejectImporterFailureListRequest) => void;
  rejectProductFailureList: (rejectRequest: RejectProductFailureListRequest) => void;

  retryLoading: boolean;
  rejectLoading: boolean;
}

interface State {
  selectedImporterFailureIds: number[];
  selectedProductFailureIds: number[];
}

class FailureListPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.onPaginationChanged = this.onPaginationChanged.bind(this);
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.onSortingChanged = this.onSortingChanged.bind(this);
    this.onFilterChanged = this.onFilterChanged.bind(this);
    this.onRetry = this.onRetry.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.onGenerateReport = this.onGenerateReport.bind(this);

    this.state = {
      selectedImporterFailureIds: [],
      selectedProductFailureIds: []
    };
  }

  componentDidMount () {
    const { importStatus, failureStatus, failureType, errorType } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

    this.props.fetchImporterFailureList({
      ... this.props.importerFailuresPageRequest,
      importStatus: importStatus || this.props.importerFailuresPageRequest.importStatus,
      failureType: failureType || this.props.importerFailuresPageRequest.failureType,
    });

    this.props.fetchProductFailureList({
      ... this.props.productFailuresPageRequest,
      failureStatus: failureStatus || this.props.productFailuresPageRequest.failureStatus,
      errorType: errorType || this.props.productFailuresPageRequest.errorType,
    })
  }

  private onPaginationChanged(productFailures: boolean): (page: number, size: number) => void {
    const props = this.props;

    if (productFailures) {
      return (page: number, size: number) => {
        console.log("Page: " + page + ", size: " + size);

        props.fetchProductFailureList({
          ... props.productFailuresPageRequest,
          page,
          size
        })
      }
    } else {
      return (page: number, size: number) => {
        console.log("Page: " + page + ", size: " + size);

        props.fetchImporterFailureList({
          ... props.importerFailuresPageRequest,
          page,
          size
        })
      }
    }
  }

  private onRowClick(productFailures: boolean): (id: number, newTab?: boolean) => void {

    if (productFailures) {
      return (id: number, newTab?: boolean) => {
        if (!!newTab) {
          const href = this.props.history.createHref({pathname: '/app/product-failures/' + id});
          window.open(href);
        } else {
          this.props.history.push("/app/product-failures/" + id);
        }
      }
    } else {
      return (id: number, newTab?: boolean) => {
        if (!!newTab) {
          const href = this.props.history.createHref({ pathname: '/app/importer-failures/' + id });
          window.open(href);
        } else {
          this.props.history.push("/app/importer-failures/" + id);
        }
      }
    }
  }

  private onFilterChanged(productFailures: boolean): (filterField: string, value: string) => void {

    if (productFailures) {
      return (filterField: string, value: string) => {
        console.log("Filter: " + filterField + ", value: " + value);

        this.props.fetchProductFailureList({
          ... this.props.productFailuresPageRequest,
          [filterField]: value
        })
      }
    } else {
      return (filterField: string, value: string) => {
        console.log("Filter: " + filterField + ", value: " + value);

        this.props.fetchImporterFailureList({
          ... this.props.importerFailuresPageRequest,
          [filterField]: value
        })
      }
    }

  }

  private onRetry(productFailures: boolean) {

    if (productFailures) {
      return () => {
        this.props.retryProductFailureList({
          ids: this.state.selectedProductFailureIds
        });
      }
    } else {
      return () => {
        this.props.retryImporterFailureList({
          ids: this.state.selectedImporterFailureIds
        });
      }
    }
  }

  private onReject(productFailures: boolean) {

    if (productFailures) {
      return () => {
        this.props.rejectProductFailureList({
          ids: this.state.selectedProductFailureIds
        });
      }
    } else {
      return () => {
        this.props.rejectImporterFailureList({
          ids: this.state.selectedImporterFailureIds
        });
      }
    }
  }

  private onGenerateReport() {

    this.props.generateReport({
      ... this.props.productFailuresPageRequest
    });
  }

  private onSelectionChanged(productFailures: boolean): (selectedIds: number[]) => void {

    if (productFailures) {
      return (selectedIds: number[]) => {
        this.setState({
          selectedProductFailureIds: selectedIds
        }, () => {
          console.log(selectedIds);
        });
      }
    } else {
      return (selectedIds: number[]) => {
        this.setState({
          selectedImporterFailureIds: selectedIds
        }, () => {
          console.log(selectedIds);
        });
      }
    }
  }

  private onSortingChanged(productFailures: boolean): (orderBy: string, order: Order) => void {

    if (productFailures) {
      return (orderBy: string, order: Order) => {
        console.log("Order by: " + orderBy + ", order: " + order);

        this.props.fetchProductFailureList({
          ... this.props.productFailuresPageRequest,
          sort: {
            field: orderBy,
            direction: order
          }
        });
      }
    } else {
      return (orderBy: string, order: Order) => {
        console.log("Order by: " + orderBy + ", order: " + order);

        this.props.fetchImporterFailureList({
          ... this.props.importerFailuresPageRequest,
          sort: {
            field: orderBy,
            direction: order
          }
        });
      }
    }
  }

  public render() {
    const { activeTab } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

    return (
      <div className="product-imports-page">
        <PageWrapper userSession={this.props.userSession}>
          <PageHeader
            backIcon={null}
            ghost={false}
            onBack={() => window.history.back()}
            title="Failures"
            subTitle="Displays list of failures"
          >
          </PageHeader>
          <Tabs defaultActiveKey={activeTab} type="card" size={"default"}>
            <TabPane tab="Product Failures" key="1">
              <ProductFailureListView
                pageRequest={this.props.productFailuresPageRequest}
                pageResponse={this.props.productFailuresPageResponse}
                onPaginationChanged={this.onPaginationChanged(true)}
                onSelectionChanged={this.onSelectionChanged(true)}
                onSortingChanged={this.onSortingChanged(true)}
                onFilterChanged={this.onFilterChanged(true)}
                loading={this.props.loading || this.props.generateReportLoading}
                generateReportLoading={this.props.generateReportLoading}
                error={this.props.error}
                onRetry={this.state.selectedProductFailureIds.length > 0 && this.onRetry(true)}
                retryLoading={this.props.retryLoading}
                onReject={this.state.selectedProductFailureIds.length > 0 && this.onReject(true)}
                rejectLoading={this.props.rejectLoading}
                onRowClick={this.onRowClick(true)}
                onGenerateReport={this.onGenerateReport}
              />
            </TabPane>
            <TabPane tab="Importer Failures" key="2">
              <ImporterFailureListView
                pageRequest={this.props.importerFailuresPageRequest}
                pageResponse={this.props.importerFailuresPageResponse}
                onPaginationChanged={this.onPaginationChanged(false)}
                onSelectionChanged={this.onSelectionChanged(false)}
                onSortingChanged={this.onSortingChanged(false)}
                onFilterChanged={this.onFilterChanged(false)}
                loading={this.props.loading}
                error={this.props.error}
                onRetry={this.state.selectedImporterFailureIds.length > 0 && this.onRetry(false)}
                retryLoading={this.props.retryLoading}
                onReject={this.state.selectedImporterFailureIds.length > 0 && this.onReject(false)}
                rejectLoading={this.props.rejectLoading}
                onRowClick={this.onRowClick(false)}
              />
            </TabPane>
          </Tabs>
        </PageWrapper>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  userSession: state.userSession.userSession,

  importerFailuresPageRequest: state.failures.importerFailuresPageRequest,
  importerFailuresPageResponse: state.failures.importerFailuresPageResponse,

  productFailuresPageRequest: state.failures.productFailuresPageRequest,
  productFailuresPageResponse: state.failures.productFailuresPageResponse,

  loading: state.failures.loading,
  error: state.failures.error,
  retryLoading: state.retryFailures.retryLoading,
  rejectLoading: state.rejectFailures.rejectLoading,

  generateReportLoading: state.failures.generateReportLoading
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchImporterFailureList: (pageRequest: FetchImporterFailuresPageRequest) => {
    dispatch(fetchImporterFailureList(pageRequest))
  },
  fetchProductFailureList: (pageRequest: FetchProductFailuresPageRequest) => {
    dispatch(fetchProductFailureList(pageRequest))
  },
  generateReport: (pageRequest: FetchProductFailuresPageRequest) => {
    dispatch(generateReport(pageRequest))
  },
  retryImporterFailureList: (retryRequest: RetryImporterFailureListRequest) => {
    dispatch(retryImporterFailureList(retryRequest))
  },
  retryProductFailureList: (retryRequest: RetryProductFailureListRequest) => {
    dispatch(retryProductFailureList(retryRequest))
  },
  rejectImporterFailureList: (rejectRequest: RejectImporterFailureListRequest) => {
    dispatch(rejectImporterFailureList(rejectRequest))
  },
  rejectProductFailureList: (rejectRequest: RejectProductFailureListRequest) => {
    dispatch(rejectProductFailureList(rejectRequest))
  }
});

const enhance = flowRight(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(FailureListPage);
