import * as React from "react";
import "./ProductHistoryView.css"
import EnhancedTable, {Column} from "../EnhancedTable/EnhancedTable";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import {Form, Space, Typography} from 'antd';
import {
  FetchCoreProductChangeRequest,
  FetchCoreProductChangeResponse,
  ProductChangeResponse
} from "../../store/prouctHistory/types";
import SubsidiarySelect from "../inputs/SubsidiarySelect/SubsidiarySelect";

const { Text } = Typography;

export interface Props {
  pageRequest: FetchCoreProductChangeRequest;
  pageResponse: FetchCoreProductChangeResponse;

  onPaginationChanged: (page: number, size: number) => void;
  onFilterChanged?: (filterField: string, value: string) => void;
  onRowClick: (productChangeResponse: ProductChangeResponse) => void;

  loading: boolean;
  error: string;
}

export const ProductHistoryView: React.FunctionComponent<Props> = (props) => {

  const [form] = Form.useForm();

  const formItemLayout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 8 },
  };

  const { pageRequest, pageResponse, onPaginationChanged, onRowClick, onFilterChanged, loading } = props;

  const columns: Column[] = [
    {
      label: 'ID',
      field: 'id',
      type: 'computed',
      computedValue: (info: ProductChangeResponse) => {
        return <Space direction={"horizontal"}>
          {info.historyFilePath ? <a onClick={() => onRowClick(info)}>Show details</a> : <p>No details</p>}
        </Space>
      }
    },
    {
      label: 'Change Type',
      field: 'changeType',
      type: 'computed',
      computedValue: (info: ProductChangeResponse) => {
        return <Text code>{info.changeType}</Text>
      }
    },
    {
      label: 'Product Change Type',
      field: 'productChangeType',
      type: 'computed',
      computedValue: (info: ProductChangeResponse) => {
        return <Text code>{info.productChangeType}</Text>
      }
    },
    {
      label: 'Subsidiary',
      field: 'subsidiary',
      type: 'computed',
      computedValue: (info: ProductChangeResponse) => {
        return <Text code>{info.subsidiary}</Text>
      }
    },
    {
      label: 'Product',
      field: 'productId',
      type: 'computed',
      computedValue: (info: ProductChangeResponse) => {
        return (<a>{info.productId}</a>)
      }
    },
    {
      label: 'Kind of Product',
      field: 'kindOfProduct',
      type: 'computed',
      computedValue: (info: ProductChangeResponse) => {
        return (<a>{info.kindOfProduct}</a>)
      }
    },
    {
      label: 'Modification',
      field: 'updatedAt',
      type: 'computed',
      computedValue: (info: ProductChangeResponse) => {

        return (<a>{new Date(info.updatedAt).toLocaleString()}</a>)
      }
    },
  ];

  return props.error ?
    (
      <div className="view-error">{props.error}</div>
    )
    :
    (
      <React.Fragment>

        {
          loading && <div>
            <div className="view-loading"/>
            <CircularProgress className="view-loading-spinner"/>
          </div>
        }

        <Form
            {...formItemLayout}
            layout={'horizontal'}
            form={form}>

          <Form.Item label="Target Market">
            <SubsidiarySelect id="subsidiary-select"
                              value={pageRequest.subsidiary}
                              onChange={(value) => onFilterChanged('subsidiary', value)}
            />
          </Form.Item>
        </Form>

        <EnhancedTable tableName={"History"}
                       rowsSelectable={false}
                       columns={columns}
                       data={pageResponse.contentItems}
                       totalCount={pageResponse.total}
                       countOnPage={pageResponse.numberOfElements}
                       currentPage={pageRequest.page}
                       pageSize={pageRequest.size}
                       sort={[{field: "updatedAt", direction: "DESC"}]}
                       pagination={true}
                       onPaginationChanged={(page: number, size: number) => {
                         onPaginationChanged(page, size)
                       }}
        />
      </React.Fragment>
    );
};

export default ProductHistoryView;
