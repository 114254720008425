import * as React from "react";
import EnhancedTable, { Column, Order } from "../../EnhancedTable/EnhancedTable";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import {
  FetchManufacturerMPNStatsPageRequest,
  FetchManufacturerMPNStatsPageResponse,
  ManufacturerMPNStats
} from "../../../store/globalProducts/types";
import {Button, Form, Input, PageHeader, Popover, Tooltip} from "antd";


export interface GlobalProductsViewProps {
  manufacturerMPNStatsPageResponse: FetchManufacturerMPNStatsPageResponse;
  manufacturerMPNStatsPageRequest: FetchManufacturerMPNStatsPageRequest;

  onPaginationChanged: (page: number, size: number) => void;
  onSortingChanged?: (orderBy: string, order: Order) => void;
  onFilterChanged?: (filterField: string, value: string | boolean) => void;

  onGenerateReport?: () => void;
  generateReportLoading?: boolean;

  onRowClick?: (id: number) => void;
  loading: boolean;
  error: string;
}

export const ManufacturerMPNStatsView: React.FunctionComponent<GlobalProductsViewProps> = (props) => {

  const [form] = Form.useForm();

  const formItemLayout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 8 },
  };

  const buttonItemLayout = {
    wrapperCol: { span: 8, offset: 2 },
  };

  const {manufacturerMPNStatsPageResponse, manufacturerMPNStatsPageRequest, onPaginationChanged, onSortingChanged, onFilterChanged, onGenerateReport, generateReportLoading, loading } = props;

  const columns: Column[] = [
    {
      label: 'Manufacturer',
      field: 'manufacturerName',
      type: 'computed',
      computedValue: (stats: ManufacturerMPNStats) => {
        return (stats.manufacturerName)
      },
    },
    {
      label: 'Manufacturer ID',
      field: 'manufacturerCompositeId',
      type: 'computed',
      computedValue: (stats: ManufacturerMPNStats) => {
        return (stats.manufacturerCompositeId)
      },
    },
    {
      label: 'MPN',
      field: 'manufacturerPartNumber',
      type: 'computed',
      computedValue: (stats: ManufacturerMPNStats) => {
        return (stats.manufacturerPartNumber)
      },
    },
    {
      label: 'Global Product Ids',
      field: 'globalProducts',
      type: 'computed',
      computedValue: (stats: ManufacturerMPNStats) => {
        return (
          <span>{stats.globalProducts.slice(0, 2).join(', ')}&nbsp;&nbsp;
            {stats.globalProducts.length > 2 &&
            <Popover content={stats.globalProducts.join(', ')} trigger="click">
                <Button>See all</Button>
            </Popover>}
          </span>
        )
      }
    },
    { label: 'Core Products Count', field: 'globalProductsCount' },
    {
      label: 'Legacy Products',
      field: 'productIds',
      type: 'computed',
      computedValue: (stats: ManufacturerMPNStats) => {
        return (
          <span>{stats.productIds.slice(0, 2).join(', ')}&nbsp;&nbsp;
            {stats.productIds.length > 2 &&
            <Popover content={stats.productIds.join(', ')} trigger="click">
                <Button>See all</Button>
            </Popover>}
          </span>
        )
      }
    }
  ];

  const handleGlobalProductsCountChange = (e: any) => {
    const { value } = e.target;
    const reg = /^\d*(\.\d*)?$/;
    if ((!isNaN(value) && reg.test(value)) || value === '') {
      onFilterChanged('globalProductsCount', value)
    }
  };

  return props.error ?
    (
      <div className="view-error">{props.error}</div>
    )
    :
    (
      <React.Fragment>

        {
          loading && (<div>
            <div className="view-loading"/>
            <CircularProgress className="view-loading-spinner"/>
          </div>)
        }

        <PageHeader
            backIcon={null}
            ghost={false}
            onBack={() => window.history.back()}
            title="Manufacturer MPN Statistics"
            subTitle="Displays unique manufacturer MPN values found in different core products"
        >
        </PageHeader>

        <Form
          {...formItemLayout}
          layout={'horizontal'}
          form={form}
        >

          <Form.Item label="Global Product Id">
            <Input id="global-id-input"
                   placeholder="Search by Global Product Id"
                   value={manufacturerMPNStatsPageRequest.globalId}
                   onChange={(value) => onFilterChanged('globalId', value.target.value)}
            />
          </Form.Item>

          <Form.Item label="Product">
            <Input id="legacy-product-id-input"
                   placeholder="Search by product id"
                   value={manufacturerMPNStatsPageRequest.legacyProductId}
                   onChange={(value) => onFilterChanged('legacyProductId', value.target.value)}
            />
          </Form.Item>

          <Form.Item label="Manufacturer">
            <Input id="manufacturer-input"
                   placeholder="Search by manufacturer name or manufacturer id"
                   value={manufacturerMPNStatsPageRequest.manufacturer}
                   onChange={(value) => onFilterChanged('manufacturer', value.target.value)}
            />
          </Form.Item>

          <Form.Item label="MPN">
            <Input id="mpn-input"
                   placeholder="Search by MPN value"
                   value={manufacturerMPNStatsPageRequest.mpn}
                   onChange={(value) => onFilterChanged('mpn', value.target.value)}
            />
          </Form.Item>

          <Form.Item label="Products Count">
            <Input value={manufacturerMPNStatsPageRequest.globalProductsCount}
                   onChange={handleGlobalProductsCountChange}
                   placeholder="Input a number"
                   maxLength={2}
            />
          </Form.Item>

          <Form.Item {...buttonItemLayout}>
            <Tooltip title="Limited to 25000 items">
              <Button type="primary" loading={generateReportLoading} size={"middle"} onClick={onGenerateReport}>Generate
                Report
              </Button>
            </Tooltip>
          </Form.Item>
        </Form>

        <EnhancedTable tableName={"Results"}
                       rowsSelectable={false}
                       columns={columns}
                       data={manufacturerMPNStatsPageResponse.content}
                       totalCount={manufacturerMPNStatsPageResponse.totalElements}
                       countOnPage={manufacturerMPNStatsPageResponse.numberOfElements}
                       currentPage={manufacturerMPNStatsPageRequest.page}
                       pageSize={manufacturerMPNStatsPageRequest.size}
                       sort={[manufacturerMPNStatsPageRequest.sort]}
                       onPaginationChanged={onPaginationChanged}
                       onSortingChanged={onSortingChanged}
        />
      </React.Fragment>
    );
};

export default ManufacturerMPNStatsView;
