import * as React from "react";
import "./Login.css";
import { Button } from "@material-ui/core";
import { resolveApiBaseUrl } from "../../environment";
import * as qs from 'qs';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

const redirectToLogin = () => {
  window.location.href = window.location.href = resolveApiBaseUrl() + "/gateway/login";
};

export default function Login() {

  const queryString = window.location.search;
  const { error_login } = qs.parse(queryString, { ignoreQueryPrefix: true });

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  return (
    <div className="login__page">
      {error_login && (<Alert severity="error" className="login__page__error">{error_login}</Alert>)}
      <div className="login__block">
        <div className="login__block__caption">MMS Core Product Control Panel</div>
        <Button className="login__block__button" variant="contained" color="primary" size="large" onClick={redirectToLogin}>
          Login with FIFA
        </Button>
      </div>
    </div>
  );
}
