import { all, call, put, takeLatest } from 'redux-saga/effects'

import {
  LIST_FLAT_ID_DIRECTORIES,
  ListFlatIdDirectoriesAction,
  LIST_FLAT_ID_FILES,
  ListFlatIdFilesAction,
  DownloadFlatIdFileAction,
  DOWNLOAD_FLAT_ID_FILE,
  PUBLISH_FLAT_ID,
  PublishFlatIdAction
} from './types'
import {
  listFlatIdFilesSucceeded,
  listFlatIdFilesFailed,
  listFlatIdDirectoriesSucceeded,
  listFlatIdDirectoriesFailed,
  downloadFlatIdFileSucceeded,
  downloadFlatIdFileFailed,
  publishFlatIdSucceeded,
  publishFlatIdFailed
} from './actions'

import dataService from '../../services/DataService'
import { message } from "antd";

export function* maintenanceSaga() {
  yield all([
    watchListFlatIdDirectories(),
    watchListFlatIdFiles(),
    watchDownloadFlatIdFile(),
    watchPublishFlatId()
  ])
}

function* watchListFlatIdDirectories() {
  yield takeLatest(LIST_FLAT_ID_DIRECTORIES, handleListFlatIdDirectories)
}

function* watchListFlatIdFiles() {
  yield takeLatest(LIST_FLAT_ID_FILES, handleListFlatIdFiles)
}

function* watchDownloadFlatIdFile() {
  yield takeLatest(DOWNLOAD_FLAT_ID_FILE, handleDownloadFlatIdFile)
}

function* watchPublishFlatId() {
  yield takeLatest(PUBLISH_FLAT_ID, handlePublishFlatId)
}

function* handleListFlatIdDirectories(action: ListFlatIdDirectoriesAction) {
  try {
    const data = yield call([dataService, dataService.listFlatIdDirectories], action.listFlatIdDirectoriesPageRequest);
    yield put(listFlatIdDirectoriesSucceeded(data))
  } catch (error) {
    message.error(error.message);
    yield put(listFlatIdDirectoriesFailed(`${error.message}`))
  }
}

function* handleListFlatIdFiles(action: ListFlatIdFilesAction) {
  try {
    const data = yield call([dataService, dataService.listFlatIdFiles], action.listFlatIdFilesPageRequest);
    yield put(listFlatIdFilesSucceeded(data))
  } catch (error) {
    message.error(error.message);
    yield put(listFlatIdFilesFailed(`${error.message}`))
  }
}

function* handleDownloadFlatIdFile(action: DownloadFlatIdFileAction) {
  try {
    const data = yield call([dataService, dataService.downloadFlatIdFile], action.downloadFlatIdFileRequest);
    yield put(downloadFlatIdFileSucceeded())
  } catch (error) {
    message.error(error.message);
    yield put(downloadFlatIdFileFailed(`${error.message}`))
  }
}

function* handlePublishFlatId(action: PublishFlatIdAction) {
  try {
    const data = yield call([dataService, dataService.publishFlatId]);
    yield put(publishFlatIdSucceeded())
  } catch (error) {
    message.error(error.message);
    yield put(publishFlatIdFailed(`${error.message}`))
  }
}