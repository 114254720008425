import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import "./ImportGpcClassificationPage.css";
import { Dispatch } from "redux";
import { AppState, store } from "../../store";
import {flowRight} from "lodash";
import { connect } from 'react-redux';
import { UserSession } from "../../store/userSession/types";
import PageWrapper from "../../components/Drawer/PageWrapper";
import { PageHeader } from "antd";
import { ImportGpcClassification } from "../../store/classifications/gpc/types";
import { closeGpcClassificationErrorMessage, importGpcClassification } from "../../store/classifications/gpc/actions";
import ImportGpcClassificationView from "../../components/ImportGpcClassification/ImportGpcClassificationView";
import importResponseService from '../../services/ImportResponseService'
import { ImportProgressResponse } from "../../store/classifications/importProgress/types";
import { fetchImportProgress } from "../../store/classifications/importProgress/actions";

interface Props extends RouteComponentProps {
  userSession: UserSession;
  importClassificationRequest: ImportGpcClassification;
  importClassification: (request: ImportGpcClassification) => void;
  importRunning: boolean;
  importProgress: number;
  loading?: boolean;
  error?: string;
}

class ImportGpcClassificationPage extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    this.onFileUpload = this.onFileUpload.bind(this);
  }

  componentDidMount() {
    importResponseService.getRunningImport('GPC').then((runningImport: ImportProgressResponse) => {

      if (!!runningImport && (runningImport.importId && runningImport.importType)) {
        store.dispatch(fetchImportProgress({
          importId: runningImport.importId,
          importType: runningImport.importType
        }));
      }

    }).catch(error => {
      console.error(error);
    })
  }

  private static onMessageClose() {
    store.dispatch(closeGpcClassificationErrorMessage());
  }

  private onFileUpload(file: File) {
    this.props.importClassification({
      file
    });
  }

  public render() {
    return (
      <div className="import-gpc-classification-page">
        <PageWrapper userSession={this.props.userSession}>
          <PageHeader
            backIcon={null}
            ghost={false}
            onBack={() => window.history.back()}
            title="Import GPC classification"
            subTitle="Imports GPC classification XML file"
          >
          </PageHeader>
          <ImportGpcClassificationView
            onMessageClose={ImportGpcClassificationPage.onMessageClose}
            onFileUpload={this.onFileUpload}
            importRunning={this.props.importRunning}
            importProgress={this.props.importProgress}
            error={this.props.error}
            loading={this.props.loading}
          />
        </PageWrapper>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  userSession: state.userSession.userSession,
  importClassificationRequest: state.gpcClassification.importClassification,
  importRunning: !!state.classificationImportProgress.gpc.response,
  importProgress: state.classificationImportProgress.gpc.response && state.classificationImportProgress.gpc.response.progress,
  loading: state.gpcClassification.loading || state.classificationImportProgress.gpc.loading,
  error: state.gpcClassification.error || state.classificationImportProgress.gpc.error,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  importClassification: (request: ImportGpcClassification) => {
    dispatch(importGpcClassification(request))
  },
});

const enhance = flowRight(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(ImportGpcClassificationPage);
