import {
  ADD_USER,
  ADD_USER_FAILED,
  ADD_USER_SUCCEEDED,
  AddUserRequest,
  AddUserResponse,
  CLOSE_USERS_TAB_ERROR_MESSAGE,
  CREATE_GROUP,
  CREATE_GROUP_FAILED,
  CREATE_GROUP_SUCCEEDED,
  CREATE_PERMISSION,
  CREATE_PERMISSION_FAILED,
  CREATE_PERMISSION_SUCCEEDED,
  CreateGroupRequest,
  CreateGroupResponse,
  CreatePermissionRequest,
  CreatePermissionResponse,
  DELETE_USER,
  DELETE_USER_SUCCEEDED,
  DELETE_USER_FAILED,
  DeleteUserRequest,
  DeleteUserResponse,
  FETCH_GROUP,
  FETCH_GROUP_FAILED,
  FETCH_GROUP_SUCCEEDED,
  FETCH_GROUPS_LIST,
  FETCH_GROUPS_LIST_FAILED,
  FETCH_GROUPS_LIST_SUCCEEDED,
  FETCH_PERMISSION,
  FETCH_PERMISSION_FAILED,
  FETCH_PERMISSION_SUCCEEDED,
  FETCH_PERMISSIONS_LIST,
  FETCH_PERMISSIONS_LIST_FAILED,
  FETCH_PERMISSIONS_LIST_SUCCEEDED,
  FETCH_USER,
  FETCH_USER_FAILED,
  FETCH_USER_SUCCEEDED,
  FETCH_USERS_LIST,
  FETCH_USERS_LIST_FAILED,
  FETCH_USERS_LIST_SUCCEEDED,
  FetchGroupRequest,
  FetchGroupResponse,
  FetchGroupsListResponse,
  FetchPermissionRequest,
  FetchPermissionResponse,
  FetchPermissionsListResponse,
  FetchUserRequest,
  FetchUserResponse,
  FetchUsersListResponse,
  PermissionsManagementActionTypes,
  REFRESH_USER,
  REFRESH_USER_SUCCEEDED,
  REFRESH_USER_FAILED,
  RefreshUserRequest,
  RefreshUserResponse,
  UPDATE_GROUP,
  UPDATE_GROUP_FAILED,
  UPDATE_GROUP_SUCCEEDED,
  UPDATE_PERMISSION,
  UPDATE_PERMISSION_FAILED,
  UPDATE_PERMISSION_SUCCEEDED,
  UPDATE_USER,
  UPDATE_USER_FAILED,
  UPDATE_USER_SUCCEEDED,
  UpdateGroupRequest,
  UpdateGroupResponse,
  UpdatePermissionRequest,
  UpdatePermissionResponse,
  UpdateUserRequest,
  UpdateUserResponse,
} from './types'

export function fetchGroupsList(): PermissionsManagementActionTypes {
  return {
    type: FETCH_GROUPS_LIST,
  }
}

export function fetchGroupsListSucceeded(payload: FetchGroupsListResponse): PermissionsManagementActionTypes {
  return {
    type: FETCH_GROUPS_LIST_SUCCEEDED,
    payload
  }
}

export function fetchGroupsListFailed(error: string): PermissionsManagementActionTypes {
  return {
    type: FETCH_GROUPS_LIST_FAILED,
    error
  }
}


export function fetchPermissionsList(): PermissionsManagementActionTypes {
  return {
    type: FETCH_PERMISSIONS_LIST,
  }
}

export function fetchPermissionsListSucceeded(payload: FetchPermissionsListResponse): PermissionsManagementActionTypes {
  return {
    type: FETCH_PERMISSIONS_LIST_SUCCEEDED,
    payload
  }
}

export function fetchPermissionsListFailed(error: string): PermissionsManagementActionTypes {
  return {
    type: FETCH_PERMISSIONS_LIST_FAILED,
    error
  }
}


export function fetchUsersList(): PermissionsManagementActionTypes {
  return {
    type: FETCH_USERS_LIST,
  }
}

export function fetchUsersListSucceeded(payload: FetchUsersListResponse): PermissionsManagementActionTypes {
  return {
    type: FETCH_USERS_LIST_SUCCEEDED,
    payload
  }
}

export function fetchUsersListFailed(error: string): PermissionsManagementActionTypes {
  return {
    type: FETCH_USERS_LIST_FAILED,
    error
  }
}


export function addUser(payload: AddUserRequest): PermissionsManagementActionTypes {
  return {
    type: ADD_USER,
    payload
  }
}

export function addUserSucceeded(payload: AddUserResponse): PermissionsManagementActionTypes {
  return {
    type: ADD_USER_SUCCEEDED,
    payload
  }
}

export function addUserFailed(error: string): PermissionsManagementActionTypes {
  return {
    type: ADD_USER_FAILED,
    error
  }
}

export function closeUsersTabErrorMessage(): PermissionsManagementActionTypes {
  return {
    type: CLOSE_USERS_TAB_ERROR_MESSAGE,
  }
}


export function fetchUser(payload: FetchUserRequest): PermissionsManagementActionTypes {
  return {
    type: FETCH_USER,
    payload
  }
}

export function fetchUserSucceeded(payload: FetchUserResponse): PermissionsManagementActionTypes {
  return {
    type: FETCH_USER_SUCCEEDED,
    payload
  }
}

export function fetchUserFailed(error: string): PermissionsManagementActionTypes {
  return {
    type: FETCH_USER_FAILED,
    error
  }
}

export function updateUser(payload: UpdateUserRequest): PermissionsManagementActionTypes {
  return {
    type: UPDATE_USER,
    payload
  }
}

export function updateUserSucceeded(payload: UpdateUserResponse): PermissionsManagementActionTypes {
  return {
    type: UPDATE_USER_SUCCEEDED,
    payload
  }
}

export function updateUserFailed(error: string): PermissionsManagementActionTypes {
  return {
    type: UPDATE_USER_FAILED,
    error
  }
}

export function refreshUser(payload: RefreshUserRequest): PermissionsManagementActionTypes {
  return {
    type: REFRESH_USER,
    payload
  }
}

export function refreshUserSucceeded(payload: RefreshUserResponse): PermissionsManagementActionTypes {
  return {
    type: REFRESH_USER_SUCCEEDED,
    payload
  }
}

export function refreshUserFailed(error: string): PermissionsManagementActionTypes {
  return {
    type: REFRESH_USER_FAILED,
    error
  }
}

export function deleteUser(payload: DeleteUserRequest): PermissionsManagementActionTypes {
  return {
    type: DELETE_USER,
    payload
  }
}

export function deleteUserSucceeded(payload: DeleteUserResponse): PermissionsManagementActionTypes {
  return {
    type: DELETE_USER_SUCCEEDED,
    payload
  }
}

export function deleteUserFailed(error: string): PermissionsManagementActionTypes {
  return {
    type: DELETE_USER_FAILED,
    error
  }
}

export function fetchGroup(payload: FetchGroupRequest): PermissionsManagementActionTypes {
  return {
    type: FETCH_GROUP,
    payload
  }
}

export function fetchGroupSucceeded(payload: FetchGroupResponse): PermissionsManagementActionTypes {
  return {
    type: FETCH_GROUP_SUCCEEDED,
    payload
  }
}

export function fetchGroupFailed(error: string): PermissionsManagementActionTypes {
  return {
    type: FETCH_GROUP_FAILED,
    error
  }
}

export function updateGroup(payload: UpdateGroupRequest): PermissionsManagementActionTypes {
  return {
    type: UPDATE_GROUP,
    payload
  }
}

export function updateGroupSucceeded(payload: UpdateGroupResponse): PermissionsManagementActionTypes {
  return {
    type: UPDATE_GROUP_SUCCEEDED,
    payload
  }
}

export function updateGroupFailed(error: string): PermissionsManagementActionTypes {
  return {
    type: UPDATE_GROUP_FAILED,
    error
  }
}

export function createGroup(payload: CreateGroupRequest): PermissionsManagementActionTypes {
  return {
    type: CREATE_GROUP,
    payload
  }
}

export function createGroupSucceeded(payload: CreateGroupResponse): PermissionsManagementActionTypes {
  return {
    type: CREATE_GROUP_SUCCEEDED,
    payload
  }
}

export function createGroupFailed(error: string): PermissionsManagementActionTypes {
  return {
    type: CREATE_GROUP_FAILED,
    error
  }
}

export function fetchPermission(payload: FetchPermissionRequest): PermissionsManagementActionTypes {
  return {
    type: FETCH_PERMISSION,
    payload
  }
}

export function fetchPermissionSucceeded(payload: FetchPermissionResponse): PermissionsManagementActionTypes {
  return {
    type: FETCH_PERMISSION_SUCCEEDED,
    payload
  }
}

export function fetchPermissionFailed(error: string): PermissionsManagementActionTypes {
  return {
    type: FETCH_PERMISSION_FAILED,
    error
  }
}

export function createPermission(payload: CreatePermissionRequest): PermissionsManagementActionTypes {
  return {
    type: CREATE_PERMISSION,
    payload
  }
}

export function createPermissionSucceeded(payload: CreatePermissionResponse): PermissionsManagementActionTypes {
  return {
    type: CREATE_PERMISSION_SUCCEEDED,
    payload
  }
}

export function createPermissionFailed(error: string): PermissionsManagementActionTypes {
  return {
    type: CREATE_PERMISSION_FAILED,
    error
  }
}

export function updatePermission(payload: UpdatePermissionRequest): PermissionsManagementActionTypes {
  return {
    type: UPDATE_PERMISSION,
    payload
  }
}

export function updatePermissionSucceeded(payload: UpdatePermissionResponse): PermissionsManagementActionTypes {
  return {
    type: UPDATE_PERMISSION_SUCCEEDED,
    payload
  }
}

export function updatePermissionFailed(error: string): PermissionsManagementActionTypes {
  return {
    type: UPDATE_PERMISSION_FAILED,
    error
  }
}
