import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import "./GlobalProductsPage.css";
import { Dispatch, compose } from "redux";
import { AppState } from "../../store";
import { connect } from 'react-redux';
import { UserSession } from "../../store/userSession/types";
import { Order } from "../../components/EnhancedTable/EnhancedTable";
import PageWrapper from "../../components/Drawer/PageWrapper";
import { PageHeader } from 'antd';
import { FetchProductsPageRequest, FetchProductsPageResponse } from "../../store/globalProducts/types";
import GlobalProductView from "../../components/GlobalProducts/GlobalProductsView";
import { fetchProducts } from "../../store/globalProducts/actions";
import {flowRight} from "lodash";

interface Props extends RouteComponentProps {
  userSession: UserSession;
  pageRequest: FetchProductsPageRequest;
  pageResponse: FetchProductsPageResponse;
  fetchProducts: (pageRequest: FetchProductsPageRequest) => void;
  loading?: boolean;
  error?: string;
}

interface State {
  selectedIds: string[];
}

class GlobalProductsPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.onPaginationChanged = this.onPaginationChanged.bind(this);
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.onSortingChanged = this.onSortingChanged.bind(this);
    this.onFilterChanged = this.onFilterChanged.bind(this);
    this.onSearchChanged = this.onSearchChanged.bind(this);
    this.onRowClick = this.onRowClick.bind(this);

    this.state = {
      selectedIds: []
    };
  }

  componentDidMount () {
    this.props.fetchProducts(this.props.pageRequest)
  }

  private onRowClick(id: number) {
    this.props.history.push("/app/products/" + id);
  }

  private onPaginationChanged(page: number, size: number) {
    this.props.fetchProducts({
      ... this.props.pageRequest,
      page,
      size
    })
  }

  private onFilterChanged(filterField: string, value: string) {
    console.log("Filter: " + filterField + ", value: " + value);

    this.props.fetchProducts({
      ...this.props.pageRequest,
      [filterField]: value
    });
  }

  private onSearchChanged(search: string) {
    console.log("Search: " + search);

    this.props.fetchProducts({
      ... this.props.pageRequest,
      search
    })
  }

  private onSelectionChanged(selectedIds: string[]) {
    this.setState({
      selectedIds
    }, () => {
      console.log(selectedIds);
    });

  }

  private onSortingChanged(orderBy: string, order: Order) {
    console.log("Order by: " + orderBy + ", order: " + order);

    this.props.fetchProducts({
      ... this.props.pageRequest,
      sort: {
        field: orderBy,
        direction: order
      }
    });
  }

  public render() {
    return (
      <div className="products-page">
        <PageWrapper userSession={this.props.userSession}>
          <PageHeader
            backIcon={null}
            ghost={false}
            onBack={() => window.history.back()}
            title="Core Products"
            subTitle="Displays core products with assigned global product id and connected subsidiary products"
          >
          </PageHeader>
          <GlobalProductView
            pageRequest={this.props.pageRequest}
            pageResponse={this.props.pageResponse}
            onPaginationChanged={this.onPaginationChanged}
            onSelectionChanged={this.onSelectionChanged}
            onSortingChanged={this.onSortingChanged}
            onFilterChanged={this.onFilterChanged}
            onSearchChanged={this.onSearchChanged}
            loading={this.props.loading}
            error={this.props.error}
            onRowClick={this.onRowClick}
          />
        </PageWrapper>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  userSession: state.userSession.userSession,

  pageRequest: state.products.pageRequest,
  pageResponse: state.products.pageResponse,

  loading: state.products.loading,
  error: state.products.error,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchProducts: (pageRequest: FetchProductsPageRequest) => {
    dispatch(fetchProducts(pageRequest))
  }
});

const enhance = flowRight(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(GlobalProductsPage);
