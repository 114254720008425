import { all, call, put, takeLatest } from 'redux-saga/effects'

import {
  ADD_PRODUCT,
  ADD_PRODUCTS,
  AddProductAction,
  AddProductResponse,
  AddProductsAction,
  FETCH_CORE_PRODUCT,
  FETCH_DUPLICATED_PRODUCTS,
  FETCH_MANUFACTURER_MPN_STATS,
  FETCH_AMBIGUOUS_MPN_STATS,
  FETCH_PRODUCTS,
  FetchCoreProductAction,
  FetchDuplicatedProductsAction,
  FetchManufacturerMPNStatsAction,
  FetchProductsAction,
  GENERATE_DUPLICATED_PRODUCTS_REPORT,
  GENERATE_MPN_STATS_REPORT,
  UPDATE_PRIMARY_PRODUCT,
  UpdatePrimaryProductAction,
  UpdatePrimaryProductRequest,
  FetchAmbiguousMPNStatsAction,
} from './types'
import {
  addProductFailed, addProductsFailed, addProductsSucceeded,
  addProductSucceeded,
  fetchAmbiguousMPNStatsFailed,
  fetchAmbiguousMPNStatsSucceeded,
  fetchCoreProduct,
  fetchCoreProductFailed,
  fetchCoreProductSucceeded,
  fetchDuplicatedProductsFailed,
  fetchDuplicatedProductsSucceeded,
  fetchManufacturerMPNStatsFailed,
  fetchManufacturerMPNStatsSucceeded,
  fetchProductsFailed,
  fetchProductsSucceeded,
  generateDuplicatedProductsReportFailed,
  generateDuplicatedProductsReportSucceeded,
  generateMPNStatsReportFailed,
  generateMPNStatsReportSucceeded, updatePrimaryProductFailed, updatePrimaryProductSucceeded,
} from './actions'

import dataService from '../../services/DataService'
import { message } from "antd";
import * as React from "react";

export function* productsSaga() {
  yield all([
    watchAddProduct(),
    watchAddProducts(),
    watchFetchProducts(),
    watchFetchDuplicatedProducts(),
    watchGenerateDuplicatedProductsReport(),
    watchFetchManufacturerMPNStats(),
    watchFetchAmbiguousMPNStats(),
    watchGenerateManufacturerMPNStatsReport(),
    watchFetchCoreProduct(),
    watchUpdatePrimaryProduct()
  ])
}

function* watchAddProduct() {
  yield takeLatest(ADD_PRODUCT, handleAddProduct)
}

function* watchAddProducts() {
  yield takeLatest(ADD_PRODUCTS, handleAddProducts)
}

function* watchFetchProducts() {
  yield takeLatest(FETCH_PRODUCTS, handleFetchProducts)
}

function* watchFetchDuplicatedProducts() {
  yield takeLatest(FETCH_DUPLICATED_PRODUCTS, handleFetchDuplicatedProducts)
}

function* watchGenerateDuplicatedProductsReport() {
  yield takeLatest(GENERATE_DUPLICATED_PRODUCTS_REPORT, handleGenerateDuplicatedProductsReport)
}

function* watchFetchManufacturerMPNStats() {
  yield takeLatest(FETCH_MANUFACTURER_MPN_STATS, handleFetchManufacturerMPNStats)
}

function* watchFetchAmbiguousMPNStats() {
  yield takeLatest(FETCH_AMBIGUOUS_MPN_STATS, handleFetchAmbiguousMPNStats)
}

function* watchGenerateManufacturerMPNStatsReport() {
  yield takeLatest(GENERATE_MPN_STATS_REPORT, handleGenerateManufacturerMPNStatsReport)
}

function* watchFetchCoreProduct() {
  yield takeLatest(FETCH_CORE_PRODUCT, handleFetchCoreProduct)
}

function* watchUpdatePrimaryProduct() {
  yield takeLatest(UPDATE_PRIMARY_PRODUCT, handleUpdatePrimaryProduct)
}

function* handleAddProduct(action: AddProductAction) {
  try {
    const data: AddProductResponse = yield call([dataService, dataService.addProduct], action.addProductRequest);

    message.success((<span>Imported Core Product <a href={"/app/products/" + data.globalId}><span>{data.globalId}</span></a></span>), 5);
    yield put(addProductSucceeded(data));
  } catch (error) {
    message.error(error.response.data.title);
    yield put(addProductFailed(`${error.response.data.detail}`))
  }
}

function* handleAddProducts(action: AddProductsAction) {
  try {
    yield call([dataService, dataService.addProducts], action.addProductsRequest);

    message.success((<span>Core Products import has been triggered</span>), 5);
    yield put(addProductsSucceeded());
  } catch (error) {
    message.error(error.response.data.title);
    yield put(addProductsFailed(`${error.response.data.detail}`))
  }
}

function* handleFetchProducts(action: FetchProductsAction) {
  try {
    const data = yield call([dataService, dataService.findProducts], action.pageRequest);
    yield put(fetchProductsSucceeded(data))
  } catch (error) {
    message.error(error.message);
    yield put(fetchProductsFailed(`${error.message}`))
  }
}

function* handleFetchDuplicatedProducts(action: FetchDuplicatedProductsAction) {
  try {
    const data = yield call([dataService, dataService.findDuplicatedProductsOrGenerateReport], action.pageRequest, false);
    yield put(fetchDuplicatedProductsSucceeded(data))
  } catch (error) {
    message.error(error.message);
    yield put(fetchDuplicatedProductsFailed(`${error.message}`))
  }
}

function* handleGenerateDuplicatedProductsReport(action: FetchDuplicatedProductsAction) {
  try {
    yield call([dataService, dataService.findDuplicatedProductsOrGenerateReport], action.pageRequest, true);
    yield put(generateDuplicatedProductsReportSucceeded())
  } catch (error) {
    message.error(error.message);
    yield put(generateDuplicatedProductsReportFailed(`${error.message}`))
  }
}

function* handleFetchManufacturerMPNStats(action: FetchManufacturerMPNStatsAction) {
  try {
    const data = yield call([dataService, dataService.findManufacturerMPNStatsOrGenerateReport], action.manufacturerMPNStatsPageRequest, false);
    yield put(fetchManufacturerMPNStatsSucceeded(data))
  } catch (error) {
    message.error(error.message);
    yield put(fetchManufacturerMPNStatsFailed(`${error.message}`))
  }
}

function* handleGenerateManufacturerMPNStatsReport(action: FetchManufacturerMPNStatsAction) {
  try {
    yield call([dataService, dataService.findManufacturerMPNStatsOrGenerateReport], action.manufacturerMPNStatsPageRequest, true);
    yield put(generateMPNStatsReportSucceeded())
  } catch (error) {
    message.error(error.message);
    yield put(generateMPNStatsReportFailed(`${error.message}`))
  }
}

function* handleFetchAmbiguousMPNStats(action: FetchAmbiguousMPNStatsAction) {
  try {
    const data = yield call([dataService, dataService.findAmbiguousMPNStats], action.ambiguousMPNStatsPageRequest);
    yield put(fetchAmbiguousMPNStatsSucceeded(data))
  } catch (error) {
    message.error(error.message);
    yield put(fetchAmbiguousMPNStatsFailed(`${error.message}`))
  }
}

function* handleFetchCoreProduct(action: FetchCoreProductAction) {
  try {
    const data = yield call([dataService, dataService.fetchCoreProduct], action.id);
    yield put(fetchCoreProductSucceeded(data))
  } catch (error) {
    message.error(error.message);
    yield put(fetchCoreProductFailed(`${error.message}`))
  }
}

function* handleUpdatePrimaryProduct(action: UpdatePrimaryProductAction) {
  try {
    const updatePrimaryRequest: UpdatePrimaryProductRequest = action.updatePrimaryProductRequest;
    yield call([dataService, dataService.updatePrimaryProduct], updatePrimaryRequest);
    yield put(fetchCoreProduct(updatePrimaryRequest.globalId?.toString()));
    yield put(updatePrimaryProductSucceeded())
  } catch (error) {
    message.error(error.message);
    yield put(updatePrimaryProductFailed(`${error.message}`))
  }
}
