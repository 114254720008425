import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import "./CoreProductPage.css";
import { Dispatch } from "redux";
import { AppState } from "../../store";
import {flowRight} from "lodash";
import { connect } from 'react-redux';
import { UserSession } from "../../store/userSession/types";
import PageWrapper from "../../components/Drawer/PageWrapper";
import { PageHeader } from 'antd';
import { CoreProductView } from "../../components/CoreProduct/CoreProductView";
import {GlobalProduct, ImportProduct, UpdatePrimaryProductRequest} from "../../store/globalProducts/types";
import { fetchCoreProduct, updatePrimaryProduct } from "../../store/globalProducts/actions";
import { ProductSourceSystem, Subsidiary } from "../../store/common/types";

interface Props extends RouteComponentProps<{id: string}> {
  userSession: UserSession;
  coreProductId: string;
  coreProduct: GlobalProduct;
  fetchCoreProduct: (id: string) => void;
  updatePrimaryProduct: (updatePrimaryProductRequest: UpdatePrimaryProductRequest) => void;
  loading?: boolean;
  error?: string;
}

interface State {
}

class CoreProductPage extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.updatePrimaryProduct = this.updatePrimaryProduct.bind(this);
  }

  componentDidMount () {
    this.props.fetchCoreProduct(this.props.match.params.id)
  }

  private updatePrimaryProduct(globalProductId: number, legacyProduct: ImportProduct) {
    this.props.updatePrimaryProduct({
      globalId: globalProductId,
      productId: legacyProduct.productId,
      subsidiary: legacyProduct.subsidiary as Subsidiary,
      sourceSystem: legacyProduct.sourceSystem as ProductSourceSystem
    });
  }

  public render() {

    return (
      <div className="core-product-page">
        <PageWrapper userSession={this.props.userSession}>
          <PageHeader
            ghost={false}
            onBack={() => window.history.back()}
            title="Core Product"
            subTitle={"Details of the core product"}
          >
          </PageHeader>
          <CoreProductView
            coreProduct={this.props.coreProduct}
            updatePrimaryProduct={this.updatePrimaryProduct}
            loading={this.props.loading}
            error={this.props.error}
          />
        </PageWrapper>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  userSession: state.userSession.userSession,

  coreProductId: state.products.coreProductId,
  coreProduct: state.products.coreProduct,

  loading: state.products.loading,
  error: state.products.error
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchCoreProduct: (id: string) => {
    dispatch(fetchCoreProduct(id))
  },
  updatePrimaryProduct: (updatePrimaryProductRequest: UpdatePrimaryProductRequest) => {
    dispatch(updatePrimaryProduct(updatePrimaryProductRequest))
  }
});

const enhance = flowRight(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(CoreProductPage);
