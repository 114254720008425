import * as React from "react";
import "./GroupDetailsView.css"
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import { Alert, Button, Descriptions, Divider, Form, Input, Select, Space, Switch, Tag, Typography } from 'antd';
import { Group, Permission } from "../../store/permissionsManagement/types";

const { Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

export interface Props {
  group: Group;
  permissions: Permission[];
  onGroupCreate: (name: string, description: string, permissionIds: number[], active: boolean) => void;
  onGroupUpdate: (id: number, name: string, description: string, permissionIds: number[], active: boolean) => void;
  loading: boolean;
  error: string;
}

export const GroupDetailsView: React.FunctionComponent<Props> = (props) => {

  const { group, permissions, onGroupCreate, onGroupUpdate, loading, error } = props;

  const [form] = Form.useForm();

  const formItemLayout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 10 },
  };

  const buttonItemLayout = {
    wrapperCol: { span: 10, offset: 3 },
  };

  const statusTag = (isActive: boolean) => {
    return (isActive ? (<Tag color="success">ACTIVE</Tag>) : (<Tag color="error">DISABLED</Tag>));
  };

  React.useEffect(() => {

    if (!!group) {

      form.setFieldsValue({
        "name": group.name,
        "description": group.description,
        "permissionIds": group.permissions.map(permission => permission.id),
        "active": group.active
      })
    } else {

      form.setFieldsValue({
        "name": null,
        "description": null,
        "permissionIds": [],
        "active": false
      })
    }

  }, [!!group ? group.id : 0]);

  const onSubmit = () => {

    const name = form.getFieldValue("name");
    const description = form.getFieldValue("description");
    const selectedPermissions = form.getFieldValue("permissionIds");
    const isActive = form.getFieldValue("active");

    if (!!group) {
      onGroupUpdate(group.id, name, description, selectedPermissions, isActive);
    } else {
      onGroupCreate(name, description, selectedPermissions, isActive);
    }
  };

  return <React.Fragment>

    {
      loading && (<div>
        <div className="view-loading"/>
        <CircularProgress className="view-loading-spinner"/>
      </div>)
    }

    {
      error && (<React.Fragment>
        <Alert
          message="Error"
          description={error}
          type="error"
        />
        <br/>
      </React.Fragment>)
    }

    {
      !!group &&
      <Descriptions size="small" column={2} bordered>
          <Descriptions.Item label="ID">
              <a>{group.id}</a>
          </Descriptions.Item>
          <Descriptions.Item label="Status">
              <Space>
                {statusTag(group.active)}
              </Space>
          </Descriptions.Item>
          <Descriptions.Item label="Name"><Text strong={true}>{group.name}</Text>
          </Descriptions.Item>
          <Descriptions.Item label="Description">{group.description}
          </Descriptions.Item>
          <Descriptions.Item label="Permissions" span={2}><Space>{group.permissions.map(permission => {
            return <Text code>{permission.operation + "." + permission.domain + (!!permission.domainId ? ("." + permission.domainId) : "")}</Text>;
          })}</Space>
          </Descriptions.Item>
          <Descriptions.Item label="Users" span={2}>
              <Space size={"large"}>
                {group.users.map(user => {
                  return <a href={"/app/settings/permissions-management/users/" + user.id}>{user.fullName}</a>;
                })}
              </Space>
          </Descriptions.Item>
          <Descriptions.Item
              label="Created">{new Date(group.createdAt).toLocaleString()}
          </Descriptions.Item>
          <Descriptions.Item
              label="Created By">{group.createdByFullName}
          </Descriptions.Item>
          <Descriptions.Item
              label="Modified">{new Date(group.updatedAt).toLocaleString()}
          </Descriptions.Item>
          <Descriptions.Item
              label="Modified By">{group.updatedByFullName}
          </Descriptions.Item>
      </Descriptions>
    }
    {
      !!group && <React.Fragment><br/><Divider orientation={"left"}>
          Edit Group
      </Divider><br/></React.Fragment>
    }
    <Form
      {...formItemLayout}
      layout={'horizontal'}
      form={form}
      onFinish={onSubmit}
    >

      <Form.Item label="Name"
                 name={"name"}
                 rules={[{ required: true, message: 'Please enter name' }]}>
        <Input
          placeholder="Enter name..."
        />
      </Form.Item>

      <Form.Item label="Description"
                 name={"description"}
                 rules={[{ required: true, message: 'Please enter description' }]}>
        <TextArea
          rows={4}
          placeholder="Enter description..."
        />
      </Form.Item>

      <Form.Item label="Group Permissions"
                 name={"permissionIds"}
                 rules={[{ required: true, message: 'Please select permissions' }]}>
        <Select
          mode="multiple"
          size={"large"}
          placeholder="Please select permissions..."
          style={{ width: '100%' }}
        >
          {permissions.map(permission => {
            return (<Option key={permission.id}
                            value={permission.id}>{permission.operation + "." + permission.domain + (!!permission.domainId ? "." + permission.domainId : "")}</Option>)
          })}
        </Select>
      </Form.Item>

      <Form.Item label="Active"
                 name={"active"}
                 valuePropName="checked">
        <Switch />
      </Form.Item>

      <Form.Item {...buttonItemLayout}>
        <Button type="primary" htmlType="submit">
          {!!group ? "Update" : "Create"}
        </Button>
      </Form.Item>
    </Form>

  </React.Fragment>
};

export default GroupDetailsView;
