import * as React from "react";
import {useEffect, useState} from "react";
import {LoadableSelect, LoadableSelectItem} from "./LoadableSelect";
import {Alert} from "antd";
import MappingService from "../../services/MappingService";
import {MappingSourceType} from "../../store/common/types";

export interface Props {
    value?: number;
    mappingSource: MappingSourceType;
    onChange?: (value: string) => void;
}

export const MappedValueLoadableSelect: React.FunctionComponent<Props> = (props) => {

    const [error, setError] = React.useState(false);
    const [mappedValue, setMappedValue] = useState<LoadableSelectItem>(null);
    const [loadOptionsTrigger, setLoadOptionsTrigger] = useState(0);

    const setMappedValueAndNotify = (mappedValue: any) => {
        setMappedValue(mappedValue);
        props.onChange(mappedValue ? (mappedValue.value as string) : undefined);
    };

    useEffect(() => {
        setLoadOptionsTrigger(Math.random() * 10000.00);
        setMappedValue(null);
    }, [props.mappingSource]);

    useEffect(() => {

        if (props.value) {

            searchMappedValues(props.value, null, 0)
                .then(mappedValues => {
                    if (mappedValues.length == 1) {
                        setMappedValue(mappedValues[0]);
                    }
                })
                .catch((error) => {
                    console.error(error)
                    setMappedValue(null);
                    setError(true);
                });
        } else {
            setMappedValue(null);
        }
    }, [props.value]);

    const searchMappedValues = (mdngId: number, searchInput: string, page: number): Promise<LoadableSelectItem[]> => {

        return !props.mappingSource ? Promise.resolve([]) : MappingService.getMappedValuesItems({

            search: searchInput,
            id: null,
            page: page,
            size: 50,
            sort: [{field: "id", direction: 'ASC'}],
            language: "en_GB",
            mappingSource: props.mappingSource
        })
            .then(response => {
                return response.content.map(
                    (mappedValues) => {
                        return {
                            value: mappedValues.id,
                            label: mappedValues.id + " - " + mappedValues.name
                        }
                    }
                );
            }).catch(error => {
                setError(true);
                throw error;
            });
    };

    return (
        <React.Fragment>
            {
                error && <Alert message="Error loading data" type="error" showIcon closable onClose={() => {
                    setError(false)
                }}/>
            }
            <LoadableSelect
                value={mappedValue || undefined}
                placeholder="Search Mapped Value"
                fetchOptions={(search, page) => searchMappedValues(null, search, page)}
                loadOptionsTrigger={loadOptionsTrigger}
                onChange={newMappedValue => {
                    setMappedValueAndNotify(newMappedValue);
                }}
                style={{width: '100%'}}
            />
        </React.Fragment>
    );
};

export default MappedValueLoadableSelect;
