import {Reducer} from "redux";
import {
    ADD_KIND_OF_ITEM,
    ADD_KIND_OF_ITEMS_FAILED,
    ADD_KIND_OF_ITEMS_SUCCEEDED,
    CLOSE_KIND_OF_ERROR_MESSAGE,
    DELETE_KIND_OF_ITEM,
    DELETE_KIND_OF_ITEM_FAILED,
    DELETE_KIND_OF_ITEM_SUCCEEDED,
    FETCH_DEPRECATED_KIND_OF_PRODUCT_ITEMS, FETCH_DEPRECATED_KIND_OF_PRODUCT_ITEMS_FAILED,
    FETCH_DEPRECATED_KIND_OF_PRODUCT_ITEMS_SUCCEEDED,
    FETCH_KIND_OF_ITEMS,
    FETCH_KIND_OF_ITEMS_FAILED,
    FETCH_KIND_OF_ITEMS_SUCCEEDED,
    GENERATE_MAPPING,
    GENERATE_MAPPING_FAILED,
    GENERATE_MAPPING_SUCCEEDED,
    GET_KIND_OF_ITEM,
    GET_KIND_OF_ITEM_FAILED,
    GET_KIND_OF_ITEM_SUCCEEDED,
    KindOfActionTypes,
    KindOfState,
    UPDATE_KIND_OF_ITEM,
    UPDATE_KIND_OF_ITEM_FAILED,
    UPDATE_KIND_OF_ITEM_SUCCEEDED
} from "./types";
import {pageResponse} from "../common/types";

const initialState: KindOfState = {
    itemRequest: {
        language: null
    },
    pageRequest: {
        page: 0,
        size: 10,
        sort: [
            {
                field: "code",
                direction: 'ASC'
            }
        ],
        code: null,
        search: null,
        language: "en-GB"
    },
    pageResponse: {
        ...pageResponse(),
        content: []
    },
    addKindOfItemRequest: {
        typographies: [],
        code: null
    },
    addKindOfItemResponse: {
        code: null,
        name: null,
        description: null
    },
    getKindOfItemResponse: {
        code: null,
        typographies: []
    },
    updateKindOfItemRequest: {
        code: null,
        typographies: []
    },
    code: null,
    importKindOf: null,
    loading: false,
    error: null,
    generateKindOfProductMappingReportLoading: false,
};

export const kindOfReducer: Reducer<KindOfState, KindOfActionTypes> = (state: KindOfState = initialState, action: KindOfActionTypes): KindOfState => {
    switch (action.type) {

        case FETCH_KIND_OF_ITEMS: {
            return {
                ...state,
                pageRequest: {
                    ...action.pageRequest
                },
                loading: true,
                error: null
            }
        }

        case FETCH_KIND_OF_ITEMS_SUCCEEDED: {
            return {
                ...state,
                pageResponse: action.payload,
                loading: false,
                error: null
            }
        }

        case FETCH_KIND_OF_ITEMS_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.error
            }
        }

        case CLOSE_KIND_OF_ERROR_MESSAGE: {
            return {
                ...state,
                loading: false,
                error: null
            }
        }

        case ADD_KIND_OF_ITEM: {
            return {
                ...state,
                addKindOfItemRequest: {
                    code: null,
                    typographies: []
                },
                loading: true,
                error: null
            }
        }

        case ADD_KIND_OF_ITEMS_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.error
            }
        }

        case ADD_KIND_OF_ITEMS_SUCCEEDED: {
            return {
                ...state,
                addKindOfItemResponse: action.payload,
                loading: false,
                error: null
            }
        }

        case GET_KIND_OF_ITEM: {
            return {
                ...state,
                loading: true,
                error: null
            }
        }

        case GET_KIND_OF_ITEM_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.error
            }
        }

        case GET_KIND_OF_ITEM_SUCCEEDED: {
            return {
                ...state,
                getKindOfItemResponse: action.payload,
                loading: false,
                error: null
            }
        }

        case UPDATE_KIND_OF_ITEM: {
            return {
                ...state,
                updateKindOfItemRequest: action.payload,
                loading: true,
                error: null
            }
        }

        case UPDATE_KIND_OF_ITEM_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.error
            }
        }

        case UPDATE_KIND_OF_ITEM_SUCCEEDED: {
            return {
                ...state,
                loading: false,
                error: null
            }
        }

        case DELETE_KIND_OF_ITEM: {
            return {
                ...state,
                code: action.code,
                loading: true,
                error: null
            }
        }

        case DELETE_KIND_OF_ITEM_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.error
            }
        }

        case DELETE_KIND_OF_ITEM_SUCCEEDED: {
            return {
                ...state,
                loading: false,
                error: null
            }
        }

        case GENERATE_MAPPING: {
            return {
                ... state,
                generateKindOfProductMappingReportLoading: true,
                error: null
            }
        }

        case GENERATE_MAPPING_SUCCEEDED: {
            return {
                ...state,
                generateKindOfProductMappingReportLoading: true,
                error: null
            }
        }

        case GENERATE_MAPPING_FAILED: {
            return {
                ...state,
                generateKindOfProductMappingReportLoading: true,
                error: action.error
            }
        }

        case FETCH_DEPRECATED_KIND_OF_PRODUCT_ITEMS: {
            return {
                ...state,
                pageRequest: {
                    ...action.pageRequest
                },
                loading: true,
                error: null
            }
        }

        case FETCH_DEPRECATED_KIND_OF_PRODUCT_ITEMS_SUCCEEDED: {
            return {
                ...state,
                pageResponse: action.payload,
                loading: false,
                error: null
            }
        }

        case FETCH_DEPRECATED_KIND_OF_PRODUCT_ITEMS_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.error
            }
        }

        default:
            neverReached(action);
    }

    return state;
};

const neverReached = (never: never) => {
};
