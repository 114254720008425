import { AxiosInstance } from "axios";
import axiosInstance from "../axiosInstance";
import { resolveApiBaseUrl } from "../environment";
import * as qs from "qs";
import {
  FetchGpcLevelItemRequest,
  FetchGpcLevelItemResponse,
  FetchGpcLevelItemsPageRequest,
  FetchGpcLevelItemsPageResponse
} from "../store/classifications/gpc/types";
import {ImportProgressResponse} from "../store/classifications/importProgress/types";

class ClassificationService {
  axios: AxiosInstance;

  constructor () {
    this.axios = axiosInstance;
  }

  uploadGpcClassificationFile(file: File): Promise<ImportProgressResponse> {

    const reader = new FileReader();
    const fileAsBytesPromise = new Promise((resolve, reject) => {
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = () => {
        reject(new Error("Error reading file"))
      }
    });

    reader.readAsText(file, 'UTF-8');

    return fileAsBytesPromise
        .then((fileAsBytes: any) => {
          return this.axios.post(`/gateway/core-product-classification/api/v1/gpc`,
              fileAsBytes,
              {
                baseURL: resolveApiBaseUrl(),
                headers: {
                  'Content-Type': 'application/xml'
                }
              })
              .then((response: any) => {
                return {
                  ...response['data']
                } as ImportProgressResponse;
              })
              .catch((error: any) => {
                this.handleError(error);
                throw error
              })
        })
        .catch((error: any) => {
          this.handleError(error);
          throw error
        })
  }

  getGpcItems(pageRequest: FetchGpcLevelItemsPageRequest): Promise<FetchGpcLevelItemsPageResponse> {

    const params = {
      page: pageRequest.page,
      size: pageRequest.size,
      sort: pageRequest.sort.map(sort => sort.field + (sort.direction && ("," + sort.direction)))
    } as any;

    if (pageRequest.level) {
      params["level"] = pageRequest.level;
    }

    if (pageRequest.code) {
      params["code"] = pageRequest.code;
    }

    if (pageRequest.search) {
      params["search"] = pageRequest.search;
    }

    return this.axios.get(`/gateway/core-product-classification/api/v1/gpc/items`, {
      baseURL: resolveApiBaseUrl(),
      params: params,
      paramsSerializer: {
          serialize: params => {
              return qs.stringify(params, {
                  arrayFormat: 'repeat'
              })
          }
      }
    })
        .then((response: any) => {
          return {
            ...response['data']
          } as FetchGpcLevelItemsPageResponse;
        })
        .catch((error: any) => {
          this.handleError(error);
          throw error
        })
  }

  getGpcItem(itemRequest: FetchGpcLevelItemRequest): Promise<FetchGpcLevelItemResponse> {

    return this.axios.get(`/gateway/core-product-classification/api/v1/gpc/items/${itemRequest.code}?level=${itemRequest.level}`, {
      baseURL: resolveApiBaseUrl()
    })
        .then((response: any) => {
          return {
            item: response['data']
          } as FetchGpcLevelItemResponse;
        })
        .catch((error: any) => {
          this.handleError(error);
          throw error
        })
  }

  handleError (error: any) {
    console.error('ClassificationService:', error)
  }
}

export default new ClassificationService()
