import * as React from "react";
import "./PermissionsTabView.css"
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import { Button, Form, List, Space, Tag, Typography } from 'antd';
import { Permission } from "../../store/permissionsManagement/types";
import { PlusOutlined } from "@ant-design/icons/lib";
import {useState} from "react";

const { Text } = Typography;

export interface Props {
  permissions: Permission[];

  onEdit: (id: number) => void;
  onAdd: () => void;

  loading: boolean;
  error: string;
}

export const PermissionsTabView: React.FunctionComponent<Props> = (props) => {

  const [form] = Form.useForm();
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { permissions, error, loading, onAdd, onEdit } = props;

  const statusTag = (isActive: boolean) => {
    return (isActive ? (<Tag color="success">ACTIVE</Tag>) : (<Tag color="error">DISABLED</Tag>));
  };

  return error ?
    (
      <div className="view-error">{error}</div>
    )
    :
    (
      <React.Fragment>

        {
          loading && (<div>
            <div className="view-loading"/>
            <CircularProgress className="view-loading-spinner"/>
          </div>)
        }

        <Form
          layout={'horizontal'}
          form={form}
        >
          <Form.Item>
            <Button type="primary" icon={<PlusOutlined />} onClick={onAdd}>
              Add New Permission
            </Button>
          </Form.Item>
        </Form>

        {
          permissions && permissions.length > 0 &&
          (
            <div>
              <List
                bordered={true}
                itemLayout="horizontal"
                size="large"
                pagination={{
                  onShowSizeChange: (current, pageSize) => {
                    setPageSize(pageSize);
                    },
                  onChange: (page, pageSize) => {
                    setCurrentPage(page);
                  },
                  current: currentPage,
                  pageSize: pageSize,
                  hideOnSinglePage: false
                }}
                dataSource={permissions || []}
                renderItem={item => (
                  <List.Item
                    key={item.id}
                    actions={[
                      (statusTag(item.active)),
                      <Button type="primary" size={"small"}
                              onClick={() => onEdit(item.id)}>Details</Button>
                    ]}
                  >
                    <Space size={"large"} direction={"vertical"}>
                      <List.Item.Meta
                        title={<Space>
                          <Text code>{item.operation + "." + item.domain + (!!item.domainId ? ("." + item.domainId) : "")}</Text>
                        </Space>}
                      />
                      {item.description && <Text><b>Description:</b> {item.description}</Text>}
                      {item.operation && <Text><b>Operation:</b> <a>{item.operation}</a></Text>}
                      {item.domain && <Text><b>Domain:</b> <a>{item.domain}</a></Text>}
                      {item.domainId && <Text><b>Domain ID:</b> <a>{item.domainId}</a></Text>}
                    </Space>
                  </List.Item>
                )}
              />
            </div>
          )
        }

      </React.Fragment>
    );
};

export default PermissionsTabView;
