import { all, call, delay, put, takeLatest } from 'redux-saga/effects'

import importResponseService from '../../../services/ImportResponseService'
import { message } from "antd";
import { FETCH_IMPORT_CLASSIFICATION_PROGRESS, FetchImportProgressAction, ImportProgressResponse } from "./types";
import { fetchImportProgressContinues, fetchImportProgressFailed, fetchImportProgressSucceeded } from "./actions";

export function* importProgressSaga() {
    yield all([
        watchImportProgress()
    ])
}

const pollingInterval = 1000;

function* watchImportProgress() {
    yield takeLatest(FETCH_IMPORT_CLASSIFICATION_PROGRESS, handleImportProgressPolling);
}

function* handleImportProgressPolling(action: FetchImportProgressAction) {
    try {
        let progress: ImportProgressResponse;
        while (!progress || progress.running === true) {
            progress = yield call([importResponseService, importResponseService.getImportProgress], action.request);
            if (progress.progress === 0.0) {
                progress.progress = 1.0;
            }
            yield put(fetchImportProgressContinues(progress));
            yield delay(pollingInterval);
        }
        if (progress.succeeded) {
            yield put(fetchImportProgressSucceeded(progress));
        } else {
            message.error("Import failed");
            yield put(fetchImportProgressFailed(progress.importType, progress.error));
        }
    } catch (error) {
        message.error(error.response.data.title);
        yield put(fetchImportProgressFailed(action.request.importType,`${error.response.data.detail}`))
    }
}
