import {
    CLOSE_PRODUCT_GROUP_CLASSIFICATION_ERROR_MESSAGE,
    FETCH_PRODUCT_GROUP_ITEM,
    FETCH_PRODUCT_GROUP_ITEM_FAILED,
    FETCH_PRODUCT_GROUP_ITEM_SUCCEEDED,
    FETCH_PRODUCT_GROUP_ITEMS,
    FETCH_PRODUCT_GROUP_ITEMS_FAILED,
    FETCH_PRODUCT_GROUP_ITEMS_SUCCEEDED,
    FetchProductGroupLevelItemRequest,
    FetchProductGroupLevelItemResponse,
    FetchProductGroupLevelItemsPageRequest,
    FetchProductGroupLevelItemsPageResponse,
    IMPORT_PRODUCT_GROUP_CLASSIFICATION,
    IMPORT_PRODUCT_GROUP_CLASSIFICATION_FAILED,
    IMPORT_PRODUCT_GROUP_CLASSIFICATION_SUCCEEDED,
    ProductGroupClassificationsActionTypes
} from './types'

export function closeProductGroupClassificationErrorMessage(): ProductGroupClassificationsActionTypes {
    return {
        type: CLOSE_PRODUCT_GROUP_CLASSIFICATION_ERROR_MESSAGE
    }
}

export function fetchProductGroupLevelItem(itemRequest: FetchProductGroupLevelItemRequest): ProductGroupClassificationsActionTypes {
    return {
        type: FETCH_PRODUCT_GROUP_ITEM,
        itemRequest
    }
}

export function fetchProductGroupLevelItemSucceeded(payload: FetchProductGroupLevelItemResponse): ProductGroupClassificationsActionTypes {
    return {
        type: FETCH_PRODUCT_GROUP_ITEM_SUCCEEDED,
        payload
    }
}

export function fetchProductGroupLevelItemFailed(error: string): ProductGroupClassificationsActionTypes {
    return {
        type: FETCH_PRODUCT_GROUP_ITEM_FAILED,
        error
    }
}

export function fetchProductGroupLevelItems(pageRequest: FetchProductGroupLevelItemsPageRequest): ProductGroupClassificationsActionTypes {
    return {
        type: FETCH_PRODUCT_GROUP_ITEMS,
        pageRequest
    }
}

export function fetchProductGroupLevelItemsSucceeded(payload: FetchProductGroupLevelItemsPageResponse): ProductGroupClassificationsActionTypes {
    return {
        type: FETCH_PRODUCT_GROUP_ITEMS_SUCCEEDED,
        payload
    }
}

export function fetchProductGroupLevelItemsFailed(error: string): ProductGroupClassificationsActionTypes {
    return {
        type: FETCH_PRODUCT_GROUP_ITEMS_FAILED,
        error
    }
}

export function importProductGroupClassification(): ProductGroupClassificationsActionTypes {
    return {
        type: IMPORT_PRODUCT_GROUP_CLASSIFICATION
    }
}

export function importProductGroupClassificationSucceeded(): ProductGroupClassificationsActionTypes {
    return {
        type: IMPORT_PRODUCT_GROUP_CLASSIFICATION_SUCCEEDED
    }
}

export function importProductGroupClassificationFailed(error: string): ProductGroupClassificationsActionTypes {
    return {
        type: IMPORT_PRODUCT_GROUP_CLASSIFICATION_FAILED,
        error
    }
}

