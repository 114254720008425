import * as React from "react";
import "./ImportProductGroupClassificationView.css"
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import { Button } from 'antd';
import {Alert, Progress} from 'antd';

export interface Props {
    onImportButtonClick: () => void;
    onMessageClose: () => void;
    importRunning: boolean;
    importProgress: number;
    loading: boolean;
    error: string;
}

export const ImportProductGroupClassificationView: React.FunctionComponent<Props> = (props) => {

    const {onImportButtonClick, onMessageClose, importRunning, importProgress, loading, error} = props;

    return (
        <React.Fragment>

            {
                loading && (<div>
                    <div className="view-loading"/>
                    <CircularProgress className="view-loading-spinner"/>
                </div>)
            }

            {
                error && (
                    <React.Fragment>
                        <Alert
                            message="Error"
                            description={error}
                            type="error"
                            closable
                            onClose={onMessageClose}
                        />
                        <br/>
                    </React.Fragment>
                )
            }
            <div className="import__block">
                <Button type="primary" className="import__block__button"
                        disabled={importRunning && importProgress < 100.00}
                        onClick={onImportButtonClick}>
                    Import Product Groups
                </Button>
            </div>

            <div className={"progress__bar"}>
                {importRunning && <Progress percent={importProgress}
                                            status={error ? "exception" : (importProgress === 100.00 ? null : "active")}
                                            type={"dashboard"}/>}
            </div>

        </React.Fragment>
    );
};

export default ImportProductGroupClassificationView;
