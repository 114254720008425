import {
  FETCH_IMPORT_TASK_EXECUTION,
  FETCH_IMPORT_TASK_EXECUTION_FAILED,
  FETCH_IMPORT_TASK_EXECUTION_SUCCEEDED,
  FETCH_IMPORTS,
  FETCH_IMPORTS_FAILED,
  FETCH_IMPORTS_SUCCEEDED,
  FetchImportTaskExecutionPageRequest,
  FetchImportTaskExecutionPageResponse,
  ImportsActionTypes,
  ImportTaskExecution,
  ReimportRequest,
  RETRY_IMPORTS,
  RETRY_IMPORTS_FAILED,
  RETRY_IMPORTS_SUCCEEDED,
  RetryActionTypes,
} from './types'

export function fetchProductImports(pageRequest: FetchImportTaskExecutionPageRequest): ImportsActionTypes {
  return {
    type: FETCH_IMPORTS,
    pageRequest
  }
}

export function fetchProductImportsSucceeded(payload: FetchImportTaskExecutionPageResponse): ImportsActionTypes {
  return {
    type: FETCH_IMPORTS_SUCCEEDED,
    payload
  }
}

export function fetchProductImportsFailed(error: string): ImportsActionTypes {
  return {
    type: FETCH_IMPORTS_FAILED,
    error
  }
}

export function fetchImportTaskExecution(id: string): ImportsActionTypes {
  return {
    type: FETCH_IMPORT_TASK_EXECUTION,
    id
  }
}

export function fetchImportTaskExecutionSucceeded(payload: ImportTaskExecution): ImportsActionTypes {
  return {
    type: FETCH_IMPORT_TASK_EXECUTION_SUCCEEDED,
    payload
  }
}

export function fetchImportTaskExecutionFailed(error: string): ImportsActionTypes {
  return {
    type: FETCH_IMPORT_TASK_EXECUTION_FAILED,
    error
  }
}

export function retryProductImports(retryRequest: ReimportRequest): RetryActionTypes {
  return {
    type: RETRY_IMPORTS,
    retryRequest
  }
}

export function retryProductImportsSucceeded(): RetryActionTypes {
  return {
    type: RETRY_IMPORTS_SUCCEEDED
  }
}

export function retryProductImportsFailed(error: string): RetryActionTypes {
  return {
    type: RETRY_IMPORTS_FAILED,
    error
  }
}
