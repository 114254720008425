import * as React from "react";
import "./ImporterFailureView.css"
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import { Button, Collapse, Descriptions, Dropdown, Menu, Modal, Space, Tag, Typography } from 'antd';
import { ImporterFailure, ImportStatus } from "../../store/failures/types";
import Highlight from "../Highlight/Highlight";
import { DownOutlined, ExclamationCircleOutlined } from "@ant-design/icons/lib";

const { confirm } = Modal;
const { Text } = Typography;
const { Panel } = Collapse;

export interface ImportTaskExecutionFailureViewProps {
  productDetails: string;
  onProductDetailsOpen: (productId: string) => void;
  importFailure: ImporterFailure;
  onFailureRetry: (id: number) => void;
  onFailureReject: (id: number) => void;
  retryLoading: boolean;
  rejectLoading: boolean;
  loading: boolean;
  error: string;
}

export const ImporterFailureView: React.FunctionComponent<ImportTaskExecutionFailureViewProps> = (props) => {

  const { productDetails, importFailure, onFailureRetry, onFailureReject, loading, onProductDetailsOpen } = props;

  const statusTag = (importStatus: ImportStatus | null) => {
    return (importStatus == 'FAILED' ?
        (<Tag color="error">FAILED</Tag>)
        : (importStatus == 'SUCCEEDED'
          ? (<Tag color="success">SUCCEEDED</Tag>)
          : ((importStatus == 'REJECTED' || importStatus == 'RUNNING') ? <Tag color="warning">{importStatus}</Tag> : null))
    );
  };

  const onProductDetailsPanelOpen = (productId: string[]) => {
    if (productId && productId[0]) {
      onProductDetailsOpen(productId[0]);
    }
  };

  const onFailureRejectOpenConfirmDialog = (id: number) => {

    confirm({
      title: 'Are you sure to reject this failure ' + id + '?',
      icon: <ExclamationCircleOutlined />,
      content: 'Rejected failures are treated as non-important, and thus ignored during statistics calculation.',
      okText: 'Yes, reject!',
      okType: 'danger',
      cancelText: 'No, wait!',
      onOk() {
        !!onFailureReject && onFailureReject(id);
      },
      onCancel() {
      },
    });
  };

  const menu = (
    importFailure && importFailure.importStatus == 'FAILED' &&
    <Menu>
      {
        <Menu.Item key="1">
          <Button type="primary" loading={props.retryLoading} size={"small"}
                  onClick={() => onFailureRetry(importFailure.id)}>Retry</Button>
        </Menu.Item>
      }
      {
        <Menu.Item key="2">
          <Button type="primary" danger loading={props.rejectLoading} size={"small"}
                  onClick={() => onFailureRejectOpenConfirmDialog(importFailure.id)}>Reject</Button>
        </Menu.Item>
      }
    </Menu>
  );

  return props.error ?
    (
      <div className="view-error">{props.error}</div>
    )
    :
    (
      <React.Fragment>

        {
          loading && (<div>
            <div className="view-loading"/>
            <CircularProgress className="view-loading-spinner"/>
          </div>)
        }

        <Descriptions size="small" column={2} bordered>
          <Descriptions.Item label="ID">
            <a>{importFailure && importFailure.id}</a>
          </Descriptions.Item>
          <Descriptions.Item label="Status">
            <Space>
              {statusTag((importFailure && importFailure.importStatus) || null)}
              {
                importFailure && importFailure.importStatus == 'FAILED' &&
                <Dropdown overlay={menu} trigger={['click']}>
                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                        Actions <DownOutlined/>
                    </a>
                </Dropdown>
              }
            </Space>
          </Descriptions.Item>
          <Descriptions.Item label="Subsidiary">{importFailure && importFailure.subsidiary}</Descriptions.Item>
          <Descriptions.Item label="Source system">{importFailure && importFailure.productSourceSystem}</Descriptions.Item>
          <Descriptions.Item label="Failure type">{importFailure && importFailure.failureType && (<Text code>{importFailure.failureType}</Text>)}</Descriptions.Item>

          {
            importFailure && importFailure.failureType == 'PRODUCT_FAILURE' && (
              <Descriptions.Item label="Product Id">{importFailure && importFailure.productId && (<a>{importFailure.productId}</a>)}</Descriptions.Item>
            )
          }
          {
            importFailure && importFailure.failureType == 'RANGE_IMPORT_FAILURE' && (
              <React.Fragment>
                <Descriptions.Item label=""><br/></Descriptions.Item>
                <Descriptions.Item label="From">{importFailure && importFailure.fromDate}</Descriptions.Item>
                <Descriptions.Item label="To">{importFailure && importFailure.toDate}</Descriptions.Item>
                <Descriptions.Item label="Page">{importFailure && importFailure.page}</Descriptions.Item>
                <Descriptions.Item label="Size">{importFailure && importFailure.size}</Descriptions.Item>
              </React.Fragment>
            )
          }

          <Descriptions.Item label="Create time">{importFailure && new Date(importFailure.createdAt).toLocaleString()}</Descriptions.Item>
          <Descriptions.Item label="Created by">
            <a>{importFailure && importFailure.createdByFullName}</a>
          </Descriptions.Item>
          <Descriptions.Item label="Update time">{importFailure && new Date(importFailure.updatedAt).toLocaleString()}</Descriptions.Item>
          <Descriptions.Item label="Updated by">
            <a>{importFailure && importFailure.updatedByFullName}</a>
          </Descriptions.Item>

          <Descriptions.Item span={2} label="Message"><Text strong>{importFailure && importFailure.failureMessage}</Text></Descriptions.Item>
        </Descriptions>
        <br/>
        {
          importFailure && importFailure.failureType == 'PRODUCT_FAILURE' && (
            <Collapse defaultActiveKey={[]} onChange={onProductDetailsPanelOpen}>
              <Panel header={
                (importFailure && importFailure.productSourceSystem) + " - " +
                (importFailure && importFailure.subsidiary) + ' - ' +
                (importFailure && importFailure.productId)
              }
                     key={importFailure && importFailure.productId}>
                <Highlight language="json">
                  {productDetails}
                </Highlight>
              </Panel>
            </Collapse>)
        }

      </React.Fragment>
    );
};

export default ImporterFailureView;
