import * as React from "react";
import "./ProductGroupClassificationItemView.css"
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import {Descriptions, Divider, List, Tree} from 'antd';
import {
    ProductGroupChildrenResolver,
    ProductGroupClassificationItem,
    ProductGroupLevelItemType
} from "../../store/classifications/productGroup/types";
import {LanguageType} from "../../store/common/types";

export interface Props {
    id: string;
    level: ProductGroupLevelItemType;
    language: LanguageType;
    item: ProductGroupClassificationItem;
    loading: boolean;
    error: string;
}

export const ProductGroupClassificationItemView: React.FunctionComponent<Props> = (props) => {

    const {language, level, item, loading, error} = props;

    return error ?
        (
            <div className="view-error">{error}</div>
        )
        :
        (
            <React.Fragment>

                {
                    loading && (<div>
                        <div className="view-loading"/>
                        <CircularProgress className="view-loading-spinner"/>
                    </div>)
                }

                {
                    !!item &&
                    <Descriptions size="small" column={1} bordered>
                        <Descriptions.Item label="Level">
                            <a>{level.replace(/_/gi, " ")}</a>
                        </Descriptions.Item>
                        <Descriptions.Item label="Id">
                            <a>{item.id}</a>
                        </Descriptions.Item>
                        <Descriptions.Item label="Description">{item.description}
                        </Descriptions.Item>
                        <Descriptions.Item label="Language">
                            <a>{language}</a>
                        </Descriptions.Item>
                    </Descriptions>
                }

                <React.Fragment>
                    {
                        level === 'DOMAIN' &&
                        <Divider orientation={"left"} dashed={true}>Product Group Department List</Divider>
                    }
                    {
                        level === 'DEPARTMENT' &&
                        <Divider orientation={"left"} dashed={true}>Main Product Group List</Divider>
                    }
                    {
                         level === 'MAIN_PRODUCT_GROUP' &&
                        <Divider orientation={"left"} dashed={true}>Product Group List</Divider>
                    }
                </React.Fragment>

                {
                    !!item && !!level && !!language && <List
                        itemLayout="horizontal"
                        dataSource={
                            ProductGroupChildrenResolver.getChildren(item, language).map((child, index) => {
                                return child;
                            })
                        }
                        renderItem={child => (
                            <List.Item>
                                <List.Item.Meta
                                    title={<a
                                        href={`/app/classification/productGroup/${child.level.toLowerCase()}/${child.id}/${child.language}`}>{child.id + ' - ' + child.description}</a>}
                                />
                            </List.Item>
                        )}
                    />
                }
            </React.Fragment>
        );
};

export default ProductGroupClassificationItemView;
