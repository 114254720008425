import { AxiosInstance } from "axios";
import axiosInstance from "../axiosInstance";
import { FailuresStatistics, ProductsStatistics } from "../store/statistics/types";
import { resolveApiBaseUrl } from "../environment";
import { Subsidiary } from "../store/common/types";
import * as qs from "qs";

class StatisticsService {
  axios: AxiosInstance;

  constructor () {
    this.axios = axiosInstance;
  }

  getProductsStatistics(subsidiaries: Subsidiary[], excludeArchivedProducts: boolean): Promise<ProductsStatistics> {

    const params = {} as any;
    if (subsidiaries && subsidiaries.length > 0) {
      params["subsidiaries"] = subsidiaries;
    }
    params["excludeArchivedProducts"] = excludeArchivedProducts;

    return this.axios.get(`/gateway/core-product-api-internal/api/v1/statistics/products`, {
      baseURL: resolveApiBaseUrl(),
      params: params,
      paramsSerializer: {
        serialize: params => {
          return qs.stringify(params, {
            arrayFormat: 'repeat'
          })
        }
      }
    })
      .then((response: any) => {
        return {
          ... response['data']
        } as ProductsStatistics;
      })
      .catch((error: any) => {
        this.handleError(error);
        throw error
      })
  }

  getImporterFailuresCount(subsidiaries: Subsidiary[]): Promise<number> {

    const params = {} as any;
    if (subsidiaries && subsidiaries.length > 0) {
      params["subsidiaries"] = subsidiaries;
    }

    return this.axios.get(`/gateway/core-product-importer/api/v1/statistics/failures`, {
      baseURL: resolveApiBaseUrl(),
      params: params,
      paramsSerializer: {
        serialize: params => {
          return qs.stringify(params, {
            arrayFormat: 'repeat'
          })
        }
      }
    })
      .then((response: any) => {
        return response['data'].failures;
      })
      .catch((error: any) => {
        this.handleError(error);
        throw error
      })
  }

  getFailuresStatistics(subsidiaries: Subsidiary[], excludeArchivedProducts: boolean): Promise<FailuresStatistics> {

    const params = {} as any;
    if (subsidiaries && subsidiaries.length > 0) {
      params["subsidiaries"] = subsidiaries;
    }
    params["excludeArchivedProducts"] = excludeArchivedProducts;

    return this.axios.get(`/gateway/core-product-api-internal/api/v1/statistics/failures`, {
      baseURL: resolveApiBaseUrl(),
      params: params,
      paramsSerializer: {
        serialize: params => {
          return qs.stringify(params, {
            arrayFormat: 'repeat'
          })
        }
      }
    })
      .then((response: any) => {
        return {
          ... response['data']
        } as FailuresStatistics;
      })
      .catch((error: any) => {
        this.handleError(error);
        throw error
      })
  }

  handleError (error: any) {
    console.error('StatisticsService:', error)
  }
}

export default new StatisticsService()
