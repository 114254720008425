import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import "./ImporterFailurePage.css";
import { Dispatch } from "redux";
import { AppState } from "../../store";
import {flowRight} from "lodash";
import { connect } from 'react-redux';
import { UserSession } from "../../store/userSession/types";
import PageWrapper from "../../components/Drawer/PageWrapper";
import { PageHeader } from 'antd';
import { ImporterFailure } from "../../store/failures/types";
import ImporterFailureView from "../../components/ImporterFailure/ImporterFailureView";
import {
  fetchImporterFailure,
  fetchLegacyProduct,
  rejectImporterFailure,
  retryImporterFailure
} from "../../store/failures/actions";
import { ProductSourceSystem, Subsidiary } from "../../store/common/types";

interface Props extends RouteComponentProps<{id: string}> {
  userSession: UserSession;

  failureId: string;
  failure: ImporterFailure;

  legacyProduct?: string
  fetchLegacyProduct?: (sourceSystem: ProductSourceSystem, subsidiary: Subsidiary, productId: string) => void;

  fetchFailure: (id: number) => void;
  loading?: boolean;
  error?: string;

  retryFailure: (id: number) => void;
  retryLoading?: boolean;
  retryError?: string;

  rejectFailure: (id: number) => void;
  rejectLoading?: boolean;
}

class ImporterFailurePage extends React.Component<Props> {

  constructor(props: Props) {
    super(props);

    this.onFailureRetry = this.onFailureRetry.bind(this);
    this.onFailureReject = this.onFailureReject.bind(this);
    this.onProductDetailsOpen = this.onProductDetailsOpen.bind(this);
  }

  componentDidMount () {
    this.props.fetchFailure(parseInt(this.props.match.params.id))
  }

  private onProductDetailsOpen(productId: string) {
    if (this.props.failure.productSourceSystem === 'MDNG') {
      this.props.fetchLegacyProduct(this.props.failure.productSourceSystem, this.props.failure.subsidiary, productId);
    }
  }

  private onFailureRetry(id: number) {
    this.props.retryFailure(id);
  }

  private onFailureReject(id: number) {
    this.props.rejectFailure(id);
  }

  public render() {

    return (
      <div className="importer-failure-page">
        <PageWrapper userSession={this.props.userSession}>
          <PageHeader
            ghost={false}
            onBack={() => window.history.back()}
            title="Importer Failure"
            subTitle={"Details of the importer service failure"}
          >
          </PageHeader>
          <ImporterFailureView
            productDetails={(this.props.failure && this.props.failure.sourcePayload && JSON.stringify(JSON.parse(this.props.failure.sourcePayload), null, 2))
                      || this.props.legacyProduct}
            onProductDetailsOpen={this.onProductDetailsOpen}
            importFailure={this.props.failure}
            loading={this.props.loading}
            error={this.props.error}
            onFailureRetry={this.onFailureRetry}
            onFailureReject={this.onFailureReject}
            retryLoading={this.props.retryLoading}
            rejectLoading={this.props.rejectLoading}
          />
        </PageWrapper>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  userSession: state.userSession.userSession,

  failureId: state.failures.importerFailureId,
  failure: state.failures.importerFailure,
  legacyProduct: state.failures.legacyProduct,

  loading: state.failures.loading,
  error: state.failures.error,

  rejectLoading: state.rejectFailures.rejectLoading,

  retryLoading: state.retryFailures.retryLoading,
  retryError: state.retryFailures.retryError,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchFailure: (id: number) => {
    dispatch(fetchImporterFailure(id))
  },
  retryFailure: (id: number) => {
    dispatch(retryImporterFailure(id))
  },
  rejectFailure: (id: number) => {
    dispatch(rejectImporterFailure(id))
  },
  fetchLegacyProduct: (sourceSystem: ProductSourceSystem, subsidiary: Subsidiary, productId: string) => {
    dispatch(fetchLegacyProduct(sourceSystem, subsidiary, productId))
  },
});

const enhance = flowRight(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(ImporterFailurePage);
