import { Reducer } from "redux";
import {
    CLOSE_GPC_CLASSIFICATION_ERROR_MESSAGE,
    FETCH_GPC_ITEM,
    FETCH_GPC_ITEM_FAILED,
    FETCH_GPC_ITEM_SUCCEEDED,
    FETCH_GPC_ITEMS,
    FETCH_GPC_ITEMS_FAILED,
    FETCH_GPC_ITEMS_SUCCEEDED,
    GpcClassificationsActionTypes,
    GpcClassificationState,
    IMPORT_GPC_CLASSIFICATION,
    IMPORT_GPC_CLASSIFICATION_FAILED,
    IMPORT_GPC_CLASSIFICATION_SUCCEEDED
} from "./types";
import { pageResponse } from "../../common/types";

const initialState: GpcClassificationState = {
    itemRequest: {
        code: null,
        level: null
    },
    itemResponse: {
        item: null
    },
    pageRequest: {
        page: 0,
        size: 10,
        sort: [
            {
                field: "code",
                direction: 'ASC'
            }
        ],
        level: "SEGMENT",
        code: null,
        search: null
    },
    pageResponse: {
        ...pageResponse(),
        content: []
    },
    importClassification: null,
    loading: false,
    error: null
};

export const gpcClassificationsReducer: Reducer<GpcClassificationState, GpcClassificationsActionTypes> = (state: GpcClassificationState = initialState, action: GpcClassificationsActionTypes): GpcClassificationState => {
    switch (action.type) {

        case FETCH_GPC_ITEM: {
            return {
                ...state,
                itemRequest: {
                    ...action.itemRequest
                },
                itemResponse: {
                    item: null
                },
                loading: true,
                error: null
            }
        }

        case FETCH_GPC_ITEM_SUCCEEDED: {
            return {
                ...state,
                itemResponse: action.payload,
                loading: false,
                error: null
            }
        }

        case FETCH_GPC_ITEM_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.error
            }
        }

        case FETCH_GPC_ITEMS: {
            return {
                ...state,
                pageRequest: {
                    ...action.pageRequest
                },
                loading: true,
                error: null
            }
        }

        case FETCH_GPC_ITEMS_SUCCEEDED: {
            return {
                ...state,
                pageResponse: action.payload,
                loading: false,
                error: null
            }
        }

        case FETCH_GPC_ITEMS_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.error
            }
        }

        case IMPORT_GPC_CLASSIFICATION: {
            return {
                ...state,
                importClassification: action.request,
                loading: true,
                error: null
            }
        }

        case IMPORT_GPC_CLASSIFICATION_SUCCEEDED: {
            return {
                ...state,
                loading: false,
                error: null
            }
        }

        case IMPORT_GPC_CLASSIFICATION_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.error
            }
        }

        case CLOSE_GPC_CLASSIFICATION_ERROR_MESSAGE: {
            return {
                ...state,
                loading: false,
                error: null
            }
        }

        default:
            neverReached(action);
    }

    return state;
};

const neverReached = (never: never) => {
};
