import * as React from "react";
import "./MappingMVToKOPsView.css"
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import {Alert, Button, Divider, Form, message, Select, Table} from 'antd';
import MappingSourceTypeSelect from "../inputs/MappingSourceTypeSelect/MappingSourceTypeSelect";
import {useState} from "react";
import MappingService from "../../services/MappingService";
import {MappingSourceType} from "../../store/common/types";
import {MappedValueLoadableSelect} from "../LoadableSelect/MappedValueLodaleSelect";
import KindOfProductService from "../../services/KindOfProductService";

const {Option} = Select;

export interface Props {
    loading: boolean;
    error: string;
}

export const MappingMVToKOPsView: React.FunctionComponent<Props> = (props) => {

    const {loading} = props;
    const [error, setError] = React.useState(false);
    const [mappingSource, setMappingSource] = useState(null);
    const [selectedKindOfProducts, setSelectedKindOfProducts] = React.useState([]);
    const [mappedValue, setMappedValue] = React.useState(null);
    const [kindOfProducts, setKindOfProducts] = React.useState([]);
    const [unmappedMappedValues, setUnmappedMappedValues] = React.useState([]);

    const [form] = Form.useForm();

    const resetForm = () => {
        setUnmappedMappedValues([]);
        setSelectedKindOfProducts([]);
        form.resetFields();
    }

    const formItemLayout = {
        labelCol: {span: 3},
        wrapperCol: {span: 10},
    };

    const buttonItemLayout = {
        wrapperCol: {span: 10, offset: 3},
    };

    const onMappingSourceChange = (mappingSource: MappingSourceType) => {
        setMappedValue(null);
        setMappingSource(mappingSource);
        setSelectedKindOfProducts([])
    }

    const onKindOfProductChange = (mappedValues: []) => {
        setSelectedKindOfProducts(mappedValues);
    };

    const onMappedValueChange = (id: string) => {
        setMappedValue(id);
    };

    const onSubmit = (data: any) => {
        MappingService.createMappedValueMapping({
            mappingSource: data.mappingSource,
            mappedValue: data.mappedValue,
            kindOfProducts: selectedKindOfProducts
        }).then(() => {
            message.success((<span>Mapping Created Successfully</span>), 5);
            resetForm();
        }).catch((e) => {
            message.error((<span>Create Mapping Failed: {e.message}</span>), 5);
        });
    }

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
    ];

    React.useEffect(() => {
        if (!!mappedValue && !!mappingSource) {

            KindOfProductService.getMappedValueMapping(mappedValue, mappingSource)
                .then(values => {
                    setSelectedKindOfProducts(values.content.map(value => value.id))
                })
                .catch(error => {
                    console.log(error)
                    setError(true);
                    setSelectedKindOfProducts(null);
                });
        } else {
            setSelectedKindOfProducts([]);
        }

    }, [!!mappedValue ? mappedValue : null]);

    React.useEffect(() => {

        MappingService.fetchKindOfProductList()
            .then(values => {
                setKindOfProducts(values.content)
            })
            .catch(error => {
                console.log(error)
                setError(true);
                setKindOfProducts(null);
            });
    }, []);

    React.useEffect(() => {

        if (!!mappingSource) {

            MappingService.fetchUnmappedMappedValuesList(mappingSource)
                .then(values => {
                    setUnmappedMappedValues(values.content)
                })
                .catch(error => {
                    console.log(error)
                    setError(true);
                    setUnmappedMappedValues(null);
                });
        } else {
            setUnmappedMappedValues([]);
        }

    }, [!!mappingSource ? mappingSource : null]);

    return <React.Fragment>

        {
            loading && (<div>
                <div className="view-loading"/>
                <CircularProgress className="view-loading-spinner"/>
            </div>)
        }

        {
            error && (<React.Fragment>
                <Alert
                    message="Error"
                    description={error}
                    type="error"
                />
                <br/>
            </React.Fragment>)
        }

        <br/>

        <Divider orientation={"left"}>
            Map Mapped Value to Kind Of Products
        </Divider>
        <br/>

        <Form
            {...formItemLayout}
            layout={'horizontal'}
            form={form}
            onFinish={onSubmit}
        >

            <Form.Item label="Mapping Source"
                       name={"mappingSource"}
                       rules={[{required: true, message: 'Please choose Mapping Source'}]}>

                <MappingSourceTypeSelect id={"mapping-source-select"}
                                         value={mappingSource}
                                         onChange={onMappingSourceChange}/>
            </Form.Item>

            <Form.Item label="Mapped Values"
                       name={"mappedValue"}
                       rules={[{required: true, message: 'Please choose Mapped Value'}]}>

                <MappedValueLoadableSelect mappingSource={mappingSource} onChange={onMappedValueChange}/>
            </Form.Item>

            <Form.Item label="Kind Of Products">
                <Select
                    mode="multiple"
                    size={"large"}
                    placeholder="Please select Kind Of Products..."
                    value={selectedKindOfProducts}
                    onChange={onKindOfProductChange}
                    style={{width: '100%'}}
                >
                    {kindOfProducts.map(kindOfProduct => {
                        return (<Option key={kindOfProduct.code}
                                        value={kindOfProduct.code}>{kindOfProduct.code + "  -  " + kindOfProduct.name}</Option>)
                    })}
                </Select>
            </Form.Item>

            <Form.Item {...buttonItemLayout}>
                <Button type="primary" htmlType="submit">
                    Update
                </Button>
                <span> </span>
                <Button danger onClick={() => resetForm()}>Cancel</Button>
            </Form.Item>
        </Form>

        <br/>
        <Divider orientation={"left"}>
            Unmapped Values of Mapping Source
        </Divider>
        <br/>

        <Table dataSource={unmappedMappedValues} columns={columns}/>

    </React.Fragment>
};

export default MappingMVToKOPsView;
