import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import "./MPNStatsPage.css";
import { Dispatch } from "redux";
import { AppState } from "../../store";
import {flowRight} from "lodash";
import { connect } from 'react-redux';
import { UserSession } from "../../store/userSession/types";
import { Order } from "../../components/EnhancedTable/EnhancedTable";
import PageWrapper from "../../components/Drawer/PageWrapper";
import {Tabs} from 'antd';
import {
  FetchAmbiguousMPNStatsPageRequest, FetchAmbiguousMPNStatsPageResponse,
  FetchManufacturerMPNStatsPageRequest,
  FetchManufacturerMPNStatsPageResponse
} from "../../store/globalProducts/types";
import {
  fetchAmbiguousMPNStats,
  fetchManufacturerMPNStats,
  generateMPNStatsReport
} from "../../store/globalProducts/actions";
import ManufacturerMPNStatsView from "../../components/MPNStatsViews/ManufacturerMPNStatsView/ManufacturerMPNStatsView";
import * as qs from "qs";
import AmbiguousMPNStatsView from "../../components/MPNStatsViews/AmbiguousMPNStatsView/AmbiguousMPNStatsView";

const { TabPane } = Tabs;

interface MPNStatsProps extends RouteComponentProps {
  userSession: UserSession;
  manufacturerMPNStatsPageRequest: FetchManufacturerMPNStatsPageRequest;
  manufacturerMPNStatsPageResponse: FetchManufacturerMPNStatsPageResponse;
  ambiguousMPNStatsPageRequest: FetchAmbiguousMPNStatsPageRequest;
  ambiguousMPNStatsPageResponse: FetchAmbiguousMPNStatsPageResponse;
  fetchManufacturerMPNStats: (manufacturerMPNStatsPageRequest: FetchManufacturerMPNStatsPageRequest) => void;
  fetchAmbiguousMPNStats: (ambiguousMPNStatsPageRequest: FetchAmbiguousMPNStatsPageRequest) => void;
  generateStatsReport: (manufacturerMPNStatsPageRequest: FetchManufacturerMPNStatsPageRequest) => void;
  generateStatsReportLoading?: boolean;
  loading?: boolean;
  error?: string;
}

interface State {
}

class MPNStatsPage extends React.Component<MPNStatsProps, State> {

  constructor(props: MPNStatsProps) {
    super(props);

    this.onManufacturerMPNPaginationChanged = this.onManufacturerMPNPaginationChanged.bind(this);
    this.onManufacturerMPNSortingChanged = this.onManufacturerMPNSortingChanged.bind(this);
    this.onManufacturerMPNFilterChanged = this.onManufacturerMPNFilterChanged.bind(this);
    this.onManufacturerMPNGenerateReport = this.onManufacturerMPNGenerateReport.bind(this);

    this.onAmbiguousMPNPaginationChanged = this.onAmbiguousMPNPaginationChanged.bind(this);
    this.onAmbiguousMPNSortingChanged = this.onAmbiguousMPNSortingChanged.bind(this);
    this.onAmbiguousMPNFilterChanged = this.onAmbiguousMPNFilterChanged.bind(this);
  }

  componentDidMount () {
    this.props.fetchManufacturerMPNStats(this.props.manufacturerMPNStatsPageRequest)
    this.props.fetchAmbiguousMPNStats(this.props.ambiguousMPNStatsPageRequest)
  }

  private onManufacturerMPNPaginationChanged(page: number, size: number) {
    this.props.fetchManufacturerMPNStats({
      ... this.props.manufacturerMPNStatsPageRequest,
      page,
      size
    })
  }
  private onManufacturerMPNFilterChanged(filterField: string, value: string) {
    console.log("Filter: " + filterField + ", value: " + value);

    this.props.fetchManufacturerMPNStats({
      ...this.props.manufacturerMPNStatsPageRequest,
      [filterField]: value
    });
  }

  private onManufacturerMPNSortingChanged(orderBy: string, order: Order) {
    console.log("Order by: " + orderBy + ", order: " + order);

    this.props.fetchManufacturerMPNStats({
      ... this.props.manufacturerMPNStatsPageRequest,
      sort: {
        field: orderBy,
        direction: order
      }
    });
  }

  private onAmbiguousMPNPaginationChanged(page: number, size: number) {
    this.props.fetchAmbiguousMPNStats({
      ... this.props.ambiguousMPNStatsPageRequest,
      page,
      size
    })
  }

  private onAmbiguousMPNFilterChanged(filterField: string, value: string) {
    console.log("Filter: " + filterField + ", value: " + value);

    this.props.fetchAmbiguousMPNStats({
      ...this.props.ambiguousMPNStatsPageRequest,
      [filterField]: value
    });
  }

  private onAmbiguousMPNSortingChanged(orderBy: string, order: Order) {
    console.log("Order by: " + orderBy + ", order: " + order);

    this.props.fetchAmbiguousMPNStats({
      ... this.props.ambiguousMPNStatsPageRequest,
      sort: {
        field: orderBy,
        direction: order
      }
    });
  }

  private onManufacturerMPNGenerateReport() {
    this.props.generateStatsReport({
      ... this.props.manufacturerMPNStatsPageRequest
    });
  }

  public render() {
    const { activeTab } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

    return (
      <div className="mpn-stats-page">
        <PageWrapper userSession={this.props.userSession}>
          <Tabs defaultActiveKey={activeTab} type="card" size={"default"}>
            <TabPane tab="Manufacturer MPN Statistics" key="1">
              <ManufacturerMPNStatsView
                manufacturerMPNStatsPageRequest={this.props.manufacturerMPNStatsPageRequest}
                manufacturerMPNStatsPageResponse={this.props.manufacturerMPNStatsPageResponse}
                onPaginationChanged={this.onManufacturerMPNPaginationChanged}
                onSortingChanged={this.onManufacturerMPNSortingChanged}
                onFilterChanged={this.onManufacturerMPNFilterChanged}
                onGenerateReport={this.onManufacturerMPNGenerateReport}
                generateReportLoading={this.props.generateStatsReportLoading}
                loading={this.props.loading}
                error={this.props.error}
              />
            </TabPane>
            <TabPane tab="Ambiguous MPN Statistics" key="2">
              <AmbiguousMPNStatsView
                  ambiguousMPNStatsPageRequest={this.props.ambiguousMPNStatsPageRequest}
                  ambiguousMPNStatsPageResponse={this.props.ambiguousMPNStatsPageResponse}
                  onPaginationChanged={this.onAmbiguousMPNPaginationChanged}
                  onSortingChanged={this.onAmbiguousMPNSortingChanged}
                  onFilterChanged={this.onAmbiguousMPNFilterChanged}
                  loading={this.props.loading}
                  error={this.props.error}
              />
            </TabPane>
          </Tabs>
        </PageWrapper>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  userSession: state.userSession.userSession,

  manufacturerMPNStatsPageRequest: state.products.manufacturerMPNStatsPageRequest,
  manufacturerMPNStatsPageResponse: state.products.manufacturerMPNStatsPageResponse,

  ambiguousMPNStatsPageRequest: state.products.ambiguousMPNStatsPageRequest,
  ambiguousMPNStatsPageResponse: state.products.ambiguousMPNStatsPageResponse,

  generateStatsReportLoading: state.products.generateManufacturerMPNStatsReportLoading,
  loading: state.products.loading,
  error: state.products.error
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchManufacturerMPNStats: (manufacturerMPNStatsPageRequest: FetchManufacturerMPNStatsPageRequest) => {
    dispatch(fetchManufacturerMPNStats(manufacturerMPNStatsPageRequest))
  },
  generateStatsReport: (manufacturerMPNStatsPageRequest: FetchManufacturerMPNStatsPageRequest) => {
    dispatch(generateMPNStatsReport(manufacturerMPNStatsPageRequest))
  },
  fetchAmbiguousMPNStats: (ambiguousMPNStatsPageRequest: FetchAmbiguousMPNStatsPageRequest) => {
    dispatch(fetchAmbiguousMPNStats(ambiguousMPNStatsPageRequest))
  },
});

const enhance = flowRight(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(MPNStatsPage);
