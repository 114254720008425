import { LanguageType, PageResponse } from "../../common/types";

export const IMPORT_PRODUCT_GROUP_CLASSIFICATION = 'IMPORT_PRODUCT_GROUP_CLASSIFICATION'
export const IMPORT_PRODUCT_GROUP_CLASSIFICATION_SUCCEEDED = 'IMPORT_PRODUCT_GROUP_CLASSIFICATION_SUCCEEDED'
export const IMPORT_PRODUCT_GROUP_CLASSIFICATION_FAILED = 'IMPORT_PRODUCT_GROUP_CLASSIFICATION_FAILED'

export const FETCH_PRODUCT_GROUP_ITEMS = 'FETCH_PRODUCT_GROUP_ITEMS'
export const FETCH_PRODUCT_GROUP_ITEMS_SUCCEEDED = 'FETCH_PRODUCT_GROUP_ITEMS_SUCCEEDED'
export const FETCH_PRODUCT_GROUP_ITEMS_FAILED = 'FETCH_PRODUCT_GROUP_ITEMS_FAILED'

export const FETCH_PRODUCT_GROUP_ITEM = 'FETCH_PRODUCT_GROUP_ITEM'
export const FETCH_PRODUCT_GROUP_ITEM_SUCCEEDED = 'FETCH_PRODUCT_GROUP_ITEM_SUCCEEDED'
export const FETCH_PRODUCT_GROUP_ITEM_FAILED = 'FETCH_PRODUCT_GROUP_ITEM_FAILED'

export const CLOSE_PRODUCT_GROUP_CLASSIFICATION_ERROR_MESSAGE = 'CLOSE_PRODUCT_GROUP_CLASSIFICATION_ERROR_MESSAGE'

export type ProductGroupLevelItemType = 'DOMAIN' | 'DEPARTMENT' | 'MAIN_PRODUCT_GROUP' | 'PRODUCT_GROUP';
export const productGroupLevelTypeList = ['DOMAIN', 'DEPARTMENT', 'MAIN_PRODUCT_GROUP', 'PRODUCT_GROUP'];
export type ProductGroupClassificationItem = (ProductGroupDomain | ProductGroupDepartment | ProductGroupMainProductGroup);

export interface FetchProductGroupLevelItemRequest {
    level: ProductGroupLevelItemType;
    id: string;
    language: LanguageType;
}

export interface FetchProductGroupLevelItemResponse {
    item: ProductGroupClassificationItem;
}

export interface FetchProductGroupLevelItemsPageRequest {
    page: number;
    size: number;
    sort: { field: string, direction: 'ASC' | 'DESC' }[];
    level: ProductGroupLevelItemType;
    language: LanguageType,
    id: string;
    search: string;
}

export interface FetchProductGroupLevelItemsPageResponse extends PageResponse {
    content: ProductGroupLevelItem[];
}

export class ProductGroupLevelItem {
    id: string;
    description: string;
}

export class ProductGroupChildrenResolver {

    public static getChildren(item: ProductGroupClassificationItem, language: LanguageType): (ProductGroupLevelItem & { level: ProductGroupLevelItemType } & { language: LanguageType }) [] {

        if (!!(item as ProductGroupDomain).departmentList) {
            return (item as ProductGroupDomain).departmentList.map(child => {
                return {...child, level: 'DEPARTMENT', language: language};
            });
        }

        if (!!(item as ProductGroupDepartment).mainProductGroupList) {
            return (item as ProductGroupDepartment).mainProductGroupList.map(child => {
                return {...child, level: 'MAIN_PRODUCT_GROUP', language: language};
            });
        }

        if (!!(item as ProductGroupMainProductGroup).productGroupList) {
            return (item as ProductGroupMainProductGroup).productGroupList.map(child => {
                return {...child, level: 'PRODUCT_GROUP', language: language};
            });
        }
        return [];
    }
}

export interface ProductGroupDomain extends ProductGroupLevelItem {
    departmentList: ProductGroupLevelItem[];
}

export class ProductGroupDepartment extends ProductGroupLevelItem {
    mainProductGroupList: ProductGroupLevelItem[];
}

export interface ProductGroupMainProductGroup extends ProductGroupLevelItem {
    productGroupList: ProductGroupLevelItem[];
}

export interface ProductGroupClassificationState {

    pageRequest: FetchProductGroupLevelItemsPageRequest;
    pageResponse: FetchProductGroupLevelItemsPageResponse;

    itemRequest: FetchProductGroupLevelItemRequest;
    itemResponse: FetchProductGroupLevelItemResponse;

    loading: boolean;
    error: string;
}

export interface FetchProductGroupItemAction {
    type: typeof FETCH_PRODUCT_GROUP_ITEM
    itemRequest: FetchProductGroupLevelItemRequest
}

export interface FetchProductGroupItemSucceededAction {
    type: typeof FETCH_PRODUCT_GROUP_ITEM_SUCCEEDED
    payload: FetchProductGroupLevelItemResponse
}

export interface FetchProductGroupItemFailedAction {
    type: typeof FETCH_PRODUCT_GROUP_ITEM_FAILED
    error: string
}

export interface FetchProductGroupItemsAction {
    type: typeof FETCH_PRODUCT_GROUP_ITEMS
    pageRequest: FetchProductGroupLevelItemsPageRequest
}

export interface FetchProductGroupItemsSucceededAction {
    type: typeof FETCH_PRODUCT_GROUP_ITEMS_SUCCEEDED
    payload: FetchProductGroupLevelItemsPageResponse
}

export interface FetchProductGroupItemsFailedAction {
    type: typeof FETCH_PRODUCT_GROUP_ITEMS_FAILED
    error: string
}

export interface ImportClassificationAction {
    type: typeof IMPORT_PRODUCT_GROUP_CLASSIFICATION
}

export interface ImportClassificationSucceededAction {
    type: typeof IMPORT_PRODUCT_GROUP_CLASSIFICATION_SUCCEEDED
}

export interface ImportClassificationFailedAction {
    type: typeof IMPORT_PRODUCT_GROUP_CLASSIFICATION_FAILED
    error: string
}

export interface CloseProductGroupClassificationErrorMessageAction {
    type: typeof CLOSE_PRODUCT_GROUP_CLASSIFICATION_ERROR_MESSAGE
}

export type ProductGroupClassificationsActionTypes =
    FetchProductGroupItemAction
    | FetchProductGroupItemSucceededAction
    | FetchProductGroupItemFailedAction
    | FetchProductGroupItemsAction
    | FetchProductGroupItemsSucceededAction
    | FetchProductGroupItemsFailedAction
    | ImportClassificationAction
    | ImportClassificationSucceededAction
    | ImportClassificationFailedAction
    | CloseProductGroupClassificationErrorMessageAction;


