import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import "./GroupPage.css";
import { Dispatch } from "redux";
import { AppState, store } from "../../store";
import {flowRight} from "lodash";
import { connect } from 'react-redux';
import { UserSession } from "../../store/userSession/types";
import PageWrapper from "../../components/Drawer/PageWrapper";
import { PageHeader } from 'antd';
import { Group, Permission } from "../../store/permissionsManagement/types";
import {
  createGroup,
  fetchGroup,
  fetchGroupSucceeded,
  fetchPermissionsList,
  updateGroup
} from "../../store/permissionsManagement/actions";
import GroupDetailsView from "../../components/GroupDetails/GroupDetailsView";

interface Props extends RouteComponentProps<{id: string}> {
  userSession: UserSession;
  groupId: number;
  groupDetails: Group;
  availablePermissions: Permission[];
  fetchGroupDetails: (id: number) => void;
  createGroup: (name: string, description: string, permissionIds: number[], active: boolean) => void;
  updateGroup: (id: number, name: string, description: string, permissionIds: number[], active: boolean) => void;
  loading?: boolean;
  error?: string;
}

interface State {
}

class GroupPage extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.onGroupCreate = this.onGroupCreate.bind(this);
    this.onGroupUpdate = this.onGroupUpdate.bind(this);
  }

  componentDidMount() {
    store.dispatch(fetchPermissionsList());

    if (this.props.match.params.id) {
      this.props.fetchGroupDetails(parseInt(this.props.match.params.id))
    } else {
      store.dispatch(fetchGroupSucceeded({
        group: null
      }));
    }
  }

  private onGroupCreate(name: string, description: string, permissionIds: number[], active: boolean) {
    this.props.createGroup(name, description, permissionIds, active);
  }

  private onGroupUpdate(id: number, name: string, description: string, permissionIds: number[], active: boolean) {
    this.props.updateGroup(id, name, description, permissionIds, active);
  }

  public render() {

    return (
      <div className="group-page">
        <PageWrapper userSession={this.props.userSession}>
          <PageHeader
            ghost={false}
            onBack={() => window.history.back()}
            title="Group Details"
            subTitle={"General information about group of users with assigned permissions"}
          >
          </PageHeader>
          <GroupDetailsView
            group={this.props.groupDetails}
            permissions={this.props.availablePermissions}
            onGroupCreate={this.onGroupCreate}
            onGroupUpdate={this.onGroupUpdate}
            loading={this.props.loading}
            error={this.props.error}
          />
        </PageWrapper>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  userSession: state.userSession.userSession,

  groupId: state.permissionsManagement.groupId,
  groupDetails: state.permissionsManagement.groupDetails,

  availablePermissions: state.permissionsManagement.permissions,

  loading: state.permissionsManagement.groupDetailsLoading,
  error: state.permissionsManagement.groupDetailsError
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchGroupDetails: (id: number) => {
    dispatch(fetchGroup({id}))
  },
  updateGroup: (id: number, name: string, description: string, permissionIds: number[], active: boolean) => {
    dispatch(updateGroup({
      id,
      name,
      description,
      permissionIds,
      active
    }))
  },
  createGroup: (name: string, description: string, permissionIds: number[], active: boolean) => {
    dispatch(createGroup({
      name,
      description,
      permissionIds,
      active
    }))
  }
});

const enhance = flowRight(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(GroupPage);
