export type LanguageType = 'en_GB' | 'ca_ES' | 'de_AT' | 'de_CH' | 'de_DE' | 'de_LU' | 'el_GR' | 'es_ES' |
    'fr_BE' | 'fr_CH' | 'fr_FR' | 'fr_LU' | 'hu_HU' | 'it_CH' | 'it_IT' | 'nl_BE' | 'nl_NL' | 'no_NO' | 'pl_PL' | 'pt_PT' | 'ru_RU' | 'sv_SE' | 'tr_TR';

export const languageTypeList = ['en_GB', 'ca_ES', 'de_AT', 'de_CH', 'de_DE', 'de_LU', 'el_GR', 'es_ES',
    'fr_BE', 'fr_CH', 'fr_FR', 'fr_LU', 'hu_HU', 'it_CH', 'it_IT', 'nl_BE', 'nl_NL', 'no_NO', 'pl_PL', 'pt_PT', 'ru_RU', 'sv_SE', 'tr_TR'];

export type KindOfProductLanguageType = 'en-GB' | 'ca-ES' | 'de-AT' | 'de-CH' | 'de-DE' | 'de-LU' | 'es-ES' |
    'fr-BE' | 'fr-CH' | 'fr-FR' | 'fr-LU' | 'hu-HU' | 'it-CH' | 'it-IT' | 'nl-BE' | 'nl-NL' | 'no-NO' | 'pl-PL'
    | 'pt-PT' | 'sv_SE' | 'tr_TR';

export const kindOfProductLanguageTypeList = ['en-GB', 'ca-ES', 'de-AT', 'de-CH', 'de-DE', 'de-LU', 'es-ES',
    'fr-BE', 'fr-CH', 'fr-FR', 'fr-LU', 'hu-HU', 'it-CH', 'it-IT', 'nl-BE', 'nl-NL', 'no-NO', 'pl-PL', 'pt-PT',
    'sv-SE', 'tr-TR'];

export type ProductSourceSystem = 'MDNG' | 'ACL_ITALY';

export type ProductPriority = 'DEFAULT' | 'PRIMARY' | 'SECONDARY'

export type Subsidiary = 'AT' | 'BE' | 'CH' | 'DE' | 'ES' | 'HU' | 'IT' | 'LU' | 'NL' | 'PL' | 'PT' | 'SE' | 'TR';
export type PushTarget = 'Sales Product';

export const subsidiariesList = ['AT', 'BE', 'CH', 'DE', 'ES', 'HU', 'IT', 'LU', 'NL', 'PL', 'PT', 'SE', 'TR'];
export const pushTargetList = ['Sales Product'];

export const sourceSystemList = [
    { key: 'MDNG', name: 'MasterData NG', disabled: false },
    { key: 'ACL_ITALY', name: 'ACL Italy', disabled: false }
];

export interface PageResponse {
    first: boolean;
    last: boolean;
    empty: boolean;
    pageable: {
        pageSize: number;
        pageNumber: number;
    };
    perPage: number;
    total: number;
    totalPages: number;
    totalElements: number;
    numberOfElements: number;
}

const pageResponseOriginal: PageResponse = {
    empty: true,
    first: false,
    last: false,
    pageable: {
        pageSize: 10,
        pageNumber: 0
    },
    perPage: 10,
    totalPages: 0,
    total: 0,
    totalElements: 0,
    numberOfElements: 0
};

export const pageResponse = ():PageResponse => {
    return JSON.parse(JSON.stringify(pageResponseOriginal));
};

export const mappingSourceTypeList = [
    { key: 'PRODUCT_GROUP', name: 'PRODUCT GROUP', disabled: false },
    { key: 'FEATURE_FRAME', name: 'FEATURE FRAME', disabled: false },
    { key: 'GPC', name: 'GPC', disabled: true }
];

export type MappingSourceType = 'PRODUCT GROUP' | 'FEATURE FRAME' | 'GPC';
