import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import {
  CREATE_TASK,
  CreateTaskAction,
  FETCH_TASKS,
  FetchTasksAction,
  FetchTasksPageRequest,
  ImportTask,
  UPDATE_TASK,
  UpdateTaskAction
} from './types'
import {
  createTaskFailed,
  createTaskSucceeded,
  fetchTasks,
  fetchTasksFailed,
  fetchTasksSucceeded,
  updateTaskFailed,
  updateTaskSucceeded
} from './actions'

import dataService from '../../services/DataService'
import { message } from "antd";
import { AppState } from "../index";

export function* tasksSaga() {
  yield all([
    watchFetchTasks(),
    watchCreateTask(),
    watchUpdateTask(),
  ])
}

function* watchFetchTasks() {
  yield takeLatest(FETCH_TASKS, handleFetchTasks)
}

function* watchCreateTask() {
  yield takeLatest(CREATE_TASK, handleCreateTask)
}

function* watchUpdateTask() {
  yield takeLatest(UPDATE_TASK, handleUpdateTask)
}

function* handleFetchTasks(action: FetchTasksAction) {
  try {
    const data = yield call([dataService, dataService.getTasks], action.pageRequest);
    yield put(fetchTasksSucceeded(data))
  } catch (error) {
    message.error(error.message);
    yield put(fetchTasksFailed(`${error.message}`))
  }
}

function* handleCreateTask(action: CreateTaskAction) {
  try {
    const data: ImportTask = yield call([dataService, dataService.createTask], action.request);

    message.success("Created task successfully", 5);
    yield put(createTaskSucceeded(data));
  } catch (error) {
    message.error(error.response.data.title);
    yield put(createTaskFailed(`${error.response.data.detail}`))
  }
}

function* handleUpdateTask(action: UpdateTaskAction) {
  try {
    const data: ImportTask = yield call([dataService, dataService.updateTask], action.request);
    yield put(updateTaskSucceeded(data));

    const pageRequest: FetchTasksPageRequest = yield select((state: AppState) => state.tasks.pageRequest);
    if (!!pageRequest) {
      yield put(fetchTasks(pageRequest));
    }
  } catch (error) {
    message.error(error.message);
    yield put(updateTaskFailed(`${error.message}`))
  }
}
