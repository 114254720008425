import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import "./ViewProductGroupClassificationPage.css";
import { Dispatch } from "redux";
import { AppState } from "../../store";
import {flowRight} from "lodash";
import { connect } from 'react-redux';
import { UserSession } from "../../store/userSession/types";
import { Order } from "../../components/EnhancedTable/EnhancedTable";
import PageWrapper from "../../components/Drawer/PageWrapper";
import { PageHeader } from 'antd';
import { FetchProductGroupLevelItemsPageRequest, FetchProductGroupLevelItemsPageResponse } from "../../store/classifications/productGroup/types";
import { fetchProductGroupLevelItems } from "../../store/classifications/productGroup/actions";
import ProductGroupClassificationList from "../../components/ProductGroupClassificationList/ProductGroupClassification";

interface Props extends RouteComponentProps {
  userSession: UserSession;
  pageRequest: FetchProductGroupLevelItemsPageRequest;
  pageResponse: FetchProductGroupLevelItemsPageResponse;
  fetchProductGroupItems: (pageRequest: FetchProductGroupLevelItemsPageRequest) => void;
  loading?: boolean;
  error?: string;
}

interface State {
}

class ViewProductGroupClassificationPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.onPaginationChanged = this.onPaginationChanged.bind(this);
    this.onSortingChanged = this.onSortingChanged.bind(this);
    this.onFilterChanged = this.onFilterChanged.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
  }

  componentDidMount () {
    this.props.fetchProductGroupItems(this.props.pageRequest)
  }

  private onPaginationChanged(page: number, size: number) {
    console.log("Page: " + page + ", size: " + size);

    this.props.fetchProductGroupItems({
      ... this.props.pageRequest,
      page,
      size
    })
  }

  private onRowClick(id: string) {
    this.props.history.push("/app/classification/productGroup/" + this.props.pageRequest.level.toLowerCase() + "/" + id + "/" + this.props.pageRequest.language);
  }

  private onFilterChanged(filterField: string, value: string) {
    console.log("Filter: " + filterField + ", value: " + value);

    this.props.fetchProductGroupItems({
      ... this.props.pageRequest,
      [filterField]: value
    })
  }

  private onSortingChanged(orderBy: string, order: Order) {
    console.log("Order by: " + orderBy + ", order: " + order);

    this.props.fetchProductGroupItems({
      ... this.props.pageRequest,
      sort: [{
        field: orderBy,
        direction: order
      }]
    });
  }

  public render() {
    return (
      <div className="import-tasks-page">
        <PageWrapper userSession={this.props.userSession}>
          <PageHeader
            backIcon={null}
            ghost={false}
            onBack={() => window.history.back()}
            title="Product Group classifications"
            subTitle="Displays list of classification items for the selected classification level"
          >
          </PageHeader>
          <ProductGroupClassificationList
            pageRequest={this.props.pageRequest}
            pageResponse={this.props.pageResponse}
            onPaginationChanged={this.onPaginationChanged}
            onSortingChanged={this.onSortingChanged}
            onFilterChanged={this.onFilterChanged}
            onRowClick={this.onRowClick}
            loading={this.props.loading}
            error={this.props.error}
          />
        </PageWrapper>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  userSession: state.userSession.userSession,
  pageRequest: state.productGroupClassification.pageRequest,
  pageResponse: state.productGroupClassification.pageResponse,
  loading: state.productGroupClassification.loading,
  error: state.productGroupClassification.error,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchProductGroupItems: (pageRequest: FetchProductGroupLevelItemsPageRequest) => {
    dispatch(fetchProductGroupLevelItems(pageRequest))
  }
});

const enhance = flowRight(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(ViewProductGroupClassificationPage);
