import * as React from "react";
import "./FlatIdMapView.css"
import EnhancedTable, { Column } from "../EnhancedTable/EnhancedTable";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import { ListFlatIdFilesPageRequest, ListFlatIdFilesPageResponse } from "../../store/maintenance/types";

export interface FlatIdFilesViewProps {
  listFlatIdFilesPageRequest: ListFlatIdFilesPageRequest;
  listFlatIdFilesPageResponse: ListFlatIdFilesPageResponse;

  onDownloadClick?: (name: string) => void;
  loading: boolean;
  error: string;
}

export const FlatIdFilesView: React.FunctionComponent<FlatIdFilesViewProps> = (props) => {

  const { listFlatIdFilesPageRequest, listFlatIdFilesPageResponse, onDownloadClick, loading } = props;

  const columns: Column[] = [
    {
      label: 'Subsidiary',
      field: 'name',
      type: 'computed',
      computedValue: (file: File) => {
        return (<b>{file.name}</b>)
      }
    },
    {
      label: 'Size',
      field: 'size',
      type: 'computed',
      computedValue: (file: File) => {
        return (<span>{file.size} Mb</span>)
      }
    },
    {
      label: 'Actions',
      field: 'action',
      type: 'computed',
      computedValue: (file: File) => {
        return (<a onClick={() => onDownloadClick(file.name)}>Download</a>)
      }
    }
  ];

  return <React.Fragment>

    {loading && <div>
      <div className="view-loading"/>
      <CircularProgress className="view-loading-spinner"/>
    </div>}

    <EnhancedTable tableName={`Files ${listFlatIdFilesPageRequest.name}`}
                   pagination={false}
                   rowsSelectable={false}
                   columns={columns}
                   data={listFlatIdFilesPageResponse.files}
                   totalCount={listFlatIdFilesPageResponse.files.length}
                   countOnPage={listFlatIdFilesPageResponse.files.length}
                   currentPage={0}
                   pageSize={listFlatIdFilesPageResponse.files.length}
                   sort={[{field: "id", direction: "ASC"}]}/>

    </React.Fragment>;
};

export default FlatIdFilesView;
