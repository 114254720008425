import * as React from "react";
import "./ProductGroupClassification.css"
import EnhancedTable, { Column, Order } from "../EnhancedTable/EnhancedTable";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import { Form } from 'antd';
import {
  FetchProductGroupLevelItemsPageRequest,
  FetchProductGroupLevelItemsPageResponse,
  ProductGroupLevelItem
} from "../../store/classifications/productGroup/types";
import ProductGroupLevelTypeSelect from "../inputs/ProductGroupLevelTypeSelect/ProductGroupLevelTypeSelect";
import LanguageTypeSelect from "../inputs/LanguageTypeSelect/LanguageTypeSelect";
import Search from "antd/es/input/Search";
import {languageTypeList} from "../../store/common/types";

export interface Props {
  pageRequest: FetchProductGroupLevelItemsPageRequest;
  pageResponse: FetchProductGroupLevelItemsPageResponse;

  onPaginationChanged: (page: number, size: number) => void;
  onSortingChanged?: (orderBy: string, order: Order) => void;
  onFilterChanged?: (filterField: string, value: string) => void;
  onRowClick: (id: string) => void;

  loading: boolean;
  error: string;
}

export const ProductGroupClassification: React.FunctionComponent<Props> = (props) => {

  const [form] = Form.useForm();

  const { pageRequest, pageResponse, onPaginationChanged, onSortingChanged, onRowClick, onFilterChanged, loading } = props;

  const columns: Column[] = [
    {
      label: 'Id',
      field: 'id',
      type: 'computed',
      computedValue: (item: ProductGroupLevelItem) => {
        return (<a onClick={() => onRowClick(item.id)}>{item.id}</a>)
      }
    },
    {
      label: 'Description',
      field: 'description',
      type: 'computed',
      computedValue: (item: ProductGroupLevelItem) => {
        return (item.description);
      }
    }
  ];

  const handleFilterChange = (field: string, value: string) => {
    onFilterChanged && onFilterChanged(field, value);
  };

  const formItemLayout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 8 },
  };

  return props.error ?
    (
      <div className="view-error">{props.error}</div>
    )
    :
    (
      <React.Fragment>

        {
          loading && (<div>
            <div className="view-loading"/>
            <CircularProgress className="view-loading-spinner"/>
          </div>)
        }

        <Form
          {...formItemLayout}
          layout={'horizontal'}
          form={form}
        >
          <Form.Item label="Level">
            <ProductGroupLevelTypeSelect id={"productGroup-level-select"}
                                         value={pageRequest.level}
                                         onChange={(value) => handleFilterChange('level', value)}
            />
          </Form.Item>
          <Form.Item label="Language">
            <LanguageTypeSelect id={"language-select"}
                                value={pageRequest.language}
                                list={languageTypeList}
                                onChange={(value) => handleFilterChange('language', value)}
            />
          </Form.Item>
          <Form.Item label={'Search'}>
            <Search
              placeholder="Search by Id or Description"
              enterButton
              size="middle"
              defaultValue={pageRequest.search}
              onSearch={(value) => handleFilterChange('search', value)}
            />
          </Form.Item>
        </Form>

        <EnhancedTable tableName={"Results"}
                       rowsSelectable={false}
                       columns={columns}
                       data={pageResponse.content}
                       totalCount={pageResponse.totalElements}
                       countOnPage={pageResponse.numberOfElements}
                       currentPage={pageRequest.page}
                       pageSize={pageRequest.size}
                       sort={pageRequest.sort}
                       onPaginationChanged={onPaginationChanged}
                       onSortingChanged={onSortingChanged}
        >
          {{
            actionButtons: null
          }}
        </EnhancedTable>
      </React.Fragment>
    );
};

export default ProductGroupClassification;
