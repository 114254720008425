import * as React from "react";
import {Button, Form, message, Radio, Spin, Typography} from "antd";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import {useEffect, useState} from "react";
import DataService from "../../services/DataService";
import KindOfProductLoadableSelect from "../LoadableSelect/KindOfProdactLoadableSelect";
import KindOfProductPredictorService from "../../services/KindOfProductPredictorService";
import {KindOfProductPredictionResponse, Prediction} from "../../store/KindOfProductPredictor/types";

export interface Props {
    coreProductId: number;
    extension?: boolean
}

export const EditGlobalProductView: React.FunctionComponent<Props> = (props) => {

    let form: any;

    const {coreProductId, extension} = props;
    const [coreProduct, setCoreProduct] = useState(null as string);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null as string);
    const [selectedKindOfProduct, setSelectedKindOfProduct] = React.useState(null);
    const [kindOfProductPredictions, setKindOfProductPredictions] = React.useState(null);
    const [resetKOP, setResetKOP] = useState(false);

    const resetKOPForm = () => {
        setResetKOP(!resetKOP);
    }

    const formItemLayout = {
        labelCol: {span: 3},
        wrapperCol: {span: 10},
    };

    const buttonItemLayout = {
        wrapperCol: {span: 10, offset: 3},
    };

    useEffect(() => {

        if (coreProductId) {
            setLoading(true);

            if (extension) {

                DataService
                    .fetchCoreProductExtensionApi(coreProductId.toString())
                    .then(coreProduct => {
                        setLoading(false);
                        setCoreProduct(coreProduct);
                        setSelectedKindOfProduct(JSON.parse(coreProduct).kind_of_product)
                    })
                    .catch(error => {
                        setLoading(false);
                        setError(error.response.data.detail);
                    });

            } else {

                DataService
                    .fetchCoreProductApi(coreProductId.toString())
                    .then(coreProduct => {
                        setLoading(false);
                        setCoreProduct(coreProduct);
                        const coreProductObj = JSON.parse(coreProduct)
                        setSelectedKindOfProduct(coreProductObj.kind_of_product)
                        getKindOfProductPredictions(coreProductObj)
                    })
                    .catch(error => {
                        setLoading(false);
                        setError(error.response.data.detail);
                    });

            }
        } else {
            setLoading(false);
            setCoreProduct(null);
        }
    }, [coreProductId, resetKOP]);

    const renderPredictionResultRadios = () => {
        return <Radio.Group name={"kindOfProduct"} onChange={(e) => {
            setSelectedKindOfProduct(e.target.value)
        }} value={selectedKindOfProduct}>
            {(!kindOfProductPredictions) && <Spin style={{margin: "16px 0"}}
                                                  tip={"Kind of Product predictions are loading, please wait "}/>}
            {kindOfProductPredictions && kindOfProductPredictions.map((kindOfProductPrediction: KindOfProductPredictionResponse, i: number) =>
                <div key={`prediction-${i}`}>
                    <Typography style={{padding: "8px 0", fontWeight: 700}}>
                        {kindOfProductPrediction.country}/{kindOfProductPrediction.product_name}/{kindOfProductPrediction.manufacturer_id}
                    </Typography>
                    <div>
                        {kindOfProductPrediction.predictions && kindOfProductPrediction.predictions.length > 0 &&
                        kindOfProductPrediction.predictions.map((prediction: Prediction, j: Number) =>
                            <Radio style={{width: "100%"}} key={`prediction-item-${j}`} value={prediction.kind_of_product}>
                                <span>{prediction.kind_of_product} </span>
                                <span style={{color: KindOfProductPredictorService.getColor(prediction.probability)}}>{Number((prediction.probability * 100).toFixed(2))}%</span>
                            </Radio>)}
                    </div>
                </div>)}
        </Radio.Group>
    }

    const sendKindOfProductPredictionFeedback = () => {
        if (selectedKindOfProduct && kindOfProductPredictions && kindOfProductPredictions.length > 0) {
            kindOfProductPredictions.forEach ((kindOfProductPrediction: KindOfProductPredictionResponse) => {
                KindOfProductPredictorService.putKindOfProductPredictionFeedback(kindOfProductPrediction.prediction_id, {kind_of_product: selectedKindOfProduct})
            })
        }
    }

    const getKindOfProductPredictions = (coreProductObj: any) => {
        if (coreProductObj) {
            const products = coreProductObj.country_specifics.map ((country_specific: any) => {
                return {
                    source_id: coreProductId,
                    product_name: country_specific.name,
                    manufacturer_id: country_specific.manufacturer?.id,
                    manufacturer_name: country_specific.manufacturer?.name,
                    country: country_specific.country
                }
            })
            KindOfProductPredictorService.getKindOfProductPrediction({
                    products: products
                })
                .then(kindOfProductPredictions => {
                    setKindOfProductPredictions(kindOfProductPredictions.results)
                })
                .catch(error => {
                    setError(error.response.data.detail);
                });
        }
    }

    const onKindOfProductChange = (kindOfProduct: any) => {
        setSelectedKindOfProduct(kindOfProduct);
    }


    const globalProduct = JSON.parse(coreProduct);

    const onSubmit = () => {
        const updateGlobalProductRequest = {
            global_id: globalProduct.global_id,
            kind_of_product: selectedKindOfProduct ? selectedKindOfProduct : globalProduct.kind_of_product
        }
        DataService.updateGlobalProduct(updateGlobalProductRequest).then(() => {
            message.success((<span>Global Product Updated Successfully</span>), 5);
            sendKindOfProductPredictionFeedback()
            resetKOPForm();
        }).catch((e) => {
            message.error((<span>Create Mapping Failed: {e.message}</span>), 5);
        });
    }

    return error ?
        (
            <div className="view-error">{error}</div>
        )
        :
        (
            <React.Fragment>
                {
                    loading && (<div>
                        <div className="view-loading"/>
                        <CircularProgress className="view-loading-spinner"/>
                    </div>)
                }
                {
                    !!coreProduct &&
                    <React.Fragment>
                        <Form
                            {...formItemLayout}
                            layout={'horizontal'}
                            form={form}
                            onFinish={onSubmit}
                        >
                            <Form.Item label="Global Product Id"
                                       name={"globalId"}>
                                <span className="ant-form-text">{globalProduct.global_id}</span>
                            </Form.Item>

                            <Form.Item label={"Target Markets"}
                                       name={"targetMarkets"}>
                                <span className="ant-form-text">{globalProduct.target_markets.toString()}</span>
                            </Form.Item>

                            <Form.Item label="Kind Of Product"
                                       rules={[{required: true, message: 'Please select Kind Of Product'}]}>

                                <KindOfProductLoadableSelect onChange={onKindOfProductChange}
                                                             kindOfProduct={selectedKindOfProduct}
                                                             search={selectedKindOfProduct}/>
                                {renderPredictionResultRadios()}
                            </Form.Item>

                            <Form.Item {...buttonItemLayout}>
                                <Button type="primary" htmlType="submit"
                                        disabled={!selectedKindOfProduct || selectedKindOfProduct == globalProduct?.kind_of_product}>
                                    Update
                                </Button>
                                <span> </span>
                                <Button danger onClick={() => resetKOPForm()}>Cancel</Button>
                            </Form.Item>

                        </Form>
                    </React.Fragment>
                }
            </React.Fragment>
        )
};

export default EditGlobalProductView;
