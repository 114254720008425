import {Reducer} from "redux";
import {
  ADD_PRODUCT,
  ADD_PRODUCT_FAILED,
  ADD_PRODUCT_SUCCEEDED,
  ADD_PRODUCTS,
  ADD_PRODUCTS_FAILED,
  ADD_PRODUCTS_SUCCEEDED,
  CLOSE_ERROR_MESSAGE,
  FETCH_AMBIGUOUS_MPN_STATS,
  FETCH_AMBIGUOUS_MPN_STATS_FAILED,
  FETCH_AMBIGUOUS_MPN_STATS_SUCCEEDED,
  FETCH_CORE_PRODUCT,
  FETCH_CORE_PRODUCT_FAILED,
  FETCH_CORE_PRODUCT_SUCCEEDED,
  FETCH_DUPLICATED_PRODUCTS,
  FETCH_DUPLICATED_PRODUCTS_FAILED,
  FETCH_DUPLICATED_PRODUCTS_SUCCEEDED,
  FETCH_MANUFACTURER_MPN_STATS,
  FETCH_MANUFACTURER_MPN_STATS_FAILED,
  FETCH_MANUFACTURER_MPN_STATS_SUCCEEDED,
  FETCH_PRODUCTS,
  FETCH_PRODUCTS_FAILED,
  FETCH_PRODUCTS_SUCCEEDED,
  FetchProductsActionTypes,
  GENERATE_DUPLICATED_PRODUCTS_REPORT,
  GENERATE_DUPLICATED_PRODUCTS_REPORT_FAILED,
  GENERATE_DUPLICATED_PRODUCTS_REPORT_SUCCEEDED,
  GENERATE_MPN_STATS_REPORT,
  GENERATE_MPN_STATS_REPORT_FAILED,
  GENERATE_MPN_STATS_REPORT_SUCCEEDED,
  GlobalProductsState,
  UPDATE_PRIMARY_PRODUCT,
  UPDATE_PRIMARY_PRODUCT_FAILED,
  UPDATE_PRIMARY_PRODUCT_SUCCEEDED
} from "./types";
import {pageResponse} from "../common/types";

const initialState: GlobalProductsState = {
  coreProductId: null,
  coreProduct: null,
  addProductRequest: {
    productId: null,
    subsidiary: null,
    sourceSystem: null
  },
  addProductResponse: {
    globalId: null
  },
  addProductsRequest: {
    requestItems: [],
  },
  duplicatedProductsPageRequest: {
    page: 0,
    size: 10,
    sort: {
      field: "globalId",
      direction: 'ASC'
    },
    productId: null,
    subsidiary: null,
    sourceSystem: null,
    duplicatesCount: null,
    anyPrimaryAssigned: null,
    createdAtDateRange: null,
    updatedAtDateRange: null
  },
  duplicatedProductsPageResponse: {
    ...pageResponse(),
    content: []
  },
  manufacturerMPNStatsPageRequest: {
    page: 0,
    size: 10,
    sort: {
      field: "manufacturerName",
      direction: 'ASC'
    },
    manufacturer: null,
    mpn: null,
    globalId: null,
    legacyProductId: null,
    globalProductsCount: null
  },
  manufacturerMPNStatsPageResponse: {
    ...pageResponse(),
    content: []
  },
  ambiguousMPNStatsPageRequest: {
    page: 0,
    size: 10,
    sort: {
      field: "globalProductId",
      direction: 'ASC'
    },
    globalProductId: null
  },
  pageRequest: {
    page: 0,
    size: 10,
    sort: {
      field: "id",
      direction: 'ASC'
    },
    filters: [],
    search: null,
    targetMarket: null,
    active: null,
    connectedProducts: null
  },
  pageResponse: {
    ...pageResponse(),
    content: []
  },
  updatePrimaryProductRequest: {
    globalId: null,
    productId: null,
    subsidiary: null,
    sourceSystem: null
  },
  generateDuplicatedProductsReportLoading: false,
  generateManufacturerMPNStatsReportLoading: false,
  loading: false,
  error: null
};

export const productsReducer: Reducer<GlobalProductsState, FetchProductsActionTypes> = (state: GlobalProductsState = initialState, action: FetchProductsActionTypes): GlobalProductsState => {
  switch (action.type) {

    case ADD_PRODUCT: {
      return {
        ... state,
        addProductRequest: {
          ... action.addProductRequest
        },
        loading: true,
        error: null
      }
    }

    case ADD_PRODUCT_SUCCEEDED: {
      return {
        ...state,
        addProductResponse: action.payload,
        loading: false,
        error: null
      }
    }

    case ADD_PRODUCT_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.error
      }
    }

    case ADD_PRODUCTS: {
      return {
        ... state,
        addProductsRequest: action.addProductsRequest,
        loading: true,
        error: null
      }
    }

    case ADD_PRODUCTS_SUCCEEDED: {
      return {
        ...state,
        loading: false,
        error: null
      }
    }

    case ADD_PRODUCTS_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.error
      }
    }

    case FETCH_PRODUCTS: {
      return {
        ... state,
        pageRequest: {
          ... action.pageRequest
        },
        loading: true,
        error: null
      }
    }

    case FETCH_PRODUCTS_SUCCEEDED: {
      return {
        ...state,
        pageResponse: action.payload,
        loading: false,
        error: null
      }
    }

    case FETCH_PRODUCTS_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.error
      }
    }

    case FETCH_DUPLICATED_PRODUCTS: {
      return {
        ... state,
        duplicatedProductsPageRequest: {
          ... action.pageRequest
        },
        loading: true,
        error: null
      }
    }

    case FETCH_DUPLICATED_PRODUCTS_SUCCEEDED: {
      return {
        ...state,
        duplicatedProductsPageResponse: action.payload,
        loading: false,
        error: null
      }
    }

    case FETCH_DUPLICATED_PRODUCTS_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.error
      }
    }

    case GENERATE_DUPLICATED_PRODUCTS_REPORT: {
      return {
        ... state,
        duplicatedProductsPageRequest: {
          ... action.pageRequest
        },
        generateDuplicatedProductsReportLoading: true,
        error: null
      }
    }

    case GENERATE_DUPLICATED_PRODUCTS_REPORT_SUCCEEDED: {
      return {
        ...state,
        generateDuplicatedProductsReportLoading: false,
        error: null
      }
    }

    case GENERATE_DUPLICATED_PRODUCTS_REPORT_FAILED: {
      return {
        ...state,
        generateDuplicatedProductsReportLoading: false,
        error: action.error
      }
    }

    case FETCH_MANUFACTURER_MPN_STATS: {
      return {
        ... state,
        manufacturerMPNStatsPageRequest: {
          ... action.manufacturerMPNStatsPageRequest
        },
        loading: true,
        error: null
      }
    }

    case FETCH_MANUFACTURER_MPN_STATS_SUCCEEDED: {
      return {
        ...state,
        manufacturerMPNStatsPageResponse: action.payload,
        loading: false,
        error: null
      }
    }

    case FETCH_MANUFACTURER_MPN_STATS_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.error
      }
    }

    case FETCH_AMBIGUOUS_MPN_STATS: {
      return {
        ... state,
        ambiguousMPNStatsPageRequest: {
          ... action.ambiguousMPNStatsPageRequest
        },
        loading: true,
        error: null
      }
    }

    case FETCH_AMBIGUOUS_MPN_STATS_SUCCEEDED: {
      return {
        ...state,
        ambiguousMPNStatsPageResponse: action.payload,
        loading: false,
        error: null
      }
    }

    case FETCH_AMBIGUOUS_MPN_STATS_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.error
      }
    }

    case GENERATE_MPN_STATS_REPORT: {
      return {
        ... state,
        manufacturerMPNStatsPageRequest: {
          ... action.manufacturerMPNStatsPageRequest
        },
        generateManufacturerMPNStatsReportLoading: true,
        error: null
      }
    }

    case GENERATE_MPN_STATS_REPORT_SUCCEEDED: {
      return {
        ...state,
        generateManufacturerMPNStatsReportLoading: false,
        error: null
      }
    }

    case GENERATE_MPN_STATS_REPORT_FAILED: {
      return {
        ...state,
        generateManufacturerMPNStatsReportLoading: false,
        error: action.error
      }
    }

    case FETCH_CORE_PRODUCT: {
      return {
        ... state,
        coreProductId: action.id,
        loading: true,
        error: null
      }
    }

    case FETCH_CORE_PRODUCT_SUCCEEDED: {
      return {
        ...state,
        coreProduct: action.payload,
        loading: false,
        error: null
      }
    }

    case FETCH_CORE_PRODUCT_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.error
      }
    }

    case CLOSE_ERROR_MESSAGE: {
      return {
        ...state,
        loading: false,
        error: null
      }
    }

    case UPDATE_PRIMARY_PRODUCT: {
      return {
        ... state,
        updatePrimaryProductRequest: {
          ... action.updatePrimaryProductRequest
        },
        error: null,
        loading: true
      }
    }

    case UPDATE_PRIMARY_PRODUCT_SUCCEEDED: {
      return {
        ...state,
        loading: false
      }
    }

    case UPDATE_PRIMARY_PRODUCT_FAILED: {
      return {
        ...state,
        error: action.error,
        loading: false
      }
    }

    default:
      neverReached(action);
  }

  return state;
};


const neverReached = (never: never) => {};
