import { Reducer } from "redux";
import {
    CLOSE_PRODUCT_GROUP_CLASSIFICATION_ERROR_MESSAGE,
    FETCH_PRODUCT_GROUP_ITEM,
    FETCH_PRODUCT_GROUP_ITEM_FAILED,
    FETCH_PRODUCT_GROUP_ITEM_SUCCEEDED,
    FETCH_PRODUCT_GROUP_ITEMS,
    FETCH_PRODUCT_GROUP_ITEMS_FAILED,
    FETCH_PRODUCT_GROUP_ITEMS_SUCCEEDED,
    IMPORT_PRODUCT_GROUP_CLASSIFICATION,
    IMPORT_PRODUCT_GROUP_CLASSIFICATION_FAILED,
    IMPORT_PRODUCT_GROUP_CLASSIFICATION_SUCCEEDED,
    ProductGroupClassificationsActionTypes,
    ProductGroupClassificationState
} from "./types";
import { pageResponse } from "../../common/types";

const initialState: ProductGroupClassificationState = {
    itemRequest: {
        id: null,
        level: null,
        language: null
    },
    itemResponse: {
        item: null
    },
    pageRequest: {
        page: 0,
        size: 10,
        sort: [
            {
                field: "id",
                direction: 'ASC'
            }
        ],
        level: "DOMAIN",
        language: "de_DE",
        id: null,
        search: null
    },
    pageResponse: {
        ...pageResponse(),
        content: []
    },
    loading: false,
    error: null
};

export const productGroupClassificationsReducer: Reducer<ProductGroupClassificationState, ProductGroupClassificationsActionTypes> = (state: ProductGroupClassificationState = initialState, action: ProductGroupClassificationsActionTypes): ProductGroupClassificationState => {
    switch (action.type) {

        case FETCH_PRODUCT_GROUP_ITEM: {
            return {
                ...state,
                itemRequest: {
                    ...action.itemRequest
                },
                itemResponse: {
                    item: null
                },
                loading: true,
                error: null
            }
        }

        case FETCH_PRODUCT_GROUP_ITEM_SUCCEEDED: {
            return {
                ...state,
                itemResponse: action.payload,
                loading: false,
                error: null
            }
        }

        case FETCH_PRODUCT_GROUP_ITEM_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.error
            }
        }

        case FETCH_PRODUCT_GROUP_ITEMS: {
            return {
                ...state,
                pageRequest: {
                    ...action.pageRequest
                },
                loading: true,
                error: null
            }
        }

        case FETCH_PRODUCT_GROUP_ITEMS_SUCCEEDED: {
            return {
                ...state,
                pageResponse: action.payload,
                loading: false,
                error: null
            }
        }

        case FETCH_PRODUCT_GROUP_ITEMS_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.error
            }
        }

        case IMPORT_PRODUCT_GROUP_CLASSIFICATION: {
            return {
                ...state,
                loading: true,
                error: null
            }
        }

        case IMPORT_PRODUCT_GROUP_CLASSIFICATION_SUCCEEDED: {
            return {
                ...state,
                loading: false,
                error: null
            }
        }

        case IMPORT_PRODUCT_GROUP_CLASSIFICATION_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.error
            }
        }

        case CLOSE_PRODUCT_GROUP_CLASSIFICATION_ERROR_MESSAGE: {
            return {
                ...state,
                loading: false,
                error: null
            }
        }

        default:
            neverReached();
    }

    return state;
};

const neverReached = () => {
};
