import {AxiosInstance} from "axios";
import axiosInstance from "../axiosInstance";
import {resolveApiBaseUrl} from "../environment";
import * as qs from "qs";

import {
    ApplyKindOfProductRecalculationRequest,
    CreateKindOfProductRecalculationRequest,
    FetchKindOfProductRecalculationPageRequest,
    FetchKindOfProductRecalculationPageResponse, KopRecalculation, KopRecalculationReport
} from "../store/kindOfProductRecalculation/types";

class KindOfProductRecalculationService {
    axios: AxiosInstance;

    constructor() {
        this.axios = axiosInstance;
    }

    getKopRecalculations(pageRequest: FetchKindOfProductRecalculationPageRequest): Promise<FetchKindOfProductRecalculationPageResponse> {

        const params = {
            page: pageRequest.page,
            size: pageRequest.size,
            sort: pageRequest.sort.field + (pageRequest.sort.direction && ("," + pageRequest.sort.direction))
        } as any;

        return this.axios.get(`/gateway/core-product-api-internal/api/v1/kop-recalculation`, {
            baseURL: resolveApiBaseUrl(),
            params: params,
            paramsSerializer: {
                serialize: params => {
                    return qs.stringify(params, {
                        arrayFormat: 'repeat'
                    })
                }
            }
        })
            .then((response: any) => {
                return {
                    ...response['data']
                } as FetchKindOfProductRecalculationPageResponse;
            })
            .catch((error: any) => {
                this.handleError(error);
                throw error
            })
    }

    createKopRecalculation(request: CreateKindOfProductRecalculationRequest): Promise<KopRecalculation> {
        return this.axios.post(`/gateway/core-product-api-internal/api/v1/kop-recalculation`, request, {
            headers: {
                'Content-Type': 'application/json'
            },
            baseURL: resolveApiBaseUrl(),
        })
            .then((response: any) => {
                return {
                    ...response['data']
                } as KopRecalculation;
            })
            .catch((error: any) => {
                this.handleError(error);
                throw error
            })
    }

    downloadKopRecalculationReport(id: number): Promise<Boolean> {
        return this.axios.get(`/gateway/core-product-api-internal/api/v1/kop-recalculation-report/${id}`, {
            responseType: 'blob',
            baseURL: resolveApiBaseUrl(),
            paramsSerializer: {
                serialize: params => {
                    return qs.stringify(params, {
                        arrayFormat: 'indices',
                        allowDots: true
                    })
                }
            }
        })
            .then((response: any) => {
                const filename = `kop_recalculation_report_${id}.xlsx`;

                // instantiates an invisible anchor in the DOM tree and fires a click event manually in order to
                // serve the file download request when it is available
                let url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/octet-stream' }));
                let a = document.createElement('a');

                a.href = url;
                a.download = filename;
                a.click();

                return true;
            })
            .catch((error: any) => {
                this.handleError(error);
                throw error
            })
    }

    updateKopRecalculationReport(file: File): Promise<KopRecalculation> {
        let formData = new FormData();
        formData.append("file", file);

        return this.axios.put(`/gateway/core-product-api-internal/api/v1/kop-recalculation-report`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            baseURL: resolveApiBaseUrl()
        })
            .then((response: any) => {
                return {
                    ...response['data']
                } as KopRecalculation;
            })
            .catch((error: any) => {
                this.handleError(error);
                throw error
            })
    }

    applyKindOfProductRecalculation(request: ApplyKindOfProductRecalculationRequest): Promise<KopRecalculation> {
        return this.axios.put(`/gateway/core-product-api-internal/api/v1/kop-recalculation`, request, {
            headers: {
                'Content-Type': 'application/json'
            },
            baseURL: resolveApiBaseUrl(),
        })
            .then((response: any) => {
                return {
                    ...response['data']
                } as KopRecalculation;
            })
            .catch((error: any) => {
                this.handleError(error);
                throw error
            })
    }

    rejectKindOfProductRecalculation(kopRecalculationdId: Number): Promise<KopRecalculation> {
        return this.axios.delete(`/gateway/core-product-api-internal/api/v1/kop-recalculation/${kopRecalculationdId}`, {
            headers: {
                'Content-Type': 'application/json'
            },
            baseURL: resolveApiBaseUrl(),
        })
            .then((response: any) => {
                return {
                    ...response['data']
                } as KopRecalculation;
            })
            .catch((error: any) => {
                this.handleError(error);
                throw error
            })
    }

    handleError(error: any) {
        console.error('KindOf:', error)
    }
}

export default new KindOfProductRecalculationService()
