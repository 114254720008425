import * as React from "react";
import { useEffect, useState } from "react";
import "./CoreProductView.css"
import CoreProductView from "./CoreProductView";
import DataService from "../../services/DataService";
import { GlobalProduct } from "../../store/globalProducts/types";

export interface Props {
  coreProductId: string;
}

export const LoadableCoreProductView: React.FunctionComponent<Props> = (props) => {

  const { coreProductId } = props;

  const [globalProduct, setGlobalProduct] = useState(null as GlobalProduct);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null as string);

  useEffect(() => {

    if (coreProductId) {
      setLoading(true);

      DataService
        .fetchCoreProduct(coreProductId)
        .then(globalProduct => {
          setLoading(false);
          setGlobalProduct(globalProduct);
        })
        .catch(error => {
          setLoading(false);
          setError(error.response.data.detail);
        });
    } else {
      setLoading(false);
      setGlobalProduct(null);
    }
  }, [coreProductId]);

  return (
    <CoreProductView coreProduct={globalProduct} columns={1} loading={loading} error={error} />
    );
};

export default LoadableCoreProductView;
