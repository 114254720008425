import * as React from "react";
import "./ImportGpcClassificationView.css"
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import { Alert, message, Progress } from 'antd';
import { InboxOutlined } from "@ant-design/icons/lib";
import Dragger from "antd/es/upload/Dragger";
import { RcCustomRequestOptions } from "antd/es/upload/interface";

export interface Props {
  onFileUpload: (file: File) => void;
  onMessageClose: () => void;
  importRunning: boolean;
  importProgress: number;
  loading: boolean;
  error: string;
}

export const ImportGpcClassificationView: React.FunctionComponent<Props> = (props) => {

  const { onFileUpload, onMessageClose, importRunning, importProgress, loading, error } = props;

  const draggerProps = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    disabled: importRunning && importProgress < 100.00,

    customRequest: (options: RcCustomRequestOptions) => {
      onFileUpload(options.file);
    },

    beforeUpload(file: File) {

      const isXml = file.type === 'application/xml' || file.type === 'text/xml';
      if (!isXml) {

        message.error('You can only upload XML file');
        return false;
      } else {

        const isLessThan50Mb = file.size / 1024 / 1024 < 50;
        if (!isLessThan50Mb) {
          message.error('XML file must be smaller than 50 MB');
        }
        return isLessThan50Mb;
      }
    }
  };

  return (
    <React.Fragment>

      {
        loading && (<div>
          <div className="view-loading"/>
          <CircularProgress className="view-loading-spinner"/>
        </div>)
      }

      {
        error && (
          <React.Fragment>
            <Alert
              message="Error"
              description={error}
              type="error"
              closable
              onClose={onMessageClose}
            />
            <br/>
          </React.Fragment>
        )
      }

      <Dragger {...draggerProps} height={300}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
        <p className="ant-upload-hint">
          Maximum file size is 50 Mb
        </p>
      </Dragger>
      {importRunning && <Progress percent={importProgress} status={error ? "exception" : (importProgress === 100.00 ? null : "active")} />}

    </React.Fragment>
  );
};

export default ImportGpcClassificationView;
