import {all, call, put, takeLatest} from 'redux-saga/effects'

import {
    ADD_KIND_OF_ITEM,
    AddKindOfItemAction,
    DELETE_KIND_OF_ITEM,
    DeleteKindOfItemAction, FETCH_DEPRECATED_KIND_OF_PRODUCT_ITEMS,
    FETCH_KIND_OF_ITEMS, FetchDeprecatedKindOfProductItemsAction,
    FetchKindOfItemsAction,
    GENERATE_MAPPING,
    GET_KIND_OF_ITEM,
    GetKindOfItemAction,
    UPDATE_KIND_OF_ITEM,
    UpdateKindOfItemAction,
} from './types'
import {
    addKindOfItemFailed,
    addKindOfItemSucceeded,
    deleteKindOfItemFailed,
    deleteKindOfItemSucceeded, fetchDeprecatedKindOfProductItemsFailed, fetchDeprecatedKindOfProductItemsSucceeded,
    fetchKindOfItemsFailed,
    fetchKindOfItemsSucceeded,
    generateKindOfProductMappingReportFailed,
    generateKindOfProductMappingReportSucceeded,
    getKindOfItemFailed,
    getKindOfItemSucceeded,
    updateKindOfItemFailed,
    updateKindOfItemSucceeded
} from './actions'

import kindOfService from '../../services/KindOfProductService'
import {message} from "antd";
import * as React from "react";

export function* kindOfSaga() {
    yield all([
        watchFetchKindOFLevelItems(),
        watchAddKindOFItem(),
        watchGetKindOFItem(),
        watchUpdateKindOFItem(),
        watchDeleteKindOFItem(),
        watchGenerateMapping(),
        watchFetchDeprecatedKindOFProductLevelItems()
    ])
}

function* watchFetchKindOFLevelItems() {
    yield takeLatest(FETCH_KIND_OF_ITEMS, handleFetchKindOfItems)
}

function* handleFetchKindOfItems(action: FetchKindOfItemsAction) {
    try {
        const data = yield call([kindOfService, kindOfService.getKindOfItems], action.pageRequest);
        yield put(fetchKindOfItemsSucceeded(data))
    } catch (error) {
        message.error(error.message);
        yield put(fetchKindOfItemsFailed(`${error.response.data.detail}`))
    }
}

function* watchAddKindOFItem() {
    yield takeLatest(ADD_KIND_OF_ITEM, handleAddKindOfItem)
}

function* handleAddKindOfItem(action: AddKindOfItemAction) {
    try {
        const data = yield call([kindOfService, kindOfService.addKindOfItems], action.addKindOfItemRequest);
        message.success((<span>Kind-of successfully added <b>{data.id}</b></span>), 5);
        yield put(addKindOfItemSucceeded(data))
    } catch (error) {
        message.error(error.message);
        yield put(addKindOfItemFailed(`${error.response.data.detail}`))
    }
}

function* watchGetKindOFItem() {
    yield takeLatest(GET_KIND_OF_ITEM, handleGetKindOfItem)
}

function* handleGetKindOfItem(action: GetKindOfItemAction) {
    try {
        const data = yield call([kindOfService, kindOfService.getKindOfItem], action.code);
        yield put(getKindOfItemSucceeded(data))
    } catch (error) {
        message.error(error.message);
        yield put(getKindOfItemFailed(`${error.response.data.detail}`))
    }
}

function* watchUpdateKindOFItem() {
    yield takeLatest(UPDATE_KIND_OF_ITEM, handleUpdateKindOfItem)
}

function* handleUpdateKindOfItem(action: UpdateKindOfItemAction) {
    try {
        yield call([kindOfService, kindOfService.updateKindOfItem], action.payload);
        message.success((<span>Kind-of successfully updated</span>), 5);
        yield put(updateKindOfItemSucceeded())
    } catch (error) {
        message.error(error.message);
        yield put(updateKindOfItemFailed(`${error.response.data.detail}`))
    }
}

function* watchDeleteKindOFItem() {
    yield takeLatest(DELETE_KIND_OF_ITEM, deleteKindOfItem)
}

function* deleteKindOfItem(action: DeleteKindOfItemAction) {
    try {
        yield call([kindOfService, kindOfService.deleteKindOfItem], action.code);
        message.success((<span>Kind-of successfully deleted</span>), 5);
        yield put(deleteKindOfItemSucceeded())
    } catch (error) {
        message.error(error.message);
        yield put(deleteKindOfItemFailed(`${error.response.data.detail}`))
    }
}

function* watchGenerateMapping() {
    yield takeLatest(GENERATE_MAPPING, handleGenerateMapping)
}

function* handleGenerateMapping(action: FetchKindOfItemsAction) {
    try {
        yield call([kindOfService, kindOfService.getMapping]);
        yield put(generateKindOfProductMappingReportSucceeded())
    } catch (error) {
        message.error(error.message);
        yield put(generateKindOfProductMappingReportFailed(`${error.message}`))
    }
}

function* watchFetchDeprecatedKindOFProductLevelItems() {
    yield takeLatest(FETCH_DEPRECATED_KIND_OF_PRODUCT_ITEMS, handleFetchDeprecatedKindOfProductItems)
}
function* handleFetchDeprecatedKindOfProductItems(action: FetchDeprecatedKindOfProductItemsAction) {
    try {
        const data = yield call([kindOfService, kindOfService.getDeprecatedKindOfProductItems], action.pageRequest);
        yield put(fetchDeprecatedKindOfProductItemsSucceeded(data))
    } catch (error) {
        message.error(error.message);
        yield put(fetchDeprecatedKindOfProductItemsFailed(`${error.response.data.detail}`))
    }
}