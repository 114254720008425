import {Reducer} from "redux";
import {
  CoreProductChangeState,
  FETCH_CORE_PRODUCT_CHANGE,
  FETCH_CORE_PRODUCT_CHANGE_FAILED,
  FETCH_CORE_PRODUCT_CHANGE_SUCCEEDED,
  FetchCoreProductChangeActionTypes
} from "./types";
import {pageResponse} from "../common/types";

const initialState: CoreProductChangeState = {
  coreProductChangeDetailResponse: {
    ...pageResponse(),
    content: null
  },
  coreProductChangeDetailRequest: null,
  coreProductChangeRequest: {
    page: 0,
    size: 10,
    subsidiary: null,
    globalProductId: null
  }, coreProductChangeResponse: {
    ...pageResponse(),
    contentItems: []
  },
  error: null,
  loading: false
};

export const coreProductChangeReducer: Reducer<CoreProductChangeState, FetchCoreProductChangeActionTypes> = (state: CoreProductChangeState = initialState, action: FetchCoreProductChangeActionTypes): CoreProductChangeState => {
  switch (action.type) {

    case FETCH_CORE_PRODUCT_CHANGE: {
      return {
        ... state,
        coreProductChangeRequest: action.request,
        loading: true,
        error: null
      }
    }

    case FETCH_CORE_PRODUCT_CHANGE_SUCCEEDED: {
      return {
        ...state,
        coreProductChangeResponse: action.payload,
        loading: false,
        error: null
      }
    }

    case FETCH_CORE_PRODUCT_CHANGE_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.error
      }
    }

    default:
      neverReached(action);
  }

  return state;
};

const neverReached = (never: never) => {};