import {
  DOWNLOAD_FLAT_ID_FILE,
  DOWNLOAD_FLAT_ID_FILE_FAILED,
  DOWNLOAD_FLAT_ID_FILE_SUCCEEDED,
  DownloadFlatIdFileRequest,
  LIST_FLAT_ID_DIRECTORIES,
  LIST_FLAT_ID_DIRECTORIES_FAILED,
  LIST_FLAT_ID_DIRECTORIES_SUCCEEDED,
  LIST_FLAT_ID_FILES,
  LIST_FLAT_ID_FILES_SUCCEEDED,
  ListFlatIdDirectoriesPageRequest,
  ListFlatIdDirectoriesPageResponse,
  ListFlatIdFilesPageRequest,
  ListFlatIdFilesPageResponse,
  MaintenanceActionTypes,
  PUBLISH_FLAT_ID,
  PUBLISH_FLAT_ID_FAILED,
  PUBLISH_FLAT_ID_SUCCEEDED
} from './types'

export function listFlatIdDirectories(listFlatIdDirectoriesPageRequest: ListFlatIdDirectoriesPageRequest): MaintenanceActionTypes {
  return {
    type: LIST_FLAT_ID_DIRECTORIES,
    listFlatIdDirectoriesPageRequest
  }
}

export function listFlatIdDirectoriesSucceeded(listFlatIdDirectoriesPageResponse: ListFlatIdDirectoriesPageResponse): MaintenanceActionTypes {
  return {
    type: LIST_FLAT_ID_DIRECTORIES_SUCCEEDED,
    listFlatIdDirectoriesPageResponse
  }
}

export function listFlatIdDirectoriesFailed(error: string): MaintenanceActionTypes {
  return {
    type: LIST_FLAT_ID_DIRECTORIES_FAILED,
    error
  }
}

export function listFlatIdFiles(listFlatIdFilesPageRequest: ListFlatIdFilesPageRequest): MaintenanceActionTypes {
  return {
    type: LIST_FLAT_ID_FILES,
    listFlatIdFilesPageRequest
  }
}

export function listFlatIdFilesSucceeded(listFlatIdFilesPageResponse: ListFlatIdFilesPageResponse): MaintenanceActionTypes {
  return {
    type: LIST_FLAT_ID_FILES_SUCCEEDED,
    listFlatIdFilesPageResponse
  }
}

export function listFlatIdFilesFailed(error: string): MaintenanceActionTypes {
  return {
    type: LIST_FLAT_ID_DIRECTORIES_FAILED,
    error
  }
}

export function downloadFlatIdFile(downloadFlatIdFileRequest: DownloadFlatIdFileRequest): MaintenanceActionTypes {
  return {
    type: DOWNLOAD_FLAT_ID_FILE,
    downloadFlatIdFileRequest
  }
}

export function downloadFlatIdFileSucceeded(): MaintenanceActionTypes {
  return {
    type: DOWNLOAD_FLAT_ID_FILE_SUCCEEDED
  }
}

export function downloadFlatIdFileFailed(error: string): MaintenanceActionTypes {
  return {
    type: DOWNLOAD_FLAT_ID_FILE_FAILED,
    error
  }
}

export function publishFlatId(): MaintenanceActionTypes {
  return {
    type: PUBLISH_FLAT_ID
  }
}

export function publishFlatIdSucceeded(): MaintenanceActionTypes {
  return {
    type: PUBLISH_FLAT_ID_SUCCEEDED
  }
}

export function publishFlatIdFailed(error: string): MaintenanceActionTypes {
  return {
    type: PUBLISH_FLAT_ID_FAILED,
    error
  }
}
