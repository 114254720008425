import { Select } from "antd";
import * as React from "react";
import { sourceSystemList } from "../../../store/common/types";

const { Option } = Select;

export interface SelectProps {
  id: string
  value: any;
  mode?: 'multiple' | 'tags';
  onChange: (value: string) => void;
  allEnabled?: boolean;
  notSelectedOption?: boolean;
}

export default function SourceSystemSelect(props: SelectProps) {

  const { id, mode, value, onChange, allEnabled, notSelectedOption } = props;

  return (
    <Select id={id}
            mode={mode}
            value={value}
            placeholder="Select source system"
            onChange={(value) => onChange(value)}>
      {!!notSelectedOption && <Option value="">Not Selected</Option>}
      {
        sourceSystemList.map(sourceSystem => {
          return <Option value={sourceSystem.key} disabled={!!!allEnabled && sourceSystem.disabled}>{sourceSystem.name}</Option>
        })
      }
    </Select>
  )
}
