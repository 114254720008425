import * as React from "react";
import "./GlobalProductsView.css"
import EnhancedTable, { Column, Order } from "../EnhancedTable/EnhancedTable";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import { FetchProductsPageRequest, FetchProductsPageResponse, GlobalProduct } from "../../store/globalProducts/types";
import clsx from "clsx";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { Checkbox, Form, Input } from "antd";
import Search from "antd/es/input/Search";
import SubsidiarySelect from "../inputs/SubsidiarySelect/SubsidiarySelect";


export interface GlobalProductsViewProps {
  pageResponse: FetchProductsPageResponse;
  pageRequest: FetchProductsPageRequest;

  onPaginationChanged: (page: number, size: number) => void;
  onSelectionChanged: (selectedIds: string[]) => void;
  onSortingChanged?: (orderBy: string, order: Order) => void;
  onFilterChanged?: (filterField: string, value: string | boolean) => void;
  onSearchChanged?: (value: string) => void;

  onRowClick?: (id: number) => void;
  loading: boolean;
  error: string;
}

const useStatusColumnStyles = makeStyles((theme: Theme) => createStyles({
  deactivated: {
    color: 'red'
  },
  active: {
    color: 'green'
  }
}));

export const GlobalProductView: React.FunctionComponent<GlobalProductsViewProps> = (props) => {

  const [form] = Form.useForm();

  const formItemLayout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 8 },
  };

  const { pageRequest, pageResponse, onPaginationChanged, onSelectionChanged, onSortingChanged, onFilterChanged, onSearchChanged, loading, onRowClick } = props;

  const statusColumnClasses = useStatusColumnStyles();
  const columns: Column[] = [
    {
      label: 'Global Product ID',
      field: 'id',
      type: 'computed',
      computedValue: (product: GlobalProduct) => {
        return (<a onClick={event => onRowClick(product.id)}>{product.id}</a>)
      },
    },
    {
      label: 'Target Markets',
      field: 'targetMarkets',
      type: 'computed',
      computedValue: (globalProduct: GlobalProduct) => {
        return (globalProduct.targetMarkets.join(', '))
      }
    },
    { label: 'Connected Products', field: 'connectedProductsCount' },
    {
      label: 'Modification Date',
      field: 'globalProductModifiedAt',
      type: 'computed',
      computedValue: (globalProduct: GlobalProduct) => {
        return (<>{new Date(globalProduct.globalProductModifiedAt).toLocaleString()}</>)
      }
    },
    {
      label: 'Status',
      field: 'isDeactivated',
      type: 'computed',
      computedValue: (product: GlobalProduct) => {
        if (product.isDeactivated) {
          return 'NOT ACTIVE'
        } else {
          return 'ACTIVE'
        }
      },
      className: (product: GlobalProduct) => {

        return clsx({
          [statusColumnClasses.deactivated]: product.isDeactivated === true,
          [statusColumnClasses.active]: product.isDeactivated === false,
        })

      }
    },
  ];

  const handleSearchChange = (value: string) => {
    onSearchChanged && onSearchChanged(value);
  };

  const handleConnectedProductsChange = (e: any) => {
    const { value } = e.target;
    const reg = /^\d*(\.\d*)?$/;
    if ((!isNaN(value) && reg.test(value)) || value === '') {
      // @ts-ignore
      onFilterChanged('connectedProducts', value)
    }
  };

  // @ts-ignore
  // @ts-ignore
  return props.error ?
    (
      <div className="view-error">{props.error}</div>
    )
    :
    (
      <React.Fragment>

        {
          loading && (<div>
            <div className="view-loading"/>
            <CircularProgress className="view-loading-spinner"/>
          </div>)
        }

        <Form
          {...formItemLayout}
          layout={'horizontal'}
          form={form}
        >
          <Form.Item label={'Search'}>
            <Search
              placeholder="Search by Global Product ID, product id, name, brand or GTIN"
              enterButton
              size="middle"
              defaultValue={pageRequest.search}
              onSearch={handleSearchChange}
            />
          </Form.Item>

          <Form.Item label="Connected">
            <Input value={pageRequest.connectedProducts}
                   onChange={handleConnectedProductsChange}
                   placeholder="Input a number"
                   maxLength={2}
            />
          </Form.Item>

          <Form.Item label="Status">
            <Checkbox
              indeterminate={pageRequest.active === null}
              onClick={(event) => onFilterChanged('active', pageRequest.active === null ? true : (pageRequest.active === true ? false : null))}
              checked={pageRequest.active !== null && pageRequest.active === true}
            />
          </Form.Item>
        </Form>

        <EnhancedTable tableName={"Results"}
                       rowsSelectable={false}
                       columns={columns}
                       data={pageResponse.content}
                       totalCount={pageResponse.totalElements}
                       countOnPage={pageResponse.numberOfElements}
                       currentPage={pageRequest.page}
                       pageSize={pageRequest.size}
                       sort={[pageRequest.sort]}
                       onPaginationChanged={onPaginationChanged}
                       onSelectionChanged={onSelectionChanged}
                       onSortingChanged={onSortingChanged}
        />
      </React.Fragment>
    );
};

export default GlobalProductView;
