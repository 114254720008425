import {AxiosInstance} from "axios";
import axiosInstance from "../axiosInstance";
import {resolveApiBaseUrl} from "../environment";
import * as qs from "qs";

import {
    FetchProductGroupLevelItemRequest,
    FetchProductGroupLevelItemResponse,
    FetchProductGroupLevelItemsPageRequest,
    FetchProductGroupLevelItemsPageResponse
} from "../store/classifications/productGroup/types";
import {ImportProgressResponse} from "../store/classifications/importProgress/types";

class ProductGroupClassificationService {
    axios: AxiosInstance;

    constructor() {
        this.axios = axiosInstance;
    }

    importProductGroupClassification(): Promise<ImportProgressResponse> {
        return this.axios.post(`/gateway/core-product-classification/api/v1/product-group/import`, null, {
            baseURL: resolveApiBaseUrl()
        }).then((response: any) => {
            return {
                ...response['data']
            } as ImportProgressResponse;
        }).catch((error: any) => {
            this.handleError(error);
            throw error
        })
    }

    getProductGroupItems(pageRequest: FetchProductGroupLevelItemsPageRequest): Promise<FetchProductGroupLevelItemsPageResponse> {

        const params = {
            page: pageRequest.page,
            size: pageRequest.size,
            sort: pageRequest.sort.map(sort => sort.field + (sort.direction && ("," + sort.direction)))
        } as any;

        if (pageRequest.level) {
            params["level"] = pageRequest.level;
        }

        if (pageRequest.id) {
            params["id"] = pageRequest.id;
        }

        if (pageRequest.search) {
            params["search"] = pageRequest.search;
        }

        if (pageRequest.language) {
            params["language"] = pageRequest.language;
        }

        return this.axios.get(`/gateway/core-product-classification/api/v1/product-group/items`, {
            baseURL: resolveApiBaseUrl(),
            params: params,
            paramsSerializer: {
                serialize: params => {
                    return qs.stringify(params, {
                        arrayFormat: 'repeat'
                    })
                }
            }
        })
            .then((response: any) => {
                return {
                    ...response['data']
                } as FetchProductGroupLevelItemsPageResponse;
            })
            .catch((error: any) => {
                this.handleError(error);
                throw error
            })
    }

    getProductGroupItem(itemRequest: FetchProductGroupLevelItemRequest): Promise<FetchProductGroupLevelItemResponse> {

        return this.axios.get(`/gateway/core-product-classification/api/v1/product-group/items/${itemRequest.id}?level=${itemRequest.level}&language=${itemRequest.language}`, {
            baseURL: resolveApiBaseUrl()
        })
            .then((response: any) => {
                return {
                    item: response['data']
                } as FetchProductGroupLevelItemResponse;
            })
            .catch((error: any) => {
                this.handleError(error);
                throw error
            })
    }

    handleError(error: any) {
        console.error('ClassificationService:', error)
    }
}

export default new ProductGroupClassificationService()
