import * as React from "react";
import "./UserDetailsView.css"
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import {
  Alert,
  Button,
  Descriptions,
  Divider,
  Form,
  Modal,
  Select,
  Space,
  Switch,
  Tag,
  Typography
} from 'antd';
import { Group, Permission, User } from "../../store/permissionsManagement/types";
import {ExclamationCircleOutlined} from "@ant-design/icons";

const { Text } = Typography;
const { Option } = Select;
const { confirm } = Modal;

export interface Props {
  user: User;
  groups: Group[];
  permissions: Permission[];
  onUserUpdate: (id: number, groupIds: number[], permissionIds: number[], active: boolean) => void;
  onUserRefresh: (id: number) => void;
  onUserDelete: (id: number) => void;
  loading: boolean;
  error: string;
}

export const UserDetailsView: React.FunctionComponent<Props> = (props: Props) => {

  const { user, groups, permissions, onUserUpdate, onUserRefresh, onUserDelete, loading, error } = props;

  const [form] = Form.useForm();

  const formItemLayout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 10 },
  };

  const buttonItemLayout = {
    wrapperCol: { span: 10, offset: 3 },
  };

  const statusTag = (isActive: boolean) => {
    return (isActive ? (<Tag color="success">ACTIVE</Tag>) : (<Tag color="error">DISABLED</Tag>));
  };

  const [selectedGroups, setSelectedGroups] = React.useState(!!user ? user.groups.map(group => group.id) : []);
  const [selectedPermissions, setSelectedPermissions] = React.useState(!!user ? user.permissions.map(permission => permission.id) : []);
  const [isActive, setActive] = React.useState(!!user ? user.active : false);

  React.useEffect(() => {

    if (!!user) {
      setSelectedGroups(user.groups.map(group => group.id));
      setSelectedPermissions(user.permissions.map(permission => permission.id));
      setActive(user.active)
    } else {
      setSelectedGroups([]);
      setSelectedPermissions([]);
      setActive(false);
    }

  }, [!!user ? user.id : 0]);

  const onSubmit = () => {
    onUserUpdate(user.id, selectedGroups, selectedPermissions, isActive);
  };

  const onRefreshBtnClicked = () => {
    onUserRefresh(user.id);
  };

  const onDeleteBtnClicked = () => {
    onUserDelete(user.id);
  }

  const onDeleteOpenConfirmDialog = () => {
    confirm({
      title: 'Are you sure to delete this user?',
      icon: <ExclamationCircleOutlined />,
      content: 'Deleted users are removed from the Core Product System, not FIFA. ' +
          'If only a domain (External ID) update is intended, please use the Refresh button instead!',
      okText: 'Yes, delete!',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        onDeleteBtnClicked();
      },
      onCancel() {
      },
    });
  };

  const handleGroupsChange = (list: []) => {
    setSelectedGroups(list);
  };

  const handlePermissionsChange = (list: []) => {
    setSelectedPermissions(list);
  };

  const handleActiveFlagChange = (value: boolean) => {
    setActive(value);
  };

  return <React.Fragment>

    {
      loading && (<div>
        <div className="view-loading"/>
        <CircularProgress className="view-loading-spinner"/>
      </div>)
    }

    {
      error && (<React.Fragment>
        <Alert
          message="Error"
          description={error}
          type="error"
        />
        <br/>
      </React.Fragment>)
    }

    {
      !!user &&
      <Descriptions size="small" column={2} bordered>
          <Descriptions.Item label="ID">
              <a>{user.id}</a>
          </Descriptions.Item>
          <Descriptions.Item label="Status">
              <Space>
                {statusTag(user.active)}
              </Space>
          </Descriptions.Item>
          <Descriptions.Item label="Full Name"><Text strong={true}>{user.fullName}</Text>
          </Descriptions.Item>
          <Descriptions.Item label="Email"><a>{user.email}</a>
          </Descriptions.Item>
          <Descriptions.Item label="External ID"><Text code={true}>{user.externalId}</Text>
          </Descriptions.Item>
          <Descriptions.Item label="External System">{user.externalSystem}
          </Descriptions.Item>
          <Descriptions.Item label="Groups" span={2}><Space>{user.groups.map(group => {
            return <Text code>{group.name}</Text>;
          })}</Space>
          </Descriptions.Item>
          <Descriptions.Item label="Permissions" span={2}><Space>{user.permissions.map(permission => {
            return <Text
              code>{permission.operation + "." + permission.domain + (!!permission.domainId ? ("." + permission.domainId) : "")}</Text>;
          })}</Space>
          </Descriptions.Item>
          <Descriptions.Item
              label="Created">{new Date(user.createdAt).toLocaleString()}
          </Descriptions.Item>
          <Descriptions.Item
              label="Created By">{user.createdByFullName}
          </Descriptions.Item>
          <Descriptions.Item
              label="Modified">{new Date(user.updatedAt).toLocaleString()}
          </Descriptions.Item>
          <Descriptions.Item
              label="Modified By">{user.updatedByFullName}
          </Descriptions.Item>
      </Descriptions>
    }
    <br/>
    <Divider orientation={"left"}>
      Edit User
    </Divider>
    <br/>
    <Form
      {...formItemLayout}
      layout={'horizontal'}
      form={form}
      onFinish={onSubmit}
    >

      <Form.Item label="User Groups">
        <Select
          mode="multiple"
          size={"large"}
          placeholder="Please select groups..."
          value={selectedGroups}
          onChange={handleGroupsChange}
          style={{ width: '100%' }}
        >
          {groups.map(group => {
            return (<Option key={group.id} value={group.id}>{group.name}</Option>)
          })}
        </Select>
      </Form.Item>

      <Form.Item label="User Permissions">
        <Select
          mode="multiple"
          size={"large"}
          placeholder="Please select permissions..."
          value={selectedPermissions}
          onChange={handlePermissionsChange}
          style={{ width: '100%' }}
        >
          {permissions.map(permission => {
            return (<Option key={permission.id}
                            value={permission.id}>{permission.operation + "." + permission.domain + (!!permission.domainId ? "." + permission.domainId : "")}</Option>)
          })}
        </Select>
      </Form.Item>

      <Form.Item label="Active">
        <Switch checked={isActive} onChange={handleActiveFlagChange}/>
      </Form.Item>

      <Form.Item {...buttonItemLayout}>
        <Button type="primary" id="update" htmlType="submit">
          Update
        </Button>
        <span> </span>
        <Button type="primary" id="refresh" htmlType="button" style={{backgroundColor: "green", borderColor: "green"}}
            onClick={onRefreshBtnClicked}>
          Refresh
        </Button>
        <span> </span>
        <Button type="primary" danger id="delete" style={{backgroundColor: "red", borderColor: "red"}}
            onClick={onDeleteOpenConfirmDialog}>
          Delete
        </Button>
      </Form.Item>
    </Form>

  </React.Fragment>
};

export default UserDetailsView;
