import {PageResponse} from "../common/types";

export const FETCH_AVOIDANCE_FIX_DISREGARD = 'FETCH_AVOIDANCE_FIX_DISREGARD'
export const CREATE_AVOIDANCE_FIX_DISREGARD = 'CREATE_AVOIDANCE_FIX_DISREGARD'
export const DELETE_AVOIDANCE_FIX_DISREGARD = 'DELETE_AVOIDANCE_FIX_DISREGARD'
export const CREATE_AVOIDANCE_FIX_DISREGARD_SUCCEEDED = 'CREATE_AVOIDANCE_FIX_DISREGARD_SUCCEEDED'
export const FETCH_AVOIDANCE_FIX_DISREGARD_SUCCEEDED = 'FETCH_AVOIDANCE_FIX_DISREGARD_SUCCEEDED'
export const DELETE_AVOIDANCE_FIX_DISREGARD_SUCCEEDED = 'DELETE_AVOIDANCE_FIX_DISREGARD_SUCCEEDED'
export const FETCH_AVOIDANCE_FIX_DISREGARD_FAILED = 'FETCH_AVOIDANCE_FIX_DISREGARD_FAILED'
export const CLOSE_ERROR_MESSAGE = 'CLOSE_ERROR_MESSAGE'

export interface AvoidanceFixDisregardRuleCreateRequest {
  productId: string;
  subsidiary: string;
  sourceSystem: string;
  globalProductId: string;
  gtins: string;
}

export interface AvoidanceFixDisregardRuleCreateResponse {
  id: string;
  productId: string;
  subsidiary: string;
  sourceSystem: string;
  productGlobalId: string;
  gtins: string;
}

export interface AvoidanceFixDisregardRulesPageRequest {
  page: number;
  size: number;
  sort: {field: string, direction: 'ASC' | 'DESC'};
  productId: string;
}

export interface AvoidanceFixDisregardRulesPageResponse extends PageResponse {
  content: AvoidanceFixDisregardRule[];
}

export interface AvoidanceFixDisregardRule {
  id: string;
  productId: string;
  subsidiary: string;
  sourceSystem: string;
  globalProductId: string;
  gtins: string;
}

export interface AvoidanceFixDisregardRulesState {
  rulesPageRequest: AvoidanceFixDisregardRulesPageRequest;
  rulesPageResponse: AvoidanceFixDisregardRulesPageResponse;
  createRequest: AvoidanceFixDisregardRuleCreateRequest;
  createResponse: AvoidanceFixDisregardRuleCreateResponse;
  loading: boolean;
  error: string | null;
  runningAction: string | null;
}

export interface CloseErrorMessageAction {
  type: typeof CLOSE_ERROR_MESSAGE
}

export interface FetchAvoidanceFixDisregardRulesAction {
  type: typeof FETCH_AVOIDANCE_FIX_DISREGARD
  pageRequest: AvoidanceFixDisregardRulesPageRequest
}

export interface FetchAvoidanceFixDisregardRulesSucceededAction {
  type: typeof FETCH_AVOIDANCE_FIX_DISREGARD_SUCCEEDED
  payload: AvoidanceFixDisregardRulesPageResponse
}

export interface FetchAvoidanceFixDisregardRulesFailedAction {
  type: typeof FETCH_AVOIDANCE_FIX_DISREGARD_FAILED
  error: string
}

export interface CreateAvoidanceFixDisregardRuleAction {
  type: typeof CREATE_AVOIDANCE_FIX_DISREGARD
  request: AvoidanceFixDisregardRuleCreateRequest
}

export interface CreateAvoidanceFixDisregardRuleSucceededAction {
  type: typeof CREATE_AVOIDANCE_FIX_DISREGARD_SUCCEEDED
  payload: AvoidanceFixDisregardRuleCreateResponse
}

export interface DeleteAvoidanceFixDisregardRulesAction {
  type: typeof DELETE_AVOIDANCE_FIX_DISREGARD
  request: string[]
}

export interface DeleteAvoidanceFixDisregardRulesSucceededAction {
  type: typeof DELETE_AVOIDANCE_FIX_DISREGARD_SUCCEEDED
}

export type AvoidanceFixDisregardRulesActionTypes = CloseErrorMessageAction | FetchAvoidanceFixDisregardRulesAction
    | FetchAvoidanceFixDisregardRulesSucceededAction | FetchAvoidanceFixDisregardRulesFailedAction | CreateAvoidanceFixDisregardRuleAction
    | CreateAvoidanceFixDisregardRuleSucceededAction | DeleteAvoidanceFixDisregardRulesAction | DeleteAvoidanceFixDisregardRulesSucceededAction;

