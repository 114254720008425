import { PageResponse } from "../common/types";

export const FETCH_CORE_PRODUCT_CHANGE = 'FETCH_CORE_PRODUCT_CHANGE'
export const FETCH_CORE_PRODUCT_CHANGE_SUCCEEDED = 'FETCH_CORE_PRODUCT_CHANGE_SUCCEEDED'
export const FETCH_CORE_PRODUCT_CHANGE_FAILED = 'FETCH_CORE_PRODUCT_CHANGE_FAILED'

export interface FetchCoreProductChangeRequest {
  globalProductId: number;
  subsidiary: string | null;
  page: number;
  size: number;
}

export interface FetchCoreProductChangeResponse extends PageResponse {
  contentItems: ProductChangeResponse[]
}

export interface ProductChangeResponse {
  id: number
  changeType: string
  productChangeType: string | null
  productId: string
  subsidiary: string
  kindOfProduct: string | null
  updatedAt: string
  historyFilePath: string | null
  failureUri: string | null
}

export interface FetchCoreProductChangeDetailRequest {
  id: number
}

export interface FetchCoreProductChangeDetailResponse extends PageResponse {
  content: string
}

export interface FetchCoreProductChangeAction {
  type: typeof FETCH_CORE_PRODUCT_CHANGE
  request: FetchCoreProductChangeRequest
}

export interface FetchCoreProductChangeSucceededAction {
  type: typeof FETCH_CORE_PRODUCT_CHANGE_SUCCEEDED,
  payload: FetchCoreProductChangeResponse
}

export interface FetchCoreProductChangeFailedAction {
  type: typeof FETCH_CORE_PRODUCT_CHANGE_FAILED
  error: string
}

export interface CoreProductChangeState {
  coreProductChangeRequest: FetchCoreProductChangeRequest;
  coreProductChangeResponse: FetchCoreProductChangeResponse;
  coreProductChangeDetailRequest: FetchCoreProductChangeDetailRequest;
  coreProductChangeDetailResponse: FetchCoreProductChangeDetailResponse;
  loading: boolean;
  error: string;
}

export type FetchCoreProductChangeActionTypes =
    FetchCoreProductChangeAction | FetchCoreProductChangeSucceededAction | FetchCoreProductChangeFailedAction;
