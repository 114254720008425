import {
  FailuresStatistics,
  FETCH_STATISTICS_FAILED,
  FETCH_STATISTICS_SUCCEEDED,
  ProductsStatistics,
  START_FETCHING_STATISTICS,
  StatisticsActionTypes,
  STOP_FETCHING_STATISTICS
} from './types'
import { Subsidiary } from "../common/types";

export function startFetchingStatistics(subsidiaries: Subsidiary[], excludeArchivedProducts: boolean): StatisticsActionTypes {
  return {
    type: START_FETCHING_STATISTICS,
    subsidiaries,
    excludeArchivedProducts
  }
}

export function stopFetchingStatistics(): StatisticsActionTypes {
  return {
    type: STOP_FETCHING_STATISTICS
  }
}

export function fetchStatisticsSucceeded(productsStatistics: ProductsStatistics,
                                         failuresStatistics: FailuresStatistics,
                                         importerFailuresCount: number): StatisticsActionTypes {
  return {
    type: FETCH_STATISTICS_SUCCEEDED,
    productsStatistics,
    failuresStatistics,
    importerFailuresCount
  }
}

export function fetchStatisticsFailed(error: string): StatisticsActionTypes {
  return {
    type: FETCH_STATISTICS_FAILED,
    error
  }
}
