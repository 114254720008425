import {
    ClassificationType,
    ImportProgressRequest,
    ImportProgressResponse
} from "../store/classifications/importProgress/types";
import {resolveApiBaseUrl} from "../environment";
import {AxiosInstance} from "axios";
import axiosInstance from "../axiosInstance";


class ImportResponseService {

    axios: AxiosInstance;

    constructor() {
        this.axios = axiosInstance;
    }

    handleError(error: any) {
        console.error('ImportResponseService:', error)
    }

    getImportProgress(request: ImportProgressRequest): Promise<ImportProgressResponse> {

        return this.axios.get(`/gateway/core-product-classification/api/v1/imports/${request.importId}?importType=${request.importType}`, {
            baseURL: resolveApiBaseUrl()
        })
            .then((response: any) => {
                return {
                    ...response['data']
                } as ImportProgressResponse;
            })
            .catch((error: any) => {
                this.handleError(error);
                throw error
            })
    }

    getRunningImport(importType: ClassificationType): Promise<ImportProgressResponse> {

        return this.axios.get(`/gateway/core-product-classification/api/v1/imports/running/${importType}`, {
            baseURL: resolveApiBaseUrl()
        })
            .then((response: any) => {
                return {
                    ...response['data']
                } as ImportProgressResponse;
            })
            .catch((error: any) => {
                this.handleError(error);
                throw error
            })
    }
}

export default new ImportResponseService()
