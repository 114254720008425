import { all, call, put, takeLatest } from 'redux-saga/effects'

import { push } from 'connected-react-router';
import {
  ADD_USER,
  AddUserAction,
  AddUserResponse,
  CREATE_GROUP,
  CREATE_PERMISSION,
  CreateGroupAction,
  CreateGroupResponse,
  CreatePermissionAction,
  CreatePermissionResponse,
  FETCH_GROUP,
  FETCH_GROUPS_LIST,
  FETCH_PERMISSION,
  FETCH_PERMISSIONS_LIST,
  FETCH_USER,
  FETCH_USERS_LIST,
  REFRESH_USER,
  DELETE_USER,

  FetchGroupAction,
  FetchGroupsListAction,
  FetchPermissionAction,
  FetchPermissionsListAction,
  FetchUserAction,
  FetchUsersListAction,
  RefreshUserAction,
  RefreshUserResponse,
  DeleteUserResponse,

  UPDATE_GROUP,
  UPDATE_PERMISSION,
  UPDATE_USER,
  UpdateGroupAction,
  UpdateGroupResponse,
  UpdatePermissionAction,
  UpdatePermissionResponse,
  UpdateUserAction,
  UpdateUserResponse
} from './types'
import {
  addUserFailed,
  addUserSucceeded,
  createGroupFailed,
  createGroupSucceeded,
  createPermissionFailed,
  createPermissionSucceeded,
  fetchGroupFailed,
  fetchGroupsListFailed,
  fetchGroupsListSucceeded,
  fetchGroupSucceeded,
  fetchPermissionFailed,
  fetchPermissionsListFailed,
  fetchPermissionsListSucceeded,
  fetchPermissionSucceeded,
  fetchUserFailed,
  fetchUsersList,
  fetchUsersListFailed,
  fetchUsersListSucceeded,
  fetchUserSucceeded,
  refreshUserSucceeded,
  refreshUserFailed,
  deleteUserSucceeded,
  deleteUserFailed,
  updateGroupFailed,
  updateGroupSucceeded,
  updatePermissionFailed,
  updatePermissionSucceeded,
  updateUserFailed,
  updateUserSucceeded
} from './actions'

import permissionsService from '../../services/PermissionsService'
import { message } from "antd";
import * as React from "react";

export function* permissionsManagementSaga() {
  yield all([
    watchFetchGroupsList(),
    watchFetchPermissionsList(),
    watchFetchUsersList(),
    watchAddUser(),
    watchFetchUser(),
    watchUpdateUser(),
    watchRefreshUser(),
    watchDeleteUser(),
    watchFetchGroup(),
    watchUpdateGroup(),
    watchCreateGroup(),
    watchFetchPermission(),
    watchCreatePermission(),
    watchUpdatePermission(),
  ])
}

function* watchFetchGroupsList() {
  yield takeLatest(FETCH_GROUPS_LIST, handleFetchGroupsList)
}

function* watchFetchPermissionsList() {
  yield takeLatest(FETCH_PERMISSIONS_LIST, handleFetchPermissionsList)
}

function* watchFetchUsersList() {
  yield takeLatest(FETCH_USERS_LIST, handleFetchUsersList)
}

function* watchAddUser() {
  yield takeLatest(ADD_USER, handleAddUser)
}

function* watchFetchUser() {
  yield takeLatest(FETCH_USER, handleFetchUser)
}

function* watchFetchGroup() {
  yield takeLatest(FETCH_GROUP, handleFetchGroup)
}

function* watchUpdateUser() {
  yield takeLatest(UPDATE_USER, handleUpdateUser)
}

function* watchRefreshUser() {
  yield takeLatest(REFRESH_USER, handleRefreshUser)
}

function* watchDeleteUser() {
  yield takeLatest(DELETE_USER, handleDeleteUser)
}

function* watchUpdateGroup() {
  yield takeLatest(UPDATE_GROUP, handleUpdateGroup)
}

function* watchCreateGroup() {
  yield takeLatest(CREATE_GROUP, handleCreateGroup)
}

function* watchCreatePermission() {
  yield takeLatest(CREATE_PERMISSION, handleCreatePermission)
}

function* watchUpdatePermission() {
  yield takeLatest(UPDATE_PERMISSION, handleUpdatePermission)
}

function* watchFetchPermission() {
  yield takeLatest(FETCH_PERMISSION, handleFetchPermission)
}

function* handleFetchGroupsList(action: FetchGroupsListAction) {
  try {
    const data = yield call([permissionsService, permissionsService.fetchGroupsList]);
    yield put(fetchGroupsListSucceeded(data))
  } catch (error) {
    message.error(error.message);
    yield put(fetchGroupsListFailed(`${error.message}`))
  }
}

function* handleFetchPermissionsList(action: FetchPermissionsListAction) {
  try {
    const data = yield call([permissionsService, permissionsService.fetchPermissionsList]);
    yield put(fetchPermissionsListSucceeded(data))
  } catch (error) {
    message.error(error.message);
    yield put(fetchPermissionsListFailed(`${error.message}`))
  }
}

function* handleFetchUsersList(action: FetchUsersListAction) {
  try {
    const data = yield call([permissionsService, permissionsService.fetchUsersList]);
    yield put(fetchUsersListSucceeded(data))
  } catch (error) {
    message.error(error.message);
    yield put(fetchUsersListFailed(`${error.message}`))
  }
}

function* handleAddUser(action: AddUserAction) {
  try {
    const data: AddUserResponse = yield call([permissionsService, permissionsService.addUser], action.payload);

    message.success((<span>Added User: <a href={"/app/settings/permissions-management/users/" + data.content.id}>{data.content.fullName}</a></span>), 5);
    yield put(addUserSucceeded(data));
    yield put(fetchUsersList())
  } catch (error) {
    message.error(error.response.data.title);
    yield put(addUserFailed(`${error.response.data.detail}`))
  }
}

function* handleUpdateUser(action: UpdateUserAction) {
  try {
    const data: UpdateUserResponse = yield call([permissionsService, permissionsService.updateUser], action.payload);

    message.success((<span>User updated</span>), 5);
    yield put(updateUserSucceeded(data));
  } catch (error) {
    message.error(error.response.data.title);
    yield put(updateUserFailed(`${error.response.data.detail}`))
  }
}

function* handleRefreshUser(action: RefreshUserAction) {
  try {
    const data: RefreshUserResponse = yield call([permissionsService, permissionsService.refreshUser], action.payload);

    message.success((<span>User refreshed</span>), 5);
    yield put(refreshUserSucceeded(data));
  } catch (error) {
    message.error(error.response.data.title);
    yield put(refreshUserFailed(`${error.response.data.detail}`))
  }
}

function* handleDeleteUser(action: UpdateUserAction) {
  try {
    const data: DeleteUserResponse = yield call([permissionsService, permissionsService.deleteUser], action.payload);

    message.success((<span>User deleted</span>), 5);
    yield put(deleteUserSucceeded(data));
  } catch (error) {
    message.error(error.response.data.title);
    yield put(deleteUserFailed(`${error.response.data.detail}`))
  }
}

function* handleUpdateGroup(action: UpdateGroupAction) {
  try {
    const data: UpdateGroupResponse = yield call([permissionsService, permissionsService.updateGroup], action.payload);

    message.success((<span>Group updated</span>), 5);
    yield put(updateGroupSucceeded(data));
  } catch (error) {
    message.error(error.response.data.title);
    yield put(updateGroupFailed(`${error.response.data.detail}`))
  }
}

function* handleCreateGroup(action: CreateGroupAction) {
  try {
    const data: CreateGroupResponse = yield call([permissionsService, permissionsService.createGroup], action.payload);

    yield put(createGroupSucceeded(data));
    yield put(push('/app/settings/permissions-management/groups/' + data.group.id));

    message.success((<span>Group created</span>), 5);
  } catch (error) {
    message.error(error.response.data.title);
    yield put(createGroupFailed(`${error.response.data.detail}`))
  }
}

function* handleCreatePermission(action: CreatePermissionAction) {
  try {
    const data: CreatePermissionResponse = yield call([permissionsService, permissionsService.createPermission], action.payload);

    yield put(createPermissionSucceeded(data));
    yield put(push('/app/settings/permissions-management/permissions/' + data.permission.id));

    message.success((<span>Permission created</span>), 5);
  } catch (error) {
    message.error(error.response.data.title);
    yield put(createPermissionFailed(`${error.response.data.detail}`))
  }
}

function* handleUpdatePermission(action: UpdatePermissionAction) {
  try {
    const data: UpdatePermissionResponse = yield call([permissionsService, permissionsService.updatePermission], action.payload);

    message.success((<span>Permission updated</span>), 5);
    yield put(updatePermissionSucceeded(data));
  } catch (error) {
    message.error(error.response.data.title);
    yield put(updatePermissionFailed(`${error.response.data.detail}`))
  }
}

function* handleFetchUser(action: FetchUserAction) {
  try {
    const data = yield call([permissionsService, permissionsService.fetchUser], action.payload);
    yield put(fetchUserSucceeded(data))
  } catch (error) {
    message.error(error.message);
    yield put(fetchUserFailed(`${error.message}`))
  }
}

function* handleFetchGroup(action: FetchGroupAction) {
  try {
    const data = yield call([permissionsService, permissionsService.fetchGroup], action.payload);
    yield put(fetchGroupSucceeded(data))
  } catch (error) {
    message.error(error.message);
    yield put(fetchGroupFailed(`${error.message}`))
  }
}

function* handleFetchPermission(action: FetchPermissionAction) {
  try {
    const data = yield call([permissionsService, permissionsService.fetchPermission], action.payload);
    yield put(fetchPermissionSucceeded(data))
  } catch (error) {
    message.error(error.message);
    yield put(fetchPermissionFailed(`${error.message}`))
  }
}
