import * as React from "react";
import "./GroupsTabView.css"
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import { Button, Form, List, Space, Tag, Typography } from 'antd';
import { Group } from "../../store/permissionsManagement/types";
import { PlusOutlined } from "@ant-design/icons/lib";

const { Text } = Typography;

export interface Props {
  groups: Group[];

  onEdit: (id: number) => void;
  onAdd: () => void;

  loading: boolean;
  error: string;
}

export const GroupsTabView: React.FunctionComponent<Props> = (props) => {

  const [form] = Form.useForm();

  const { groups, error, loading, onEdit, onAdd } = props;

  const statusTag = (isActive: boolean) => {
    return (isActive ? (<Tag color="success">ACTIVE</Tag>) : (<Tag color="error">DISABLED</Tag>));
  };

  return error ?
    (
      <div className="view-error">{error}</div>
    )
    :
    (
      <React.Fragment>

        {
          loading && (<div>
            <div className="view-loading"/>
            <CircularProgress className="view-loading-spinner"/>
          </div>)
        }

        <Form
          layout={'horizontal'}
          form={form}
        >
          <Form.Item>
            <Button type="primary" icon={<PlusOutlined />} onClick={onAdd}>
              Add New Group
            </Button>
          </Form.Item>
        </Form>

        {
          groups && groups.length > 0 &&
          (
            <div>
              <List
                bordered={true}
                itemLayout="horizontal"
                size="large"
                pagination={{
                  onChange: page => {
                  },
                  pageSize: 10,
                  hideOnSinglePage: false
                }}
                dataSource={groups || []}
                renderItem={item => (
                  <List.Item
                    key={item.id}
                    actions={[
                      (statusTag(item.active)),
                      <Button type="primary" size={"small"}
                              onClick={() => onEdit(item.id)}>Details</Button>
                    ]}
                  >
                    <Space direction={"vertical"}>
                      {<Text><b>Name:</b> {item.name}</Text>}
                      {<Text><b>Description:</b> {item.description}</Text>}
                    </Space>
                  </List.Item>
                )}
              />
            </div>
          )
        }

      </React.Fragment>
    );
};

export default GroupsTabView;
