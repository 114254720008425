export const FETCH_IMPORT_CLASSIFICATION_PROGRESS = 'FETCH_IMPORT_CLASSIFICATION_PROGRESS'
export const FETCH_IMPORT_CLASSIFICATION_PROGRESS_CONTINUES = 'FETCH_IMPORT_CLASSIFICATION_PROGRESS_CONTINUES'
export const FETCH_IMPORT_CLASSIFICATION_PROGRESS_SUCCEEDED = 'FETCH_IMPORT_CLASSIFICATION_PROGRESS_SUCCEEDED'
export const FETCH_IMPORT_CLASSIFICATION_PROGRESS_FAILED = 'FETCH_IMPORT_CLASSIFICATION_PROGRESS_FAILED'

export type ClassificationType = 'GPC' | 'PRODUCT_GROUP';

export interface ImportProgressRequest {
    importId: string;
    importType: ClassificationType;
}

export interface ImportProgressResponse {
    importId: string;
    running: boolean;
    progress: number;
    succeeded: boolean;
    error: string;
    importType: ClassificationType;
}

export interface FetchImportProgressAction {
    type: typeof FETCH_IMPORT_CLASSIFICATION_PROGRESS
    request: ImportProgressRequest
}

export interface FetchImportProgressSucceededAction {
    type: typeof FETCH_IMPORT_CLASSIFICATION_PROGRESS_SUCCEEDED
    payload: ImportProgressResponse
}

export interface FetchImportProgressContinuesAction {
    type: typeof FETCH_IMPORT_CLASSIFICATION_PROGRESS_CONTINUES
    payload: ImportProgressResponse
}

export interface FetchImportProgressFailedAction {
    type: typeof FETCH_IMPORT_CLASSIFICATION_PROGRESS_FAILED
    importType: ClassificationType
    error: string
}

export interface ImportProgressState {

    productGroups: {
        request: ImportProgressRequest;
        response: ImportProgressResponse;
        loading: boolean;
        error: string;
    };

    gpc: {
        request: ImportProgressRequest;
        response: ImportProgressResponse;
        loading: boolean;
        error: string;
    };
}

export type ImportProgressActionTypes = FetchImportProgressAction | FetchImportProgressContinuesAction |
    FetchImportProgressSucceededAction | FetchImportProgressFailedAction


