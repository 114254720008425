import {Reducer} from "redux";
import {
    MappingState
} from "./types";

const initialState: MappingState = {

    mappedValues: [],
    mappedValuesLoading: false,
    mappedValuesError: null,
};

export const mappingReducer: Reducer<MappingState> = (state: MappingState = initialState): MappingState => {
    return state;
};
