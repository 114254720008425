import * as React from "react";
import "./UsersTabView.css"
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import { Alert, Button, Form, List, Space, Tag } from 'antd';
import { User } from "../../store/permissionsManagement/types";
import Search from "antd/es/input/Search";

export interface Props {
  users: User[];

  onEdit: (id: number) => void;
  onAdd: (email: string) => void;
  onMessageClose: () => void;

  loading: boolean;
  error: string;
}

export const UsersTabView: React.FunctionComponent<Props> = (props) => {

  const [form] = Form.useForm();
  const [pageSize, setPageSize] = React.useState<number>(10);
  const [currentPage, setCurrentPage] = React.useState<number>(1);

  const formItemLayout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 8 },
  };

  const { users, error, loading, onEdit, onAdd, onMessageClose } = props;

  const [filteredUsers, setFilteredUsers] = React.useState(users);
  const [filteredExactMatchUsers, setFilteredExactMatchUsers] = React.useState(users);
  const [searchLabel, setSearchLabel] = React.useState("Search");
  const [filter, setFilter] = React.useState(null);

  React.useEffect(() => {

    if (!!filter) {

      const filtered = users.filter(user => {
        return user.email.indexOf(filter) > -1
      });

      const filteredExactMatch = users.filter(user => {
        return user.email === filter
      });

      setFilteredUsers(filtered);
      setFilteredExactMatchUsers(filteredExactMatch);
      setSearchLabel(filteredExactMatch.length == 0 ? "Add" : "Search")
    } else {
      setFilteredUsers(users);
      setFilteredExactMatchUsers(users);
      setSearchLabel(users.length == 0 ? "Add" : "Search")
    }

  }, [users, filter]);

  const filterUsers = (filter: string) => {
    setFilter(filter);
  };

  const searchUser = (email: string) => {

    if (filteredExactMatchUsers.length == 0) {
      onAdd(email);
    }
  };

  const statusTag = (isActive: boolean) => {
    return (isActive ? (<Tag color="success">ACTIVE</Tag>) : (<Tag color="error">DISABLED</Tag>));
  };

  return <React.Fragment>

    {
      loading && (<div>
        <div className="view-loading"/>
        <CircularProgress className="view-loading-spinner"/>
      </div>)
    }

    {
      error && (<React.Fragment>
        <Alert
          message="Error"
          description={error}
          type="error"
          closable
          onClose={onMessageClose}
        />
        <br/>
      </React.Fragment>)
    }

    <Form
      {...formItemLayout}
      layout={'horizontal'}
      form={form}
    >
      <Form.Item>
        <Search
          placeholder="Enter email or username"
          enterButton={searchLabel}
          size="middle"
          onChange={value => filterUsers(value.target.value)}
          onSearch={value => searchUser(value)}
        />
      </Form.Item>
    </Form>

    {
      users && users.length > 0 &&
      (
        <div>
          <List
            bordered={true}
            itemLayout="horizontal"
            size="large"
            pagination={{
              onShowSizeChange: (current, pageSize) => {
                setPageSize(pageSize);
              },
              onChange: (page, pageSize) => {
                  setCurrentPage(page);
              },
              pageSize: pageSize,
              current: currentPage,
              hideOnSinglePage: false
            }}
            dataSource={filteredUsers}
            renderItem={item => (
              <List.Item
                key={item.id}
                actions={[
                  (statusTag(item.active)),
                  <Button type="primary" size={"small"}
                          onClick={() => onEdit(item.id)}>Details</Button>
                ]}
              >
                <Space direction={"vertical"}>
                  {item.fullName}
                  {<Space>
                    {(item.email && <a>{item.email}</a>)}
                    {(item.externalId && <span>{item.externalId}</span>)}
                  </Space>
                  }
                </Space>
              </List.Item>
            )}
          />
        </div>
      )
    }

  </React.Fragment>

};

export default UsersTabView;
