import {
  CLOSE_ERROR_MESSAGE,
  CREATE_TASK,
  CREATE_TASK_FAILED,
  CREATE_TASK_SUCCEEDED,
  CreateImportTask,
  FETCH_TASKS,
  FETCH_TASKS_FAILED,
  FETCH_TASKS_SUCCEEDED,
  FetchTasksPageRequest,
  FetchTasksPageResponse,
  ImportTask,
  TasksActionTypes,
  UPDATE_TASK,
  UPDATE_TASK_FAILED,
  UPDATE_TASK_SUCCEEDED,
  UpdateImportTask,
} from './types'

export function closeErrorMessage(): TasksActionTypes {
  return {
    type: CLOSE_ERROR_MESSAGE,
  }
}

export function fetchTasks(pageRequest: FetchTasksPageRequest): TasksActionTypes {
  return {
    type: FETCH_TASKS,
    pageRequest
  }
}

export function fetchTasksSucceeded(payload: FetchTasksPageResponse): TasksActionTypes {
  return {
    type: FETCH_TASKS_SUCCEEDED,
    payload
  }
}

export function fetchTasksFailed(error: string): TasksActionTypes {
  return {
    type: FETCH_TASKS_FAILED,
    error
  }
}

export function createTask(request: CreateImportTask): TasksActionTypes {
  return {
    type: CREATE_TASK,
    request
  }
}

export function createTaskSucceeded(payload: ImportTask): TasksActionTypes {
  return {
    type: CREATE_TASK_SUCCEEDED,
    payload
  }
}

export function createTaskFailed(error: string): TasksActionTypes {
  return {
    type: CREATE_TASK_FAILED,
    error
  }
}

export function updateTask(request: UpdateImportTask): TasksActionTypes {
  return {
    type: UPDATE_TASK,
    request
  }
}

export function updateTaskSucceeded(payload: ImportTask): TasksActionTypes {
  return {
    type: UPDATE_TASK_SUCCEEDED,
    payload
  }
}

export function updateTaskFailed(error: string): TasksActionTypes {
  return {
    type: UPDATE_TASK_FAILED,
    error
  }
}

