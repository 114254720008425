import { all, call, put, takeLatest } from 'redux-saga/effects'

import { START_FETCHING_STATISTICS, StartFetchingStatisticsAction } from './types'
import { fetchStatisticsFailed, fetchStatisticsSucceeded } from './actions'

import statisticsService from '../../services/StatisticsService'

export default function* statisticsSaga() {
  yield all([
    watchStartFetchingStatistics()
  ])
}

function* watchStartFetchingStatistics() {
  yield takeLatest(START_FETCHING_STATISTICS, handleFetchStatistics)
}

function* handleFetchStatistics(action: StartFetchingStatisticsAction) {
  try {
    const statistics = yield all([
      call([statisticsService, statisticsService.getProductsStatistics], action.subsidiaries, action.excludeArchivedProducts),
      call([statisticsService, statisticsService.getFailuresStatistics], action.subsidiaries, action.excludeArchivedProducts),
      call([statisticsService, statisticsService.getImporterFailuresCount], action.subsidiaries)
    ]);
    yield put(fetchStatisticsSucceeded(statistics[0], statistics[1], statistics[2]))
  } catch (error) {
    yield put(fetchStatisticsFailed(`${error.message}`))
  }
}
