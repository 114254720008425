import {PageResponse, ProductPriority, ProductSourceSystem, Subsidiary} from "../common/types";

export const ADD_PRODUCT = 'ADD_PRODUCT'
export const ADD_PRODUCT_SUCCEEDED = 'ADD_PRODUCT_SUCCEEDED'
export const ADD_PRODUCT_FAILED = 'ADD_PRODUCT_FAILED'
export const ADD_PRODUCTS = 'ADD_PRODUCTS'
export const ADD_PRODUCTS_SUCCEEDED = 'ADD_PRODUCTS_SUCCEEDED'
export const ADD_PRODUCTS_FAILED = 'ADD_PRODUCTS_FAILED'
export const FETCH_PRODUCTS = 'FETCH_PRODUCTS'
export const FETCH_PRODUCTS_SUCCEEDED = 'FETCH_PRODUCTS_SUCCEEDED'
export const FETCH_PRODUCTS_FAILED = 'FETCH_PRODUCTS_FAILED'
export const FETCH_CORE_PRODUCT = 'FETCH_CORE_PRODUCT'
export const FETCH_CORE_PRODUCT_SUCCEEDED = 'FETCH_CORE_PRODUCT_SUCCEEDED'
export const FETCH_CORE_PRODUCT_FAILED = 'FETCH_CORE_PRODUCT_FAILED'
export const FETCH_DUPLICATED_PRODUCTS = 'FETCH_DUPLICATED_PRODUCTS'
export const FETCH_DUPLICATED_PRODUCTS_SUCCEEDED = 'FETCH_DUPLICATED_PRODUCTS_SUCCEEDED'
export const FETCH_DUPLICATED_PRODUCTS_FAILED = 'FETCH_DUPLICATED_PRODUCTS_FAILED'
export const GENERATE_DUPLICATED_PRODUCTS_REPORT = 'GENERATE_DUPLICATED_PRODUCTS_REPORT'
export const GENERATE_DUPLICATED_PRODUCTS_REPORT_SUCCEEDED = 'GENERATE_DUPLICATED_PRODUCTS_REPORT_SUCCEEDED'
export const GENERATE_DUPLICATED_PRODUCTS_REPORT_FAILED = 'GENERATE_DUPLICATED_PRODUCTS_REPORT_FAILED'
export const FETCH_MANUFACTURER_MPN_STATS = 'FETCH_MANUFACTURER_MPN_STATS'
export const FETCH_MANUFACTURER_MPN_STATS_SUCCEEDED = 'FETCH_MANUFACTURER_MPN_STATS_SUCCEEDED'
export const FETCH_MANUFACTURER_MPN_STATS_FAILED = 'FETCH_MANUFACTURER_MPN_STATS_FAILED'
export const FETCH_AMBIGUOUS_MPN_STATS = 'FETCH_AMBIGUOUS_MPN_STATS'
export const FETCH_AMBIGUOUS_MPN_STATS_SUCCEEDED = 'FETCH_AMBIGUOUS_MPN_STATS_SUCCEEDED'
export const FETCH_AMBIGUOUS_MPN_STATS_FAILED = 'FETCH_AMBIGUOUS_MPN_STATS_FAILED'
export const GENERATE_MPN_STATS_REPORT = 'GENERATE_MPN_STATS_REPORT'
export const GENERATE_MPN_STATS_REPORT_SUCCEEDED = 'GENERATE_MPN_STATS_REPORT_SUCCEEDED'
export const GENERATE_MPN_STATS_REPORT_FAILED = 'GENERATE_MPN_STATS_REPORT_FAILED'
export const CLOSE_ERROR_MESSAGE = 'CLOSE_ERROR_MESSAGE'
export const UPDATE_PRIMARY_PRODUCT = 'UPDATE_PRIMARY_PRODUCT'
export const UPDATE_PRIMARY_PRODUCT_SUCCEEDED = 'UPDATE_PRIMARY_PRODUCT_SUCCEEDED'
export const UPDATE_PRIMARY_PRODUCT_FAILED = 'UPDATE_PRIMARY_PRODUCT_FAILED'

export interface AddProductRequest {
  productId: string;
  subsidiary: Subsidiary;
  sourceSystem: ProductSourceSystem;
}

export interface AddProductResponse {
  globalId: number;
}

export interface AddProductsRequest {
  requestItems: AddProductRequest[];
}

export interface ManufacturerMPNStats {
  manufacturerCompositeId: string;
  manufacturerPartNumber: string;
  manufacturerName: string;
  globalProductsCount: number;
  globalProducts: string[];
  productIds: string[];
}

export interface FetchManufacturerMPNStatsPageRequest {
  page: number;
  size: number;
  sort: {field: string, direction: 'ASC' | 'DESC'};
  manufacturer: string;
  mpn: string;
  globalId: number;
  legacyProductId: string;
  globalProductsCount: number;
}

export interface FetchManufacturerMPNStatsPageResponse extends PageResponse {
  content: ManufacturerMPNStats[];
}

export interface AmbiguousMPNStat {
  globalProductId: string;
  manufacturerPartNumbers: string;
  targetMarkets: string;
}

export interface FetchAmbiguousMPNStatsPageRequest {
  page: number;
  size: number;
  sort: {field: string, direction: 'ASC' | 'DESC'};
  globalProductId: number;
}

export interface FetchAmbiguousMPNStatsPageResponse extends PageResponse {
  content: AmbiguousMPNStat[];
}

export interface DuplicatedProduct {
  globalId: number;
  productIds: string[];
  subsidiary: Subsidiary;
  sourceSystem: ProductSourceSystem;
  productsCount: number;
}

export interface FetchDuplicatedProductsPageRequest {
  page: number;
  size: number;
  sort: {field: string, direction: 'ASC' | 'DESC'};
  subsidiary: Subsidiary;
  sourceSystem: ProductSourceSystem;
  productId: string;
  duplicatesCount: number;
  anyPrimaryAssigned: boolean;
  createdAtDateRange: DateRange;
  updatedAtDateRange: DateRange;
}

export interface FetchDuplicatedProductsPageResponse extends PageResponse {
  content: DuplicatedProduct[];
}

export interface FetchProductsPageRequest {
  page: number;
  size: number;
  sort: {field: string, direction: 'ASC' | 'DESC'};
  filters: FilterData[];
  search: string;
  targetMarket: Subsidiary;
  active: boolean;
  connectedProducts: number;
}

export interface FilterData {
  field: string;
  values: string[];
}

export interface FetchProductsPageResponse extends PageResponse {
  content: GlobalProduct[];
}

export interface GlobalProduct {
  id: number;
  legacyProducts: ImportProduct[];
  connectedProductsCount: number;
  globalProductModifiedAt: Date;
  targetMarkets: Subsidiary[];
  isDeactivated: boolean;
  deactivatedAt: Date;
}

export interface ImportProduct {
  productId: string;
  name: string;
  brand: string;
  gtins: string;
  manufacturerPartNumber: string;
  subsidiary: string;
  sourceSystem: string;
  sourceSystemUpdatedAt: Date;
  modifiedAt: Date;
  productContent: string;
  priority: ProductPriority;
}

export interface UpdatePrimaryProductRequest {
  globalId: number;
  productId: string;
  subsidiary: Subsidiary;
  sourceSystem: ProductSourceSystem
}

export interface GlobalProductsState {
  coreProduct: GlobalProduct;
  coreProductId: string;
  pageRequest: FetchProductsPageRequest;
  pageResponse: FetchProductsPageResponse;
  addProductRequest: AddProductRequest;
  addProductResponse: AddProductResponse;
  addProductsRequest: AddProductsRequest;
  duplicatedProductsPageRequest: FetchDuplicatedProductsPageRequest;
  duplicatedProductsPageResponse: FetchDuplicatedProductsPageResponse;
  generateDuplicatedProductsReportLoading: boolean;
  manufacturerMPNStatsPageRequest: FetchManufacturerMPNStatsPageRequest;
  manufacturerMPNStatsPageResponse: FetchManufacturerMPNStatsPageResponse;
  ambiguousMPNStatsPageRequest: FetchAmbiguousMPNStatsPageRequest;
  ambiguousMPNStatsPageResponse: FetchAmbiguousMPNStatsPageResponse;
  generateManufacturerMPNStatsReportLoading: boolean;
  updatePrimaryProductRequest: UpdatePrimaryProductRequest;
  loading: boolean;
  error: string;
}

export interface CloseErrorMessageAction {
  type: typeof CLOSE_ERROR_MESSAGE
}

export interface AddProductAction {
  type: typeof ADD_PRODUCT
  addProductRequest: AddProductRequest
}

export interface AddProductSucceededAction {
  type: typeof ADD_PRODUCT_SUCCEEDED
  payload: AddProductResponse
}

export interface AddProductFailedAction {
  type: typeof ADD_PRODUCT_FAILED
  error: string
}

export interface AddProductsAction {
  type: typeof ADD_PRODUCTS
  addProductsRequest: AddProductsRequest
}

export interface AddProductsSucceededAction {
  type: typeof ADD_PRODUCTS_SUCCEEDED
}

export interface AddProductsFailedAction {
  type: typeof ADD_PRODUCTS_FAILED
  error: string
}

export interface FetchProductsAction {
  type: typeof FETCH_PRODUCTS
  pageRequest: FetchProductsPageRequest
}

export interface FetchProductsSucceededAction {
  type: typeof FETCH_PRODUCTS_SUCCEEDED
  payload: FetchProductsPageResponse
}

export interface FetchProductsFailedAction {
  type: typeof FETCH_PRODUCTS_FAILED
  error: string
}

export interface FetchDuplicatedProductsAction {
  type: typeof FETCH_DUPLICATED_PRODUCTS
  pageRequest: FetchDuplicatedProductsPageRequest
}

export interface FetchDuplicatedProductsSucceededAction {
  type: typeof FETCH_DUPLICATED_PRODUCTS_SUCCEEDED
  payload: FetchDuplicatedProductsPageResponse
}

export interface FetchDuplicatedProductsFailedAction {
  type: typeof FETCH_DUPLICATED_PRODUCTS_FAILED
  error: string
}

export interface GenerateDuplicatedProductsReportAction {
  type: typeof GENERATE_DUPLICATED_PRODUCTS_REPORT
  pageRequest: FetchDuplicatedProductsPageRequest
}

export interface GenerateDuplicatedProductsReportSucceededAction {
  type: typeof GENERATE_DUPLICATED_PRODUCTS_REPORT_SUCCEEDED
}

export interface GenerateDuplicatedProductsReportFailedAction {
  type: typeof GENERATE_DUPLICATED_PRODUCTS_REPORT_FAILED
  error: string
}

export interface FetchManufacturerMPNStatsAction {
  type: typeof FETCH_MANUFACTURER_MPN_STATS
  manufacturerMPNStatsPageRequest: FetchManufacturerMPNStatsPageRequest
}

export interface FetchManufacturerMPNStatsSucceededAction {
  type: typeof FETCH_MANUFACTURER_MPN_STATS_SUCCEEDED
  payload: FetchManufacturerMPNStatsPageResponse
}

export interface FetchManufacturerMPNStatsFailedAction {
  type: typeof FETCH_MANUFACTURER_MPN_STATS_FAILED
  error: string
}

export interface FetchAmbiguousMPNStatsAction {
  type: typeof FETCH_AMBIGUOUS_MPN_STATS
  ambiguousMPNStatsPageRequest: FetchAmbiguousMPNStatsPageRequest
}

export interface FetchAmbiguousMPNStatsSucceededAction {
  type: typeof FETCH_AMBIGUOUS_MPN_STATS_SUCCEEDED
  payload: FetchAmbiguousMPNStatsPageResponse
}

export interface FetchAmbiguousMPNStatsFailedAction {
  type: typeof FETCH_AMBIGUOUS_MPN_STATS_FAILED
  error: string
}

export interface GenerateMPNStatsReportAction {
  type: typeof GENERATE_MPN_STATS_REPORT
  manufacturerMPNStatsPageRequest: FetchManufacturerMPNStatsPageRequest
}

export interface GenerateMPNStatsReportSucceededAction {
  type: typeof GENERATE_MPN_STATS_REPORT_SUCCEEDED
}

export interface GenerateMPNStatsReportFailedAction {
  type: typeof GENERATE_MPN_STATS_REPORT_FAILED
  error: string
}

export interface FetchCoreProductAction {
  type: typeof FETCH_CORE_PRODUCT
  id: string
}

export interface FetchCoreProductSucceededAction {
  type: typeof FETCH_CORE_PRODUCT_SUCCEEDED
  payload: GlobalProduct
}

export interface FetchCoreProductFailedAction {
  type: typeof FETCH_CORE_PRODUCT_FAILED
  error: string
}

export interface UpdatePrimaryProductAction {
  type: typeof UPDATE_PRIMARY_PRODUCT
  updatePrimaryProductRequest: UpdatePrimaryProductRequest
}

export interface UpdatePrimaryProductSucceededAction {
  type: typeof UPDATE_PRIMARY_PRODUCT_SUCCEEDED
}

export interface UpdatePrimaryProductFailedAction {
  type: typeof UPDATE_PRIMARY_PRODUCT_FAILED
  error: string
}

export interface UpdateGlobalProductRequest {
  global_id: string;
  kind_of_product: string;
}

export interface DateRange {
  startDate: Date;
  endDate: Date;
}

export type FetchProductsActionTypes = AddProductAction | AddProductSucceededAction
    | AddProductFailedAction | FetchProductsAction | FetchProductsSucceededAction | FetchProductsFailedAction
    | FetchDuplicatedProductsAction | FetchDuplicatedProductsSucceededAction | FetchDuplicatedProductsFailedAction
    | GenerateDuplicatedProductsReportAction | GenerateDuplicatedProductsReportSucceededAction | GenerateDuplicatedProductsReportFailedAction
    | FetchManufacturerMPNStatsAction | FetchManufacturerMPNStatsSucceededAction | FetchManufacturerMPNStatsFailedAction
    | FetchAmbiguousMPNStatsAction | FetchAmbiguousMPNStatsSucceededAction | FetchAmbiguousMPNStatsFailedAction
    | GenerateMPNStatsReportAction | GenerateMPNStatsReportSucceededAction | GenerateMPNStatsReportFailedAction
    | FetchCoreProductAction | FetchCoreProductSucceededAction | FetchCoreProductFailedAction | CloseErrorMessageAction
    | UpdatePrimaryProductAction | UpdatePrimaryProductSucceededAction | UpdatePrimaryProductFailedAction
    | AddProductsAction | AddProductsSucceededAction | AddProductsFailedAction;

