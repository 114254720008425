import { Select } from "antd";
import * as React from "react";

const { Option } = Select;

export interface Props {
  id: string
  value: any;
  list: string[];
  mode?: 'multiple' | 'tags';
  onChange: (value: string) => void;
}

export default function LanguageTypeSelect(props: Props) {

  const { id, mode, value, onChange, list } = props;

  return (
    <Select id={id}
            mode={mode}
            value={value}
            placeholder="Select level"
            onChange={(value) => onChange(value)}>
      {
        list.map(language => {
          return <Option value={language}>{language}</Option>
        })
      }
    </Select>
  )
}
