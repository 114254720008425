export const LIST_FLAT_ID_DIRECTORIES = 'LIST_FLAT_ID_DIRECTORIES'
export const LIST_FLAT_ID_DIRECTORIES_SUCCEEDED = 'LIST_FLAT_ID_DIRECTORIES_SUCCEEDED'
export const LIST_FLAT_ID_DIRECTORIES_FAILED = 'LIST_FLAT_ID_DIRECTORIES_FAILED'
export const LIST_FLAT_ID_FILES = 'LIST_FLAT_ID_FILES'
export const LIST_FLAT_ID_FILES_SUCCEEDED = 'LIST_FLAT_ID_FILES_SUCCEEDED'
export const LIST_FLAT_ID_FILES_FAILED = 'LIST_FLAT_ID_FILES_FAILED'
export const DOWNLOAD_FLAT_ID_FILE = 'DOWNLOAD_FLAT_ID_FILE'
export const DOWNLOAD_FLAT_ID_FILE_FAILED = 'DOWNLOAD_FLAT_ID_FILE_FAILED'
export const DOWNLOAD_FLAT_ID_FILE_SUCCEEDED = 'DOWNLOAD_FLAT_ID_FILE_SUCCEEDED'
export const PUBLISH_FLAT_ID = 'PUBLISH_FLAT_ID'
export const PUBLISH_FLAT_ID_FAILED = 'PUBLISH_FLAT_ID_FAILED'
export const PUBLISH_FLAT_ID_SUCCEEDED = 'PUBLISH_FLAT_ID_SUCCEEDED'

export interface ListFlatIdDirectoriesPageRequest {
}

export interface ListFlatIdDirectoriesPageResponse {
  directories: Directory[];
}

export interface ListFlatIdFilesPageRequest {
  name: string;
}

export interface ListFlatIdFilesPageResponse {
  files: File[];
}

export interface DownloadFlatIdFileRequest {
  directory: string,
  file: string
}

export interface Directory {
  name: string
}

export interface File {
  name: string,
  size: number
}

export interface MaintenanceState {
  listFlatIdDirectoriesPageRequest: ListFlatIdDirectoriesPageRequest,
  listFlatIdDirectoriesPageResponse: ListFlatIdDirectoriesPageResponse,
  listFlatIdFilesPageRequest: ListFlatIdFilesPageRequest,
  listFlatIdFilesPageResponse: ListFlatIdFilesPageResponse,
  downloadFlatIdFileRequest: DownloadFlatIdFileRequest,
  loading: boolean;
  error: string;
}

export interface ListFlatIdDirectoriesAction {
  type: typeof LIST_FLAT_ID_DIRECTORIES
  listFlatIdDirectoriesPageRequest: ListFlatIdDirectoriesPageRequest
}

export interface ListFlatIdDirectoriesSucceededAction {
  type: typeof LIST_FLAT_ID_DIRECTORIES_SUCCEEDED
  listFlatIdDirectoriesPageResponse: ListFlatIdDirectoriesPageResponse
}

export interface ListFlatIdDirectoriesFailedAction {
  type: typeof LIST_FLAT_ID_DIRECTORIES_FAILED
  error: string
}

export interface ListFlatIdFilesAction {
  type: typeof LIST_FLAT_ID_FILES
  listFlatIdFilesPageRequest: ListFlatIdFilesPageRequest
}

export interface ListFlatIdFilesSucceededAction {
  type: typeof LIST_FLAT_ID_FILES_SUCCEEDED
  listFlatIdFilesPageResponse: ListFlatIdFilesPageResponse
}

export interface ListFlatIdFilesFailedAction {
  type: typeof LIST_FLAT_ID_FILES_FAILED
  error: string
}

export interface DownloadFlatIdFileAction {
  type: typeof DOWNLOAD_FLAT_ID_FILE
  downloadFlatIdFileRequest: DownloadFlatIdFileRequest
}

export interface DownloadFlatIdFileSucceededAction {
  type: typeof DOWNLOAD_FLAT_ID_FILE_SUCCEEDED
}

export interface DownloadFlatIdFileFailedAction {
  type: typeof DOWNLOAD_FLAT_ID_FILE_FAILED
  error: string
}

export interface PublishFlatIdAction {
  type: typeof PUBLISH_FLAT_ID
}

export interface PublishFlatIdSucceededAction {
  type: typeof PUBLISH_FLAT_ID_SUCCEEDED
}

export interface PublishFlatIdFailedAction {
  type: typeof PUBLISH_FLAT_ID_FAILED,
  error: string
}

export type MaintenanceActionTypes = ListFlatIdDirectoriesAction | ListFlatIdDirectoriesSucceededAction | ListFlatIdDirectoriesFailedAction
    | ListFlatIdFilesAction | ListFlatIdFilesSucceededAction | ListFlatIdFilesFailedAction
    | DownloadFlatIdFileAction | DownloadFlatIdFileSucceededAction | DownloadFlatIdFileFailedAction
    | PublishFlatIdAction | PublishFlatIdSucceededAction | PublishFlatIdFailedAction;

