import * as React from "react";
import {RouteComponentProps, withRouter} from "react-router-dom";
import "./KindOfProductRecalculationPage.css";
import {Dispatch} from "redux";
import {AppState} from "../../store";
import {connect} from 'react-redux';
import {UserSession} from "../../store/userSession/types";
import {Order} from "../../components/EnhancedTable/EnhancedTable";
import PageWrapper from "../../components/Drawer/PageWrapper";
import {PageHeader} from 'antd';
import {flowRight} from "lodash";
import KindOfProductRecalculationView from "../../components/KindOfProductRecalculation/KindOfProductRecalculationView";
import {
    ApplyKindOfProductRecalculationRequest,
    CreateKindOfProductRecalculationRequest,
    FetchKindOfProductRecalculationPageRequest,
    FetchKindOfProductRecalculationPageResponse,
} from "../../store/kindOfProductRecalculation/types";
import {
    fetchKindOfProductRecalculations,
    createKindOfProductRecalculation,
    downloadKindOfProductRecalculationReport,
    updateKindOfProductRecalculationReport,
    applyKindOfProductRecalculation, rejectKindOfProductRecalculation
} from "../../store/kindOfProductRecalculation/actions";

interface Props extends RouteComponentProps {
    userSession: UserSession;
    pageRequest: FetchKindOfProductRecalculationPageRequest;
    pageResponse: FetchKindOfProductRecalculationPageResponse;
    fetchKindOfProductRecalculations: (pageRequest: FetchKindOfProductRecalculationPageRequest) => void;
    globalProductIDs: string;
    createKindOfProductRecalculation: (request: CreateKindOfProductRecalculationRequest) => void;
    downloadKindOfProductRecalculationReport: (request: number) => void;
    updateKindOfProductRecalculationReport: (request: File) => void;
    applyKindOfProductRecalculation: (request: ApplyKindOfProductRecalculationRequest) => void;
    rejectKindOfProductRecalculation: (request: number) => void;
    loading: boolean;
    error: string;
}

interface State {
    selectedId: string | null,
    globalProductIDs: string | null
}

class KindOfProductRecalculationPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.onPaginationChanged = this.onPaginationChanged.bind(this);
        this.onFormFieldChanged = this.onFormFieldChanged.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.onSortingChanged = this.onSortingChanged.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onDownloadReport = this.onDownloadReport.bind(this);
        this.onUpdateReport = this.onUpdateReport.bind(this);
        this.onApplyKindOfProductRecalculation = this.onApplyKindOfProductRecalculation.bind(this);
        this.onRejectKindOfProductRecalculation = this.onRejectKindOfProductRecalculation.bind(this);

        this.state = {
            selectedId: null,
            globalProductIDs: null
        };
    }

    componentDidMount() {
        this.props.fetchKindOfProductRecalculations(this.props.pageRequest)
    }

    private onPaginationChanged(page: number, size: number) {
        this.props.fetchKindOfProductRecalculations({
            ...this.props.pageRequest,
            page,
            size
        })
    }

    private onSelectionChanged(selectedId: string) {
        this.setState({
            ...this.state,
            selectedId
        }, () => {
            console.log(selectedId);
        });

    }

    private onSortingChanged(orderBy: string, order: Order) {
        console.log("Order by: " + orderBy + ", order: " + order);

        this.props.fetchKindOfProductRecalculations({
            ...this.props.pageRequest,
            sort: {
                field: orderBy,
                direction: order
            }
        });
    }

    private onFormFieldChanged(filterField: string, value: string) {
        this.setState({
            ...this.state,
            [filterField]: value
        }, () => {
            console.log("Filter: " + filterField + ", value: " + value);
        })
    }

    private onSubmit() {
        let arr = this.state.globalProductIDs;
        const globalProductIDs = arr.split(",")
        if (globalProductIDs.length > 10000) {
            alert("Request is not approved. Max number of items is 10k")
        } else {
            const createKopRequest = {
                globalProductIds: globalProductIDs.map(string => Number(string))
            } as CreateKindOfProductRecalculationRequest;

            this.props.createKindOfProductRecalculation(createKopRequest);
        }
    }

    private onDownloadReport(id: number) {
        this.props.downloadKindOfProductRecalculationReport(id);
    }

    private onUpdateReport(report: File) {
        this.props.updateKindOfProductRecalculationReport(report);
    }

    private onApplyKindOfProductRecalculation(id: number) {
        const request = {kopRecalculationId: id} as ApplyKindOfProductRecalculationRequest;
        this.props.applyKindOfProductRecalculation(request);
    }

    private onRejectKindOfProductRecalculation(id: number) {
        this.props.rejectKindOfProductRecalculation(id);
    }

    public render() {
        return (
            <div className="products-page">
                <PageWrapper userSession={this.props.userSession}>
                    <PageHeader
                        backIcon={null}
                        ghost={false}
                        onBack={() => window.history.back()}
                        title="Kind of Product Recalculation"
                        subTitle="Presents Kind of Product Recalculation Requests, Recalculation Reports, and allows to
                                  update Global Product KOPs in bulk"
                    >
                    </PageHeader>
                    <KindOfProductRecalculationView
                        pageRequest={this.props.pageRequest}
                        pageResponse={this.props.pageResponse}
                        onFormFieldChange={this.onFormFieldChanged}
                        onPaginationChanged={this.onPaginationChanged}
                        onSortingChanged={this.onSortingChanged}
                        globalProductIDs={this.props.globalProductIDs}
                        loading={this.props.loading}
                        error={this.props.error}
                        onSubmit={this.onSubmit}
                        onDownloadReport={this.onDownloadReport}
                        onFileUpload={this.onUpdateReport}
                        onApplyKindOfProductRecalculation={this.onApplyKindOfProductRecalculation}
                        onRejectKindOfProductRecalculation={this.onRejectKindOfProductRecalculation}
                    />
                </PageWrapper>
            </div>
        );
    }
}

const mapStateToProps = (state: AppState) => ({
    userSession: state.userSession.userSession,

    pageRequest: state.kindOfProductRecalculation.pageRequest,
    pageResponse: state.kindOfProductRecalculation.pageResponse,

    globalProductIDs: state.kindOfProductRecalculation.globalProductIDs,

    loading: state.kindOfProductRecalculation.loading,
    error: state.kindOfProductRecalculation.error,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchKindOfProductRecalculations: (pageRequest: FetchKindOfProductRecalculationPageRequest) => {
        dispatch(fetchKindOfProductRecalculations(pageRequest))
    },
    createKindOfProductRecalculation: (request: CreateKindOfProductRecalculationRequest) => {
        dispatch(createKindOfProductRecalculation(request))
    },
    downloadKindOfProductRecalculationReport: (request: number) => {
        dispatch(downloadKindOfProductRecalculationReport(request))
    },
    updateKindOfProductRecalculationReport: (request: File) => {
        dispatch(updateKindOfProductRecalculationReport(request));
    },
    applyKindOfProductRecalculation: (request: ApplyKindOfProductRecalculationRequest) => {
        dispatch(applyKindOfProductRecalculation(request))
    },
    rejectKindOfProductRecalculation: (request: number) => {
        dispatch(rejectKindOfProductRecalculation(request))
    }
});

const enhance = flowRight(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(KindOfProductRecalculationPage);
