import { ImporterFailure, ImportStatus } from "../failures/types";
import { PageResponse, ProductSourceSystem, Subsidiary } from "../common/types";

export const RETRY_IMPORTS = 'RETRY_IMPORTS'
export const RETRY_IMPORTS_SUCCEEDED = 'RETRY_IMPORTS_SUCCEEDED'
export const RETRY_IMPORTS_FAILED = 'RETRY_IMPORTS_FAILED'
export const FETCH_IMPORTS = 'FETCH_IMPORTS'
export const FETCH_IMPORTS_SUCCEEDED = 'FETCH_IMPORTS_SUCCEEDED'
export const FETCH_IMPORTS_FAILED = 'FETCH_IMPORTS_FAILED'
export const FETCH_IMPORT_TASK_EXECUTION = 'FETCH_IMPORT_TASK_EXECUTION'
export const FETCH_IMPORT_TASK_EXECUTION_SUCCEEDED = 'FETCH_IMPORT_TASK_EXECUTION_SUCCEEDED'
export const FETCH_IMPORT_TASK_EXECUTION_FAILED = 'FETCH_IMPORT_TASK_EXECUTION_FAILED'

export interface ReimportRequest {
  ids: string[];
}

export interface FetchImportTaskExecutionPageRequest {
  page: number;
  size: number;
  sort: {field: string, direction: 'ASC' | 'DESC'};
  importStatus: ImportStatus;
}

export interface FetchImportTaskExecutionPageResponse extends PageResponse {
  content: ImportTaskExecution[];
}

export interface ImportTaskExecution {
  id: string;
  importTaskId: string;
  subsidiary: Subsidiary;
  sourceSystem: ProductSourceSystem;
  importStartedAt: Date;
  importFinishedAt: Date;
  fromDate: Date;
  toDate: Date;
  failures: ImporterFailure[];
  importedCount: number;
  importStatus: ImportStatus;
  updatedAt: Date;
  updatedByFullName: string;
}

export interface ImportTaskExecutionState {
  importTaskExecutionId: string;
  importTaskExecution: ImportTaskExecution;
  pageRequest: FetchImportTaskExecutionPageRequest;
  pageResponse: FetchImportTaskExecutionPageResponse;
  loading: boolean;
  error: string;
}

export interface RetryImportState {
  retryRequest: ReimportRequest;
  retryLoading: boolean;
  retryError: string;
}

export interface FetchImportsAction {
  type: typeof FETCH_IMPORTS
  pageRequest: FetchImportTaskExecutionPageRequest
}

export interface FetchImportsSucceededAction {
  type: typeof FETCH_IMPORTS_SUCCEEDED
  payload: FetchImportTaskExecutionPageResponse
}

export interface FetchImportsFailedAction {
  type: typeof FETCH_IMPORTS_FAILED
  error: string
}

export interface FetchImportTaskExecutionAction {
  type: typeof FETCH_IMPORT_TASK_EXECUTION
  id: string
}

export interface FetchImportTaskExecutionSucceededAction {
  type: typeof FETCH_IMPORT_TASK_EXECUTION_SUCCEEDED;
  payload: ImportTaskExecution;
}

export interface FetchImportTaskExecutionFailedAction {
  type: typeof FETCH_IMPORT_TASK_EXECUTION_FAILED
  error: string
}

export interface RetryImportsAction {
  type: typeof RETRY_IMPORTS
  retryRequest: ReimportRequest
}

export interface RetryImportsSucceededAction {
  type: typeof RETRY_IMPORTS_SUCCEEDED
}

export interface RetryImportsFailedAction {
  type: typeof RETRY_IMPORTS_FAILED
  error: string
}

export type ImportsActionTypes = FetchImportsAction | FetchImportsSucceededAction | FetchImportsFailedAction
  | FetchImportTaskExecutionAction | FetchImportTaskExecutionSucceededAction | FetchImportTaskExecutionFailedAction;

export type RetryActionTypes = RetryImportsAction
  | RetryImportsSucceededAction | RetryImportsFailedAction;

