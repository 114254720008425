import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import "./SettingsPage.css";
import { Dispatch } from "redux";
import { AppState } from "../../store";
import {flowRight} from "lodash";
import { connect } from 'react-redux';
import { UserSession } from "../../store/userSession/types";
import PageWrapper from "../../components/Drawer/PageWrapper";

interface Props extends RouteComponentProps {
  userSession: UserSession;
}

class SettingsPage extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  public render() {
    return (
      <div className="settings-page">
        <PageWrapper userSession={this.props.userSession}>
          Settings Page
        </PageWrapper>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  userSession: state.userSession.userSession,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
});

const enhance = flowRight(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(SettingsPage);
