import {
  ADD_KIND_OF_ITEM,
  ADD_KIND_OF_ITEMS_FAILED,
  ADD_KIND_OF_ITEMS_SUCCEEDED,
  AddKindOfItemRequest,
  AddKindOfItemResponse,
  DELETE_KIND_OF_ITEM,
  DELETE_KIND_OF_ITEM_FAILED,
  DELETE_KIND_OF_ITEM_SUCCEEDED,
  FETCH_DEPRECATED_KIND_OF_PRODUCT_ITEMS,
  FETCH_DEPRECATED_KIND_OF_PRODUCT_ITEMS_FAILED,
  FETCH_DEPRECATED_KIND_OF_PRODUCT_ITEMS_SUCCEEDED,
  FETCH_KIND_OF_ITEMS,
  FETCH_KIND_OF_ITEMS_FAILED,
  FETCH_KIND_OF_ITEMS_SUCCEEDED,
  FetchKindOfLevelItemsPageRequest,
  FetchKindOfLevelItemsPageResponse,
  GENERATE_MAPPING,
  GENERATE_MAPPING_FAILED,
  GENERATE_MAPPING_SUCCEEDED,
  GET_KIND_OF_ITEM,
  GET_KIND_OF_ITEM_FAILED,
  GET_KIND_OF_ITEM_SUCCEEDED,
  GetKindOfItemResponse,
  KindOfActionTypes,
  UPDATE_KIND_OF_ITEM,
  UPDATE_KIND_OF_ITEM_FAILED,
  UPDATE_KIND_OF_ITEM_SUCCEEDED,
  UpdateKindOfItemRequest
} from './types'

export function fetchKindOfItems(pageRequest: FetchKindOfLevelItemsPageRequest): KindOfActionTypes {
  return {
    type: FETCH_KIND_OF_ITEMS,
    pageRequest
  }
}

export function fetchKindOfItemsSucceeded(payload: FetchKindOfLevelItemsPageResponse): KindOfActionTypes {
  return {
    type: FETCH_KIND_OF_ITEMS_SUCCEEDED,
    payload
  }
}

export function fetchKindOfItemsFailed(error: string): KindOfActionTypes {
  return {
    type: FETCH_KIND_OF_ITEMS_FAILED,
    error
  }
}

export function addKindOfItem(addKindOfItemRequest: AddKindOfItemRequest): KindOfActionTypes {
  return {
    type: ADD_KIND_OF_ITEM,
    addKindOfItemRequest
  }
}

export function addKindOfItemSucceeded(payload: AddKindOfItemResponse): KindOfActionTypes {
  return {
    type: ADD_KIND_OF_ITEMS_SUCCEEDED,
    payload
  }
}

export function addKindOfItemFailed(error: string): KindOfActionTypes {
  return {
    type: ADD_KIND_OF_ITEMS_FAILED,
    error
  }
}

export function getKindOfItem(code: string): KindOfActionTypes {
  return {
    type: GET_KIND_OF_ITEM,
    code
  }
}

export function getKindOfItemSucceeded(payload: GetKindOfItemResponse): KindOfActionTypes {
  return {
    type: GET_KIND_OF_ITEM_SUCCEEDED,
    payload
  }
}

export function getKindOfItemFailed(error: string): KindOfActionTypes {
  return {
    type: GET_KIND_OF_ITEM_FAILED,
    error
  }
}

export function updateKindOfItem(payload: UpdateKindOfItemRequest): KindOfActionTypes {
  return {
    type: UPDATE_KIND_OF_ITEM,
    payload
  }
}

export function updateKindOfItemSucceeded(): KindOfActionTypes {
  return {
    type: UPDATE_KIND_OF_ITEM_SUCCEEDED,
  }
}

export function updateKindOfItemFailed(error: string): KindOfActionTypes {
  return {
    type: UPDATE_KIND_OF_ITEM_FAILED,
    error
  }
}

export function deleteKindOfItem(code: string): KindOfActionTypes {
  return {
    type: DELETE_KIND_OF_ITEM,
    code
  }
}

export function deleteKindOfItemSucceeded(): KindOfActionTypes {
  return {
    type: DELETE_KIND_OF_ITEM_SUCCEEDED,
  }
}

export function deleteKindOfItemFailed(error: string): KindOfActionTypes {
  return {
    type: DELETE_KIND_OF_ITEM_FAILED,
    error
  }
}

export function generateKindOfProductMappingReport(): KindOfActionTypes {
  return {
    type: GENERATE_MAPPING
  }
}

export function generateKindOfProductMappingReportSucceeded(): KindOfActionTypes {
  return {
    type: GENERATE_MAPPING_SUCCEEDED
  }
}

export function generateKindOfProductMappingReportFailed(error: string): KindOfActionTypes {
  return {
    type: GENERATE_MAPPING_FAILED,
    error
  }
}

export function fetchDeprecatedKindOfProductItems(pageRequest: FetchKindOfLevelItemsPageRequest): KindOfActionTypes {
  return {
    type: FETCH_DEPRECATED_KIND_OF_PRODUCT_ITEMS,
    pageRequest
  }
}

export function fetchDeprecatedKindOfProductItemsSucceeded(payload: FetchKindOfLevelItemsPageResponse): KindOfActionTypes {
  return {
    type: FETCH_DEPRECATED_KIND_OF_PRODUCT_ITEMS_SUCCEEDED,
    payload
  }
}

export function fetchDeprecatedKindOfProductItemsFailed(error: string): KindOfActionTypes {
  return {
    type: FETCH_DEPRECATED_KIND_OF_PRODUCT_ITEMS_FAILED,
    error
  }
}