import * as React from "react";
import "./FlatIdMapView.css"
import EnhancedTable, { Column } from "../EnhancedTable/EnhancedTable";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import { ListFlatIdDirectoriesPageRequest, ListFlatIdDirectoriesPageResponse } from "../../store/maintenance/types";

export interface FlatIdDirectoriesProps {
  listFlatIdDirectoriesPageResponse: ListFlatIdDirectoriesPageResponse;
  listFlatIdDirectoriesPageRequest: ListFlatIdDirectoriesPageRequest;

  onDirectoryClick?: (file: string) => void;
  loading: boolean;
  error: string;
}

export const FlatIdDirectoriesView: React.FunctionComponent<FlatIdDirectoriesProps> = (props) => {

  const { listFlatIdDirectoriesPageResponse, loading, onDirectoryClick } = props;

  const columns: Column[] = [
    {
      label: 'Name',
      field: 'name',
      type: 'computed',
      computedValue: (name: string) => {
        return (<a onClick={() => onDirectoryClick(name)}>{name}</a>)
      }
    }
  ];

  return <React.Fragment>

        {loading && (<div>
            <div className="view-loading"/>
            <CircularProgress className="view-loading-spinner"/>
          </div>)}

        <EnhancedTable tableName={"Directories"}
                       pagination={false}
                       rowsSelectable={false}
                       columns={columns}
                       data={listFlatIdDirectoriesPageResponse.directories.map(it => it.name)}
                       totalCount={listFlatIdDirectoriesPageResponse.directories.length}
                       countOnPage={listFlatIdDirectoriesPageResponse.directories.length}
                       currentPage={0}
                       pageSize={listFlatIdDirectoriesPageResponse.directories.length}
                       sort={[{field: "id", direction: "ASC"}]}/>
      </React.Fragment>;
};

export default FlatIdDirectoriesView;
