import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import "./ViewGpcClassificationItemPage.css";
import { Dispatch } from "redux";
import { AppState } from "../../store";
import {flowRight} from "lodash";
import { connect } from 'react-redux';
import { UserSession } from "../../store/userSession/types";
import PageWrapper from "../../components/Drawer/PageWrapper";
import { PageHeader } from 'antd';
import { GpcClassificationItem, GpcLevelItemType } from "../../store/classifications/gpc/types";
import { fetchGpcLevelItem } from "../../store/classifications/gpc/actions";
import GpcClassificationItemView from "../../components/GpcClassificationItem/GpcClassificationItemView";

interface Props extends RouteComponentProps<{code: string, level: string}> {
  userSession: UserSession;
  code: string;
  level: GpcLevelItemType;
  gpcClassificationItem: GpcClassificationItem;
  fetchGpcClassificationItem: (code: string, level: string) => void;
  loading?: boolean;
  error?: string;
}

interface State {
}

class ViewGpcClassificationItemPage extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
  }

  componentDidMount () {
    this.props.fetchGpcClassificationItem(this.props.match.params.code, this.props.match.params.level)
  }

  public render() {

    return (
      <div className="view-gpc-item-page">
        <PageWrapper userSession={this.props.userSession}>
          <PageHeader
            ghost={false}
            onBack={() => window.history.back()}
            title="GPC classification item details"
            subTitle={"Details of the GPC classification item for the specified level and code"}
          >
          </PageHeader>
          <GpcClassificationItemView
            code={this.props.code}
            level={this.props.level}
            item={this.props.gpcClassificationItem}
            loading={this.props.loading}
            error={this.props.error}
          />
        </PageWrapper>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  userSession: state.userSession.userSession,

  code: state.gpcClassification.itemRequest.code,
  level: state.gpcClassification.itemRequest.level,
  gpcClassificationItem: state.gpcClassification.itemResponse.item,

  loading: state.gpcClassification.loading,
  error: state.gpcClassification.error
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchGpcClassificationItem: (code: string, level: string) => {
    dispatch(fetchGpcLevelItem({
      code,
      level: level.toUpperCase() as GpcLevelItemType
    }))
  }
});

const enhance = flowRight(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(ViewGpcClassificationItemPage);
