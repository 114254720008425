import {Select} from "antd";
import * as React from "react";
import {mappingSourceTypeList} from "../../../store/common/types";

const {Option} = Select;

export interface Props {
    id: string
    value: any;
    mode?: 'multiple' | 'tags';
    onChange: (value: string) => void;
    allEnabled?: boolean;
    notSelectedOption?: boolean;
}

export default function MappingSourceTypeSelect(props: Props) {

    const {id, mode, value, onChange, allEnabled, notSelectedOption} = props;

    return (
        <Select id={id}
                mode={mode}
                value={value}
                placeholder="Select Mapping Source"
                onChange={(value) => onChange(value)}>
            {!!notSelectedOption && <Option value="">Not Selected</Option>}
            {
                mappingSourceTypeList.map(mappingSource => {
                    return <Option value={mappingSource.key}
                                   disabled={!!!allEnabled && mappingSource.disabled}>{mappingSource.name}</Option>
                })
            }
        </Select>
    )
}
