import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import "./UserPage.css";
import { Dispatch } from "redux";
import { AppState, store } from "../../store";
import {flowRight} from "lodash";
import { connect } from 'react-redux';
import { UserSession } from "../../store/userSession/types";
import PageWrapper from "../../components/Drawer/PageWrapper";
import { PageHeader } from 'antd';
import { Group, Permission, User } from "../../store/permissionsManagement/types";
import {
  fetchGroupsList,
  fetchPermissionsList,
  fetchUser,
  updateUser,
  refreshUser,
  deleteUser
} from "../../store/permissionsManagement/actions";
import UserDetailsView from "../../components/UserDetails/UserDetailsView";

interface Props extends RouteComponentProps<{id: string}> {
  userSession: UserSession;
  userId: number;
  userDetails: User;
  availableGroups: Group[];
  availablePermissions: Permission[];
  fetchUserDetails: (id: number) => void;
  updateUser: (id: number, groupIds: number[], permissionIds: number[], active: boolean) => void;
  refreshUser: (id: number) => void;
  deleteUser: (id: number) => void;
  loading?: boolean;
  error?: string;
}

interface State {
}

class UserPage extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.onUserUpdate = this.onUserUpdate.bind(this);
    this.onUserRefresh = this.onUserRefresh.bind(this);
    this.onUserDelete = this.onUserDelete.bind(this);
  }

  componentDidMount() {
    store.dispatch(fetchGroupsList());
    store.dispatch(fetchPermissionsList());

    this.props.fetchUserDetails(parseInt(this.props.match.params.id))
  }

  private onUserUpdate(id: number, groupIds: number[], permissionIds: number[], active: boolean) {
    this.props.updateUser(id, groupIds, permissionIds, active);
  }

  private onUserRefresh(id: number) {
    this.props.refreshUser(id);
  }

  private onUserDelete(id: number) {
    this.props.deleteUser(id);
    this.props.history.push("/app/settings/permissions-management")
  }

  public render() {

    return (
      <div className="user-page">
        <PageWrapper userSession={this.props.userSession}>
          <PageHeader
            ghost={false}
            onBack={() => window.history.back()}
            title="User Details"
            subTitle={"General information about user with assigned groups / permissions"}
          >
          </PageHeader>
          <UserDetailsView
            user={this.props.userDetails}
            groups={this.props.availableGroups}
            permissions={this.props.availablePermissions}
            onUserUpdate={this.onUserUpdate}
            onUserRefresh={this.onUserRefresh}
            onUserDelete={this.onUserDelete}
            loading={this.props.loading}
            error={this.props.error}
          />
        </PageWrapper>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  userSession: state.userSession.userSession,

  userId: state.permissionsManagement.userId,
  userDetails: state.permissionsManagement.userDetails,

  availableGroups: state.permissionsManagement.groups,
  availablePermissions: state.permissionsManagement.permissions,

  loading: state.permissionsManagement.userDetailsLoading,
  error: state.permissionsManagement.userDetailsError
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchUserDetails: (id: number) => {
    dispatch(fetchUser({id}))
  },
  updateUser: (id: number, groupIds: number[], permissionIds: number[], active: boolean) => {
    dispatch(updateUser({
      id,
      groupIds,
      permissionIds,
      active
    }))
  },
  refreshUser: (id: number) => {
    dispatch(refreshUser({
      id
    }))
  },
  deleteUser: (id: number) => {
    dispatch(deleteUser({
      id
    }))
  }
});

const enhance = flowRight(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(UserPage);
