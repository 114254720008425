import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import "./ProductFailurePage.css";
import { Dispatch } from "redux";
import { AppState } from "../../store";
import {flowRight} from "lodash";
import { connect } from 'react-redux';
import { UserSession } from "../../store/userSession/types";
import PageWrapper from "../../components/Drawer/PageWrapper";
import { PageHeader } from 'antd';
import { FailureGrantType, ProductFailure } from "../../store/failures/types";
import {
  fetchProductFailure,
  rejectProductFailure,
  resolveProductFailure,
  retryProductFailure
} from "../../store/failures/actions";
import ProductFailureView from "../../components/ProductFailure/ProductFailureView";

interface Props extends RouteComponentProps<{id: string}> {
  userSession: UserSession;

  failureId: string;
  failure: ProductFailure;

  fetchFailure: (id: number) => void;
  loading?: boolean;
  error?: string;

  retryFailure: (id: number) => void;
  rejectFailure: (id: number) => void;
  resolveFailure: (id: number, grant: FailureGrantType, errorTypeDetails: {}) => void;

  retryLoading?: boolean;
  rejectLoading?: boolean;
  resolveLoading?: boolean;

  retryError?: string;
  rejectError?: string;
  resolveError?: string;
}

class ProductFailurePage extends React.Component<Props> {

  constructor(props: Props) {
    super(props);

    this.onFailureRetry = this.onFailureRetry.bind(this);
    this.onFailureReject = this.onFailureReject.bind(this);
    this.onFailureResolve = this.onFailureResolve.bind(this);
  }

  componentDidMount () {
    this.props.fetchFailure(parseInt(this.props.match.params.id))
  }

  private onFailureRetry(id: number) {
    this.props.retryFailure(id);
  }

  private onFailureReject(id: number) {
    this.props.rejectFailure(id);
  }

  private onFailureResolve(id: number, grant: FailureGrantType, errorTypeDetails: {}) {
    this.props.resolveFailure(id, grant, errorTypeDetails);
  }

  public render() {

    return (
      <div className="product-failure-page">
        <PageWrapper userSession={this.props.userSession}>
          <PageHeader
            ghost={false}
            onBack={() => window.history.back()}
            title="Product Failure"
            subTitle={"Details of the product failure"}
          >
          </PageHeader>
          <ProductFailureView
            productFailure={this.props.failure}
            loading={this.props.loading}
            error={this.props.error}
            onFailureRetry={this.onFailureRetry}
            onFailureReject={this.onFailureReject}
            onFailureResolve={this.onFailureResolve}
            retryLoading={this.props.retryLoading}
            rejectLoading={this.props.rejectLoading}
            resolveLoading={this.props.resolveLoading}
          />
        </PageWrapper>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  userSession: state.userSession.userSession,

  failureId: state.failures.productFailureId,
  failure: state.failures.productFailure,

  loading: state.failures.loading,
  error: state.failures.error,

  retryLoading: state.retryFailures.retryLoading,
  retryError: state.retryFailures.retryError,

  rejectLoading: state.rejectFailures.rejectLoading,
  rejectError: state.rejectFailures.rejectError,

  resolveLoading: state.resolveFailures.resolveLoading,
  resolveError: state.resolveFailures.resolveError,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchFailure: (id: number) => {
    dispatch(fetchProductFailure(id))
  },
  retryFailure: (id: number) => {
    dispatch(retryProductFailure(id))
  },
  rejectFailure: (id: number) => {
    dispatch(rejectProductFailure(id))
  },
  resolveFailure: (id: number, failureGrantType: FailureGrantType, errorTypeDetails: {}) => {
    dispatch(resolveProductFailure(id, failureGrantType, errorTypeDetails))
  }
});

const enhance = flowRight(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(ProductFailurePage);
