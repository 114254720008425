import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import "./ProductImportsPage.css";
import { Dispatch } from "redux";
import { AppState } from "../../store";
import {flowRight} from "lodash";
import { connect } from 'react-redux';
import { UserSession } from "../../store/userSession/types";
import ProductImportsView from "../../components/ProductImports/ProductImportsView";
import {
  FetchImportTaskExecutionPageRequest,
  FetchImportTaskExecutionPageResponse,
  ReimportRequest
} from "../../store/productImports/types";
import { fetchProductImports, retryProductImports } from "../../store/productImports/actions";
import { Order } from "../../components/EnhancedTable/EnhancedTable";
import PageWrapper from "../../components/Drawer/PageWrapper";
import { PageHeader } from 'antd';

interface Props extends RouteComponentProps {
  userSession: UserSession;
  pageRequest: FetchImportTaskExecutionPageRequest;
  pageResponse: FetchImportTaskExecutionPageResponse;
  fetchProductImports: (pageRequest: FetchImportTaskExecutionPageRequest) => void;
  loading?: boolean;
  error?: string;
  retryProductImports: (retryRequest: ReimportRequest) => void;
  retryLoading: boolean;
}

interface State {
  selectedIds: string[];
}

class ProductImportsPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.onPaginationChanged = this.onPaginationChanged.bind(this);
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.onSortingChanged = this.onSortingChanged.bind(this);
    this.onFilterChanged = this.onFilterChanged.bind(this);
    this.onRetry = this.onRetry.bind(this);
    this.onRowClick = this.onRowClick.bind(this);

    this.state = {
      selectedIds: []
    };
  }

  componentDidMount () {
    this.props.fetchProductImports(this.props.pageRequest)
  }

  private onPaginationChanged(page: number, size: number) {
    console.log("Page: " + page + ", size: " + size);

    this.props.fetchProductImports({
      ... this.props.pageRequest,
      page,
      size
    })
  }

  private onRowClick(id: string) {
    this.props.history.push("/app/product-imports/" + id);
  }

  private onFilterChanged(filterField: string, value: string) {
    console.log("Filter: " + filterField + ", value: " + value);

    this.props.fetchProductImports({
      ... this.props.pageRequest,
      [filterField]: value
    })
  }

  private onRetry() {
    this.props.retryProductImports({
      ids: this.state.selectedIds
    });
  }

  private onSelectionChanged(selectedIds: string[]) {
    this.setState({
      selectedIds
    }, () => {
      console.log(selectedIds);
    });

  }

  private onSortingChanged(orderBy: string, order: Order) {
    console.log("Order by: " + orderBy + ", order: " + order);

    this.props.fetchProductImports({
      ... this.props.pageRequest,
      sort: {
        field: orderBy,
        direction: order
      }
    });
  }

  public render() {
    return (
      <div className="product-imports-page">
        <PageWrapper userSession={this.props.userSession}>
          <PageHeader
            backIcon={null}
            ghost={false}
            onBack={() => window.history.back()}
            title="Product Imports"
            subTitle="Displays status of running product imports"
          >
          </PageHeader>
          <ProductImportsView
            pageRequest={this.props.pageRequest}
            pageResponse={this.props.pageResponse}
            onPaginationChanged={this.onPaginationChanged}
            onSelectionChanged={this.onSelectionChanged}
            onSortingChanged={this.onSortingChanged}
            onFilterChanged={this.onFilterChanged}
            loading={this.props.loading}
            error={this.props.error}
            onRetry={this.state.selectedIds.length > 0 && this.onRetry}
            retryLoading={this.props.retryLoading}
            onRowClick={this.onRowClick}
          />
        </PageWrapper>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  userSession: state.userSession.userSession,
  pageRequest: state.productImports.pageRequest,
  pageResponse: state.productImports.pageResponse,
  loading: state.productImports.loading,
  error: state.productImports.error,
  retryLoading: state.retryImports.retryLoading,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchProductImports: (pageRequest: FetchImportTaskExecutionPageRequest) => {
    dispatch(fetchProductImports(pageRequest))
  },
  retryProductImports: (reimportRequest: ReimportRequest) => {
    dispatch(retryProductImports(reimportRequest))
  }
});

const enhance = flowRight(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(ProductImportsPage);
