import {Reducer} from "redux";
import {
  DOWNLOAD_FLAT_ID_FILE,
  DOWNLOAD_FLAT_ID_FILE_FAILED,
  DOWNLOAD_FLAT_ID_FILE_SUCCEEDED,
  LIST_FLAT_ID_DIRECTORIES,
  LIST_FLAT_ID_DIRECTORIES_FAILED,
  LIST_FLAT_ID_DIRECTORIES_SUCCEEDED,
  LIST_FLAT_ID_FILES,
  LIST_FLAT_ID_FILES_FAILED,
  LIST_FLAT_ID_FILES_SUCCEEDED,
  MaintenanceActionTypes,
  MaintenanceState,
  PUBLISH_FLAT_ID,
  PUBLISH_FLAT_ID_SUCCEEDED,
  PUBLISH_FLAT_ID_FAILED

} from "./types";

const initialState: MaintenanceState = {
  listFlatIdDirectoriesPageRequest: {
    page: 0,
    size: 10,
    sort: {
      field: "id",
      direction: 'ASC'
    },
  },
  listFlatIdDirectoriesPageResponse: {
    directories: []
  },
  listFlatIdFilesPageRequest: {
    name: "",
  },
  listFlatIdFilesPageResponse: {
    files: []
  },
  downloadFlatIdFileRequest: {
    directory: undefined,
    file: undefined
  },
  loading: false,
  error: null
};

export const maintenanceReducer: Reducer<MaintenanceState, MaintenanceActionTypes> = (state: MaintenanceState = initialState, action: MaintenanceActionTypes): MaintenanceState => {
  switch (action.type) {

    case LIST_FLAT_ID_DIRECTORIES: {
      return {
        ... state,
        listFlatIdDirectoriesPageRequest: {
          ... action.listFlatIdDirectoriesPageRequest
        },
        loading: true,
        error: null
      }
    }

    case LIST_FLAT_ID_DIRECTORIES_SUCCEEDED: {
      return {
        ...state,
        listFlatIdDirectoriesPageResponse: action.listFlatIdDirectoriesPageResponse,
        loading: false,
        error: null
      }
    }

    case LIST_FLAT_ID_DIRECTORIES_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.error
      }
    }

    case LIST_FLAT_ID_FILES: {
      return {
        ... state,
        listFlatIdFilesPageRequest: {
          ... action.listFlatIdFilesPageRequest
        },
        loading: true,
        error: null
      }
    }

    case LIST_FLAT_ID_FILES_SUCCEEDED: {
      return {
        ...state,
        listFlatIdFilesPageResponse: action.listFlatIdFilesPageResponse,
        loading: false,
        error: null
      }
    }

    case LIST_FLAT_ID_FILES_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.error
      }
    }

    case DOWNLOAD_FLAT_ID_FILE: {
      return {
        ... state,
        downloadFlatIdFileRequest: {
          ... action.downloadFlatIdFileRequest
        },
        loading: true,
        error: null
      }
    }

    case DOWNLOAD_FLAT_ID_FILE_SUCCEEDED: {
      return {
        ...state,
        loading: false,
        error: null
      }
    }

    case DOWNLOAD_FLAT_ID_FILE_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.error
      }
    }

    case PUBLISH_FLAT_ID: {
      return {
        ... state,
        loading: true,
        error: null
      }
    }

    case PUBLISH_FLAT_ID_SUCCEEDED: {
      return {
        ...state,
        loading: false,
        error: null
      }
    }

    case PUBLISH_FLAT_ID_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.error
      }
    }

    default:
      neverReached(action);
  }

  return state;
};


const neverReached = (never: never) => {};
