import {Reducer} from "redux";
import {
  AvoidanceFixDisregardRulesActionTypes,
  AvoidanceFixDisregardRulesState,
  CLOSE_ERROR_MESSAGE,
  CREATE_AVOIDANCE_FIX_DISREGARD,
  CREATE_AVOIDANCE_FIX_DISREGARD_SUCCEEDED,
  DELETE_AVOIDANCE_FIX_DISREGARD, DELETE_AVOIDANCE_FIX_DISREGARD_SUCCEEDED,
  FETCH_AVOIDANCE_FIX_DISREGARD,
  FETCH_AVOIDANCE_FIX_DISREGARD_FAILED,
  FETCH_AVOIDANCE_FIX_DISREGARD_SUCCEEDED
} from "./types";
import {pageResponse} from "../common/types";

const initialState: AvoidanceFixDisregardRulesState = {
  rulesPageRequest: {
    page: 0,
    size: 10,
    sort: {
      field: "productId",
      direction: 'ASC'
    },
    productId: ""
  },
  rulesPageResponse: {
    ...pageResponse(),
    content: []
  },
  createRequest: {
    productId: "",
    subsidiary: "",
    sourceSystem: "",
    globalProductId: "",
    gtins: ""
  },
  createResponse: {
    id: "",
    productId: "",
    subsidiary: "",
    sourceSystem: "",
    productGlobalId: "",
    gtins: ""
  },
  loading: false,
  error: null,
  runningAction: null
};

export const avoidanceFixDisregardReducer: Reducer<AvoidanceFixDisregardRulesState, AvoidanceFixDisregardRulesActionTypes> = (state: AvoidanceFixDisregardRulesState = initialState, action: AvoidanceFixDisregardRulesActionTypes): AvoidanceFixDisregardRulesState => {
  switch (action.type) {

    case FETCH_AVOIDANCE_FIX_DISREGARD: {
      return {
        ... state,
        rulesPageRequest: {
          ... action.pageRequest
        },
        loading: true,
        error: null
      }
    }

    case CREATE_AVOIDANCE_FIX_DISREGARD: {
      return {
        ... state,
        createRequest: {
          ... action.request
        },
        loading: true,
        error: null,
        runningAction: 'create'
      }
    }

    case FETCH_AVOIDANCE_FIX_DISREGARD_SUCCEEDED: {
      return {
        ...state,
        rulesPageResponse: action.payload,
        loading: false,
        error: null
      }
    }

    case CREATE_AVOIDANCE_FIX_DISREGARD_SUCCEEDED: {
      return {
        ...state,
        createResponse: action.payload,
        loading: false,
        error: null,
        runningAction: null
      }
    }

    case DELETE_AVOIDANCE_FIX_DISREGARD: {
      return {
        ... state,
        loading: true,
        error: null,
        runningAction: 'delete'
      }
    }

    case DELETE_AVOIDANCE_FIX_DISREGARD_SUCCEEDED: {
      return {
        ...state,
        loading: false,
        error: null,
        runningAction: null
      }
    }

    case FETCH_AVOIDANCE_FIX_DISREGARD_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.error,
        runningAction: null
      }
    }

    case CLOSE_ERROR_MESSAGE: {
      return {
        ...state,
        loading: false,
        error: null,
        runningAction: null
      }
    }

    default:
      neverReached(action);
  }

  return state;
};


const neverReached = (never: never) => {};
