import { Reducer } from "redux";
import {
  FailuresState,
  FETCH_IMPORTER_FAILURE,
  FETCH_IMPORTER_FAILURE_FAILED,
  FETCH_IMPORTER_FAILURE_LIST,
  FETCH_IMPORTER_FAILURE_LIST_FAILED,
  FETCH_IMPORTER_FAILURE_LIST_SUCCEEDED,
  FETCH_IMPORTER_FAILURE_SUCCEEDED,
  FETCH_LEGACY_PRODUCT,
  FETCH_LEGACY_PRODUCT_FAILED,
  FETCH_LEGACY_PRODUCT_SUCCEEDED,
  FETCH_PRODUCT_FAILURE,
  FETCH_PRODUCT_FAILURE_FAILED,
  FETCH_PRODUCT_FAILURE_LIST,
  FETCH_PRODUCT_FAILURE_LIST_FAILED,
  FETCH_PRODUCT_FAILURE_LIST_SUCCEEDED,
  FETCH_PRODUCT_FAILURE_SUCCEEDED,
  FetchFailuresActionTypes,
  GENERATE_REPORT,
  GENERATE_REPORT_FAILED,
  GENERATE_REPORT_SUCCEEDED,
  REJECT_IMPORTER_FAILURE,
  REJECT_IMPORTER_FAILURE_FAILED,
  REJECT_IMPORTER_FAILURE_LIST,
  REJECT_IMPORTER_FAILURE_LIST_FAILED,
  REJECT_IMPORTER_FAILURE_LIST_SUCCEEDED,
  REJECT_IMPORTER_FAILURE_SUCCEEDED,
  REJECT_PRODUCT_FAILURE,
  REJECT_PRODUCT_FAILURE_FAILED,
  REJECT_PRODUCT_FAILURE_LIST,
  REJECT_PRODUCT_FAILURE_LIST_FAILED,
  REJECT_PRODUCT_FAILURE_LIST_SUCCEEDED,
  REJECT_PRODUCT_FAILURE_SUCCEEDED,
  RejectFailuresActionTypes,
  RejectFailuresState,
  RESOLVE_PRODUCT_FAILURE,
  RESOLVE_PRODUCT_FAILURE_FAILED,
  RESOLVE_PRODUCT_FAILURE_SUCCEEDED,
  ResolveFailuresActionTypes,
  ResolveFailuresState,
  RETRY_IMPORTER_FAILURE,
  RETRY_IMPORTER_FAILURE_FAILED,
  RETRY_IMPORTER_FAILURE_LIST,
  RETRY_IMPORTER_FAILURE_LIST_FAILED,
  RETRY_IMPORTER_FAILURE_LIST_SUCCEEDED,
  RETRY_IMPORTER_FAILURE_SUCCEEDED,
  RETRY_PRODUCT_FAILURE,
  RETRY_PRODUCT_FAILURE_FAILED,
  RETRY_PRODUCT_FAILURE_LIST,
  RETRY_PRODUCT_FAILURE_LIST_FAILED,
  RETRY_PRODUCT_FAILURE_LIST_SUCCEEDED,
  RETRY_PRODUCT_FAILURE_SUCCEEDED,
  RetryFailuresActionTypes,
  RetryFailuresState,
} from "./types";
import { pageResponse } from "../common/types";

const initialState: FailuresState = {
  importerFailureId: null,
  importerFailure: null,
  productFailureId: null,
  productFailure: null,
  importerFailuresPageRequest: {
    page: 0,
    size: 10,
    productId: null,
    sourceSystem: null,
    subsidiary: null,
    importStatus: null,
    failureType: null,
    sort: {
      field: "updatedAt",
      direction: 'DESC'
    }
  },
  importerFailuresPageResponse: {
    ...pageResponse(),
    content: []
  },
  productFailuresPageRequest: {
    page: 0,
    size: 10,
    productId: null,
    failureStatus: null,
    errorType: null,
    subsidiary: null,
    activeProduct: null,
    sort: {
      field: "updatedAt",
      direction: 'DESC'
    }
  },
  productFailuresPageResponse: {
    ...pageResponse(),
    content: []
  },
  generateReportLoading: false,
  legacyProduct: null,
  loading: false,
  error: null
};

export const failuresReducer: Reducer<FailuresState, FetchFailuresActionTypes> = (state: FailuresState = initialState, action: FetchFailuresActionTypes): FailuresState => {
  switch (action.type) {

    case FETCH_IMPORTER_FAILURE_LIST: {
      return {
        ... state,
        importerFailuresPageRequest: {
          ... action.pageRequest
        },
        loading: true,
        error: null
      }
    }

    case FETCH_IMPORTER_FAILURE_LIST_SUCCEEDED: {
      return {
        ...state,
        importerFailuresPageResponse: action.payload,
        loading: false,
        error: null
      }
    }

    case FETCH_IMPORTER_FAILURE_LIST_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.error
      }
    }

    case FETCH_PRODUCT_FAILURE_LIST: {
      return {
        ... state,
        productFailuresPageRequest: {
          ... action.pageRequest
        },
        loading: true,
        error: null
      }
    }

    case FETCH_PRODUCT_FAILURE_LIST_SUCCEEDED: {
      return {
        ...state,
        productFailuresPageResponse: action.payload,
        loading: false,
        error: null
      }
    }

    case FETCH_PRODUCT_FAILURE_LIST_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.error
      }
    }

    case FETCH_IMPORTER_FAILURE: {
      return {
        ... state,
        importerFailureId: action.id,
        loading: true,
        error: null
      }
    }

    case FETCH_IMPORTER_FAILURE_SUCCEEDED: {
      return {
        ...state,
        importerFailure: action.payload,
        loading: false,
        error: null
      }
    }

    case FETCH_IMPORTER_FAILURE_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.error
      }
    }

    case FETCH_LEGACY_PRODUCT: {
      return {
        ... state,
        loading: true,
        error: null
      }
    }

    case FETCH_LEGACY_PRODUCT_SUCCEEDED: {
      return {
        ...state,
        legacyProduct: action.payload,
        loading: false,
        error: null
      }
    }

    case FETCH_LEGACY_PRODUCT_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.error
      }
    }

    case FETCH_PRODUCT_FAILURE: {
      return {
        ... state,
        productFailureId: action.id,
        loading: true,
        error: null
      }
    }

    case FETCH_PRODUCT_FAILURE_SUCCEEDED: {
      return {
        ...state,
        productFailure: action.payload,
        loading: false,
        error: null
      }
    }

    case FETCH_PRODUCT_FAILURE_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.error
      }
    }

    case GENERATE_REPORT: {
      return {
        ... state,
        productFailuresPageRequest: {
          ... action.pageRequest
        },
        generateReportLoading: true,
        error: null
      }
    }

    case GENERATE_REPORT_SUCCEEDED: {
      return {
        ...state,
        generateReportLoading: false,
        error: null
      }
    }

    case GENERATE_REPORT_FAILED: {
      return {
        ...state,
        generateReportLoading: false,
        error: action.error
      }
    }

    default:
      neverReached(action);
  }

  return state;
};

const rejectFailuresState: RejectFailuresState = {
  rejectProductFailureRequest: {
    id: null
  },
  rejectProductFailureListRequest: {
    ids: []
  },
  rejectImporterFailureRequest: {
    id: null
  },
  rejectImporterFailureListRequest: {
    ids: []
  },
  rejectLoading: false,
  rejectError: null
};

const resolveFailuresState: ResolveFailuresState = {
  resolveProductFailureRequest: {
    id: null,
    failureGrantType: null,
    errorTypeDetails: null
  },
  resolveLoading: false,
  resolveError: null
};

const retryFailuresState: RetryFailuresState = {
  retryImporterFailureRequest: {
    id: null
  },
  retryImporterFailureListRequest: {
    ids: []
  },
  retryProductFailureRequest: {
    id: null
  },
  retryProductFailureListRequest: {
    ids: []
  },
  retryLoading: false,
  retryError: null
};

export const rejectFailuresReducer: Reducer<RejectFailuresState, RejectFailuresActionTypes> = (state: RejectFailuresState = rejectFailuresState, action: RejectFailuresActionTypes): RejectFailuresState => {
  switch (action.type) {

    case REJECT_PRODUCT_FAILURE: {
      return {
        ... state,
        rejectProductFailureRequest: {
          ... action.rejectFailureRequest
        },
        rejectLoading: true,
        rejectError: null
      }
    }

    case REJECT_PRODUCT_FAILURE_SUCCEEDED: {
      return {
        ...state,
        rejectLoading: false,
        rejectError: null
      }
    }

    case REJECT_PRODUCT_FAILURE_FAILED: {
      return {
        ...state,
        rejectLoading: false,
        rejectError: action.error
      }
    }

    case REJECT_IMPORTER_FAILURE: {
      return {
        ... state,
        rejectImporterFailureRequest: {
          ... action.rejectFailureRequest
        },
        rejectLoading: true,
        rejectError: null
      }
    }

    case REJECT_IMPORTER_FAILURE_SUCCEEDED: {
      return {
        ...state,
        rejectLoading: false,
        rejectError: null
      }
    }

    case REJECT_IMPORTER_FAILURE_FAILED: {
      return {
        ...state,
        rejectLoading: false,
        rejectError: action.error
      }
    }

    case REJECT_IMPORTER_FAILURE_LIST: {
      return {
        ... state,
        rejectImporterFailureListRequest: {
          ... action.rejectFailuresRequest
        },
        rejectLoading: true,
        rejectError: null
      }
    }

    case REJECT_IMPORTER_FAILURE_LIST_SUCCEEDED: {
      return {
        ...state,
        rejectLoading: false,
        rejectError: null
      }
    }

    case REJECT_IMPORTER_FAILURE_LIST_FAILED: {
      return {
        ...state,
        rejectLoading: false,
        rejectError: action.error
      }
    }

    case REJECT_PRODUCT_FAILURE_LIST: {
      return {
        ... state,
        rejectProductFailureListRequest: {
          ... action.rejectFailuresRequest
        },
        rejectLoading: true,
        rejectError: null
      }
    }

    case REJECT_PRODUCT_FAILURE_LIST_SUCCEEDED: {
      return {
        ...state,
        rejectLoading: false,
        rejectError: null
      }
    }

    case REJECT_PRODUCT_FAILURE_LIST_FAILED: {
      return {
        ...state,
        rejectLoading: false,
        rejectError: action.error
      }
    }

    default:
      neverReached(action);
  }

  return state;
};

export const resolveFailuresReducer: Reducer<ResolveFailuresState, ResolveFailuresActionTypes> = (state: ResolveFailuresState = resolveFailuresState, action: ResolveFailuresActionTypes): ResolveFailuresState => {
  switch (action.type) {

    case RESOLVE_PRODUCT_FAILURE: {
      return {
        ... state,
        resolveProductFailureRequest: {
          ... action.resolveFailureRequest
        },
        resolveLoading: true,
        resolveError: null
      }
    }

    case RESOLVE_PRODUCT_FAILURE_SUCCEEDED: {
      return {
        ...state,
        resolveLoading: false,
        resolveError: null
      }
    }

    case RESOLVE_PRODUCT_FAILURE_FAILED: {
      return {
        ...state,
        resolveLoading: false,
        resolveError: action.error
      }
    }

    default:
      neverReached(action);
  }

  return state;
};

export const retryFailuresReducer: Reducer<RetryFailuresState, RetryFailuresActionTypes> = (state: RetryFailuresState = retryFailuresState, action: RetryFailuresActionTypes): RetryFailuresState => {
  switch (action.type) {

    case RETRY_IMPORTER_FAILURE_LIST: {
      return {
        ... state,
        retryImporterFailureListRequest: {
          ... action.retryFailuresRequest
        },
        retryLoading: true,
        retryError: null
      }
    }

    case RETRY_IMPORTER_FAILURE_LIST_SUCCEEDED: {
      return {
        ...state,
        retryLoading: false,
        retryError: null
      }
    }

    case RETRY_IMPORTER_FAILURE_LIST_FAILED: {
      return {
        ...state,
        retryLoading: false,
        retryError: action.error
      }
    }

    case RETRY_IMPORTER_FAILURE: {
      return {
        ... state,
        retryImporterFailureRequest: {
          ... action.retryFailureRequest
        },
        retryLoading: true,
        retryError: null
      }
    }

    case RETRY_IMPORTER_FAILURE_SUCCEEDED: {
      return {
        ...state,
        retryLoading: false,
        retryError: null
      }
    }

    case RETRY_IMPORTER_FAILURE_FAILED: {
      return {
        ...state,
        retryLoading: false,
        retryError: action.error
      }
    }

    case RETRY_PRODUCT_FAILURE_LIST: {
      return {
        ... state,
        retryProductFailureListRequest: {
          ... action.retryFailuresRequest
        },
        retryLoading: true,
        retryError: null
      }
    }

    case RETRY_PRODUCT_FAILURE_LIST_SUCCEEDED: {
      return {
        ...state,
        retryLoading: false,
        retryError: null
      }
    }

    case RETRY_PRODUCT_FAILURE_LIST_FAILED: {
      return {
        ...state,
        retryLoading: false,
        retryError: action.error
      }
    }

    case RETRY_PRODUCT_FAILURE: {
      return {
        ... state,
        retryProductFailureRequest: {
          ... action.retryFailureRequest
        },
        retryLoading: true,
        retryError: null
      }
    }

    case RETRY_PRODUCT_FAILURE_SUCCEEDED: {
      return {
        ...state,
        retryLoading: false,
        retryError: null
      }
    }

    case RETRY_PRODUCT_FAILURE_FAILED: {
      return {
        ...state,
        retryLoading: false,
        retryError: action.error
      }
    }

    default:
      neverReached(action);
  }

  return state;
};


const neverReached = (never: never) => {};
