import * as React from "react";
import "./GpcClassificationItemView.css"
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import { Descriptions, Divider, List, Tree } from 'antd';
import {
  GpcAttributeType,
  GpcBrick,
  GpcChildrenResolver,
  GpcClassificationItem,
  GpcLevelItemType
} from "../../store/classifications/gpc/types";
import { CarryOutOutlined } from "@ant-design/icons/lib";

export interface Props {
  code: string;
  level: GpcLevelItemType;
  item: GpcClassificationItem;
  loading: boolean;
  error: string;
}

export const GpcClassificationItemView: React.FunctionComponent<Props> = (props) => {

  const { level, item, loading, error } = props;

  const treeData = !!item && !!level && level === 'BRICK' && (item as GpcBrick).attributeTypeList
    && (item as GpcBrick).attributeTypeList.map((attributeType, index) => {

      const children = (attributeType as GpcAttributeType).attributeValueList.map((attributeValue, index) => {
        return {
          title: attributeValue.code + ' - ' + attributeValue.text,
          key: attributeType.code + '-' + attributeValue.code,
          icon: <CarryOutOutlined/>
        };
      });

      return {
        title: attributeType.code + ' - ' + attributeType.text,
        key: attributeType.code,
        icon: <CarryOutOutlined/>,
        children
      };
    });

  return error ?
    (
      <div className="view-error">{error}</div>
    )
    :
    (
      <React.Fragment>

        {
          loading && (<div>
            <div className="view-loading"/>
            <CircularProgress className="view-loading-spinner"/>
          </div>)
        }

        {
          !!item &&
          <Descriptions size="small" column={1} bordered>
              <Descriptions.Item label="Level">
                  <a>{level}</a>
              </Descriptions.Item>
              <Descriptions.Item label="Code">
                  <a>{item.code}</a>
              </Descriptions.Item>
              <Descriptions.Item label="Text">{item.text}
              </Descriptions.Item>
              <Descriptions.Item label="Definition">{item.definition}
              </Descriptions.Item>
          </Descriptions>
        }

        <React.Fragment>
          {
            !!level && level === 'SEGMENT' && <Divider orientation={"left"} dashed={true}>GPC Family List</Divider>
          }
          {
            !!level && level === 'FAMILY' && <Divider orientation={"left"} dashed={true}>GPC Class List</Divider>
          }
          {
            !!level && level === 'CLAZZ' && <Divider orientation={"left"} dashed={true}>GPC Brick List</Divider>
          }
          {
            !!level && level === 'BRICK' && <Divider orientation={"left"} dashed={true}>GPC Attributes</Divider>
          }
          {
            !!level && level === 'ATTRIBUTE_TYPE' && <Divider orientation={"left"} dashed={true}>GPC Attribute Values</Divider>
          }
        </React.Fragment>

        {
          !!item && !!level && level !== 'BRICK' && level !== 'ATTRIBUTE_VALUE' && <List
              itemLayout="horizontal"
              dataSource={
                GpcChildrenResolver.getChildren(item).map((child, index) => {
                  return child;
                })
              }
              renderItem={child => (
                <List.Item>
                  <List.Item.Meta
                    title={<a href={`/app/classification/gpc/${child.level.toLowerCase()}/${child.code}`}>{child.code + ' - ' + child.text}</a>}
                  />
                </List.Item>
              )}
          />
        }

        {
          !!item && !!level && level === 'BRICK' &&
          (
            <Tree
              treeData={treeData}
            />
          )
        }

      </React.Fragment>
    );
};

export default GpcClassificationItemView;
