import * as React from "react";
import "./StatisticsView.css"
import { Button, Card, Col, Divider, Row, Select, Space, Spin, Statistic } from 'antd';
import { FailuresStatistics, ProductsStatistics } from "../../store/statistics/types";
import { Link } from "react-router-dom";
import { subsidiariesList, Subsidiary } from "../../store/common/types";
import { LoadingOutlined } from "@ant-design/icons/lib";
import { Chart } from "react-google-charts";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import {FormControlLabel} from "@material-ui/core";

const { Option } = Select;

export interface StatisticsViewProps {
  subsidiaries: Subsidiary[];
  productsStatistics: ProductsStatistics;
  failuresStatistics: FailuresStatistics;
  importerFailuresCount: number;
  handleSubsidiariesChange: (subsidiaries: Subsidiary[], excludeArchivedProducts: boolean) => void;
  loading?: boolean;
  error?: string;
}

export const StatisticsView: React.FunctionComponent<StatisticsViewProps> = (props) => {

  const { subsidiaries, productsStatistics, failuresStatistics, importerFailuresCount, handleSubsidiariesChange, loading } = props;

  const [selectedSubsidiaries, setSelectedSubsidiaries] = React.useState(subsidiaries);
  const [excludeArchivedProductsCheckboxState, setExcludeArchivedProductsCheckboxState] = React.useState(false);
  const [loadingState, setLoadingState] = React.useState(loading);

  const tempStats = {
    legacyProductsBySubsidiary: [
      {subsidiary: 'DE', legacyProductsCount: 10},
      {subsidiary: 'AT', legacyProductsCount: 30}
    ]

  }

  React.useEffect(() => {
    setSelectedSubsidiaries(subsidiaries)
  }, [subsidiaries]);

  React.useEffect(() => {
    setLoadingState(loading)
  }, [loading]);

  const onSubmit = () => {
    handleSubsidiariesChange(selectedSubsidiaries, excludeArchivedProductsCheckboxState);
  };

  const onSubsidiaryChange = (list: []) => {
    setSelectedSubsidiaries(list);
  };

  const loadingIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

  return props.error ?
    (
      <div className="view-error">{props.error}</div>
    )
    :
    (
      <React.Fragment>

        <Spin spinning={loadingState} indicator={loadingIcon} delay={100}>
        {
          <React.Fragment>
            <Space direction={"horizontal"}>
              <Select
                mode="multiple"
                size={"large"}
                style={{ width: '200px' }}
                placeholder="Select subsidiaries"
                value={selectedSubsidiaries}
                onChange={onSubsidiaryChange}>
                >
                {
                  subsidiariesList.map(subsidiary => {
                    return <Option value={subsidiary}>{subsidiary}</Option>
                  })
                }
              </Select>
              <FormGroup>
                <FormControlLabel control={
                  <Checkbox
                    onClick={() => setExcludeArchivedProductsCheckboxState(!excludeArchivedProductsCheckboxState)}
                    checked={excludeArchivedProductsCheckboxState}
                  />
                } label="Exclude Archived Products" />
              </FormGroup>
              <Button type="primary" htmlType="submit" size={"large"} onClick={onSubmit}>
                Apply
              </Button>
            </Space>
            <Divider/>
          </React.Fragment>
        }
        {
          (<React.Fragment>
              <Row gutter={[{ xs: 8, sm: 8, md: 8, lg: 8 }, { xs: 8, sm: 8, md: 8, lg: 8 }]}>
                <Col xs={96} sm={96} md={96} lg={96}>
                  <Card title="Global Product ID Mapping Index" size={"small"}>
                    <Statistic
                      value={productsStatistics ? productsStatistics.globalIdMappingIndex : ""}
                      precision={2}
                      valueStyle={{ color: '#3f8600' }}
                      suffix="%"
                    />
                  </Card>
                </Col>
                <Col xs={96} sm={96} md={96} lg={96}>
                  <Card title="Core Products" size={"small"}>
                    <Link to={"/app/products/list"}>
                      <Statistic
                        title={"Total"}
                        value={productsStatistics ? productsStatistics.coreProducts : ""}
                        precision={0}
                        valueStyle={{ color: '#3f8600' }}
                      />
                    </Link>
                    <Statistic
                          title={"Products without KoP"}
                          value={productsStatistics ? productsStatistics.coreProductsNoKoP : ""}
                          precision={0}
                          valueStyle={{ color: '#cf1322' }}
                    />
                  </Card>
                </Col>
                <Col xs={96} sm={96} md={96} lg={96}>
                  <Card title={"Failures"} size={"small"}>
                    <Link to={"/app/failures?importStatus=FAILED&failureStatus=UNRESOLVED"}>
                      <Statistic
                        title={"Total"}
                        value={failuresStatistics ? (failuresStatistics.failures + importerFailuresCount) : importerFailuresCount}
                        precision={0}
                        valueStyle={{ color: '#cf1322' }}
                      />
                    </Link>
                    <Link to={"/app/failures?failureStatus=UNRESOLVED&activeTab=1"}>
                      <Statistic
                        title={"Product Failures"}
                        value={(failuresStatistics && failuresStatistics.failures || 0)}
                        precision={0}
                        valueStyle={{ color: '#cf1322' }}
                      />
                    </Link>
                    <Link to={"/app/failures?importStatus=FAILED&activeTab=2"}>
                      <Statistic
                        title={"Importer Failures"}
                        value={importerFailuresCount}
                        precision={0}
                        valueStyle={{ color: '#cf1322' }}
                      />
                    </Link>
                  </Card>
                </Col>
              </Row>
                <br/>
              <Row gutter={[{ xs: 8, sm: 8, md: 8, lg: 8 }, { xs: 8, sm: 8, md: 8, lg: 8 }]}>
                <Col xs={96} sm={96} md={96} lg={96}>
                  <Card title="Imported Products" size={"small"}>
                    <Chart
                        chartType="PieChart"
                        data={
                          productsStatistics && [... [["Subsidiary", "Count"]], ... productsStatistics.legacyProductsBySubsidiary
                              .sort((a, b) => b.legacyProductsCount - a.legacyProductsCount)
                              .map(p => {
                                return [p.subsidiary, p.legacyProductsCount]
                              })]}
                        options={{
                          legend: {
                            position: 'bottom'
                          },
                          chartArea: {
                            left: 20,
                            right: 20,
                            top: 20
                          },
                        }}
                        width={350}
                        height={400}
                    />
                  </Card>
                </Col>
                <Col xs={96} sm={96} md={96} lg={96}>
                  <Card title="Duplicated Products" size={"small"}>

                    <Chart
                        chartType="PieChart"
                        data={
                            productsStatistics && [... [["Subsidiary", "Count"]], ... productsStatistics.duplicatedProductsBySubsidiary
                                .sort((a, b) => b.count - a.count)
                                .map(p => {
                                  return [p.subsidiary, p.count]
                                })]}
                        options={{
                          legend: {
                            position: 'bottom'
                          },
                          chartArea: {
                            left: 20,
                            right: 20,
                            top: 20
                          },
                        }}
                        width={350}
                        height={400}
                    />
                  </Card>
                </Col>
                <Col xs={96} sm={96} md={96} lg={96}>
                  <Card title="Product Failures" size={"small"} className="failure-statistics-card">
                    <Chart
                        chartType="PieChart"
                        data={
                            failuresStatistics && [... [["ErrorType", "Count"]], ... failuresStatistics.failuresByErrorType
                                .filter(p => p.errorType !== null)
                                .sort((a, b) => b.count - a.count)
                                .map(p => {
                                  return [p.errorType, p.count]
                                })]}
                        options={{
                          legend: {
                            position: 'left'
                          },
                          chartArea:{
                            left: 5,
                            top: 20,
                            right: 5
                          }
                        }}
                        width={500}
                        height={400}
                    />
                  </Card>
                </Col>
              </Row>
            </React.Fragment>
          )
        }
        </Spin>

      </React.Fragment>
    );
};

export default StatisticsView;


