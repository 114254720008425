import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import "./ImportTaskExecutionPage.css";
import { Dispatch } from "redux";
import { AppState } from "../../store";
import {flowRight} from "lodash";
import { connect } from 'react-redux';
import { UserSession } from "../../store/userSession/types";
import { ImportTaskExecution } from "../../store/productImports/types";
import { fetchImportTaskExecution, retryProductImports } from "../../store/productImports/actions";
import PageWrapper from "../../components/Drawer/PageWrapper";
import { PageHeader } from 'antd';
import ImportTaskExecutionView from "../../components/ImportTaskExecution/ImportTaskExecutionView";
import {rejectImporterFailure, retryImporterFailure} from "../../store/failures/actions";

interface Props extends RouteComponentProps<{id: string}> {
  userSession: UserSession;
  importTaskExecutionId: string;
  importTaskExecution: ImportTaskExecution;
  fetchImportTaskExecution: (id: string) => void;
  loading?: boolean;
  error?: string;
  retryImport: (id: string) => void;
  retryFailure: (id: number) => void;
  rejectImporterFailure: (id: number) => void;
  retryLoading: boolean;
}

interface State {
  selectedIds: string[];
}

class ImportTaskExecutionPage extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.onImportRetry = this.onImportRetry.bind(this);
    this.onFailureRetry = this.onFailureRetry.bind(this);
    this.onRejectImporterFailure = this.onRejectImporterFailure.bind(this);

    this.state = {
      selectedIds: []
    };
  }

  componentDidMount () {
    this.props.fetchImportTaskExecution(this.props.match.params.id)
  }

  private onImportRetry(id: string) {
    this.props.retryImport(id);
  }

  private onFailureRetry(id: number) {
    this.props.retryFailure(id);
  }

  private onRejectImporterFailure(id: number) {
    this.props.rejectImporterFailure(id);
  }


  public render() {

    return (
      <div className="import-task-execution-page">
        <PageWrapper userSession={this.props.userSession}>
          <PageHeader
            ghost={false}
            onBack={() => window.history.back()}
            title="Import Task Execution"
            subTitle={"Details of the import task"}
          >
          </PageHeader>
          <ImportTaskExecutionView
            importTaskExecution={this.props.importTaskExecution}
            loading={this.props.loading}
            error={this.props.error}
            onImportRetry={this.onImportRetry}
            onFailureRetry={this.onFailureRetry}
            onProductFailureReject={this.onRejectImporterFailure}
            retryLoading={this.props.retryLoading}
          />
        </PageWrapper>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  userSession: state.userSession.userSession,

  importTaskExecutionId: state.productImports.importTaskExecutionId,
  importTaskExecution: state.productImports.importTaskExecution,

  loading: state.productImports.loading,
  error: state.productImports.error,

  retryLoading: state.retryImports.retryLoading,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchImportTaskExecution: (id: string) => {
    dispatch(fetchImportTaskExecution(id))
  },
  retryImport: (id: string) => {
    dispatch(retryProductImports({
      ids: [id]
    }))
  },
  retryFailure: (id: number) => {
    dispatch(retryImporterFailure(id))
  },
  rejectImporterFailure: (id: number) => {
    dispatch(rejectImporterFailure(id))
  }
});

const enhance = flowRight(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(ImportTaskExecutionPage);
