import { Reducer } from "redux";
import {
  ADD_USER,
  ADD_USER_FAILED,
  ADD_USER_SUCCEEDED,
  CLOSE_USERS_TAB_ERROR_MESSAGE,
  CREATE_GROUP,
  CREATE_GROUP_FAILED,
  CREATE_GROUP_SUCCEEDED,
  CREATE_PERMISSION,
  CREATE_PERMISSION_FAILED,
  CREATE_PERMISSION_SUCCEEDED,
  REFRESH_USER,
  REFRESH_USER_SUCCEEDED,
  REFRESH_USER_FAILED,
  DELETE_USER,
  DELETE_USER_SUCCEEDED,
  DELETE_USER_FAILED,
  FETCH_GROUP,
  FETCH_GROUP_FAILED,
  FETCH_GROUP_SUCCEEDED,
  FETCH_GROUPS_LIST,
  FETCH_GROUPS_LIST_FAILED,
  FETCH_GROUPS_LIST_SUCCEEDED,
  FETCH_PERMISSION,
  FETCH_PERMISSION_FAILED,
  FETCH_PERMISSION_SUCCEEDED,
  FETCH_PERMISSIONS_LIST,
  FETCH_PERMISSIONS_LIST_FAILED,
  FETCH_PERMISSIONS_LIST_SUCCEEDED,
  FETCH_USER,
  FETCH_USER_FAILED,
  FETCH_USER_SUCCEEDED,
  FETCH_USERS_LIST,
  FETCH_USERS_LIST_FAILED,
  FETCH_USERS_LIST_SUCCEEDED,
  PermissionsManagementActionTypes,
  PermissionsManagementState,
  UPDATE_GROUP,
  UPDATE_GROUP_FAILED,
  UPDATE_GROUP_SUCCEEDED,
  UPDATE_PERMISSION,
  UPDATE_PERMISSION_FAILED,
  UPDATE_PERMISSION_SUCCEEDED,
  UPDATE_USER,
  UPDATE_USER_FAILED,
  UPDATE_USER_SUCCEEDED,
} from "./types";

const initialState: PermissionsManagementState = {
  groups: [],
  permissions: [],
  users: [],

  groupsLoading: false,
  groupsError: null,

  permissionsLoading: false,
  permissionsError: null,

  usersLoading: false,
  usersError: null,

  userId: null,
  userDetails: null,
  userDetailsLoading: false,
  userDetailsError: null,

  groupId: null,
  groupDetails: null,
  groupDetailsLoading: false,
  groupDetailsError: null,

  permissionId: null,
  permissionDetails: null,
  permissionDetailsLoading: false,
  permissionDetailsError: null
};

export const permissionsManagementReducer: Reducer<PermissionsManagementState, PermissionsManagementActionTypes> = (state: PermissionsManagementState = initialState, action: PermissionsManagementActionTypes): PermissionsManagementState => {
  switch (action.type) {

    case FETCH_GROUPS_LIST: {
      return {
        ... state,
        groupsLoading: true,
        groupsError: null
      }
    }

    case FETCH_GROUPS_LIST_SUCCEEDED: {
      return {
        ...state,
        groups: action.payload.content,
        groupsLoading: false,
        groupsError: null
      }
    }

    case FETCH_GROUPS_LIST_FAILED: {
      return {
        ...state,
        groupsLoading: false,
        groupsError: action.error
      }
    }


    case FETCH_PERMISSIONS_LIST: {
      return {
        ... state,
        permissionsLoading: true,
        permissionsError: null
      }
    }

    case FETCH_PERMISSIONS_LIST_SUCCEEDED: {
      return {
        ...state,
        permissions: action.payload.content,
        permissionsLoading: false,
        permissionsError: null
      }
    }

    case FETCH_PERMISSIONS_LIST_FAILED: {
      return {
        ...state,
        permissionsLoading: false,
        permissionsError: action.error
      }
    }

    case FETCH_USERS_LIST: {
      return {
        ... state,
        usersLoading: true,
        usersError: null
      }
    }

    case FETCH_USERS_LIST_SUCCEEDED: {
      return {
        ...state,
        users: action.payload.content,
        usersLoading: false,
        usersError: null
      }
    }

    case FETCH_USERS_LIST_FAILED: {
      return {
        ...state,
        usersLoading: false,
        usersError: action.error
      }
    }

    case ADD_USER: {
      return {
        ... state,
        usersLoading: true,
        usersError: null
      }
    }

    case ADD_USER_SUCCEEDED: {
      return {
        ...state,
        usersLoading: false,
        usersError: null
      }
    }

    case ADD_USER_FAILED: {
      return {
        ...state,
        usersLoading: false,
        usersError: action.error
      }
    }

    case CLOSE_USERS_TAB_ERROR_MESSAGE: {
      return {
        ...state,
        usersLoading: false,
        usersError: null
      }
    }

    case FETCH_USER: {
      return {
        ... state,
        userId: action.payload.id,
        userDetails: null,
        userDetailsLoading: true,
        userDetailsError: null
      }
    }

    case FETCH_USER_SUCCEEDED: {
      return {
        ...state,
        userDetails: action.payload.user,
        userDetailsLoading: false,
        userDetailsError: null
      }
    }

    case FETCH_USER_FAILED: {
      return {
        ...state,
        userDetailsLoading: false,
        userDetailsError: action.error
      }
    }

    case UPDATE_USER: {
      return {
        ... state,
        userDetailsLoading: true,
        userDetailsError: null
      }
    }

    case UPDATE_USER_SUCCEEDED: {
      return {
        ...state,
        userDetails: action.payload.user,
        userDetailsLoading: false,
        userDetailsError: null
      }
    }

    case UPDATE_USER_FAILED: {
      return {
        ...state,
        userDetailsLoading: false,
        userDetailsError: action.error
      }
    }

    case REFRESH_USER: {
      return {
        ... state,
        userDetailsLoading: true,
        userDetailsError: null
      }
    }

    case REFRESH_USER_SUCCEEDED: {
      return {
        ...state,
        userDetails: action.payload.user,
        userDetailsLoading: false,
        userDetailsError: null
      }
    }

    case REFRESH_USER_FAILED: {
      return {
        ...state,
        userDetailsLoading: false,
        userDetailsError: action.error
      }
    }

    case DELETE_USER: {
      return {
        ... state,
        userDetailsLoading: true,
        userDetailsError: null
      }
    }

    case DELETE_USER_SUCCEEDED: {
      return {
        ...state,
        userDetails: action.payload.user,
        userDetailsLoading: false,
        userDetailsError: null
      }
    }

    case DELETE_USER_FAILED: {
      return {
        ...state,
        userDetailsLoading: false,
        userDetailsError: action.error
      }
    }

    case FETCH_GROUP: {
      return {
        ... state,
        groupId: action.payload.id,
        groupDetails: null,
        groupDetailsLoading: true,
        groupDetailsError: null
      }
    }

    case FETCH_GROUP_SUCCEEDED: {
      return {
        ...state,
        groupDetails: action.payload.group,
        groupDetailsLoading: false,
        groupDetailsError: null
      }
    }

    case FETCH_GROUP_FAILED: {
      return {
        ...state,
        groupDetailsLoading: false,
        groupDetailsError: action.error
      }
    }

    case UPDATE_GROUP: {
      return {
        ... state,
        groupDetailsLoading: true,
        groupDetailsError: null
      }
    }

    case UPDATE_GROUP_SUCCEEDED: {
      return {
        ...state,
        groupDetails: action.payload.group,
        groupDetailsLoading: false,
        groupDetailsError: null
      }
    }

    case UPDATE_GROUP_FAILED: {
      return {
        ...state,
        groupDetailsLoading: false,
        groupDetailsError: action.error
      }
    }

    case CREATE_GROUP: {
      return {
        ... state,
        groupDetailsLoading: true,
        groupDetailsError: null
      }
    }

    case CREATE_GROUP_SUCCEEDED: {
      return {
        ...state,
        groupDetails: action.payload.group,
        groupDetailsLoading: false,
        groupDetailsError: null
      }
    }

    case CREATE_GROUP_FAILED: {
      return {
        ...state,
        groupDetailsLoading: false,
        groupDetailsError: action.error
      }
    }

    case FETCH_PERMISSION: {
      return {
        ... state,
        permissionId: action.payload.id,
        permissionDetails: null,
        permissionDetailsLoading: true,
        permissionDetailsError: null
      }
    }

    case FETCH_PERMISSION_SUCCEEDED: {
      return {
        ...state,
        permissionDetails: action.payload.permission,
        permissionDetailsLoading: false,
        permissionDetailsError: null
      }
    }

    case FETCH_PERMISSION_FAILED: {
      return {
        ...state,
        permissionDetailsLoading: false,
        permissionDetailsError: action.error
      }
    }

    case CREATE_PERMISSION: {
      return {
        ... state,
        permissionDetailsLoading: true,
        permissionDetailsError: null
      }
    }

    case CREATE_PERMISSION_SUCCEEDED: {
      return {
        ...state,
        permissionDetails: action.payload.permission,
        permissionDetailsLoading: false,
        permissionDetailsError: null
      }
    }

    case CREATE_PERMISSION_FAILED: {
      return {
        ...state,
        permissionDetailsLoading: false,
        permissionDetailsError: action.error
      }
    }

    case UPDATE_PERMISSION: {
      return {
        ... state,
        permissionDetailsLoading: true,
        permissionDetailsError: null
      }
    }

    case UPDATE_PERMISSION_SUCCEEDED: {
      return {
        ...state,
        permissionDetails: action.payload.permission,
        permissionDetailsLoading: false,
        permissionDetailsError: null
      }
    }

    case UPDATE_PERMISSION_FAILED: {
      return {
        ...state,
        permissionDetailsLoading: false,
        permissionDetailsError: action.error
      }
    }

    default:
      neverReached(action);
  }

  return state;
};


const neverReached = (never: never) => {};
