import * as React from "react";
import { useEffect, useState } from "react";
import "./CoreProductView.css"
import DataService from "../../services/DataService";
import Highlight from "../Highlight/Highlight";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";

export interface Props {
  coreProductId: number;
  extension?: boolean
}

export const LoadableCoreProductApiResponseView: React.FunctionComponent<Props> = (props) => {

  const { coreProductId, extension } = props;

  const [coreProduct, setCoreProduct] = useState(null as string);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null as string);

  useEffect(() => {

    if (coreProductId) {
      setLoading(true);

      if (extension) {

        DataService
          .fetchCoreProductExtensionApi(coreProductId.toString())
          .then(coreProduct => {
            setLoading(false);
            setCoreProduct(coreProduct);
          })
          .catch(error => {
            setLoading(false);
            setError(error.response.data.detail);
          });

      } else {

        DataService
          .fetchCoreProductApi(coreProductId.toString())
          .then(coreProduct => {
            setLoading(false);
            setCoreProduct(coreProduct);
          })
          .catch(error => {
            setLoading(false);
            setError(error.response.data.detail);
          });

      }
    } else {
      setLoading(false);
      setCoreProduct(null);
    }
  }, [coreProductId]);

  return error ?
    (
      <div className="view-error">{error}</div>
    )
    :
    (
      <React.Fragment>
        {
          loading && (<div>
            <div className="view-loading"/>
            <CircularProgress className="view-loading-spinner"/>
          </div>)
        }
        {
          !!coreProduct &&
          <Highlight language="json">
            {coreProduct}
          </Highlight>
        }
      </React.Fragment>
    )
};

export default LoadableCoreProductApiResponseView;
