import {AxiosInstance} from "axios";
import axiosInstance from "../axiosInstance";
import {resolveApiBaseUrl} from "../environment";
import * as qs from "qs";
import {
    AddKindOfItemRequest,
    AddKindOfItemResponse,
    FetchKindOfLevelItemsPageRequest,
    FetchKindOfLevelItemsPageResponse,
    GetKindOfItemResponse,
    KindOfProductMappingResponse,
    MappedValueMappingResponse,
    UpdateKindOfItemRequest
} from "../store/kindOf/types";
import {MappingSourceType} from "../store/common/types";

class KindOfProductService {
    axios: AxiosInstance;

    constructor() {
        this.axios = axiosInstance;
    }

    addKindOfItems(addKindOfItemRequest: AddKindOfItemRequest): Promise<AddKindOfItemResponse> {
        return this.axios.post(`/gateway/core-product-kind-of-product-internal/api/v1/kind-of-products`, addKindOfItemRequest, {
            headers: {
                'Content-Type': 'application/json'
            },
            baseURL: resolveApiBaseUrl()
        })
            .then((response: any) => {
                return {
                    ...response['data']
                } as AddKindOfItemResponse;
            })
            .catch((error: any) => {
                this.handleError(error.response.data.detail);
                throw error
            })
    }

    updateKindOfItem(payload: UpdateKindOfItemRequest): Promise<void> {
        return this.axios.put(`/gateway/core-product-kind-of-product-internal/api/v1/kind-of-products/${payload.code}`, payload, {
            headers: {
                'Content-Type': 'application/json'
            },
            baseURL: resolveApiBaseUrl()
        })
            .then((response: any) => {
                return {
                    ...response['data']
                } as void;
            })
            .catch((error: any) => {
                this.handleError(error.response.data.detail);
                throw error
            })
    }

    deleteKindOfItem(code: string): Promise<void> {
        return this.axios.delete(`/gateway/core-product-kind-of-product-internal/api/v1/kind-of-products/${code}`, {
            baseURL: resolveApiBaseUrl()
        })
            .then((response: any) => {
                return {
                    ...response['data']
                } as void;
            })
            .catch((error: any) => {
                this.handleError(error.response.data.detail);
                throw error
            })
    }

    getKindOfItem(code: string): Promise<GetKindOfItemResponse> {
        return this.axios.get(`/gateway/core-product-kind-of-product-internal/api/v1/kind-of-products/${code}`, {
            headers: {
                'Content-Type': 'application/json'
            },
            baseURL: resolveApiBaseUrl()
        })
            .then((response: any) => {
                return {
                    ...response['data']
                } as GetKindOfItemResponse;
            })
            .catch((error: any) => {
                this.handleError(error.response.data.detail);
                throw error
            })
    }

    getKindOfItems(pageRequest: FetchKindOfLevelItemsPageRequest): Promise<FetchKindOfLevelItemsPageResponse> {

        const params = {
            page: pageRequest.page,
            size: pageRequest.size,
            sort: pageRequest.sort.map(sort => sort.field + (sort.direction && ("," + sort.direction)))
        } as any;

        if (pageRequest.code) {
            params["code"] = pageRequest.code;
        }

        if (pageRequest.search) {
            params["search"] = pageRequest.search;
        }

        if (pageRequest.language) {
            params["language"] = pageRequest.language;
        }

        return this.axios.get(`/gateway/core-product-kind-of-product-internal/api/v1/kind-of-products/items`, {
            baseURL: resolveApiBaseUrl(),
            params: params,
            paramsSerializer: {
                serialize: params => {
                    return qs.stringify(params, {
                        arrayFormat: 'repeat'
                    })
                }
            }
        })
            .then((response: any) => {
                return {
                    ...response['data']
                } as FetchKindOfLevelItemsPageResponse;
            })
            .catch((error: any) => {
                this.handleError(error);
                throw error
            })
    }

    handleError(error: any) {
        console.error('KindOf:', error)
    }

    getMapping() {

        return this.axios.post(`/gateway/core-product-kind-of-product-internal/api/v1/kind-of-products/download-mapping`, null, {
            responseType: 'blob',
            baseURL: resolveApiBaseUrl(),
            paramsSerializer: {
                serialize: params => {
                    return qs.stringify(params, {
                        arrayFormat: 'indices',
                        allowDots: true
                    })
                }
            }
        })
            .then((response: any) => {

                const filename = 'kind_of_product_product_group_mapping.xlsx';
                let url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/octet-stream'}));
                let a = document.createElement('a');
                a.href = url;
                a.download = filename;
                a.click();

                return true;
            })
            .catch((error: any) => {
                this.handleError(error.response);
                throw error
            })
    }

    getKindOfProductMapping(code: string, mappingSource: MappingSourceType): Promise<KindOfProductMappingResponse> {

        return this.axios.get(`/gateway/core-product-kind-of-product-internal/api/v1/kind-of-products/mapping/mappings/${code}?mapping_source=${mappingSource}`, {
            baseURL: resolveApiBaseUrl()
        })
            .then((response: any) => {
                return {
                    content: response['data']
                } as KindOfProductMappingResponse;
            })
            .catch((error: any) => {
                this.handleError(error);
                throw error
            })
    }

    getMappedValueMapping(id: string, mappingSource: MappingSourceType): Promise<MappedValueMappingResponse> {

        return this.axios.get(`/gateway/core-product-kind-of-product-internal/api/v1/kind-of-products/mapping/kopmappings/${id}?mapping_source=${mappingSource}`, {
            baseURL: resolveApiBaseUrl()
        })
            .then((response: any) => {
                return {
                    content: response['data']
                } as KindOfProductMappingResponse;
            })
            .catch((error: any) => {
                this.handleError(error);
                throw error
            })
    }

    async getDeprecatedKindOfProductItems(pageRequest: FetchKindOfLevelItemsPageRequest): Promise<FetchKindOfLevelItemsPageResponse> {

        const params = {
            page: pageRequest.page,
            size: pageRequest.size,
            sort: pageRequest.sort.map(sort => sort.field + (sort.direction && ("," + sort.direction)))
        } as any;

        if (pageRequest.code) {
            params["code"] = pageRequest.code;
        }

        if (pageRequest.search) {
            params["search"] = pageRequest.search;
        }

        if (pageRequest.language) {
            params["language"] = pageRequest.language;
        }

        try {
            const response = await this.axios.get(`/gateway/core-product-kind-of-product-internal/api/v1/kind-of-products/deprecated-kop`, {
                baseURL: resolveApiBaseUrl(),
                params: params,
                paramsSerializer: {
                    serialize: params_2 => {
                        return qs.stringify(params_2, {
                            arrayFormat: 'repeat'
                        });
                    }
                }
            });
            return {
                ...response['data']
            } as FetchKindOfLevelItemsPageResponse;
        } catch (error) {
            this.handleError(error);
            throw error;
        }
    }
}

export default new KindOfProductService()
