import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import "./TaskListPage.css";
import { Dispatch } from "redux";
import { AppState } from "../../store";
import {flowRight} from "lodash";
import { connect } from 'react-redux';
import { UserSession } from "../../store/userSession/types";
import { Order } from "../../components/EnhancedTable/EnhancedTable";
import PageWrapper from "../../components/Drawer/PageWrapper";
import { PageHeader } from 'antd';
import { fetchTasks, updateTask } from "../../store/tasks/actions";
import { FetchTasksPageRequest, FetchTasksPageResponse, UpdateImportTask } from "../../store/tasks/types";
import TaskListView from "../../components/TaskList/TaskListView";

interface Props extends RouteComponentProps {
  userSession: UserSession;
  pageRequest: FetchTasksPageRequest;
  pageResponse: FetchTasksPageResponse;
  fetchTasks: (pageRequest: FetchTasksPageRequest) => void;
  updateTask: (request: UpdateImportTask) => void;
  loading?: boolean;
  error?: string;
}

interface State {
}

class TaskListPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.onPaginationChanged = this.onPaginationChanged.bind(this);
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.onSortingChanged = this.onSortingChanged.bind(this);
    this.onFilterChanged = this.onFilterChanged.bind(this);
    this.onUpdateTask = this.onUpdateTask.bind(this);
    this.onRowClick = this.onRowClick.bind(this);

    this.state = {
      selectedIds: []
    };
  }

  componentDidMount () {
    this.props.fetchTasks(this.props.pageRequest)
  }

  private onPaginationChanged(page: number, size: number) {
    console.log("Page: " + page + ", size: " + size);

    this.props.fetchTasks({
      ... this.props.pageRequest,
      page,
      size
    })
  }

  private onRowClick(id: string) {
    this.props.history.push("/app/product-imports/" + id);
  }

  private onFilterChanged(filterField: string, value: string) {
    console.log("Filter: " + filterField + ", value: " + value);

    this.props.fetchTasks({
      ... this.props.pageRequest,
      [filterField]: value
    })
  }

  private onUpdateTask(id: string, enabled: boolean) {
    this.props.updateTask({
      id,
      enabled
    });
  }

  private onSelectionChanged(selectedIds: string[]) {
    this.setState({
      selectedIds
    }, () => {
      console.log(selectedIds);
    });

  }

  private onSortingChanged(orderBy: string, order: Order) {
    console.log("Order by: " + orderBy + ", order: " + order);

    this.props.fetchTasks({
      ... this.props.pageRequest,
      sort: [{
        field: orderBy,
        direction: order
      }]
    });
  }

  public render() {
    return (
      <div className="import-tasks-page">
        <PageWrapper userSession={this.props.userSession}>
          <PageHeader
            backIcon={null}
            ghost={false}
            onBack={() => window.history.back()}
            title="Tasks"
            subTitle="Displays status of tasks for importing products from various source systems for various subsidiaries"
          >
          </PageHeader>
          <TaskListView
            pageRequest={this.props.pageRequest}
            pageResponse={this.props.pageResponse}
            onPaginationChanged={this.onPaginationChanged}
            onSortingChanged={this.onSortingChanged}
            onFilterChanged={this.onFilterChanged}
            loading={this.props.loading}
            error={this.props.error}
            onChangeEnabledStatus={this.onUpdateTask}
            changeEnabledStatusLoading={this.props.loading}
          />
        </PageWrapper>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  userSession: state.userSession.userSession,
  pageRequest: state.tasks.pageRequest,
  pageResponse: state.tasks.pageResponse,
  loading: state.tasks.loading,
  error: state.tasks.error,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchTasks: (pageRequest: FetchTasksPageRequest) => {
    dispatch(fetchTasks(pageRequest))
  },
  updateTask: (updateTaskRequest: UpdateImportTask) => {
    dispatch(updateTask(updateTaskRequest))
  }
});

const enhance = flowRight(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(TaskListPage);
