import * as React from "react";
import { useEffect, useState } from "react";
import { LoadableSelect, LoadableSelectItem } from "./LoadableSelect";
import KindOfProductService from "../../services/KindOfProductService";
import { Alert } from "antd";

export interface Props {
  search?: any;
  onChange?: (value: string) => void;
  kindOfProduct?: string;
}

export const KindOfProductLoadableSelect: React.FunctionComponent<Props> = (props) => {

  const [error, setError] = React.useState(false);
  const [kindOfProduct, setKindOfProduct] = useState<LoadableSelectItem>(null);
  const [loadOptionsTrigger, setLoadOptionsTrigger] = useState(0);

  const setKindOfProductAndNotify = (kindOfProduct: any) => {
    setKindOfProduct(kindOfProduct);
    props.onChange(kindOfProduct ? (kindOfProduct.value as string) : undefined);
  };

  useEffect(() => {
    setKindOfProduct({value: props.kindOfProduct, key: props.kindOfProduct, label: props.kindOfProduct})
  }, [props.kindOfProduct]);

  useEffect(() => {
    setLoadOptionsTrigger(Math.random() * 10000.00);

  }, []);

  useEffect(() => {

    if (props.search) {

      searchKindOfProducts(props.search, 0)
        .then(kindOfProducts => {
          if (kindOfProducts.length == 1) {
            setKindOfProduct(kindOfProducts[0]);
          }
        })
        .catch((error: any) => {
          console.error(error)
          setKindOfProduct(null);
          setError(true);
        });
    } else {
      setKindOfProduct(null);
    }
  }, [props.search]);

  const searchKindOfProducts = (searchInput: string, page: number): Promise<LoadableSelectItem[]> => {

    const searchId = (searchInput && /^[0-9]+$/.test(searchInput.trim()));

    return KindOfProductService.getKindOfItems({
      search: searchId ? null : searchInput,
      code: null,
      page: page,
      size: 50,
      sort: [],
      language: "en-GB"
    })
      .then(response => {
        return response.content.map(
          (kindOfLevelItem) => {
            return {
              value: kindOfLevelItem.code,
              label: kindOfLevelItem.code + " - " + kindOfLevelItem.name
            }
          }
        );
      }).catch(error => {
        setError(true);
        throw error;
      });
  };

  return (
    <React.Fragment>
      {
        error && <Alert message="Error loading data" type="error" showIcon closable onClose={() => {
          setError(false)
        }}/>
      }
      <LoadableSelect
        value={kindOfProduct || undefined}
        placeholder="Search Kind of Product"
        fetchOptions={(search, page) => searchKindOfProducts(search, page)}
        loadOptionsTrigger={loadOptionsTrigger}
        onChange={newKindOfProduct => {
          setKindOfProductAndNotify(newKindOfProduct);
        }}
        style={{ width: '100%' }}
      />
    </React.Fragment>
  );
};

export default KindOfProductLoadableSelect;
