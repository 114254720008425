import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import "./ImportProductGroupClassificationPage.css";
import { Dispatch } from "redux";
import { AppState, store } from "../../store";
import {flowRight} from "lodash";
import { connect } from 'react-redux';
import { UserSession } from "../../store/userSession/types";
import PageWrapper from "../../components/Drawer/PageWrapper";
import { PageHeader } from "antd";
import {
    closeProductGroupClassificationErrorMessage,
    importProductGroupClassification
} from "../../store/classifications/productGroup/actions";
import ImportProductGroupClassificationView
    from "../../components/ImportProductGroupClassification/ImportProductGroupClassificationView";
import importResponseService from '../../services/ImportResponseService'
import { ImportProgressResponse } from "../../store/classifications/importProgress/types";
import { fetchImportProgress } from "../../store/classifications/importProgress/actions";

interface Props extends RouteComponentProps {
    userSession: UserSession;
    importClassification: () => void;
    importRunning: boolean;
    importProgress: number;
    loading?: boolean;
    error?: string;
}

class ImportProductGroupClassificationPage extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.onImportButtonClick = this.onImportButtonClick.bind(this);
    }

    componentDidMount() {
        importResponseService.getRunningImport('PRODUCT_GROUP').then((runningImport: ImportProgressResponse) => {

            if (!!runningImport && runningImport.importId && runningImport.importType) {
                store.dispatch(fetchImportProgress({
                    importId: runningImport.importId,
                    importType: runningImport.importType
                }));
            }

        }).catch(error => {
            console.error(error);
        })
    }

    private static onMessageClose() {
        store.dispatch(closeProductGroupClassificationErrorMessage());
    }

    private onImportButtonClick() {
        this.props.importClassification();
    }

    public render() {
        return (
            <div className="import-productGroup-classification-page">
                <PageWrapper userSession={this.props.userSession}>
                    <PageHeader
                        backIcon={null}
                        ghost={false}
                        onBack={() => window.history.back()}
                        title="Import Product Group classification"
                        subTitle="Imports Product Group classification from MDNG"
                    >
                    </PageHeader>
                    <ImportProductGroupClassificationView
                        onMessageClose={ImportProductGroupClassificationPage.onMessageClose}
                        onImportButtonClick={this.onImportButtonClick}
                        importRunning={this.props.importRunning}
                        importProgress={this.props.importProgress}
                        error={this.props.error}
                        loading={this.props.loading}
                    />
                </PageWrapper>
            </div>

        );
    }
}

const mapStateToProps = (state: AppState) => ({
    userSession: state.userSession.userSession,
    importRunning: !!state.classificationImportProgress.productGroups.response,
    importProgress: state.classificationImportProgress.productGroups.response && state.classificationImportProgress.productGroups.response.progress,
    loading: state.productGroupClassification.loading || state.classificationImportProgress.productGroups.loading,
    error: state.productGroupClassification.error || state.classificationImportProgress.productGroups.error,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    importClassification: () => {
        dispatch(importProductGroupClassification())
    },
});

const enhance = flowRight(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(ImportProductGroupClassificationPage);
