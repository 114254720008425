import {all, call, put, takeLatest} from 'redux-saga/effects'

import {
    FETCH_PRODUCT_GROUP_ITEM,
    FETCH_PRODUCT_GROUP_ITEMS,
    FetchProductGroupItemAction,
    FetchProductGroupItemsAction,
    IMPORT_PRODUCT_GROUP_CLASSIFICATION,
} from './types'
import {
    fetchProductGroupLevelItemFailed,
    fetchProductGroupLevelItemsFailed,
    fetchProductGroupLevelItemsSucceeded,
    fetchProductGroupLevelItemSucceeded,
    importProductGroupClassificationFailed,
    importProductGroupClassificationSucceeded
} from './actions'

import productGroupClassificationService from '../../../services/ProductGroupClassificationService'
import {message} from "antd";
import {
    ImportProgressResponse
} from "../importProgress/types";
import {
    fetchImportProgress
} from "../importProgress/actions";

export function* productGroupClassificationSaga() {
    yield all([
        watchFetchProductGroupClassificationLevelItem(),
        watchFetchProductGroupClassificationLevelItems(),
        watchImportProductGroupClassification()
    ])
}

function* watchFetchProductGroupClassificationLevelItems() {
    yield takeLatest(FETCH_PRODUCT_GROUP_ITEMS, handleFetchProductGroupItems)
}

function* watchFetchProductGroupClassificationLevelItem() {
    yield takeLatest(FETCH_PRODUCT_GROUP_ITEM, handleFetchProductGroupItem)
}

function* handleFetchProductGroupItems(action: FetchProductGroupItemsAction) {
    try {
        const data = yield call([productGroupClassificationService, productGroupClassificationService.getProductGroupItems], action.pageRequest);
        yield put(fetchProductGroupLevelItemsSucceeded(data))
    } catch (error) {
        message.error(error.message);
        yield put(fetchProductGroupLevelItemsFailed(`${error.message}`))
    }
}

function* handleFetchProductGroupItem(action: FetchProductGroupItemAction) {
    try {
        const data = yield call([productGroupClassificationService, productGroupClassificationService.getProductGroupItem], action.itemRequest);
        yield put(fetchProductGroupLevelItemSucceeded(data))
    } catch (error) {
        message.error(error.message);
        yield put(fetchProductGroupLevelItemFailed(`${error.message}`))
    }
}

function* watchImportProductGroupClassification() {
    yield takeLatest(IMPORT_PRODUCT_GROUP_CLASSIFICATION, handleImportProductGroupClassification)
}

function* handleImportProductGroupClassification() {
    try {
        const data: ImportProgressResponse = yield call([productGroupClassificationService, productGroupClassificationService.importProductGroupClassification]);
        yield put(importProductGroupClassificationSucceeded());
        yield put(fetchImportProgress({
            importId: data.importId,
            importType: data.importType
        }));
    } catch (error) {
        message.error(error.response.data.title);
        yield put(importProductGroupClassificationFailed(`${error.response.data.detail}`))
    }
}
