import { all, call, put, takeLatest } from 'redux-saga/effects'

import dataService from '../../services/DataService'
import { message } from "antd";
import * as React from "react";
import {
  CREATE_AVOIDANCE_FIX_DISREGARD,
  CreateAvoidanceFixDisregardRuleAction,
  DELETE_AVOIDANCE_FIX_DISREGARD,
  DeleteAvoidanceFixDisregardRulesAction,
  FETCH_AVOIDANCE_FIX_DISREGARD,
  FetchAvoidanceFixDisregardRulesAction
} from "./types";
import {
  avoidanceFixDisregardFailed,
  avoidanceFixDisregardSucceeded,
  createAvoidanceFixDisregardRuleSucceeded, deleteAvoidanceFixDisregardRule, deleteAvoidanceFixDisregardRuleSucceeded
} from "./actions";

export function* avoidanceFixDisregardSaga() {
  yield all([
    watchFetchAvoidanceFixDisregardRules(),
    watchCreateAvoidanceFixDisregardRule(),
    watchDeleteAvoidanceFixDisregardRules()
  ])
}

function* watchFetchAvoidanceFixDisregardRules() {
  yield takeLatest(FETCH_AVOIDANCE_FIX_DISREGARD, handleFetchAvoidanceFixDisregardRules)
}

function* watchCreateAvoidanceFixDisregardRule() {
  yield takeLatest(CREATE_AVOIDANCE_FIX_DISREGARD, handleCreateAvoidanceFixDisregardRule)
}

function* watchDeleteAvoidanceFixDisregardRules() {
  yield takeLatest(DELETE_AVOIDANCE_FIX_DISREGARD, handleDeleteAvoidanceFixDisregardRules)
}

function* handleFetchAvoidanceFixDisregardRules(action: FetchAvoidanceFixDisregardRulesAction) {
  try {
    const data = yield call([dataService, dataService.findAvoidanceFixDisregardRules], action.pageRequest);
    yield put(avoidanceFixDisregardSucceeded(data))
  } catch (error) {
    message.error(error.message);
    yield put(avoidanceFixDisregardFailed(`${error.message}`))
  }
}

function* handleCreateAvoidanceFixDisregardRule(action: CreateAvoidanceFixDisregardRuleAction) {
  try {
    const data = yield call([dataService, dataService.createAvoidanceFixDisregardRule], action.request);
    yield put(createAvoidanceFixDisregardRuleSucceeded(data))
  } catch (error) {
    message.error(error.message);
    yield put(avoidanceFixDisregardFailed(`${error.message}`))
  }
}

function* handleDeleteAvoidanceFixDisregardRules(action: DeleteAvoidanceFixDisregardRulesAction) {
  try {
    yield call([dataService, dataService.deleteAvoidanceFixDisregardRule], action.request);
    yield put(deleteAvoidanceFixDisregardRuleSucceeded())
  } catch (error) {
    message.error(error.message);
    yield put(avoidanceFixDisregardFailed(`${error.message}`))
  }
}