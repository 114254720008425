import {
    ClassificationType,
    FETCH_IMPORT_CLASSIFICATION_PROGRESS,
    FETCH_IMPORT_CLASSIFICATION_PROGRESS_CONTINUES,
    FETCH_IMPORT_CLASSIFICATION_PROGRESS_FAILED,
    FETCH_IMPORT_CLASSIFICATION_PROGRESS_SUCCEEDED,
    ImportProgressActionTypes,
    ImportProgressRequest,
    ImportProgressResponse,
} from './types'


export function fetchImportProgress(request: ImportProgressRequest): ImportProgressActionTypes {
    return {
        type: FETCH_IMPORT_CLASSIFICATION_PROGRESS,
        request
    }
}

export function fetchImportProgressContinues(payload: ImportProgressResponse): ImportProgressActionTypes {
    return {
        type: FETCH_IMPORT_CLASSIFICATION_PROGRESS_CONTINUES,
        payload
    }
}

export function fetchImportProgressSucceeded(payload: ImportProgressResponse): ImportProgressActionTypes {
    return {
        type: FETCH_IMPORT_CLASSIFICATION_PROGRESS_SUCCEEDED,
        payload
    }
}

export function fetchImportProgressFailed(importType: ClassificationType, error: string): ImportProgressActionTypes {
    return {
        type: FETCH_IMPORT_CLASSIFICATION_PROGRESS_FAILED,
        importType,
        error
    }
}
