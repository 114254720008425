import * as React from "react";
import "./AvoidanceFixDisregardPage.css"
import {RouteComponentProps, withRouter} from "react-router-dom";
import {Dispatch} from "redux";
import {AppState} from "../../store";
import {flowRight} from "lodash";
import {connect} from 'react-redux';
import {UserSession} from "../../store/userSession/types";
import PageWrapper from "../../components/Drawer/PageWrapper";
import {Button, Form, Input, Modal, PageHeader, Select} from 'antd';
import {Subsidiary} from "../../store/common/types";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import EnhancedTable, {Column, Order} from "../../components/EnhancedTable/EnhancedTable";
import {
    AvoidanceFixDisregardRule, AvoidanceFixDisregardRuleCreateRequest, AvoidanceFixDisregardRuleCreateResponse,
    AvoidanceFixDisregardRulesPageRequest,
    AvoidanceFixDisregardRulesPageResponse
} from "../../store/avoidanceFixDisregardRules/types";
import {
    createAvoidanceFixDisregardRule, deleteAvoidanceFixDisregardRule,
    fetchAvoidanceFixDisregardRules
} from "../../store/avoidanceFixDisregardRules/actions";
import SubsidiarySelect from "../../components/inputs/SubsidiarySelect/SubsidiarySelect";
import SourceSystemSelect from "../../components/inputs/SourceSystemSelect/SourceSystemSelect";
import {FormInstance} from "antd/es/form";
import debounce from "lodash/debounce";

interface Props extends RouteComponentProps {
    userSession: UserSession;
    rulesPageRequest: AvoidanceFixDisregardRulesPageRequest;
    rulesPageResponse: AvoidanceFixDisregardRulesPageResponse;
    createRuleRequest: AvoidanceFixDisregardRuleCreateRequest;
    createRuleResponse: AvoidanceFixDisregardRuleCreateResponse;
    createAvoidanceFixDisregardRule: (request: AvoidanceFixDisregardRuleCreateRequest) => void;
    deleteAvoidanceFixDisregardRule: (request: string[]) => void;
    fetchAvoidanceFixDisregardRules: (avoidanceFixDisregardRulePageRequest: AvoidanceFixDisregardRulesPageRequest) => void;
    loading?: boolean;
    error?: string;
    runningAction?: string;
}

interface State {
    showCreateModal: boolean
    selectedRules: string[]
}

class AvoidanceFixDisregardPage extends React.Component<Props, State> {

    createFormRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);

        this.state = {
            showCreateModal: false,
            selectedRules: []
        }

        this.createAvoidanceFixDisregardRule = this.createAvoidanceFixDisregardRule.bind(this);
        this.onStateChange = this.onStateChange.bind(this);
        this.handleCreateFormCancel = this.handleCreateFormCancel.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.onPaginationChanged = this.onPaginationChanged.bind(this);
        this.onSortingChanged = this.onSortingChanged.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
    }

    componentDidUpdate(prevProps: any) {
        // this means a call to create or delete is triggered and successfully finished
        if (this.props.runningAction == null && this.props.error == null && prevProps.runningAction) {
            if (prevProps.runningAction == 'create') {
                this.handleCreateFormCancel(); // close the form
                this.props.fetchAvoidanceFixDisregardRules(this.props.rulesPageRequest); // reload the rules
            } else if (prevProps.runningAction == 'delete') {
                this.props.fetchAvoidanceFixDisregardRules(this.props.rulesPageRequest); // reload the rules
            }
        }
    }

    createAvoidanceFixDisregardRule() {
        this.createFormRef.current.validateFields().then(() => {
            this.props.createAvoidanceFixDisregardRule(this.props.createRuleRequest);
        });
    }

    handleCreateFormCancel() {
        this.createFormRef.current.resetFields();
        this.onStateChange("showCreateModal", false);
    }

    componentDidMount() {
        this.props.fetchAvoidanceFixDisregardRules(this.props.rulesPageRequest);
    }

    private onPaginationChanged(page: number, size: number) {
        this.props.fetchAvoidanceFixDisregardRules({
            ...this.props.rulesPageRequest,
            page,
            size
        })
    }

    handleSearchChange = debounce(async (productIdToSearch: any) => {
        this.props.rulesPageRequest.productId = productIdToSearch;
        this.props.fetchAvoidanceFixDisregardRules({
            ...this.props.rulesPageRequest
        });
    }, 300);

    private onSortingChanged(orderBy: string, order: Order) {
        console.log("Order by: " + orderBy + ", order: " + order);

        this.props.fetchAvoidanceFixDisregardRules({
            ...this.props.rulesPageRequest,
            sort: {
                field: orderBy,
                direction: order
            }
        });
    }

    onStateChange(fieldName: string, value: any) {
        this.setState({
            ...this.state,
            [fieldName]: value
        })
    }

    private onSelectionChanged(selectedIds: string[]) {
        this.setState({
            ...this.state,
            selectedRules: selectedIds
        }, () => {
            console.log(selectedIds);
        });

    }

    public render() {

        const formItemLayout = {
            labelCol: {span: 2},
            wrapperCol: {span: 8},
        };

        const columns: Column[] = [
            {
                label: 'Product Id',
                field: 'productId',
                type: 'computed',
                computedValue: (rule: AvoidanceFixDisregardRule) => {
                    return (rule.productId)
                },
            },
            {
                label: 'Subsidiary',
                field: 'subsidiary',
                type: 'string'
            },
            {
                label: 'Source System',
                field: 'sourceSystem',
                type: 'string'
            },
            {
                label: 'Global Product ID',
                field: 'globalProductId',
                type: "string",
                computedValue: (rule: AvoidanceFixDisregardRule) => {
                    return (rule.globalProductId)
                }
            },
            {
                label: 'Gtins',
                field: 'gtins',
                type: 'string'
            }
        ];


        return (
            <div className="avoidance-fix-disregard-page">
                <PageWrapper userSession={this.props.userSession}>
                    <PageHeader
                        ghost={false}
                        onBack={() => window.history.back()}
                        title="Avoidance Fix Disregard"
                        subTitle={""}
                    >
                    </PageHeader>
                    {/*<div className="view-error">{this.props.error}</div>*/}
                    <React.Fragment>

                        {
                            this.props.loading && (<div>
                                <div className="view-loading"/>
                                <CircularProgress className="view-loading-spinner"/>
                            </div>)
                        }

                        <br/>
                        <br/>

                        <Form
                            {...formItemLayout}
                            layout={'horizontal'}
                        >
                            <Form.Item label={'Product ID'}>
                                <Input
                                    placeholder="Search by Product ID"
                                    size="middle"
                                    defaultValue={this.props.rulesPageRequest.productId}
                                    onChange={(value) => this.handleSearchChange(value.target.value)}
                                />
                            </Form.Item>
                        </Form>

                        <div>
                            <Button className="right-order" type="primary" onClick={
                                () => {
                                    if (this.state.selectedRules.length == 0) {
                                        alert("Please select at least one Rule to delete!");
                                    } else {
                                        this.props.deleteAvoidanceFixDisregardRule(this.state.selectedRules);
                                    }
                                }
                            }>
                                Delete
                            </Button>

                            <Button className="right-order" type="primary" onClick={
                                () => {
                                    this.onStateChange("showCreateModal", true);
                                }
                            }>
                                New
                            </Button>
                        </div>

                        <Modal title="Create New Avoidance Fix Disregard Rule" visible={this.state.showCreateModal}
                               onOk={this.createAvoidanceFixDisregardRule} onCancel={this.handleCreateFormCancel}>
                            <Form
                                layout={'horizontal'}
                                ref={this.createFormRef}
                            >

                                <Form.Item label="Product Id"
                                           name={"productId"}
                                           rules={[{required: true, message: 'Please input a valid product ID'}]}
                                >
                                    <Input
                                        id="legacy-product-id-input"
                                        value={this.props.createRuleRequest.productId}
                                        placeholder="Product ID"
                                        onChange={(value) => {
                                            this.props.createRuleRequest.productId = value.target.value
                                        }}
                                    />
                                </Form.Item>

                                <Form.Item label="Subsidiary"
                                           name={"subsidiary"}
                                           rules={[{required: true, message: 'Please input subsidiary'}]}
                                >
                                    <SubsidiarySelect id="subsidiary-select"
                                                      value={this.props.createRuleRequest.subsidiary}
                                                      onChange={(value) => {
                                                          this.props.createRuleRequest.subsidiary = value
                                                      }}
                                    />
                                </Form.Item>

                                <Form.Item label="Source system"
                                           name={"sourceSystem"}
                                           rules={[{required: true, message: 'Please input source system'}]}
                                >
                                    <SourceSystemSelect id="source-system-select"
                                                        value={this.props.createRuleRequest.sourceSystem}
                                                        onChange={(value) => {
                                                            this.props.createRuleRequest.sourceSystem = value
                                                        }}
                                    />
                                </Form.Item>

                                <Form.Item label="Global Id"
                                           name={"globalId"}
                                           rules={[{required: true, message: 'Please input a valid Global Product ID'}]}
                                >
                                    <Input
                                        value={this.props.createRuleRequest.globalProductId}
                                        placeholder="Global Product ID"
                                        onChange={(value) => {
                                            this.props.createRuleRequest.globalProductId = value.target.value
                                        }}
                                    />
                                </Form.Item>

                                <Form.Item label="Gtins"
                                           name={"gtins"}
                                           rules={[{
                                               required: false,
                                               message: 'Please input comma seperated valid Gtins'
                                           }]}
                                >
                                    <Input
                                        value={this.props.createRuleRequest.gtins}
                                        placeholder="Comma seperated Gtins"
                                        onChange={(value) => {
                                            this.props.createRuleRequest.gtins = value.target.value
                                        }}
                                    />
                                </Form.Item>

                            </Form>
                        </Modal>

                        <EnhancedTable tableName={""}
                                       rowsSelectable={true}
                                       columns={columns}
                                       data={this.props.rulesPageResponse.content}
                                       totalCount={this.props.rulesPageResponse.totalElements}
                                       countOnPage={this.props.rulesPageResponse.numberOfElements}
                                       currentPage={this.props.rulesPageRequest.page}
                                       pageSize={this.props.rulesPageRequest.size}
                                       sort={[this.props.rulesPageRequest.sort]}
                                       onPaginationChanged={this.onPaginationChanged}
                                       onSelectionChanged={this.onSelectionChanged}
                                       onSortingChanged={this.onSortingChanged}
                        />
                    </React.Fragment>

                </PageWrapper>
            </div>
        );
    }
}

const mapStateToProps = (state: AppState) => ({
    userSession: state.userSession.userSession,

    rulesPageRequest: state.avoidanceFixDisregard.rulesPageRequest,
    rulesPageResponse: state.avoidanceFixDisregard.rulesPageResponse,

    createRuleRequest: state.avoidanceFixDisregard.createRequest,
    createRuleResponse: state.avoidanceFixDisregard.createResponse,

    loading: state.avoidanceFixDisregard.loading,
    error: state.avoidanceFixDisregard.error,
    runningAction: state.avoidanceFixDisregard.runningAction
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchAvoidanceFixDisregardRules: (avoidanceFixDisregardRulePageRequest: AvoidanceFixDisregardRulesPageRequest) => {
        dispatch(fetchAvoidanceFixDisregardRules(avoidanceFixDisregardRulePageRequest))
    },
    createAvoidanceFixDisregardRule: (request: AvoidanceFixDisregardRuleCreateRequest) => {
        dispatch(createAvoidanceFixDisregardRule(request))
    },
    deleteAvoidanceFixDisregardRule: (request: string[]) => {
        dispatch(deleteAvoidanceFixDisregardRule(request))
    }
});

const enhance = flowRight(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(AvoidanceFixDisregardPage);
