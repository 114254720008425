import { all, call, put, takeLatest } from 'redux-saga/effects'

import {
  FETCH_CORE_PRODUCT_CHANGE, FetchCoreProductChangeAction
} from './types'
import {
  fetchCoreProductChangeFailed,
  fetchCoreProductChangeSucceeded
} from './actions'

import dataService from '../../services/DataService'
import { message } from "antd";

export function* coreProductChangeSaga() {
  yield all([
      watchFetchCoreProductChanges(),
  ])
}

function* watchFetchCoreProductChanges() {
  yield takeLatest(FETCH_CORE_PRODUCT_CHANGE, handleFetchCoreProductChanges)
}

function* handleFetchCoreProductChanges(action: FetchCoreProductChangeAction) {
  try {
    const data = yield call([dataService, dataService.fetchCoreProductChanges], action.request);
    yield put(fetchCoreProductChangeSucceeded(data))
  } catch (error) {
    message.error(error.message);
    yield put(fetchCoreProductChangeFailed(`${error.message}`))
  }
}
