import {Reducer} from "redux";
import {
  FETCH_USER_SESSION,
  FETCH_USER_SESSION_FAILED,
  FETCH_USER_SESSION_SILENT,
  FETCH_USER_SESSION_SUCCEEDED,
  UserSessionActionTypes,
  UserSessionState
} from "./types";

const initialState: UserSessionState = {
  userSession: null,
  loading: true,
  error: null
};

export const userSessionReducer: Reducer<UserSessionState, UserSessionActionTypes> = (state: UserSessionState = initialState, action: UserSessionActionTypes): UserSessionState => {
  switch (action.type) {

    case FETCH_USER_SESSION: {
      return {
        ... state,
        loading: true
      }
    }

    // Refresh the backend session timeout without changing the GUI state
    case FETCH_USER_SESSION_SILENT: {
      return {
        ... state
      }
    }

    case FETCH_USER_SESSION_SUCCEEDED: {
      return {
        ...state,
        userSession: action.payload,
        loading: false,
        error: null
      }
    }

    case FETCH_USER_SESSION_FAILED: {
      return {
        ...state,
        userSession: {
          ... state.userSession,
          loggedIn: false
        },
        loading: false,
        error: action.error
      }
    }

    default:
      neverReached(action);
  }

  return state;
};

const neverReached = (never: never) => {};
