import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import "./AddProductPage.css";
import { Dispatch } from "redux";
import { AppState, store } from "../../store";
import {flowRight} from "lodash";
import { connect } from 'react-redux';
import { UserSession } from "../../store/userSession/types";
import PageWrapper from "../../components/Drawer/PageWrapper";
import { PageHeader } from "antd";
import {AddProductRequest, AddProductResponse, AddProductsRequest} from "../../store/globalProducts/types";
import {addProduct, addProducts, closeErrorMessage} from "../../store/globalProducts/actions";
import AddProductView from "../../components/AddProduct/AddProduct";
import { ProductSourceSystem, Subsidiary } from "../../store/common/types";

interface Props extends RouteComponentProps {
  userSession: UserSession;
  addProductRequest: AddProductRequest;
  addProductResponse: AddProductResponse;
  addProduct: (addProductRequest: AddProductRequest) => void;
  addProducts: (addProductsRequest: AddProductsRequest) => void;
  loading?: boolean;
  error?: string;
}

interface State {
  subsidiary: Subsidiary | null;
  sourceSystem: ProductSourceSystem | null;
  productId: string | null;
}

class AddProductPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.onFormFieldChanged = this.onFormFieldChanged.bind(this);
    this.onMessageClose = this.onMessageClose.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      subsidiary: null,
      sourceSystem: null,
      productId: null
    };
  }

  private onMessageClose() {
    store.dispatch(closeErrorMessage());
  }

  private onFormFieldChanged(filterField: string, value: string) {

    this.setState({
      ... this.state,
      [filterField]: value
    }, () => {
      console.log("Filter: " + filterField + ", value: " + value);
    })
  }

  private onSubmit() {
    let arr = this.state.productId || this.props.addProductRequest.productId
    const productIds = arr.split(",")
    if (productIds.length > 10000) {
      alert("Request is not approved. Max number of items is 10.000")
    } else if (productIds.length === 1) {
      this.props.addProduct({
        subsidiary: this.state.subsidiary || this.props.addProductRequest.subsidiary,
        sourceSystem: this.state.sourceSystem || this.props.addProductRequest.sourceSystem,
        productId: this.state.productId || this.props.addProductRequest.productId
      });
    } else {
      let  addProductsRequest: AddProductsRequest = {requestItems: []}
      productIds.forEach(productId => {
        if (productId.length > 0) {
          addProductsRequest.requestItems.push({
            subsidiary: this.state.subsidiary || this.props.addProductRequest.subsidiary,
            sourceSystem: this.state.sourceSystem || this.props.addProductRequest.sourceSystem,
            productId: productId.trim()
          })
        }
      })
      this.props.addProducts(addProductsRequest);
    }
  }

  public render() {
    return (
      <div className="add-product-page">
        <PageWrapper userSession={this.props.userSession}>
          <PageHeader
            backIcon={null}
            ghost={false}
            onBack={() => window.history.back()}
            title="Add product"
            subTitle="Triggers import of the product from the source system"
          >
          </PageHeader>
          <AddProductView
            subsidiary={this.state.subsidiary || this.props.addProductRequest.subsidiary}
            sourceSystem={this.state.sourceSystem || this.props.addProductRequest.sourceSystem}
            productId={this.state.productId || this.props.addProductRequest.productId}
            onFormFieldChange={this.onFormFieldChanged}
            onMessageClose={this.onMessageClose}
            onSubmit={this.onSubmit}
            error={this.props.error}
            loading={this.props.loading}
          />
        </PageWrapper>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  userSession: state.userSession.userSession,
  addProductRequest: state.products.addProductRequest,
  addProductResponse: state.products.addProductResponse,
  loading: state.products.loading,
  error: state.products.error,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  addProduct: (addProductRequest: AddProductRequest) => {
    dispatch(addProduct(addProductRequest))
  },
  addProducts: (addProductsRequest: AddProductsRequest) => {
    dispatch(addProducts(addProductsRequest))
  },
});

const enhance = flowRight(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(AddProductPage);
