import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import createSagaMiddleware from 'redux-saga';
import {all} from 'redux-saga/effects'
import {userSessionReducer} from "./userSession/reducers";
import userSessionSaga from "./userSession/sagas";
import {productImportsReducer, retryImportsReducer} from "./productImports/reducers";
import {productImportsSaga, retryImportsSaga} from "./productImports/sagas";
import {productsReducer} from "./globalProducts/reducers";
import {maintenanceReducer} from "./maintenance/reducers";
import {productsSaga} from "./globalProducts/sagas";
import {statisticsReducer} from "./statistics/reducers";
import statisticsSaga from "./statistics/sagas";
import {
    failuresReducer,
    rejectFailuresReducer,
    resolveFailuresReducer,
    retryFailuresReducer
} from "./failures/reducers";
import {failuresSaga, rejectFailuresSaga, resolveFailuresSaga, retryFailuresSaga} from "./failures/sagas";
import {tasksReducer} from "./tasks/reducers";
import {tasksSaga} from "./tasks/sagas";
import {permissionsManagementReducer} from "./permissionsManagement/reducers";
import {permissionsManagementSaga} from "./permissionsManagement/sagas";
import {connectRouter, routerMiddleware} from "connected-react-router";
import {createBrowserHistory} from 'history'
import {classificationSaga} from "./classifications/gpc/sagas";
import {gpcClassificationsReducer} from "./classifications/gpc/reducers";
import {productGroupClassificationSaga} from "./classifications/productGroup/sagas";
import {productGroupClassificationsReducer} from "./classifications/productGroup/reducers";
import {importProgressReducer} from "./classifications/importProgress/reducers";
import {importProgressSaga} from "./classifications/importProgress/sagas";
import { maintenanceSaga } from "./maintenance/sagas";
import {kindOfReducer} from "./kindOf/reducers";
import {kindOfSaga} from "./kindOf/sagas";
import {kindOfProductRecalculationSaga} from "./kindOfProductRecalculation/sagas";
import {mappingReducer} from "./mapping/reducers";
import { coreProductChangeSaga } from "./prouctHistory/sagas";
import { coreProductChangeReducer } from "./prouctHistory/reducers";
import {avoidanceFixDisregardSaga} from "./avoidanceFixDisregardRules/sagas";
import {avoidanceFixDisregardReducer} from "./avoidanceFixDisregardRules/reducers";
import {kindOfProductRecalculationReducer} from "./kindOfProductRecalculation/reducers";

export const history = createBrowserHistory({
    basename: `/`,
});

const rootReducer = combineReducers({
    router: connectRouter(history),
    userSession: userSessionReducer,
    productImports: productImportsReducer,
    retryImports: retryImportsReducer,
    products: productsReducer,
    failures: failuresReducer,
    tasks: tasksReducer,
    retryFailures: retryFailuresReducer,
    rejectFailures: rejectFailuresReducer,
    resolveFailures: resolveFailuresReducer,
    statistics: statisticsReducer,
    permissionsManagement: permissionsManagementReducer,
    gpcClassification: gpcClassificationsReducer,
    productGroupClassification: productGroupClassificationsReducer,
    classificationImportProgress: importProgressReducer,
    maintenance: maintenanceReducer,
    kindOf: kindOfReducer,
    kindOfProductRecalculation: kindOfProductRecalculationReducer,
    mapping: mappingReducer,
    coreProductChange: coreProductChangeReducer,
    avoidanceFixDisregard: avoidanceFixDisregardReducer
});

const rootSaga = function* rootSaga() {
    yield all([
        userSessionSaga(),
        productImportsSaga(),
        retryImportsSaga(),
        productsSaga(),
        failuresSaga(),
        tasksSaga(),
        retryFailuresSaga(),
        rejectFailuresSaga(),
        resolveFailuresSaga(),
        statisticsSaga(),
        permissionsManagementSaga(),
        classificationSaga(),
        productGroupClassificationSaga(),
        importProgressSaga(),
        maintenanceSaga(),
        kindOfSaga(),
        kindOfProductRecalculationSaga(),
        coreProductChangeSaga(),
        avoidanceFixDisregardSaga()
    ])
};

export type AppState = ReturnType<typeof rootReducer>

const sagaMiddleware = createSagaMiddleware();

export const configureStore = () => {
    let composeEnhancers = compose;

    if ('__REDUX_DEVTOOLS_EXTENSION_COMPOSE__' in window) {
        composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
    }

    return createStore(rootReducer,
        composeEnhancers(applyMiddleware(
            routerMiddleware(history),
            sagaMiddleware
        )));
};

export const store = configureStore();

sagaMiddleware.run(rootSaga);
