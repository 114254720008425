import * as React from "react";
import EnhancedTable, {Column, Order} from "../../EnhancedTable/EnhancedTable";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import {
    AmbiguousMPNStat,
    FetchAmbiguousMPNStatsPageRequest,
    FetchAmbiguousMPNStatsPageResponse,
} from "../../../store/globalProducts/types";
import {Form, Input, PageHeader} from "antd";

export interface AmbiguousMPNStatsViewProps {
    ambiguousMPNStatsPageResponse: FetchAmbiguousMPNStatsPageResponse;
    ambiguousMPNStatsPageRequest: FetchAmbiguousMPNStatsPageRequest;

    onPaginationChanged: (page: number, size: number) => void;
    onSortingChanged?: (orderBy: string, order: Order) => void;
    onFilterChanged?: (filterField: string, value: string | boolean) => void;

    onRowClick?: (id: number) => void;
    loading: boolean;
    error: string;
}

export const AmbiguousMPNStatsView: React.FunctionComponent<AmbiguousMPNStatsViewProps> = (props) => {

    const [form] = Form.useForm();

    const formItemLayout = {
        labelCol: {span: 2},
        wrapperCol: {span: 8},
    };

    const {
        ambiguousMPNStatsPageRequest,
        ambiguousMPNStatsPageResponse,
        onPaginationChanged,
        onSortingChanged,
        onFilterChanged,
        loading
    } = props;

    const columns: Column[] = [
        {
            label: 'Global Product Id',
            field: 'globalProductId',
            type: 'computed',
            computedValue: (stats: AmbiguousMPNStat) => {
                return (stats.globalProductId)
            },
        },
        {
            label: 'MPN Values',
            field: 'mpnValues',
            type: 'computed',
            sortable: false,
            computedValue: (stats: AmbiguousMPNStat) => {
                return (stats.manufacturerPartNumbers)
            }
        },
        {
            label: 'Target Markets',
            field: 'targetMarkets',
            type: 'computed',
            computedValue: (stats: AmbiguousMPNStat) => {
                return (stats.targetMarkets)
            },
        },
    ];

    return props.error ?
        (
            <div className="view-error">{props.error}</div>
        )
        :
        (
            <React.Fragment>

                {
                    loading && (<div>
                        <div className="view-loading"/>
                        <CircularProgress className="view-loading-spinner"/>
                    </div>)
                }

                <PageHeader
                    backIcon={null}
                    ghost={false}
                    onBack={() => window.history.back()}
                    title="Ambiguous MPN Statistics"
                    subTitle="Displays individual core products with ambiguous MPN Values"
                >
                </PageHeader>

                <Form
                    {...formItemLayout}
                    layout={'horizontal'}
                    form={form}
                >

                    <Form.Item label="Global Product Id">
                        <Input id="global-id-input"
                               placeholder="Search by Global Product Id"
                               value={ambiguousMPNStatsPageRequest.globalProductId}
                               onChange={(value) => onFilterChanged('globalProductId', value.target.value)}
                        />
                    </Form.Item>
                </Form>

                <EnhancedTable tableName={"Results"}
                               rowsSelectable={false}
                               columns={columns}
                               data={ambiguousMPNStatsPageResponse.content}
                               totalCount={ambiguousMPNStatsPageResponse.totalElements}
                               countOnPage={ambiguousMPNStatsPageResponse.numberOfElements}
                               currentPage={ambiguousMPNStatsPageRequest.page}
                               pageSize={ambiguousMPNStatsPageRequest.size}
                               sort={[ambiguousMPNStatsPageRequest.sort]}
                               onPaginationChanged={onPaginationChanged}
                               onSortingChanged={onSortingChanged}
                />
            </React.Fragment>
        );
};

export default AmbiguousMPNStatsView;
