import {
    APPLY_KIND_OF_PRODUCT_RECALCULATION,
    APPLY_KIND_OF_PRODUCT_RECALCULATION_FAILED,
    APPLY_KIND_OF_PRODUCT_RECALCULATION_SUCCEEDED,
    ApplyKindOfProductRecalculationAction,
    ApplyKindOfProductRecalculationFailedAction,
    ApplyKindOfProductRecalculationSucceededAction,
    CREATE_KIND_OF_PRODUCT_RECALCULATION,
    CREATE_KIND_OF_PRODUCT_RECALCULATION_FAILED,
    CREATE_KIND_OF_PRODUCT_RECALCULATION_SUCCEEDED,
    CreateKindOfProductRecalculationRequest,
    CreateKindOfProductRecalculationsAction,
    CreateKindOfProductRecalculationsFailedAction,
    CreateKindOfProductRecalculationsSucceededAction,
    DOWNLOAD_KIND_OF_PRODUCT_RECALCULATION_REPORT,
    DOWNLOAD_KIND_OF_PRODUCT_RECALCULATION_REPORT_FAILED,
    DOWNLOAD_KIND_OF_PRODUCT_RECALCULATION_REPORT_SUCCEEDED,
    DownloadKindOfProductRecalculationReportAction,
    DownloadKindOfProductRecalculationReportFailedAction,
    DownloadKindOfProductRecalculationReportSucceededAction,
    FETCH_KIND_OF_PRODUCT_RECALCULATIONS,
    FETCH_KIND_OF_PRODUCT_RECALCULATIONS_FAILED,
    FETCH_KIND_OF_PRODUCT_RECALCULATIONS_SUCCEEDED,
    FetchKindOfProductRecalculationsAction,
    FetchKindOfProductRecalculationPageRequest,
    FetchKindOfProductRecalculationPageResponse,
    FetchKindOfProductRecalculationsFailedAction,
    FetchKindOfProductRecalculationsSucceededAction,
    KopRecalculation,
    UpdateKindOfProductRecalculationReportAction,
    KopRecalculationReport,
    UpdateKindOfProductRecalculationReportFailedAction,
    UpdateKindOfProductRecalculationReportSucceededAction,
    UPDATE_KIND_OF_PRODUCT_RECALCULATION_REPORT,
    UPDATE_KIND_OF_PRODUCT_RECALCULATION_REPORT_SUCCEEDED,
    UPDATE_KIND_OF_PRODUCT_RECALCULATION_REPORT_FAILED,
    ApplyKindOfProductRecalculationRequest,
    RejectKindOfProductRecalculationAction,
    RejectKindOfProductRecalculationSucceededAction,
    RejectKindOfProductRecalculationFailedAction,
    REJECT_KIND_OF_PRODUCT_RECALCULATION,
    REJECT_KIND_OF_PRODUCT_RECALCULATION_SUCCEEDED,
    REJECT_KIND_OF_PRODUCT_RECALCULATION_FAILED
} from "./types";

export function fetchKindOfProductRecalculations(pageRequest: FetchKindOfProductRecalculationPageRequest): FetchKindOfProductRecalculationsAction {
    return {
        type: FETCH_KIND_OF_PRODUCT_RECALCULATIONS,
        pageRequest
    }
}

export function fetchKindOfProductRecalculationsSucceeded(payload: FetchKindOfProductRecalculationPageResponse): FetchKindOfProductRecalculationsSucceededAction {
    return {
        type: FETCH_KIND_OF_PRODUCT_RECALCULATIONS_SUCCEEDED,
        payload
    }
}

export function fetchKindOfProductRecalculationsFailed(error: string): FetchKindOfProductRecalculationsFailedAction {
    return {
        type: FETCH_KIND_OF_PRODUCT_RECALCULATIONS_FAILED,
        error
    }
}

export function createKindOfProductRecalculation(request: CreateKindOfProductRecalculationRequest): CreateKindOfProductRecalculationsAction {
    return {
        type: CREATE_KIND_OF_PRODUCT_RECALCULATION,
        request
    }
}

export function createKindOfProductRecalculationSucceeded(payload: KopRecalculation): CreateKindOfProductRecalculationsSucceededAction {
    return {
        type: CREATE_KIND_OF_PRODUCT_RECALCULATION_SUCCEEDED,
        payload
    }
}

export function createKindOfProductRecalculationFailed(error: string): CreateKindOfProductRecalculationsFailedAction {
    return {
        type: CREATE_KIND_OF_PRODUCT_RECALCULATION_FAILED,
        error
    }
}

export function downloadKindOfProductRecalculationReport(request: number): DownloadKindOfProductRecalculationReportAction {
    return {
        type: DOWNLOAD_KIND_OF_PRODUCT_RECALCULATION_REPORT,
        request
    }
}

export function downloadKindOfProductRecalculationReportSucceeded(payload: Boolean): DownloadKindOfProductRecalculationReportSucceededAction {
    return {
        type: DOWNLOAD_KIND_OF_PRODUCT_RECALCULATION_REPORT_SUCCEEDED,
        payload
    }
}

export function downloadKindOfProductRecalculationReportFailed(error: string): DownloadKindOfProductRecalculationReportFailedAction {
    return {
        type: DOWNLOAD_KIND_OF_PRODUCT_RECALCULATION_REPORT_FAILED,
        error
    }
}

export function updateKindOfProductRecalculationReport(request: File): UpdateKindOfProductRecalculationReportAction {
    return {
        type: UPDATE_KIND_OF_PRODUCT_RECALCULATION_REPORT,
        request
    }
}

export function updateKindOfProductRecalculationReportSucceeded(payload: KopRecalculation): UpdateKindOfProductRecalculationReportSucceededAction {
    return {
        type: UPDATE_KIND_OF_PRODUCT_RECALCULATION_REPORT_SUCCEEDED,
        payload
    }
}

export function updateKindOfProductRecalculationReportFailed(error: string): UpdateKindOfProductRecalculationReportFailedAction {
    return {
        type: UPDATE_KIND_OF_PRODUCT_RECALCULATION_REPORT_FAILED,
        error
    }
}

export function applyKindOfProductRecalculation(request: ApplyKindOfProductRecalculationRequest): ApplyKindOfProductRecalculationAction {
    return {
        type: APPLY_KIND_OF_PRODUCT_RECALCULATION,
        request
    }
}

export function applyKindOfProductRecalculationSucceeded(payload: KopRecalculation): ApplyKindOfProductRecalculationSucceededAction {
    return {
        type: APPLY_KIND_OF_PRODUCT_RECALCULATION_SUCCEEDED,
        payload
    }
}

export function applyKindOfProductRecalculationFailed(error: string): ApplyKindOfProductRecalculationFailedAction {
    return {
        type: APPLY_KIND_OF_PRODUCT_RECALCULATION_FAILED,
        error
    }
}

export function rejectKindOfProductRecalculation(request: number): RejectKindOfProductRecalculationAction {
    return {
        type: REJECT_KIND_OF_PRODUCT_RECALCULATION,
        request
    }
}

export function rejectKindOfProductRecalculationSucceeded(payload: KopRecalculation): RejectKindOfProductRecalculationSucceededAction {
    return {
        type: REJECT_KIND_OF_PRODUCT_RECALCULATION_SUCCEEDED,
        payload
    }
}

export function rejectKindOfProductRecalculationFailed(error: string): RejectKindOfProductRecalculationFailedAction {
    return {
        type: REJECT_KIND_OF_PRODUCT_RECALCULATION_FAILED,
        error
    }
}
