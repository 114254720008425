import axiosInstance from '../axiosInstance'
import { AxiosInstance } from "axios";
import {
  FetchImportTaskExecutionPageRequest,
  FetchImportTaskExecutionPageResponse,
  ImportTaskExecution,
  ReimportRequest,
} from "../store/productImports/types";
import { resolveApiBaseUrl } from "../environment";
import * as qs from "qs";
import {
    AddProductRequest,
    AddProductResponse, AddProductsRequest,
    FetchDuplicatedProductsPageRequest,
    FetchDuplicatedProductsPageResponse,
    FetchManufacturerMPNStatsPageRequest,
    FetchManufacturerMPNStatsPageResponse,
    FetchAmbiguousMPNStatsPageRequest,
    FetchAmbiguousMPNStatsPageResponse,
    FetchProductsPageRequest,
    FetchProductsPageResponse,
    GlobalProduct,
    UpdateGlobalProductRequest,
    UpdatePrimaryProductRequest
} from "../store/globalProducts/types";
import {
  ListFlatIdDirectoriesPageRequest,
  ListFlatIdDirectoriesPageResponse,
  ListFlatIdFilesPageRequest,
  ListFlatIdFilesPageResponse,
  DownloadFlatIdFileRequest
} from "../store/maintenance/types";
import {
  FetchImporterFailuresPageRequest,
  FetchImporterFailuresPageResponse,
  FetchProductFailuresPageRequest,
  FetchProductFailuresPageResponse,
  ImporterFailure,
  ProductFailure,
  RejectImporterFailureRequest,
  RejectProductFailureRequest,
  ResolveProductFailureRequest,
  RetryImporterFailureListRequest,
  RetryImporterFailureRequest,
  RetryProductFailureListRequest,
  RetryProductFailureRequest,
  RejectImporterFailureListRequest,
  RejectProductFailureListRequest
} from "../store/failures/types";
import {
  CreateImportTask,
  FetchTasksPageRequest,
  FetchTasksPageResponse,
  ImportTask,
  UpdateImportTask
} from "../store/tasks/types";
import {ProductSourceSystem, Subsidiary} from "../store/common/types";
import {FetchCoreProductChangeRequest, FetchCoreProductChangeResponse} from "../store/prouctHistory/types";
import {
  AvoidanceFixDisregardRule, AvoidanceFixDisregardRuleCreateRequest, AvoidanceFixDisregardRuleCreateResponse,
  AvoidanceFixDisregardRulesPageRequest,
  AvoidanceFixDisregardRulesPageResponse
} from "../store/avoidanceFixDisregardRules/types";

class DataService {
  axios: AxiosInstance;

  constructor () {
    this.axios = axiosInstance;
  }

  retryProductsImport(reimportRequest: ReimportRequest) {

    return this.axios.post(`/gateway/core-product-importer/api/v1/import-task-executions/retry`, reimportRequest, {
      baseURL: resolveApiBaseUrl()
    })
      .then((response: any) => {
        return response;
      })
      .catch((error: any) => {
        this.handleError(error);
        throw error
      })
  }

  retryImporterFailure(retryFailureRequest: RetryImporterFailureRequest) {

    return this.axios.patch(`/gateway/core-product-importer/api/v1/failures/${retryFailureRequest.id}/retry`, {},{
      baseURL: resolveApiBaseUrl()
    })
      .then((response: any) => {
        return response;
      })
      .catch((error: any) => {
        this.handleError(error);
        throw error
      })
  }

  retryImporterFailureList(retryFailureListRequest: RetryImporterFailureListRequest) {

    return this.axios.post(`/gateway/core-product-importer/api/v1/failures/retry`, retryFailureListRequest,{
      baseURL: resolveApiBaseUrl()
    })
      .then((response: any) => {
        return response;
      })
      .catch((error: any) => {
        this.handleError(error);
        throw error
      })
  }

  rejectImporterFailureList(rejectFailuresRequest: RejectImporterFailureListRequest) {

    return this.axios.post(`/gateway/core-product-importer/api/v1/failures/reject`, rejectFailuresRequest,{
      baseURL: resolveApiBaseUrl()
    })
        .then((response: any) => {
          return response;
        })
        .catch((error: any) => {
          this.handleError(error);
          throw error
        })
  }

  retryProductFailure(retryFailureRequest: RetryProductFailureRequest): Promise<boolean> {

    return this.axios.patch(`/gateway/core-product-api-internal/api/v1/failures/${retryFailureRequest.id}/retry`, {}, {
      baseURL: resolveApiBaseUrl()
    })
      .then((response: any) => {
        return true;
      })
      .catch((error: any) => {
        this.handleError(error);
        throw error
      })
  }

  rejectProductFailure(rejectFailureRequest: RejectProductFailureRequest) {

    return this.axios.patch(`/gateway/core-product-api-internal/api/v1/failures/${rejectFailureRequest.id}/reject`, {},{
      baseURL: resolveApiBaseUrl()
    })
      .then((response: any) => {
        return response;
      })
      .catch((error: any) => {
        this.handleError(error);
        throw error
      })
  }

  rejectImporterFailure(rejectFailureRequest: RejectImporterFailureRequest) {

    return this.axios.patch(`/gateway/core-product-importer/api/v1/failures/${rejectFailureRequest.id}/reject`, {},{
      baseURL: resolveApiBaseUrl()
    })
      .then((response: any) => {
        return response;
      })
      .catch((error: any) => {
        this.handleError(error);
        throw error
      })
  }

  addProductFailureGrant(resolveFailureRequest: ResolveProductFailureRequest): Promise<boolean> {

    return this.axios.post(`/gateway/core-product-api-internal/api/v1/failures/${resolveFailureRequest.id}/grants`, {
      failureGrantType: resolveFailureRequest.failureGrantType,
      errorTypeDetails: resolveFailureRequest.errorTypeDetails
    },{
      baseURL: resolveApiBaseUrl()
    })
      .then((response: any) => {
        return true;
      })
      .catch((error: any) => {
        this.handleError(error);
        throw error
      })
  }

  retryProductFailureList(retryFailureListRequest: RetryProductFailureListRequest) {

    return this.axios.post(`/gateway/core-product-api-internal/api/v1/failures/retry`, retryFailureListRequest,{
      baseURL: resolveApiBaseUrl()
    })
      .then((response: any) => {
        return response;
      })
      .catch((error: any) => {
        this.handleError(error);
        throw error
      })
  }

  rejectProductFailureList(rejectFailureListRequest: RejectProductFailureListRequest) {

    return this.axios.post(`/gateway/core-product-api-internal/api/v1/failures/reject`, rejectFailureListRequest,{
      baseURL: resolveApiBaseUrl()
    })
        .then((response: any) => {
          return response;
        })
        .catch((error: any) => {
          this.handleError(error);
          throw error
        })
  }

  fetchImportTaskExecution(id: string): Promise<ImportTaskExecution> {

    return this.axios.get(`/gateway/core-product-importer/api/v1/import-task-executions/${id}`, {
      baseURL: resolveApiBaseUrl()
    })
      .then((response: any) => {
        return {
          ... response['data']
        } as ImportTaskExecution;
      })
      .catch((error: any) => {
        this.handleError(error);
        throw error
      })
  }

  fetchImporterFailure(id: number): Promise<ImporterFailure> {

    return this.axios.get(`/gateway/core-product-importer/api/v1/failures/${id}`, {
      baseURL: resolveApiBaseUrl()
    })
      .then((response: any) => {
        return {
          ... response['data']
        } as ImporterFailure;
      })
      .catch((error: any) => {
        this.handleError(error);
        throw error
      })
  }

  fetchProductFailure(id: number): Promise<ProductFailure> {

    return this.axios.get(`/gateway/core-product-api-internal/api/v1/failures/${id}`, {
      baseURL: resolveApiBaseUrl()
    })
      .then((response: any) => {
        return {
          ... response['data'],
          productDetails: JSON.stringify(response['data'].productDetails, null, 2)
        } as ProductFailure;
      })
      .catch((error: any) => {
        this.handleError(error);
        throw error
      })
  }

  fetchLegacyProduct(productId: string, subsidiary: Subsidiary, sourceSystem: ProductSourceSystem): Promise<string> {

    if (sourceSystem !== "MDNG") {
      throw new Error("Not implemented")
    }

    return this.axios.get(`/gateway/core-product-importer/api/v1/mdng-products/${subsidiary}/${productId}`, {
      baseURL: resolveApiBaseUrl()
    })
      .then((response: any) => {
        return JSON.stringify(response['data'], null, 2);
      })
      .catch((error: any) => {
        this.handleError(error);
        throw error
      })
  }

  getImporterFailureList(pageRequest: FetchImporterFailuresPageRequest): Promise<FetchImporterFailuresPageResponse> {

    const params = {
      page: pageRequest.page,
      size: pageRequest.size,
      sort: pageRequest.sort.field + (pageRequest.sort.direction && ("," + pageRequest.sort.direction))
    } as any;

    if (pageRequest.productId) {
      params["productId"] = pageRequest.productId;
    }
    if (pageRequest.subsidiary) {
      params["subsidiary"] = pageRequest.subsidiary;
    }
    if (pageRequest.sourceSystem) {
      params["sourceSystem"] = pageRequest.sourceSystem;
    }
    if (pageRequest.importStatus) {
      params["importStatus"] = pageRequest.importStatus;
    }
    if (pageRequest.failureType) {
      params["failureType"] = pageRequest.failureType;
    }

    return this.axios.get(`/gateway/core-product-importer/api/v1/failures/find`, {
      baseURL: resolveApiBaseUrl(),
      params: params,
      paramsSerializer: {
        serialize : params => {
          return qs.stringify(params, {
            arrayFormat: 'repeat'
          })
        }
      }
    })
      .then((response: any) => {
        return {
          ... response['data']
        } as FetchImporterFailuresPageResponse;
      })
      .catch((error: any) => {
        this.handleError(error);
        throw error
      })
  }

  getProductFailureList(pageRequest: FetchProductFailuresPageRequest): Promise<FetchProductFailuresPageResponse> {

    const params = {
      page: pageRequest.page,
      size: pageRequest.size,
      sort: pageRequest.sort.field + (pageRequest.sort.direction && ("," + pageRequest.sort.direction))
    } as any;

    if (pageRequest.productId) {
      params["productId"] = pageRequest.productId;
    }
    if (pageRequest.failureStatus) {
      params["failureStatus"] = pageRequest.failureStatus;
    }
    if (pageRequest.errorType) {
      params["errorType"] = pageRequest.errorType;
    }
    if (pageRequest.subsidiary) {
      params["subsidiary"] = pageRequest.subsidiary;
    }
    if (pageRequest.activeProduct !== null) {
      params["activeProduct"] = pageRequest.activeProduct;
    }

    return this.axios.get(`/gateway/core-product-api-internal/api/v1/failures/find`, {
      baseURL: resolveApiBaseUrl(),
      params: params,
      paramsSerializer: {
        serialize: params => {
          return qs.stringify(params, {
            arrayFormat: 'repeat'
          })
        }
      }
    })
      .then((response: any) => {
        return {
          ... response['data']
        } as FetchProductFailuresPageResponse;
      })
      .catch((error: any) => {
        this.handleError(error);
        throw error
      })
  }

  generateReport(pageRequest: FetchProductFailuresPageRequest): Promise<boolean> {

    const params = {
      page: pageRequest.page,
      size: 25000,
      sort: pageRequest.sort.field + (pageRequest.sort.direction && ("," + pageRequest.sort.direction))
    } as any;

    if (pageRequest.productId) {
      params["productId"] = pageRequest.productId;
    }
    if (pageRequest.failureStatus) {
      params["failureStatus"] = pageRequest.failureStatus;
    }
    if (pageRequest.errorType) {
      params["errorType"] = pageRequest.errorType;
    }
    if (pageRequest.subsidiary) {
      params["subsidiary"] = pageRequest.subsidiary;
    }
    if (pageRequest.activeProduct !== null) {
      params["activeProduct"] = pageRequest.activeProduct;
    }

    return this.axios.post(`/gateway/core-product-api-internal/api/v1/failures/generate-report`, null, {
      responseType: 'blob',
      baseURL: resolveApiBaseUrl(),
      params: params,
      paramsSerializer: {
        serialize: params => {
          return qs.stringify(params, {
            arrayFormat: 'repeat'
          })
        }
      }
    })
      .then((response: any) => {

        const filename = 'failures_report.xlsx';
        let url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/octet-stream'}));
        let a = document.createElement('a');
        a.href = url;
        a.download = filename;
        a.click();

        return true;
      })
      .catch((error: any) => {
        this.handleError(error);
        throw error
      })
  }

  getTasks(pageRequest: FetchTasksPageRequest): Promise<FetchTasksPageResponse> {

    const params = {
      page: pageRequest.page,
      size: pageRequest.size,
      sort: pageRequest.sort.map(sort => sort.field + (sort.direction && ("," + sort.direction)))
    } as any;

    if (pageRequest.subsidiary) {
      params["subsidiary"] = pageRequest.subsidiary;
    }

    if (pageRequest.enabled) {
      params["enabled"] = pageRequest.enabled;
    }

    return this.axios.get(`/gateway/core-product-importer/api/v1/import-tasks`, {
      baseURL: resolveApiBaseUrl(),
      params: params,
      paramsSerializer: {
        serialize: params => {
          return qs.stringify(params, {
            arrayFormat: 'repeat'
          })
        }
      }
    })
      .then((response: any) => {
        return {
          ... response['data']
        } as FetchTasksPageResponse;
      })
      .catch((error: any) => {
        this.handleError(error);
        throw error
      })
  }

  createTask(request: CreateImportTask): Promise<ImportTask> {

    return this.axios.post(`/gateway/core-product-importer/api/v1/import-tasks`, request, {
      baseURL: resolveApiBaseUrl()
    })
      .then((response: any) => {
        return response['data'] as ImportTask;
      })
      .catch((error: any) => {
        this.handleError(error.response.data.detail);
        throw error
      })
  }

  updateTask(request: UpdateImportTask): Promise<ImportTask> {

    const enabled: string = request.enabled === false ? 'false' : 'true';
    return this.axios.patch(`/gateway/core-product-importer/api/v1/import-tasks/${request.id}/update?enabled=${enabled}`, null, {
      baseURL: resolveApiBaseUrl()
    })
      .then((response: any) => {
        return response['data'] as ImportTask;
      })
      .catch((error: any) => {
        this.handleError(error);
        throw error
      })
  }

  getProductImports(pageRequest: FetchImportTaskExecutionPageRequest): Promise<FetchImportTaskExecutionPageResponse> {

    const params = {
      page: pageRequest.page,
      size: pageRequest.size,
      sort: pageRequest.sort.field + (pageRequest.sort.direction && ("," + pageRequest.sort.direction))
    } as any;

    if (pageRequest.importStatus) {
      params["importStatus"] = pageRequest.importStatus;
    }

    return this.axios.get(`/gateway/core-product-importer/api/v1/import-task-executions/find`, {
      baseURL: resolveApiBaseUrl(),
      params: params,
      paramsSerializer: {
        serialize: params => {
          return qs.stringify(params, {
            arrayFormat: 'repeat'
          })
        }
      }
    })
      .then((response: any) => {
        return {
          ... response['data']
        } as FetchImportTaskExecutionPageResponse;
      })
      .catch((error: any) => {
        this.handleError(error);
        throw error
      })
  }

  addProduct(addProductRequest: AddProductRequest): Promise<AddProductResponse> {

    return this.axios.post(`/gateway/core-product-importer/api/v1/imports`, addProductRequest, {
      baseURL: resolveApiBaseUrl()
    })
      .then((response: any) => {
        return {
          ... response['data']
        } as AddProductResponse;
      })
      .catch((error: any) => {
        this.handleError(error.response.data.detail);
        throw error
      })
  }

  addProducts(addProductsRequest: AddProductsRequest): Promise<void> {

    return this.axios.post(`/gateway/core-product-importer/api/v1/imports/batch`, addProductsRequest, {
      baseURL: resolveApiBaseUrl()
    })
        .then((response: any) => {
          return response;
        })
        .catch((error: any) => {
          this.handleError(error.response);
          throw error
        })
  }

  findProducts(pageRequest: FetchProductsPageRequest): Promise<FetchProductsPageResponse> {

    const params = {
      page: pageRequest.page,
      size: pageRequest.size,
      sort: pageRequest.sort.field + (pageRequest.sort.direction && ("," + pageRequest.sort.direction))
    } as any;

    if (pageRequest.filters) {
      params["filters"] = pageRequest.filters;
    }

    if (pageRequest.search) {
      params["search"] = pageRequest.search;
    }

    if (pageRequest.targetMarket) {
      params["targetMarket"] = pageRequest.targetMarket;
    }

    if (pageRequest.connectedProducts > 0) {
      params["connectedProducts"] = pageRequest.connectedProducts;
    }

    if (pageRequest.active !== null) {
      params["active"] = pageRequest.active;
    }

    return this.axios.get(`/gateway/core-product-api-internal/api/v1/global-products/find`, {
      baseURL: resolveApiBaseUrl(),
      params: params,
      paramsSerializer: {
        serialize: params => {
          return qs.stringify(params, {
            arrayFormat: 'indices',
            allowDots: true
          })
        }
      }
    })
      .then((response: any) => {
        return {
          ... response['data']
        } as FetchProductsPageResponse;
      })
      .catch((error: any) => {
        this.handleError(error.response);
        throw error
      })
  }

  findDuplicatedProductsOrGenerateReport(pageRequest: FetchDuplicatedProductsPageRequest, generateReport: boolean): Promise<FetchDuplicatedProductsPageResponse | boolean> {

    const params = {
      page: generateReport ? 0 : pageRequest.page,
      size: generateReport ? 25000 : pageRequest.size,
      sort: pageRequest.sort.field + (pageRequest.sort.direction && ("," + pageRequest.sort.direction))
    } as any;

    if (pageRequest.productId) {
      params["productId"] = pageRequest.productId;
    }

    if (pageRequest.subsidiary) {
      params["subsidiary"] = pageRequest.subsidiary;
    }

    if (pageRequest.duplicatesCount > 0) {
      params["duplicatesCount"] = pageRequest.duplicatesCount;
    }

    if (pageRequest.sourceSystem !== null) {
      params["sourceSystem"] = pageRequest.sourceSystem;
    }

    if (pageRequest.anyPrimaryAssigned !== null) {
      params["anyPrimaryAssigned"] = pageRequest.anyPrimaryAssigned;
    }

    if (pageRequest.createdAtDateRange !== null) {
      params["createdAtDateRange"] = pageRequest.createdAtDateRange;
    }

    if (pageRequest.updatedAtDateRange !== null) {
      params["updatedAtDateRange"] = pageRequest.updatedAtDateRange;
    }

    if (generateReport) {
      return this.axios.post(`/gateway/core-product-api-internal/api/v1/duplicated-products/generate-report`, null,{
        responseType: 'blob',
        baseURL: resolveApiBaseUrl(),
        params: params,
        paramsSerializer: {
          serialize: params => {
            return qs.stringify(params, {
              arrayFormat: 'indices',
              allowDots: true
            })
          }
        }
      })
        .then((response: any) => {

          const filename = 'duplicated_products_report.xlsx';
          let url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/octet-stream'}));
          let a = document.createElement('a');
          a.href = url;
          a.download = filename;
          a.click();

          return true;
        })
        .catch((error: any) => {
          this.handleError(error.response);
          throw error
        })
    } else {
      return this.axios.get(`/gateway/core-product-api-internal/api/v1/duplicated-products/find`, {
        baseURL: resolveApiBaseUrl(),
        params: params,
        paramsSerializer: {
          serialize: params => {
            return qs.stringify(params, {
              arrayFormat: 'indices',
              allowDots: true
            })
          }
        }
      })
        .then((response: any) => {
          return {
            ...response['data']
          } as FetchDuplicatedProductsPageResponse;
        })
        .catch((error: any) => {
          this.handleError(error.response);
          throw error
        })
    }
  }

  findManufacturerMPNStatsOrGenerateReport(manufacturerMPNStatsPageRequest: FetchManufacturerMPNStatsPageRequest, generateReport: boolean): Promise<FetchManufacturerMPNStatsPageResponse | boolean> {

    const params = {
      page: generateReport ? 0 : manufacturerMPNStatsPageRequest.page,
      size: generateReport ? 25000 : manufacturerMPNStatsPageRequest.size,
      sort: manufacturerMPNStatsPageRequest.sort.field + (manufacturerMPNStatsPageRequest.sort.direction && ("," + manufacturerMPNStatsPageRequest.sort.direction))
    } as any;

    if (!!manufacturerMPNStatsPageRequest.manufacturer) {
      params["manufacturer"] = manufacturerMPNStatsPageRequest.manufacturer;
    }

    if (!!manufacturerMPNStatsPageRequest.mpn) {
      params["mpn"] = manufacturerMPNStatsPageRequest.mpn;
    }

    if (manufacturerMPNStatsPageRequest.globalId > 0) {
      params["globalId"] = manufacturerMPNStatsPageRequest.globalId;
    }

    if (!!manufacturerMPNStatsPageRequest.legacyProductId) {
      params["legacyProductId"] = manufacturerMPNStatsPageRequest.legacyProductId;
    }

    if (manufacturerMPNStatsPageRequest.globalProductsCount > 0) {
      params["globalProductsCount"] = manufacturerMPNStatsPageRequest.globalProductsCount;
    }

    if (generateReport) {
      return this.axios.post(`/gateway/core-product-api-internal/api/v1/manufacturer-mpn-stats/generate-report`, null, {
        responseType: 'blob',
        baseURL: resolveApiBaseUrl(),
        params: params,
        paramsSerializer: {
          serialize: params => {
            return qs.stringify(params, {
              arrayFormat: 'indices',
              allowDots: true
            })
          }
        }
      })
        .then((response: any) => {

          const filename = 'manufacturer_mpn_stats_report.xlsx';
          let url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/octet-stream' }));
          let a = document.createElement('a');
          a.href = url;
          a.download = filename;
          a.click();

          return true;
        })
        .catch((error: any) => {
          this.handleError(error.response);
          throw error
        })
    } else {
      return this.axios.get(`/gateway/core-product-api-internal/api/v1/manufacturer-mpn-stats/find`, {
        baseURL: resolveApiBaseUrl(),
        params: params,
        paramsSerializer: {
          serialize: params => {
            return qs.stringify(params, {
              arrayFormat: 'indices',
              allowDots: true
            })
          }
        }
      })
        .then((response: any) => {
          return {
            ...response['data']
          } as FetchManufacturerMPNStatsPageResponse;
        })
        .catch((error: any) => {
          this.handleError(error.response);
          throw error
        })
    }
  }

  findAmbiguousMPNStats(ambiguousMPNStatsPageRequest: FetchAmbiguousMPNStatsPageRequest): Promise<FetchAmbiguousMPNStatsPageResponse | boolean> {

    const params = {
      page: ambiguousMPNStatsPageRequest.page,
      size: ambiguousMPNStatsPageRequest.size,
      sort: ambiguousMPNStatsPageRequest.sort.field + (ambiguousMPNStatsPageRequest.sort.direction && ("," + ambiguousMPNStatsPageRequest.sort.direction))
    } as any;

    if (ambiguousMPNStatsPageRequest.globalProductId > 0) {
      params["globalProductId"] = ambiguousMPNStatsPageRequest.globalProductId;
    }

    return this.axios.get(`/gateway/core-product-api-internal/api/v1/manufacturer-mpn-stats/ambiguous-mpn-stats`, {
      baseURL: resolveApiBaseUrl(),
      params: params,
      paramsSerializer: {
        serialize: params => {
          return qs.stringify(params, {
            arrayFormat: 'indices',
            allowDots: true
          })
        }
      }
    })
      .then((response: any) => {
        return {
          ...response['data']
        } as FetchAmbiguousMPNStatsPageResponse;
      })
      .catch((error: any) => {
        this.handleError(error.response);
        throw error
      })
  }

  listFlatIdDirectories(pageRequest: ListFlatIdDirectoriesPageRequest): Promise<ListFlatIdDirectoriesPageResponse> {

    return this.axios.get(`/gateway/core-product-maintenance-internal/api/v1/flat-id/directories`, {
      baseURL: resolveApiBaseUrl(),
      paramsSerializer: {
        serialize: params => {
          return qs.stringify(params, {
            arrayFormat: 'indices',
            allowDots: true
          })
        }
      }
    })
        .then((response: any) => {
          return {
            ... response['data']
          } as ListFlatIdDirectoriesPageResponse;
        })
        .catch((error: any) => {
          this.handleError(error);
          throw error
        })
  }

  listFlatIdFiles(pageRequest: ListFlatIdFilesPageRequest): Promise<ListFlatIdFilesPageResponse> {

    return this.axios.get(`/gateway/core-product-maintenance-internal/api/v1/flat-id/directories/${pageRequest.name}/files`, {
      baseURL: resolveApiBaseUrl(),
      paramsSerializer: {
        serialize: params => {
          return qs.stringify(params, {
            arrayFormat: 'indices',
            allowDots: true
          })
        }
      }
    })
        .then((response: any) => {
          return {
            ... response['data']
          } as ListFlatIdFilesPageResponse;
        })
        .catch((error: any) => {
          this.handleError(error);
          throw error
        })
  }

  getCoreProductHistoryByPath(path: string): Promise<string> {

    return this.axios.get(`/gateway/core-product-maintenance-internal/api/v1/core-product-history/${path}`, {
      baseURL: resolveApiBaseUrl(),
      paramsSerializer: {
        serialize: params => {
          return qs.stringify(params, {
            arrayFormat: 'indices',
            allowDots: true
          })
        }
      }
    })
        .then((response: any) => {
          return JSON.stringify({
            ... response['data']
          }, undefined, 2);
        })
        .catch((error: any) => {
          this.handleError(error);
          throw error
        })
  }

  publishFlatId(): Promise<Boolean> {

    return this.axios.post(`/gateway/core-product-maintenance-internal/api/v1/flat-id/publish`, {
      baseURL: resolveApiBaseUrl()
    })
        .then((response: any) => {
          return true;
        })
        .catch((error: any) => {
          this.handleError(error);
          throw error
        })
  }

  downloadFlatIdFile(downloadFlatIdFileRequest: DownloadFlatIdFileRequest): Promise<Boolean> {

    return this.axios.get( `/gateway/core-product-maintenance-internal/api/v1/flat-id/directories/${downloadFlatIdFileRequest.directory}/files/${downloadFlatIdFileRequest.file}`, {
      baseURL: resolveApiBaseUrl(),
      responseType: 'blob',
      paramsSerializer: {
        serialize: params => {
          return qs.stringify(params, {
            arrayFormat: 'indices',
            allowDots: true
          })
        }
      }
    })
        .then((response: any) => {
          const filename = `${downloadFlatIdFileRequest.directory}-${downloadFlatIdFileRequest.file}.csv`;
          let url = window.URL.createObjectURL(new Blob([response.data]));
          let a = document.createElement('a');
          a.href = url;
          a.download = filename;
          a.click();
          return true;
        })
        .catch((error: any) => {
          this.handleError(error);
          throw error
        })
  }

  fetchCoreProduct(id: string): Promise<GlobalProduct> {

    return this.axios.get(`/gateway/core-product-api-internal/api/v1/global-products/${id}`, {
      baseURL: resolveApiBaseUrl()
    })
      .then((response: any) => {
        return {
          ... response['data']
        } as GlobalProduct;
      })
      .catch((error: any) => {
        this.handleError(error);
        throw error
      })
  }

  fetchCoreProductApi(id: string): Promise<string> {

    return this.axios.get(`/gateway/core-product-api/api/v1/core-products/${id}`, {
      baseURL: resolveApiBaseUrl(),
      headers: {
        'X-Flow-Id': 'control-panel-' + Math.ceil(Math.random() * 10000000000)
      }
    })
      .then((response: any) => {
        return JSON.stringify(response['data'], null, 2)
      })
      .catch((error: any) => {
        this.handleError(error);
        throw error
      })
  }

  fetchCoreProductExtensionApi(id: string): Promise<string> {

    return this.axios.get(`/gateway/core-product-api/api/v1/core-product-extensions/${id}`, {
      baseURL: resolveApiBaseUrl(),
      headers: {
        'X-Flow-Id': 'control-panel-' + Math.ceil(Math.random() * 10000000000)
      }
    })
      .then((response: any) => {
        return JSON.stringify(response['data'], null, 2)
      })
      .catch((error: any) => {
        this.handleError(error);
        throw error
      })
  }

  updatePrimaryProduct(updatePrimaryProductRequest: UpdatePrimaryProductRequest): Promise<void> {
    let url = `/gateway/core-product-api-internal/api/v1/duplicated-products/${updatePrimaryProductRequest.globalId}/update-primary-product`
    return this.axios.post(url, updatePrimaryProductRequest,{
      baseURL: resolveApiBaseUrl()
    })
        .then((response: any) => {
          return response;
        })
        .catch((error: any) => {
          this.handleError(error.response);
          throw error
        })
  }

    updateGlobalProduct(updateGlobalProductRequest: UpdateGlobalProductRequest): Promise<void> {

        return this.axios.put(`/gateway/core-product-api-internal/api/v1/global-products/${updateGlobalProductRequest.global_id}/update`, updateGlobalProductRequest, {
            headers: {
              'Content-Type': 'application/json'
            },
          baseURL: resolveApiBaseUrl()
        })
            .then((response: any) => {
              return {
                ...response['data']
              } as void;
            })
            .catch((error: any) => {
              this.handleError(error);
              throw error
            })
    }

  fetchCoreProductChanges(request: FetchCoreProductChangeRequest): Promise<FetchCoreProductChangeResponse> {

    const params = {
      from: "2000-01-01T00:00:00.000Z",
      to: "2030-01-01T00:00:00.000Z",
      page: request.page,
      size: request.size,
    } as any;

    params["global_product_id"] = request.globalProductId;
    params["subsidiary"] = request.subsidiary;

    return this.axios.get(`/gateway/core-product-api-internal/api/v1/core-product-history`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Flow-Id': 'control-panel'
      },
      baseURL: resolveApiBaseUrl(),
      params: params
    })
        .then((response: any) => {
          return {
            ...response['data']
          } as FetchCoreProductChangeResponse;
        })
        .catch((error: any) => {
          this.handleError(error);
          throw error
        })
  }

  findAvoidanceFixDisregardRules(pageRequest: AvoidanceFixDisregardRulesPageRequest): Promise<AvoidanceFixDisregardRulesPageResponse> {

    console.log("fetch is triggered");
    const params = {
      page: pageRequest.page,
      size: pageRequest.size,
      sort: pageRequest.sort.field + (pageRequest.sort.direction && ("," + pageRequest.sort.direction))
    } as any;

    if (pageRequest.productId) {
      params["search"] = pageRequest.productId;
    }

    return this.axios.get(`/gateway/core-product-api-internal/api/v1/avoidance-fix-disregard/items`, {
      baseURL: resolveApiBaseUrl(),
      params: params,
      paramsSerializer: {
        serialize: params => {
          return qs.stringify(params, {
            arrayFormat: 'indices',
            allowDots: true
          })
        }
      }
    })
        .then((response: any) => {
          return {
            ... response['data']
          } as AvoidanceFixDisregardRulesPageResponse;
        })
        .catch((error: any) => {
          this.handleError(error.response);
          throw error
        })
  }

  createAvoidanceFixDisregardRule(request: AvoidanceFixDisregardRuleCreateRequest): Promise<AvoidanceFixDisregardRuleCreateResponse> {
    return this.axios.post(`/gateway/core-product-api-internal/api/v1/avoidance-fix-disregard`, request, {
      baseURL: resolveApiBaseUrl()
    })
        .then((response: any) => {
          return response['data'] as AvoidanceFixDisregardRuleCreateResponse;
        })
        .catch((error: any) => {
          this.handleError(error.response.data.detail);
          throw error
        })
  }

  deleteAvoidanceFixDisregardRule(selectedRuleIds: string[]): Promise<void> {
    const url = `/gateway/core-product-api-internal/api/v1/avoidance-fix-disregard` + "/" + selectedRuleIds.join();
    return this.axios.delete(url, {
      baseURL: resolveApiBaseUrl()
    })
        .then((response: any) => {
          return;
        })
        .catch((error: any) => {
          this.handleError(error.response.data.detail);
          throw error
        })
  }

  handleError(error: any) {
    console.error('DataService:', error)
  }
}

export default new DataService()
