import * as React from "react";
import "./ImporterFailureListView.css"
import EnhancedTable, { Column, Order } from "../EnhancedTable/EnhancedTable";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import {Button, Form, Input, Modal, Select, Space, Typography} from 'antd';
import {
  FetchImporterFailuresPageRequest,
  FetchImporterFailuresPageResponse,
  ImporterFailure
} from "../../store/failures/types";
import SubsidiarySelect from "../inputs/SubsidiarySelect/SubsidiarySelect";
import SourceSystemSelect from "../inputs/SourceSystemSelect/SourceSystemSelect";
import { ExportOutlined } from '@ant-design/icons';

const { Text } = Typography;
const { Option } = Select;
const { confirm } = Modal;

export interface Props {
  pageResponse: FetchImporterFailuresPageResponse;
  pageRequest: FetchImporterFailuresPageRequest;

  onPaginationChanged: (page: number, size: number) => void;
  onSelectionChanged: (selectedIds: number[]) => void;
  onSortingChanged?: (orderBy: string, order: Order) => void;
  onFilterChanged?: (filterField: string, value: string) => void;
  onRowClick?: (id: number, newTab?: boolean) => void;

  onRetry?: () => void;
  retryLoading: boolean;
  onReject?: () => void;
  rejectLoading: boolean;

  loading: boolean;
  error: string;
}

const useImportStatusColumnStyles = makeStyles((theme: Theme) => createStyles({
  failed: {
    color: 'red'
  },
  succeeded: {
    color: 'green'
  },
  running: {
    color: 'orange'
  },
  rejected: {
    color: 'orange'
  },
}));

export const ImporterFailureListView: React.FunctionComponent<Props> = (props) => {

  const [form] = Form.useForm();

  const { pageRequest, pageResponse, onPaginationChanged, onSelectionChanged, onSortingChanged, onRowClick, onFilterChanged,
    onRetry, onReject, loading, retryLoading, rejectLoading } = props;

  const importStatusColumnClasses = useImportStatusColumnStyles();
  const columns: Column[] = [
    {
      label: '',
      field: '',
      type: 'computed',
      computedValue: (info: ImporterFailure) => {
        return (<Space direction={"horizontal"}><a onClick={() => onRowClick(info.id)}>Details</a><Button type="link" icon={<ExportOutlined />} onClick={() => onRowClick(info.id, true)}/></Space>)
      }
    },
    {
      label: 'Failure Type',
      field: 'failureType',
      type: 'computed',
      computedValue: (info: ImporterFailure) => {
        return (<Text code>{info.failureType == 'RANGE_IMPORT_FAILURE' ? 'GATEWAY_FAILURE' : info.failureType}</Text>)
      }
    },
    {
      label: 'Source System',
      field: 'sourceSystem',
      type: 'computed',
      computedValue: (info: ImporterFailure) => {
        return (<Text code>{info.productSourceSystem}</Text>)
      }
    },
    {
      label: 'Subsidiary',
      field: 'subsidiary',
      type: 'computed',
      computedValue: (info: ImporterFailure) => {
        return (<Text code>{info.subsidiary}</Text>)
      }
    },
    {
      label: 'Product',
      field: 'productId',
      type: 'computed',
      computedValue: (info: ImporterFailure) => {
        return (<a>{info.productId}</a>)
      }
    },
    {
      label: 'Message',
      field: 'failureMessage',
      type: 'computed',
      computedValue: (info: ImporterFailure) => {
        return (<Text strong>{info.failureMessage}</Text>)
      }
    },
    {
      label: 'Modification',
      field: 'updatedAt',
      type: 'computed',
      computedValue: (info: ImporterFailure) => {

        return (<a>{new Date(info.updatedAt).toLocaleString()}</a>)
      }
    },
    {
      label: 'Updated By',
      field: 'updatedBy',
      type: 'computed',
      computedValue: (info: ImporterFailure) => {

        return (<a>{info.updatedByFullName}</a>)
      }
    },
    {
      label: 'Status',
      field: 'importStatus',
      className: (info: ImporterFailure) => {

        return clsx({
          [importStatusColumnClasses.failed]: info.importStatus === 'FAILED',
          [importStatusColumnClasses.succeeded]: info.importStatus === 'SUCCEEDED',
          [importStatusColumnClasses.running]: info.importStatus === 'RUNNING',
          [importStatusColumnClasses.rejected]: info.importStatus === 'REJECTED',
        })

      }
    }
  ];

  const handleFilterChange = (field: string, value: string) => {
    onFilterChanged && onFilterChanged(field, value);
  };

  const formItemLayout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 8 },
  };

  const buttonItemLayout = {
    wrapperCol: { span: 8, offset: 2 },
  };

  return props.error ?
    (
      <div className="view-error">{props.error}</div>
    )
    :
    (
      <React.Fragment>

        {
          loading && (<div>
            <div className="view-loading"/>
            <CircularProgress className="view-loading-spinner"/>
          </div>)
        }

        <Form
          {...formItemLayout}
          layout={'horizontal'}
          form={form}
        >

          <Form.Item label="Product Id">
            <Input id="product-id-input"
                   placeholder="Search by product id"
                   value={pageRequest.productId}
                   onChange={(value) => handleFilterChange('productId', value.target.value)}
            />
          </Form.Item>

          <Form.Item label="Source system">
            <SourceSystemSelect id="source-system-select"
                                notSelectedOption={true}
                                value={pageRequest.sourceSystem}
                                onChange={(value) => handleFilterChange('sourceSystem', value)}
            />
          </Form.Item>

          <Form.Item label="Subsidiary">
            <SubsidiarySelect id="subsidiary-select"
                              value={pageRequest.subsidiary}
                              onChange={(value) => handleFilterChange('subsidiary', value)}
            />
          </Form.Item>

          <Form.Item label="Status">
            <Select id="import-status-select"
                    value={pageRequest.importStatus}
                    onChange={(value) => handleFilterChange('importStatus', value)}>

              <Option value="">None</Option>
              <Option value="RUNNING">Running</Option>
              <Option value="SUCCEEDED">Succeeded</Option>
              <Option value="REJECTED">Rejected</Option>
              <Option value="FAILED">Failed</Option>
            </Select>
          </Form.Item>

          <Form.Item label="Failure Type">
            <Select id="failure-type-select"
                    value={pageRequest.failureType}
                    onChange={(value) => handleFilterChange('failureType', value)}>

              <Option value="">None</Option>
              <Option value="PRODUCT_FAILURE">Product Failure</Option>
              <Option value="RANGE_IMPORT_FAILURE">Gateway Failure</Option>
            </Select>
          </Form.Item>
        </Form>

        <EnhancedTable tableName={"Results"}
                       rowsSelectable={true}
                       columns={columns}
                       data={pageResponse.content}
                       totalCount={pageResponse.totalElements}
                       countOnPage={pageResponse.numberOfElements}
                       currentPage={pageRequest.page}
                       pageSize={pageRequest.size}
                       sort={[pageRequest.sort]}
                       onPaginationChanged={onPaginationChanged}
                       onSelectionChanged={ids => onSelectionChanged(ids.map(id => parseInt(id)))}
                       onSortingChanged={onSortingChanged}
        >
          {{
            actionButtons: [
              <Button type="primary" loading={retryLoading} size={"large"} onClick={onRetry}>Retry</Button>,
              <Button type="primary" className={"failure-action-button"} danger loading={rejectLoading} size={"large"}
                      onClick={() => confirm({
                        title: "Reject importer failures",
                        content: 'Are you sure?',
                        onOk() {onReject()},
                        onCancel() {}
                      })}>Reject</Button>
            ]
          }}
        </EnhancedTable>
      </React.Fragment>
    );
};

export default ImporterFailureListView;
