import * as React from "react";
import { ReactNode } from "react";
import { findDOMNode } from 'react-dom'
import highlight from 'highlight.js'

export interface Props {
  children: ReactNode;
  language: string;
  className?: string;
}

class Highlight extends React.Component<Props> {

  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    highlight.highlightBlock(findDOMNode(this.refs.code))
  }

  componentDidUpdate() {
    highlight.highlightBlock(findDOMNode(this.refs.code))
  }

  public render() {
    const { children, className, language } = this.props;

    return (
      <pre className={className}>
        <code className={language} ref='code'>
          {children}
        </code>
      </pre>
    );
  }
}

export default Highlight;
