import { AxiosInstance } from "axios";
import axiosInstance from "../axiosInstance";
import { resolveApiBaseUrl } from "../environment";
import {
  AddUserRequest,
  AddUserResponse,
  CreateGroupRequest,
  CreateGroupResponse,
  CreatePermissionRequest,
  CreatePermissionResponse,
  FetchGroupRequest,
  FetchGroupResponse,
  FetchGroupsListResponse,
  FetchPermissionRequest,
  FetchPermissionResponse,
  FetchPermissionsListResponse,
  FetchUserRequest,
  FetchUserResponse,
  RefreshUserRequest,
  RefreshUserResponse,
  DeleteUserRequest,
  DeleteUserResponse,
  FetchUsersListResponse,
  UpdateGroupRequest,
  UpdateGroupResponse,
  UpdatePermissionRequest,
  UpdatePermissionResponse,
  UpdateUserRequest,
  UpdateUserResponse
} from "../store/permissionsManagement/types";

class PermissionsService {
  axios: AxiosInstance;

  constructor() {
    this.axios = axiosInstance;
  }

  fetchGroupsList(): Promise<FetchGroupsListResponse> {

    return this.axios.get(`/gateway/core-product-permissions/api/v1/groups`, {
      baseURL: resolveApiBaseUrl()
    })
      .then((response: any) => {
        return {
          content: response['data']
        } as FetchGroupsListResponse;
      })
      .catch((error: any) => {
        this.handleError(error);
        throw error
      })
  }

  fetchPermissionsList(): Promise<FetchPermissionsListResponse> {

    return this.axios.get(`/gateway/core-product-permissions/api/v1/permissions/all`, {
      baseURL: resolveApiBaseUrl()
    })
      .then((response: any) => {
        return {
          content: response['data']
        } as FetchPermissionsListResponse;
      })
      .catch((error: any) => {
        this.handleError(error);
        throw error
      })
  }

  fetchUsersList(): Promise<FetchUsersListResponse> {

    return this.axios.get(`/gateway/core-product-permissions/api/v1/users`, {
      baseURL: resolveApiBaseUrl()
    })
      .then((response: any) => {
        return {
          content: response['data']
        } as FetchUsersListResponse;
      })
      .catch((error: any) => {
        this.handleError(error);
        throw error
      })
  }

  fetchUser(request: FetchUserRequest): Promise<FetchUserResponse> {

    return this.axios.get(`/gateway/core-product-permissions/api/v1/users/${request.id}`, {
      baseURL: resolveApiBaseUrl()
    })
      .then((response: any) => {
        return {
          user: response['data']
        } as FetchUserResponse;
      })
      .catch((error: any) => {
        this.handleError(error);
        throw error
      })
  }

  fetchGroup(request: FetchGroupRequest): Promise<FetchGroupResponse> {

    return this.axios.get(`/gateway/core-product-permissions/api/v1/groups/${request.id}`, {
      baseURL: resolveApiBaseUrl()
    })
      .then((response: any) => {
        return {
          group: response['data']
        } as FetchGroupResponse;
      })
      .catch((error: any) => {
        this.handleError(error);
        throw error
      })
  }

  fetchPermission(request: FetchPermissionRequest): Promise<FetchPermissionResponse> {

    return this.axios.get(`/gateway/core-product-permissions/api/v1/permissions/${request.id}`, {
      baseURL: resolveApiBaseUrl()
    })
      .then((response: any) => {
        return {
          permission: response['data']
        } as FetchPermissionResponse;
      })
      .catch((error: any) => {
        this.handleError(error);
        throw error
      })
  }

  addUser(request: AddUserRequest): Promise<AddUserResponse> {

    return this.axios.post(`/gateway/core-product-permissions/api/v1/users`, request, {
        baseURL: resolveApiBaseUrl()
      },
    )
      .then((response: any) => {
        return {
          content: response['data']
        } as AddUserResponse;
      })
      .catch((error: any) => {
        this.handleError(error);
        throw error
      })
  }

  updateUser(request: UpdateUserRequest): Promise<UpdateUserResponse> {

    return this.axios.put(`/gateway/core-product-permissions/api/v1/users/${request.id}`, request, {
        baseURL: resolveApiBaseUrl()
      },
    )
      .then((response: any) => {
        return {
          user: response['data']
        } as UpdateUserResponse;
      })
      .catch((error: any) => {
        this.handleError(error);
        throw error
      })
  }

  refreshUser(request: RefreshUserRequest): Promise<RefreshUserResponse> {
    return this.axios.put(`/gateway/core-product-permissions/api/v1/users/refresh/${request.id}`, null, {
          baseURL: resolveApiBaseUrl()
        },
    )
        .then((response: any) => {
          return {
            user: response['data']
          } as RefreshUserResponse;
        })
        .catch((error: any) => {
          this.handleError(error);
          throw error
        })
  }

  deleteUser(request: DeleteUserRequest): Promise<DeleteUserResponse> {
    return this.axios.delete(`/gateway/core-product-permissions/api/v1/users/${request.id}`, {
          baseURL: resolveApiBaseUrl()
        },
    )
        .then((response: any) => {
          return response as DeleteUserResponse;
        })
        .catch((error: any) => {
          this.handleError(error);
          throw error
        })
  }


  updateGroup(request: UpdateGroupRequest): Promise<UpdateGroupResponse> {

    return this.axios.put(`/gateway/core-product-permissions/api/v1/groups/${request.id}`, request, {
        baseURL: resolveApiBaseUrl()
      },
    )
      .then((response: any) => {
        return {
          group: response['data']
        } as UpdateGroupResponse;
      })
      .catch((error: any) => {
        this.handleError(error);
        throw error
      })
  }

  createGroup(request: CreateGroupRequest): Promise<CreateGroupResponse> {

    return this.axios.post(`/gateway/core-product-permissions/api/v1/groups`, request, {
        baseURL: resolveApiBaseUrl()
      },
    )
      .then((response: any) => {
        return {
          group: response['data']
        } as CreateGroupResponse;
      })
      .catch((error: any) => {
        this.handleError(error);
        throw error
      })
  }

  createPermission(request: CreatePermissionRequest): Promise<CreatePermissionResponse> {

    return this.axios.post(`/gateway/core-product-permissions/api/v1/permissions`, request, {
        baseURL: resolveApiBaseUrl()
      },
    )
      .then((response: any) => {
        return {
          permission: response['data']
        } as CreatePermissionResponse;
      })
      .catch((error: any) => {
        this.handleError(error);
        throw error
      })
  }

  updatePermission(request: UpdatePermissionRequest): Promise<UpdatePermissionResponse> {

    return this.axios.put(`/gateway/core-product-permissions/api/v1/permissions/${request.id}`, request, {
        baseURL: resolveApiBaseUrl()
      },
    )
      .then((response: any) => {
        return {
          permission: response['data']
        } as UpdatePermissionResponse;
      })
      .catch((error: any) => {
        this.handleError(error);
        throw error
      })
  }

  handleError (error: any) {
    console.error('PermissionsService:', error)
  }
}

export default new PermissionsService()
