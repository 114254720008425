import {AxiosInstance} from "axios";
import axiosInstance from "../axiosInstance";
import {resolveApiBaseUrl} from "../environment";
import {
    FetchKindOfProductListResponse,
    FetchMappedValuesListRequest,
    FetchMappedValuesListResponse, FetchMappedValuesPageRequest, KindOfProductMappingRequest, MappedValueMappingRequest
} from "../store/mapping/types";
import * as qs from "qs";
import {MappingSourceType} from "../store/common/types";

class MappingService {
    axios: AxiosInstance;

    constructor() {
        this.axios = axiosInstance;
    }

    fetchMappedValuesList(mappingSource: FetchMappedValuesListRequest): Promise<FetchMappedValuesListResponse> {

        return this.axios.get(`/gateway/core-product-classification/api/v1/mapping/mappings?mapping_source=${mappingSource.mappingSource}`, {
            baseURL: resolveApiBaseUrl()
        })
            .then((response: any) => {
                return {
                    content: response['data']
                } as FetchMappedValuesListResponse;
            })
            .catch((error: any) => {
                this.handleError(error);
                throw error
            })
    }

    handleError(error: any) {
        console.error('PermissionsService:', error)
    }

    createKindOfProductMapping(kindOfProductMappingRequest: KindOfProductMappingRequest): Promise<void> {

        return this.axios.post(`/gateway/core-product-kind-of-product-internal/api/v1/kind-of-products/mapping/mappings`, kindOfProductMappingRequest, {
            headers: {
                'Content-Type': 'application/json'
            },
            baseURL: resolveApiBaseUrl()
        })
            .then((response: any) => {
                return {
                    ...response['data']
                } as void;
            })
            .catch((error: any) => {
                this.handleError(error);
                throw error
            })
    }

    getMappedValuesItems(pageRequest: FetchMappedValuesPageRequest): Promise<FetchMappedValuesListResponse> {

        const params = {
            mapping_source: pageRequest.mappingSource,
            page: pageRequest.page,
            size: pageRequest.size,
            sort: pageRequest.sort.map(sort => sort.field + (sort.direction && ("," + sort.direction)))
        } as any;

        if (pageRequest.id) {
            params["id"] = pageRequest.id;
        }

        if (pageRequest.search) {
            params["search"] = pageRequest.search;
        }

        if (pageRequest.language) {
            params["language"] = pageRequest.language;
        }

        return this.axios.get(`/gateway/core-product-classification/api/v1/mapping/mappings/items`, {
            baseURL: resolveApiBaseUrl(),
            params: params,
            paramsSerializer: {
                serialize: params => {
                    return qs.stringify(params, {
                        arrayFormat: 'repeat'
                    })
                }
            }
        })
            .then((response: any) => {
                return {
                    ...response['data']
                } as FetchMappedValuesListResponse;
            })
            .catch((error: any) => {
                this.handleError(error);
                throw error
            })
    }

    createMappedValueMapping(mappedValueMappingRequest: MappedValueMappingRequest): Promise<void> {

        return this.axios.post(`/gateway/core-product-kind-of-product-internal/api/v1/kind-of-products/mapping/kopmappings`, mappedValueMappingRequest, {
            headers: {
                'Content-Type': 'application/json'
            },
            baseURL: resolveApiBaseUrl()
        })
            .then((response: any) => {
                return {
                    ...response['data']
                } as void;
            })
            .catch((error: any) => {
                this.handleError(error);
                throw error
            })
    }

    fetchKindOfProductList(): Promise<FetchKindOfProductListResponse> {

        return this.axios.get(`/gateway/core-product-kind-of-product-internal/api/v1/kind-of-products/mapping`, {
            baseURL: resolveApiBaseUrl()
        })
            .then((response: any) => {
                return {
                    content: response['data']
                } as FetchKindOfProductListResponse;
            })
            .catch((error: any) => {
                this.handleError(error);
                throw error
            })
    }

    fetchUnmappedKindOfProductList(mappingSource: MappingSourceType): Promise<FetchKindOfProductListResponse> {

        return this.axios.get(`/gateway/core-product-kind-of-product-internal/api/v1/kind-of-products/mapping/unmapped?mapping_source=${mappingSource}`, {
            baseURL: resolveApiBaseUrl()
        })
            .then((response: any) => {
                return {
                    content: response['data']
                } as FetchKindOfProductListResponse;
            })
            .catch((error: any) => {
                this.handleError(error);
                throw error
            })
    }

    fetchUnmappedMappedValuesList(mappingSource: MappingSourceType): Promise<FetchMappedValuesListResponse> {

        return this.axios.get(`/gateway/core-product-classification/api/v1/mapping/unmapped-values?mapping_source=${mappingSource}`, {
            baseURL: resolveApiBaseUrl()
        })
            .then((response: any) => {
                return {
                    content: response['data']
                } as FetchMappedValuesListResponse;
            })
            .catch((error: any) => {
                this.handleError(error);
                throw error
            })
    }
}

export default new MappingService()
