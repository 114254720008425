import {AxiosInstance} from "axios";
import axiosInstance from "../axiosInstance";
import {resolveApiBaseUrl} from "../environment";
import {
    KindOfProductPredictionRequests, KindOfProductPredictionResponses, Selection
} from "../store/KindOfProductPredictor/types";

class KindOfProductPredictorService {
    axios: AxiosInstance;

    constructor() {
        this.axios = axiosInstance;
    }

    getKindOfProductPrediction(kindOfProductPredictionRequest: KindOfProductPredictionRequests): Promise<KindOfProductPredictionResponses> {
        return this.axios.post(`/gateway/kind-of-product-prediction-url/kind-of-product-predictions`, kindOfProductPredictionRequest, {
            headers: {
                'Content-Type': 'application/json'
            },
            baseURL: resolveApiBaseUrl()
        })
            .then((response: any) => {
                return {
                    ...response['data']
                } as KindOfProductPredictionResponses;
            })
            .catch((error: any) => {
                this.handleError(error.response.data.detail);
                throw error
            })
    }

    putKindOfProductPredictionFeedback(predictionId: string, kindOfProductSelection: Selection): Promise<void> {
        console.log("putKindOfProductPredictionFeedback", predictionId, kindOfProductSelection)
        return this.axios.put(`/gateway/kind-of-product-prediction-url/kind-of-product-predictions/${predictionId}`, kindOfProductSelection, {
            headers: {
                'Content-Type': 'application/json'
            },
            baseURL: resolveApiBaseUrl()
        })
            .then((response: any) => {
                console.log(response['data'])
            })
            .catch((error: any) => {
                this.handleError(error.response.data.detail);
                throw error
            })
    }

    getColor(probability: number) {
        if (probability > 0.70) {
            return "green"
        }
        if (probability > 0.30) {
            return "orange"
        }
        else {
            return "red"
        }
    }

    handleError(error: any) {
        console.error('KindOfProductPrediction:', error)
    }
}

export default new KindOfProductPredictorService()
