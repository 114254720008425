import * as React from "react";
import {RouteComponentProps, withRouter} from "react-router-dom";
import "./KindOfProductListPage.css";
import {Dispatch} from "redux";
import {AppState} from "../../store";
import {flowRight} from "lodash";
import {connect} from 'react-redux';
import {UserSession} from "../../store/userSession/types";
import {Order} from "../../components/EnhancedTable/EnhancedTable";
import PageWrapper from "../../components/Drawer/PageWrapper";
import {PageHeader} from 'antd';
import KindOfProductList from "../../components/KindOfProductList/KindOfProductList";
import {
    FetchKindOfLevelItemsPageRequest,
    FetchKindOfLevelItemsPageResponse
} from "../../store/kindOf/types";
import {fetchKindOfItems, generateKindOfProductMappingReport} from "../../store/kindOf/actions";

interface Props extends RouteComponentProps {
    userSession: UserSession;
    pageRequest: FetchKindOfLevelItemsPageRequest;
    pageResponse: FetchKindOfLevelItemsPageResponse;
    fetchKindOfItems: (pageRequest: FetchKindOfLevelItemsPageRequest) => void;
    generateKindOfProductMappingReport: () => void;
    loading?: boolean;
    error?: string;
    generateDuplicatedProductsReportLoading?: boolean;
}

interface State {
}

class KindOfProductListPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.onPaginationChanged = this.onPaginationChanged.bind(this);
        this.onSortingChanged = this.onSortingChanged.bind(this);
        this.onFilterChanged = this.onFilterChanged.bind(this);
        this.onGenerateMapping = this.onGenerateMapping.bind(this);
        this.onRowClick = this.onRowClick.bind(this)
    }

    componentDidMount() {
        this.props.fetchKindOfItems(this.props.pageRequest)
    }

    private onPaginationChanged(page: number, size: number) {
        console.log("Page: " + page + ", size: " + size);

        this.props.fetchKindOfItems({
            ...this.props.pageRequest,
            page,
            size
        })
    }

    private onFilterChanged(filterField: string, value: string) {
        console.log("Filter: " + filterField + ", value: " + value);

        this.props.fetchKindOfItems({
            ...this.props.pageRequest,
            [filterField]: value
        })
    }

    private onSortingChanged(orderBy: string, order: Order) {
        console.log("Order by: " + orderBy + ", order: " + order);

        this.props.fetchKindOfItems({
            ...this.props.pageRequest,
            sort: [{
                field: orderBy,
                direction: order
            }]
        });
    }

    private onGenerateMapping() {
        this.props.generateKindOfProductMappingReport();
    }

    public render() {
        return (
            <div className="import-tasks-page">
                <PageWrapper userSession={this.props.userSession}>
                    <PageHeader
                        backIcon={null}
                        ghost={false}
                        onBack={() => window.history.back()}
                        title="Kind Of Product"
                        subTitle="Displays list of items of Kind Of Product"
                    >
                    </PageHeader>
                    <KindOfProductList
                        pageRequest={this.props.pageRequest}
                        pageResponse={this.props.pageResponse}
                        onPaginationChanged={this.onPaginationChanged}
                        onSortingChanged={this.onSortingChanged}
                        onFilterChanged={this.onFilterChanged}
                        onRowClick={this.onRowClick}
                        loading={this.props.loading}
                        error={this.props.error}
                        onGenerateMapping={this.onGenerateMapping}
                        generateReportLoading={this.props.generateDuplicatedProductsReportLoading}
                    />
                </PageWrapper>
            </div>
        );
    }

    private onRowClick(code: string) {
        this.props.history.push("/app/kind-of-product/" + code);
    }
}

const mapStateToProps = (state: AppState) => ({
    userSession: state.userSession.userSession,
    pageRequest: state.kindOf.pageRequest,
    pageResponse: state.kindOf.pageResponse,

    generateDuplicatedProductsReportLoading: state.products.generateDuplicatedProductsReportLoading,
    loading: state.kindOf.loading,
    error: state.kindOf.error,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchKindOfItems: (pageRequest: FetchKindOfLevelItemsPageRequest) => {
        dispatch(fetchKindOfItems(pageRequest))
    },

    generateKindOfProductMappingReport: () => {
      dispatch(generateKindOfProductMappingReport())
    }
});

const enhance = flowRight(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(KindOfProductListPage);
