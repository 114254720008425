import * as React from "react";
import "./TaskListView.css"
import EnhancedTable, { Column, Order } from "../EnhancedTable/EnhancedTable";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { Button, Form, Select, Space, Tag, Typography } from 'antd';
import { FetchTasksPageRequest, FetchTasksPageResponse, ImportTask } from "../../store/tasks/types";
import SubsidiarySelect from "../inputs/SubsidiarySelect/SubsidiarySelect";

const { Text } = Typography;
const { Option } = Select;

export interface Props {
  pageRequest: FetchTasksPageRequest;
  pageResponse: FetchTasksPageResponse;

  onPaginationChanged: (page: number, size: number) => void;
  onSortingChanged?: (orderBy: string, order: Order) => void;
  onFilterChanged?: (filterField: string, value: string) => void;

  onChangeEnabledStatus?: (id: string, enabled: boolean) => void;
  changeEnabledStatusLoading?: boolean;

  loading: boolean;
  error: string;
}

const useStatusColumnStyles = makeStyles((theme: Theme) => createStyles({
  failed: {
    color: 'red'
  },
  succeeded: {
    color: 'green'
  },
  running: {
    color: 'orange'
  },
}));

export const TaskListView: React.FunctionComponent<Props> = (props) => {

  const [form] = Form.useForm();

  const { pageRequest, pageResponse, onPaginationChanged, onSortingChanged, onFilterChanged, onChangeEnabledStatus, loading, changeEnabledStatusLoading } = props;

  const statusColumnClasses = useStatusColumnStyles();
  const columns: Column[] = [
    {
      label: 'Subsidiary',
      field: 'subsidiary',
      type: 'computed',
      computedValue: (info: ImportTask) => {
        return (<Text code>{info.subsidiary}</Text>)
      }
    },
    {
      label: 'Source system',
      field: 'sourceSystem',
      type: 'computed',
      computedValue: (info: ImportTask) => {
        return (<Text code>{info.sourceSystem}</Text>)
      }
    },
    {
      label: 'Interval (minutes)',
      field: 'intervalMinutes',
      type: 'computed',
      computedValue: (info: ImportTask) => {
        return info.intervalMinutes;
      }
    },
    {
      label: 'From Date',
      field: 'fromDate',
      type: 'computed',
      computedValue: (info: ImportTask) => {
        return (<a>{new Date(info.fromDate).toLocaleString()}</a>);
      }
    },
    {
      label: 'To Date',
      field: 'toDate',
      type: 'computed',
      computedValue: (info: ImportTask) => {
        return (info.toDate ? <a>{new Date(info.toDate).toLocaleString()}</a> : null)
      }
    },
    {
      label: 'Synced Till Date',
      field: 'syncDate',
      type: 'computed',
      computedValue: (info: ImportTask) => {
        return (info.syncDate ? <a>{new Date(info.syncDate).toLocaleString()}</a> : null)
      }
    },
    {
      label: 'Run Date',
      field: 'runDate',
      type: 'computed',
      computedValue: (info: ImportTask) => {
        return (info.runDate ? <a>{new Date(info.runDate).toLocaleString()}</a> : null)
      }
    },
    {
      label: 'Updated At',
      field: 'updatedAt',
      type: 'computed',
      computedValue: (info: ImportTask) => {
        return (<a>{new Date(info.updatedAt).toLocaleString()}</a>)
      }
    },
    {
      label: 'Updated By',
      field: 'updatedBy',
      type: 'computed',
      computedValue: (info: ImportTask) => {
        return (<a>{info.updatedByFullName}</a>)
      }
    },
    {
      label: 'Status',
      field: '',
      type: 'computed',
      computedValue: (info: ImportTask) => {
        const tag = (
          info.enabled === false && info.completed === false
            ? <Tag color='error'>STOPPED</Tag>
            : (info.completed === true
            ? <Tag color='success'>COMPLETED</Tag>
            : (info.running === true ? <Tag color='warning'>RUNNING</Tag> : <Tag color='warning'>READY TO RUN</Tag>))
        );

        const action = (
          info.enabled === false && info.completed === false
            ? <Button type="primary" loading={props.changeEnabledStatusLoading} size={"small"}
                      onClick={() => onChangeEnabledStatus(info.id, true)}>Enable</Button>
            : (info.completed === true
            ? null
            : <Button type="primary" loading={props.changeEnabledStatusLoading} size={"small"}
                      onClick={() => onChangeEnabledStatus(info.id, false)}>Disable</Button>)
        );

        return <Space size={"small"}>{tag} {action}</Space>;
      },
      className: (info: ImportTask) => {
        return clsx({
          [statusColumnClasses.failed]: info.enabled === false && info.completed === false,
          [statusColumnClasses.succeeded]: info.completed === true,
          [statusColumnClasses.running]: info.running === true || (info.running === false && info.enabled === true && info.completed === false),
        })

      }
    }
  ];

  const handleFilterChange = (field: string, value: string) => {
    onFilterChanged && onFilterChanged(field, value);
  };

  const formItemLayout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 8 },
  };

  const buttonItemLayout = {
    wrapperCol: { span: 8, offset: 2 },
  };

  return props.error ?
    (
      <div className="view-error">{props.error}</div>
    )
    :
    (
      <React.Fragment>

        {
          loading && (<div>
            <div className="view-loading"/>
            <CircularProgress className="view-loading-spinner"/>
          </div>)
        }

        <Form
          {...formItemLayout}
          layout={'horizontal'}
          form={form}
        >
          <Form.Item label="Subsidiary">
            <SubsidiarySelect id={"subsidiary-select"}
                              value={pageRequest.subsidiary}
                              onChange={(value) => handleFilterChange('subsidiary', value)}
            />
          </Form.Item>
          <Form.Item label="Status">
            <Select id="status-select"
                    value={!!pageRequest.enabled && pageRequest.enabled.toString()}
                    onChange={(value) => handleFilterChange('enabled', value)}>

              <Option value="">Not Selected</Option>
              <Option value="true">Enabled</Option>
              <Option value="false">Disabled</Option>
            </Select>
          </Form.Item>
        </Form>

        <EnhancedTable tableName={"Results"}
                       rowsSelectable={false}
                       columns={columns}
                       data={pageResponse.content}
                       totalCount={pageResponse.totalElements}
                       countOnPage={pageResponse.numberOfElements}
                       currentPage={pageRequest.page}
                       pageSize={pageRequest.size}
                       sort={pageRequest.sort}
                       onPaginationChanged={onPaginationChanged}
                       onSortingChanged={onSortingChanged}
        >
          {{
            actionButtons: null
          }}
        </EnhancedTable>
      </React.Fragment>
    );
};

export default TaskListView;
