import * as React from "react";
import "./ProductFailureListView.css"
import EnhancedTable, { Column, Order } from "../EnhancedTable/EnhancedTable";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import {Button, Checkbox, Form, Input, Modal, Select, Space, Tooltip, Typography} from 'antd';
import {
  FetchProductFailuresPageRequest,
  FetchProductFailuresPageResponse,
  ProductFailure
} from "../../store/failures/types";
import SubsidiarySelect from "../inputs/SubsidiarySelect/SubsidiarySelect";
import { ExportOutlined } from "@ant-design/icons/lib";

const { Text } = Typography;
const { Option } = Select;
const { confirm } = Modal;

export interface Props {
  pageRequest: FetchProductFailuresPageRequest;
  pageResponse: FetchProductFailuresPageResponse;

  onPaginationChanged: (page: number, size: number) => void;
  onSelectionChanged: (selectedIds: number[]) => void;
  onSortingChanged?: (orderBy: string, order: Order) => void;
  onFilterChanged?: (filterField: string, value: string) => void;
  onRowClick?: (id: number, newTab?: boolean) => void;

  onGenerateReport?: () => void;
  generateReportLoading?: boolean;

  onRetry?: () => void;
  retryLoading: boolean;
  onReject?: () => void;
  rejectLoading: boolean;

  loading: boolean;
  error: string;
}

const useImportStatusColumnStyles = makeStyles((theme: Theme) => createStyles({
  failed: {
    color: 'red'
  },
  succeeded: {
    color: 'green'
  },
  running: {
    color: 'orange'
  },
}));

export const ProductFailureListView: React.FunctionComponent<Props> = (props) => {

  const [form] = Form.useForm();

  const { pageRequest, pageResponse, onPaginationChanged, onSelectionChanged, onSortingChanged, onRowClick,
    onFilterChanged, onRetry, onReject, loading, retryLoading, rejectLoading, onGenerateReport, generateReportLoading } = props;

  const importStatusColumnClasses = useImportStatusColumnStyles();
  const columns: Column[] = [
    {
      label: '',
      field: '',
      type: 'computed',
      computedValue: (info: ProductFailure) => {
        return (<Space direction={"horizontal"}><a onClick={() => onRowClick(info.id)}>Details</a><Button type={"link"} icon={<ExportOutlined />} onClick={() => onRowClick(info.id, true)}/></Space>)
      }
    },
    {
      label: 'Error Type',
      field: 'errorType',
      type: 'computed',
      computedValue: (info: ProductFailure) => {
        return (info.errorType ? <Text code>{info.errorType}</Text> : null)
      }
    },
    {
      label: 'Subsidiary',
      field: 'subsidiary',
      type: 'computed',
      computedValue: (info: ProductFailure) => {
        return (<Text code>{info.subsidiary}</Text>)
      }
    },
    {
      label: 'Product',
      field: 'productId',
      type: 'computed',
      computedValue: (info: ProductFailure) => {
        return (<a>{info.productId}</a>)
      }
    },
    {
      label: 'Message',
      field: 'failureMessage',
      type: 'computed',
      computedValue: (info: ProductFailure) => {
        return (<Text strong>{info.message}</Text>)
      }
    },
    {
      label: 'Modification',
      field: 'updatedAt',
      type: 'computed',
      computedValue: (info: ProductFailure) => {

        return (<a>{new Date(info.updatedAt).toLocaleString()}</a>)
      }
    },
    {
      label: 'Updated By',
      field: 'updatedBy',
      type: 'computed',
      computedValue: (info: ProductFailure) => {

        return (<a>{info.updatedByFullName}</a>)
      }
    },
    {
      label: 'Active Product',
      field: 'activeProduct',
      type: 'computed',
      computedValue: (info: ProductFailure) => {
        return info.activeProduct === null ? '' : (info.activeProduct === true ? 'ACTIVE' : 'NOT ACTIVE');
      },
      className: (info: ProductFailure) => {

        return clsx({
          [importStatusColumnClasses.failed]: info.activeProduct === false,
          [importStatusColumnClasses.succeeded]: info.activeProduct === true
        })

      }
    },
    {
      label: 'Status',
      field: 'status',
      className: (info: ProductFailure) => {

        return clsx({
          [importStatusColumnClasses.failed]: info.status === 'UNRESOLVED',
          [importStatusColumnClasses.succeeded]: info.status === 'RESOLVED',
          [importStatusColumnClasses.running]: info.status === 'RUNNING' || info.status === 'REJECTED',
        })

      }
    }
  ];

  const handleFilterChange = (field: string, value: any) => {
    onFilterChanged && onFilterChanged(field, value);
  };

  const formItemLayout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 8 },
  };

  const buttonItemLayout = {
    wrapperCol: { span: 8, offset: 2 },
  };

  return props.error ?
    (
      <div className="view-error">{props.error}</div>
    )
    :
    (
      <React.Fragment>

        {
          (loading) && (<div>
            <div className="view-loading"/>
            <CircularProgress className="view-loading-spinner"/>
          </div>)
        }

        <Form
          {...formItemLayout}
          layout={'horizontal'}
          form={form}
        >
          <Form.Item label="Product Id">
            <Input id="product-id-input"
                   placeholder="Search by product id"
                   value={pageRequest.productId}
                   onChange={(value) => handleFilterChange('productId', value.target.value)}
            />
          </Form.Item>

          <Form.Item label="Subsidiary">
            <SubsidiarySelect id="subsidiary-select"
                              value={pageRequest.subsidiary}
                              onChange={(value) => handleFilterChange('subsidiary', value)}
            />
          </Form.Item>

          <Form.Item label="Status">
            <Select id="failure-status-select"
                    value={pageRequest.failureStatus}
                    onChange={(value) => handleFilterChange('failureStatus', value)}>

              <Option value="">None</Option>
              <Option value="UNRESOLVED">Not Resolved</Option>
              <Option value="RESOLVED">Resolved</Option>
              <Option value="REJECTED">Rejected</Option>
              <Option value="RUNNING">Running</Option>
            </Select>
          </Form.Item>

          <Form.Item label="Error Type">
            <Select id="error-type-select"
                    value={pageRequest.errorType}
                    onChange={(value) => handleFilterChange('errorType', value)}>

              <Option value="">None</Option>
              <Option value="GENERAL">General</Option>
              <Option value="GTINS_MISSING">Missing GTINs</Option>
              <Option value="INVALID_GTINS">Invalid GTINs</Option>
              <Option value="ONLY_INTERNAL_GTINS_PROVIDED">Internal GTINs</Option>
              <Option value="PRIMARY_GTIN_CONFLICT">Primary GTIN conflict</Option>
              <Option value="GLOBAL_ID_CONFLICT">Global Product Id conflict</Option>
              <Option value="MORE_THAN_1_GLOBAL_ID_RESOLVED">More than 1 Global Product Id resolved</Option>
              <Option value="KIND_OF_PRODUCT_CONFLICT">Kind of Product conflict</Option>
              <Option value="MULTIPLE_KIND_OF_PRODUCT_FOUND">Multiple Kind of Product found</Option>
              <Option value="KIND_OF_PRODUCT_NOT_FOUND">Kind of Product not found</Option>
              <Option value="DUPLICATE_COUNTRY_FOUND">Duplicate Country Found</Option>
              <Option value="SERVICE_AND_SUBSCRIPTION_ERROR">Service and Subscription error</Option>
              <Option value="AMBIGUOUS_PRODUCT_GROUP_FOUND">Ambiguous Product Group found</Option>
            </Select>
          </Form.Item>

          <Form.Item label="Active Product">
            <Checkbox
              indeterminate={pageRequest.activeProduct === null}
              onClick={(event) => handleFilterChange('activeProduct', pageRequest.activeProduct === null ? true : (pageRequest.activeProduct === true ? false : null))}
              checked={pageRequest.activeProduct !== null && pageRequest.activeProduct === true}
            />
          </Form.Item>

          <Form.Item {...buttonItemLayout}>
            <Tooltip title="Limited to 25000 items">
              <Button type="primary" loading={generateReportLoading} size={"middle"} onClick={onGenerateReport}>Generate
                Report
              </Button>
            </Tooltip>
          </Form.Item>
        </Form>

        <EnhancedTable tableName={"Results"}
                       rowsSelectable={true}
                       columns={columns}
                       data={pageResponse.content}
                       totalCount={pageResponse.totalElements}
                       countOnPage={pageResponse.numberOfElements}
                       currentPage={pageRequest.page}
                       pageSize={pageRequest.size}
                       sort={[pageRequest.sort]}
                       onPaginationChanged={onPaginationChanged}
                       onSelectionChanged={ids => onSelectionChanged(ids.map(id => parseInt(id)))}
                       onSortingChanged={onSortingChanged}
        >
          {{
            actionButtons:
                [
                  <Button type="primary" loading={retryLoading} size={"large"} onClick={onRetry}>Retry</Button>,
                  <Button type="primary" className={"failure-action-button"} danger loading={rejectLoading} size={"large"}
                          onClick={() => confirm({
                            title: "Reject product failures",
                            content: 'Are you sure?',
                            onOk() {onReject()},
                            onCancel() {}
                          })}>Reject</Button>
                ]
          }}
        </EnhancedTable>
      </React.Fragment>
    );
};

export default ProductFailureListView;
