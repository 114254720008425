import * as React from "react";
import "./CoreProductView.css"
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import { Button, Collapse, Descriptions, Space, Tabs, Tag } from 'antd';
import { GlobalProduct, ImportProduct } from "../../store/globalProducts/types";
import Highlight from "../Highlight/Highlight";
import LoadableCoreProductApiResponseView from "./LoadableCoreProductApiResponseView";
import EditGlobalProductView from "./EditGlobalProductView";
import {ExportOutlined} from "@ant-design/icons";

const { Panel } = Collapse;
const { TabPane } = Tabs;

export interface CoreProductViewProps {
  coreProduct: GlobalProduct;
  updatePrimaryProduct?: (globalProductId: number, legacyProduct: ImportProduct) => void;
  columns?: number;
  loading: boolean;
  error: string;
}

export const CoreProductView: React.FunctionComponent<CoreProductViewProps> = (props) => {

  const { coreProduct, columns, loading } = props;

  const statusTag = (isDeactivated: boolean) => {
    return (isDeactivated ? (<Tag color="error">NOT ACTIVE</Tag>) : (<Tag color="success">ACTIVE</Tag>));
  };

  let countProductsPerSubsidiary: any = {};
  props.coreProduct && props.coreProduct.legacyProducts.forEach(lp => {

    if (countProductsPerSubsidiary[lp.subsidiary] === undefined) {
      countProductsPerSubsidiary[lp.subsidiary] = 0;
    }

    countProductsPerSubsidiary[lp.subsidiary]++;
  });

  const primaryProductButtonsBlock = (legacyProduct: ImportProduct) => {

    if (!!legacyProduct && !!props.updatePrimaryProduct && countProductsPerSubsidiary[legacyProduct.subsidiary] > 1) {

      if (legacyProduct && (legacyProduct.priority === 'SECONDARY' || legacyProduct.priority === 'DEFAULT')) {
        return <React.Fragment>
          <Tag color="warning">{legacyProduct.priority}</Tag>
          <Button type="primary" className={"bg-success"} size={"small"}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    props.updatePrimaryProduct(props.coreProduct.id, legacyProduct);
                  }}>
            Set as primary
          </Button>
        </React.Fragment>;
      } else {
        return <Tag color="success">{legacyProduct.priority}</Tag>;
      }
    } else {
      return null;
    }
  };


  return props.error ?
    (
      <div className="view-error">{props.error}</div>
    )
    :
    (
      <React.Fragment>

        {
          loading && (<div>
            <div className="view-loading"/>
            <CircularProgress className="view-loading-spinner"/>
          </div>)
        }

        {
          !!coreProduct &&
          <Descriptions size="small" column={!!columns ? columns : 2} bordered>
              <Descriptions.Item label="Global Product Id">
                  <a>{coreProduct.id}</a>
              </Descriptions.Item>
              <Descriptions.Item label="Status">
                  <Space>
                    {statusTag(coreProduct.isDeactivated)}
                  </Space>
              </Descriptions.Item>
              <Descriptions.Item label="Connected products">
                {coreProduct.connectedProductsCount}
              </Descriptions.Item>
              <Descriptions.Item label="Modified">
                {new Date(coreProduct.globalProductModifiedAt).toLocaleString()}
              </Descriptions.Item>
            <Descriptions.Item label="Core product changes">
              <a target="_blank" href={`/app/products/history/${coreProduct.id}`}> <ExportOutlined /> </a>
            </Descriptions.Item>
          </Descriptions>
        }

        <br/>
        <Tabs defaultActiveKey={"1"} type="card" size={"default"}>
          <TabPane tab="Connected products" key="1">
            {
              coreProduct && coreProduct.legacyProducts.length > 0 &&
              (
                  <div>
                    <Collapse defaultActiveKey={[]}>
                      {coreProduct && (coreProduct.legacyProducts || [])
                          .map((product, index) => {
                            return (
                                <Panel header={product.subsidiary + ' ' + product.productId + ' - ' + product.name}
                                       extra={primaryProductButtonsBlock(product)}
                                       key={index}>
                                  <Descriptions size="small" column={!!columns ? columns : 3}>
                                    <Descriptions.Item label="GTINs">
                                      <a>{product && product.gtins}</a>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Brand">
                                      <a>{product && product.brand}</a>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="MPN">
                                      <a>{product && product.manufacturerPartNumber}</a>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Source system">
                                      <a>{product && product.sourceSystem}</a>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Source system modified">
                                      <a>{product && new Date(product.sourceSystemUpdatedAt).toLocaleString()}</a>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Modified">
                                      <a>{product && new Date(product.modifiedAt).toLocaleString()}</a>
                                    </Descriptions.Item>
                                  </Descriptions>
                                  <Highlight language="json">
                                    {product.productContent}
                                  </Highlight>
                                </Panel>
                            )
                          })
                      }
                    </Collapse>
                  </div>
              )
            }
          </TabPane>
          <TabPane tab="Extension API response" key="2">
            {coreProduct && <LoadableCoreProductApiResponseView coreProductId={coreProduct.id} extension={true}/>}
          </TabPane>
          <TabPane tab="API response" key="3">
            {coreProduct && <LoadableCoreProductApiResponseView coreProductId={coreProduct.id}/>}
          </TabPane>
          <TabPane tab="Edit Core Product" key="4">
            {coreProduct && <EditGlobalProductView coreProductId={coreProduct.id}/>}
          </TabPane>
        </Tabs>

      </React.Fragment>
    );
};

export default CoreProductView;
