import { PageResponse } from "../../common/types";

export const IMPORT_GPC_CLASSIFICATION = 'IMPORT_GPC_CLASSIFICATION'
export const IMPORT_GPC_CLASSIFICATION_SUCCEEDED = 'IMPORT_GPC_CLASSIFICATION_SUCCEEDED'
export const IMPORT_GPC_CLASSIFICATION_FAILED = 'IMPORT_GPC_CLASSIFICATION_FAILED'

export const FETCH_GPC_ITEMS = 'FETCH_GPC_ITEMS'
export const FETCH_GPC_ITEMS_SUCCEEDED = 'FETCH_GPC_ITEMS_SUCCEEDED'
export const FETCH_GPC_ITEMS_FAILED = 'FETCH_GPC_ITEMS_FAILED'

export const FETCH_GPC_ITEM = 'FETCH_GPC_ITEM'
export const FETCH_GPC_ITEM_SUCCEEDED = 'FETCH_GPC_ITEM_SUCCEEDED'
export const FETCH_GPC_ITEM_FAILED = 'FETCH_GPC_ITEM_FAILED'

export const CLOSE_GPC_CLASSIFICATION_ERROR_MESSAGE = 'CLOSE_GPC_CLASSIFICATION_ERROR_MESSAGE'

export type GpcLevelItemType = 'SEGMENT' | 'FAMILY' | 'CLAZZ' | 'BRICK' | 'ATTRIBUTE_TYPE' | 'ATTRIBUTE_VALUE';
export const gpcLevelTypeList = ['SEGMENT', 'FAMILY', 'CLAZZ', 'BRICK', 'ATTRIBUTE_TYPE', 'ATTRIBUTE_VALUE'];

export type GpcClassificationItem = (GpcSegment | GpcFamily | GpcClazz | GpcBrick | GpcAttributeType);

export interface FetchGpcLevelItemRequest {
    level: GpcLevelItemType;
    code: string;
}

export interface FetchGpcLevelItemResponse {
    item: GpcClassificationItem;
}

export interface FetchGpcLevelItemsPageRequest {
    page: number;
    size: number;
    sort: { field: string, direction: 'ASC' | 'DESC' }[];
    level: GpcLevelItemType;
    code: string;
    search: string;
}

export interface FetchGpcLevelItemsPageResponse extends PageResponse {
    content: GpcLevelItem[];
}

export class GpcLevelItem {
    code: string;
    text: string;
    definition: string;
}

export class GpcChildrenResolver {

    public static getChildren(item: GpcClassificationItem): (GpcLevelItem & { level: GpcLevelItemType }) [] {

        if (!!(item as GpcSegment).familyList) {
            return (item as GpcSegment).familyList.map(child => {
                return {...child, level: 'FAMILY'};
            });
        }

        if (!!(item as GpcFamily).clazzList) {
            return (item as GpcFamily).clazzList.map(child => {
                return {...child, level: 'CLAZZ'};
            });
        }

        if (!!(item as GpcClazz).brickList) {
            return (item as GpcClazz).brickList.map(child => {
                return {...child, level: 'BRICK'};
            });
        }

        if (!!(item as GpcBrick).attributeTypeList) {
            return (item as GpcBrick).attributeTypeList.map(child => {
                return {...child, level: 'ATTRIBUTE_TYPE'};
            });
        }

        if (!!(item as GpcAttributeType).attributeValueList) {
            return (item as GpcAttributeType).attributeValueList.map(child => {
                return {...child, level: 'ATTRIBUTE_VALUE'};
            });
        }

        return [];
    }
}

export interface GpcSegment extends GpcLevelItem {
    familyList: GpcLevelItem[];
}

export class GpcFamily extends GpcLevelItem {
    clazzList: GpcLevelItem[];
}

export interface GpcClazz extends GpcLevelItem {
    brickList: GpcLevelItem[];
}

export interface GpcBrick extends GpcLevelItem {
    attributeTypeList: GpcAttributeType[];
}

export interface GpcAttributeType extends GpcLevelItem {
    attributeValueList: GpcLevelItem[];
}

export interface ImportGpcClassification {
    file: File;
}

export interface GpcClassificationState {
    importClassification: ImportGpcClassification;

    pageRequest: FetchGpcLevelItemsPageRequest;
    pageResponse: FetchGpcLevelItemsPageResponse;

    itemRequest: FetchGpcLevelItemRequest;
    itemResponse: FetchGpcLevelItemResponse;

    loading: boolean;
    error: string;
}

export interface FetchGpcItemAction {
    type: typeof FETCH_GPC_ITEM
    itemRequest: FetchGpcLevelItemRequest
}

export interface FetchGpcItemSucceededAction {
    type: typeof FETCH_GPC_ITEM_SUCCEEDED
    payload: FetchGpcLevelItemResponse
}

export interface FetchGpcItemFailedAction {
    type: typeof FETCH_GPC_ITEM_FAILED
    error: string
}

export interface FetchGpcItemsAction {
    type: typeof FETCH_GPC_ITEMS
    pageRequest: FetchGpcLevelItemsPageRequest
}

export interface FetchGpcItemsSucceededAction {
    type: typeof FETCH_GPC_ITEMS_SUCCEEDED
    payload: FetchGpcLevelItemsPageResponse
}

export interface FetchGpcItemsFailedAction {
    type: typeof FETCH_GPC_ITEMS_FAILED
    error: string
}

export interface ImportClassificationAction {
    type: typeof IMPORT_GPC_CLASSIFICATION
    request: ImportGpcClassification
}

export interface ImportClassificationSucceededAction {
    type: typeof IMPORT_GPC_CLASSIFICATION_SUCCEEDED
}

export interface ImportClassificationFailedAction {
    type: typeof IMPORT_GPC_CLASSIFICATION_FAILED
    error: string
}

export interface CloseGpcClassificationErrorMessageAction {
    type: typeof CLOSE_GPC_CLASSIFICATION_ERROR_MESSAGE
}

export type GpcClassificationsActionTypes = FetchGpcItemAction | FetchGpcItemSucceededAction | FetchGpcItemFailedAction
    | FetchGpcItemsAction | FetchGpcItemsSucceededAction | FetchGpcItemsFailedAction
    | ImportClassificationAction | ImportClassificationSucceededAction | ImportClassificationFailedAction
    | CloseGpcClassificationErrorMessageAction;


