import * as React from "react";
import "./KindOfProductList.css"
import EnhancedTable, {Column, Order} from "../EnhancedTable/EnhancedTable";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import {Button, Form, Tooltip} from 'antd';
import {
    FetchKindOfLevelItemsPageRequest,
    FetchKindOfLevelItemsPageResponse,
    KindOfLevelItem
} from "../../store/kindOf/types";
import Search from "antd/es/input/Search";
import LanguageTypeSelect from "../inputs/LanguageTypeSelect/LanguageTypeSelect";
import {kindOfProductLanguageTypeList} from "../../store/common/types";

export interface Props {
    pageRequest: FetchKindOfLevelItemsPageRequest;
    pageResponse: FetchKindOfLevelItemsPageResponse;

    onPaginationChanged: (page: number, size: number) => void;
    onSortingChanged?: (orderBy: string, order: Order) => void;
    onFilterChanged?: (filterField: string, value: string) => void;
    onRowClick?: (string: string) => void;

    loading: boolean;
    error: string;

    onGenerateMapping?: () => void;
    generateReportLoading?: boolean;
}

export const KindOfProductList: React.FunctionComponent<Props> = (props) => {

    const buttonItemLayout = {
        wrapperCol: { span: 8, offset: 2 },
    };

    const [form] = Form.useForm();

    const {
        pageRequest,
        pageResponse,
        onPaginationChanged,
        onSortingChanged,
        onFilterChanged,
        onRowClick,
        loading,
        onGenerateMapping,
        generateReportLoading
    } = props;

    const columns: Column[] = [
        {
            label: 'Code',
            field: 'code',
            type: 'computed',
            computedValue: (item: KindOfLevelItem) => {
                return (<a onClick={() => onRowClick(item.code)}>{item.code}</a>)
            }
        },
        {
            label: 'Name',
            field: 'name',
            type: 'computed',
            computedValue: (item: KindOfLevelItem) => {
                return (item.name);
            }
        },
        {
            label: 'Created at',
            field: 'createdAt',
            type: 'computed',
            computedValue: (item: KindOfLevelItem) => {
                return (<>{new Date(item.createdAt).toLocaleString()}</>);
            }
        },
        {
            label: 'Updated at',
            field: 'updatedAt',
            type: 'computed',
            computedValue: (item: KindOfLevelItem) => {
                return (<>{new Date(item.updatedAt).toLocaleString()}</>);
            }
        },
        {
            label: 'Description',
            field: 'description',
            type: 'computed',
            computedValue: (item: KindOfLevelItem) => {
                return (item.description);
            }
        }
    ];

    const handleFilterChange = (field: string, value: string) => {
        onFilterChanged && onFilterChanged(field, value);
    };

    const formItemLayout = {
        labelCol: {span: 2},
        wrapperCol: {span: 8},
    };
  return props.error ?
        (
            <div className="view-error">{props.error}</div>
        )
        :
        (
            <React.Fragment>

                {
                    loading && (<div>
                        <div className="view-loading"/>
                        <CircularProgress className="view-loading-spinner"/>
                    </div>)
                }

                <Form
                    {...formItemLayout}
                    layout={'horizontal'}
                    form={form}
                >
                    <Form.Item label="Language">
                        <LanguageTypeSelect id={"language-select"}
                                            value={pageRequest.language}
                                            list={kindOfProductLanguageTypeList}
                                            onChange={(value) => handleFilterChange('language', value)}
                        />
                    </Form.Item>
                    <Form.Item label={'Search'}>
                        <Search
                            placeholder="Search by Code or Name"
                            enterButton
                            size="middle"
                            defaultValue={pageRequest.search}
                            onSearch={(value) => handleFilterChange('search', value)}
                        />
                    </Form.Item>
                    <Form.Item {...buttonItemLayout}>
                        <Tooltip title="Download mappings of kind of product to various mapping sources such as product
                        groups, feature frames etc.">
                            <Button type="primary" size={"middle"} loading={generateReportLoading}
                                    onClick={onGenerateMapping}>Download Mapping</Button>
                        </Tooltip>
                    </Form.Item>
                </Form>

                <EnhancedTable key={"search_result"}
                               tableName={"Results"}
                               rowsSelectable={false}
                               columns={columns}
                               data={pageResponse.content}
                               totalCount={pageResponse.totalElements}
                               countOnPage={pageResponse.numberOfElements}
                               currentPage={pageRequest.page}
                               pageSize={pageRequest.size}
                               sort={pageRequest.sort}
                               onPaginationChanged={onPaginationChanged}
                               onSortingChanged={onSortingChanged}/>
            </React.Fragment>
        );
};

export default KindOfProductList;
