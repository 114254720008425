import {
  FETCH_CORE_PRODUCT_CHANGE,
  FETCH_CORE_PRODUCT_CHANGE_FAILED,
  FETCH_CORE_PRODUCT_CHANGE_SUCCEEDED,
  FetchCoreProductChangeActionTypes,
  FetchCoreProductChangeRequest,
  FetchCoreProductChangeResponse
} from './types'

export function fetchCoreProductChange(request: FetchCoreProductChangeRequest): FetchCoreProductChangeActionTypes {
  return {
    type: FETCH_CORE_PRODUCT_CHANGE,
    request
  }
}

export function fetchCoreProductChangeSucceeded(payload: FetchCoreProductChangeResponse): FetchCoreProductChangeActionTypes {
  return {
    type: FETCH_CORE_PRODUCT_CHANGE_SUCCEEDED,
    payload
  }
}

export function fetchCoreProductChangeFailed(error: string): FetchCoreProductChangeActionTypes {
  return {
    type: FETCH_CORE_PRODUCT_CHANGE_FAILED,
    error
  }
}
