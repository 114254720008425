import * as React from "react";
import "./AddProduct.css"
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import { Alert, Button, Form, Input, Select } from 'antd';
import { ProductSourceSystem, Subsidiary } from "../../store/common/types";
import SubsidiarySelect from "../inputs/SubsidiarySelect/SubsidiarySelect";

const { Option } = Select;

export interface Props {
  subsidiary: Subsidiary;
  sourceSystem: ProductSourceSystem;
  productId: string;
  onFormFieldChange: (field: string, value: string) => void;
  onSubmit: () => void;
  onMessageClose: () => void;
  loading: boolean;
  error: string;
}

export const AddProductView: React.FunctionComponent<Props> = (props) => {

  const [form] = Form.useForm();

  const { productId, subsidiary, sourceSystem, onSubmit, onMessageClose, onFormFieldChange, loading, error } = props;

  const formItemLayout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 5 },
  };

  const buttonItemLayout = {
    wrapperCol: { span: 5, offset: 2 },
  };

  return (
      <React.Fragment>

        {
          loading && (<div>
            <div className="view-loading"/>
            <CircularProgress className="view-loading-spinner"/>
          </div>)
        }

        {
          error && (
            <React.Fragment>
              <Alert
                message="Error"
                description={error}
                type="error"
                closable
                onClose={onMessageClose}
              />
              <br/>
            </React.Fragment>
          )
        }

        <Form
          {...formItemLayout}
          initialValues={
            {
              productId,
              subsidiary,
              sourceSystem
            }
          }
          layout={'horizontal'}
          form={form}
          onFinish={onSubmit}
        >

          <Form.Item label="Product Id"
                     name={"productId"}
                     rules={[{ required: true, message: 'Please input comma separated list of product ids (max 10.000)' }]}
          >
            <Input
              id="legacy-product-id-input"
              value={productId}
              placeholder="Comma separated list of product ids (max 10.000)"
              onChange={(event) => onFormFieldChange('productId', event.target.value)}
            />
          </Form.Item>

          <Form.Item label="Subsidiary"
                     name={"subsidiary"}
                     rules={[{ required: true, message: 'Please input subsidiary' }]}
          >
            <SubsidiarySelect id="subsidiary-select"
                              value={subsidiary}
                              onChange={(value) => onFormFieldChange('subsidiary', value)}
            />
          </Form.Item>

          <Form.Item label="Source system"
                     name={"sourceSystem"}
                     rules={[{ required: true, message: 'Please input source system' }]}
          >
            <Select id="source-system-select"
                    value={sourceSystem}
                    onChange={(value) => onFormFieldChange('sourceSystem', value)}>

              <Option value="MDNG">MasterData NG</Option>
              <Option value="ACL_ITALY" disabled={true}>ACL Italy</Option>
            </Select>
          </Form.Item>

          <Form.Item {...buttonItemLayout}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>

      </React.Fragment>
    );
};

export default AddProductView;
