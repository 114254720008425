import React from 'react';
import {Route, Switch} from "react-router-dom";
import HomePage from "../../pages/HomePage/HomePage";
import "./App.css";
import { Provider } from "react-redux";
import { history, store } from "../../store";

import CircularProgress from '@material-ui/core/CircularProgress';
import {fetchUserSession, fetchUserSessionSilent} from "../../store/userSession/actions";
import {USER_SESSION_REFRESH_INTERVAL, UserSession} from "../../store/userSession/types";
import Login from "../Login/Login";
import ProductImportsPage from "../../pages/ProductImportsPage/ProductImportsPage";
import SettingsPage from "../../pages/SettingsPage/SettingsPage";
import GlobalProductsPage from "../../pages/GlobalProductsPage/GlobalProductsPage";
import ImportTaskExecutionPage from "../../pages/ImportTaskExecutionPage/ImportTaskExecutionPage";
import CoreProductPage from "../../pages/CoreProductPage/CoreProductPage";
import FailureListPage from "../../pages/FailureListPage/FailureListPage";
import AddProductPage from "../../pages/AddProductPage/AddProductPage";
import AddKindOfProductPage from "../../pages/AddKindOfProductPage/AddKindOfProductPage";
import ImportFailurePage from "../../pages/ImporterFailurePage/ImporterFailurePage";
import TaskListPage from "../../pages/TaskListPage/TaskListPage";
import AddTaskPage from "../../pages/AddTaskPage/AddTaskPage";
import ProductFailurePage from "../../pages/ProductFailurePage/ProductFailurePage";
import PermissionsManagementPage from "../../pages/PermissionsManagementPage/PermissionsManagementPage";
import UserPage from "../../pages/UserPage/UserPage";
import GroupPage from "../../pages/GroupPage/GroupPage";
import { ConnectedRouter } from "connected-react-router";
import PermissionPage from "../../pages/PermissionPage/PermissionPage";
import ImportGpcClassificationPage from "../../pages/ImportGpcClassificationPage/ImportGpcClassificationPage";
import ViewGpcClassificationPage from "../../pages/ViewGpcClassificationPage/ViewGpcClassificationPage";
import ViewGpcClassificationItemPage from "../../pages/ViewGpcClassificationItemPage/ViewGpcClassificationItemPage";
import ImportProductGroupClassificationPage
  from "../../pages/ImportProductGroupClassificationPage/ImportProductGroupClassificationPage";
import ViewProductGroupClassificationPage
  from "../../pages/ViewProductGroupClassificationPage/ViewProductGroupClassificationPage";
import ViewProductGroupClassificationItemPage
  from "../../pages/ViewProductGroupClassificationItemPage/ViewProductGroupClassificationItemPage";
import DuplicatedProductsPage from "../../pages/DuplicatedProductsPage/DuplicatedProductsPage";
import FlatIdPage from "../../pages/FlatIdPage/FlatIdPage";
import KindOfProductListPage from "../../pages/KindOfProductListPage/KindOfProductListPage";
import MPNStatsPage from "../../pages/MPNStatsPage/MPNStatsPage";
import EditKindOfProductPage from "../../pages/EditKindOfProductPage/EditKindOfProductPage";
import KOPTOMVsMappingPage from "../../pages/KOPTOMVsMappingPage/KOPTOMVsMappingPage";
import MVTOKOPsMappingPage from "../../pages/MVTOKOPsMappingPage/MVTOKOPsMappingPage";
import ProductHistoryPage from "../../pages/ProductHistoryPage/ProductHistoryPage";
import AvoidanceFixDisregardPage from "../../pages/AvoidanceFixDisregardPage/AvoidanceFixDisregardPage";
import DeprecatedKindOfProductListPage
  from "../../pages/DeprecatedKindOfProductListPage/DeprecatedKindOfProductListPage";
import KindOfProductRecalculationPage from "../../pages/KindOfProductRecalculationPage/KindOfProductRecalculationPage";

interface State {
  userSession: UserSession | null,
  loadingUserSession: boolean,
  error: string | null
}

class App extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);

    this.state = {
      userSession: null,
      loadingUserSession: true,
      error: null
    };

    store.subscribe(() => {
      this.setState({
        userSession: store.getState().userSession.userSession,
        loadingUserSession: store.getState().userSession.loading,
        error: store.getState().userSession.error
      });
    });

    store.dispatch(fetchUserSession());

    // Refresh the userSession in the backend
    setInterval(() => {
      store.dispatch(fetchUserSessionSilent());
    }, USER_SESSION_REFRESH_INTERVAL);
  }

  public render() {
    const { userSession, loadingUserSession, error } = this.state;

    if (loadingUserSession) {
      return (
        <div>
          <CircularProgress/>
        </div>
      );
    }

    if (userSession && !userSession.loggedIn) {
      return (
          <Login/>
      );
    }

    if (error) {
      return (
        <div>
          {error}
        </div>
      );
    }

    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <div className="app">
            <Switch>
              <Route
                exact
                path="/"
                render={() => <HomePage/>}
              />
              <Route
                exact
                path={'/app/products/add'}
                render={() => <AddProductPage/>}
              />
              <Route
                exact
                path={'/app/products/list'}
                render={() => <GlobalProductsPage/>}
              />
              <Route
                exact
                path={'/app/products/duplicates'}
                render={() => <DuplicatedProductsPage/>}
              />
              <Route
                  exact
                  path={'/app/products/avoidanceFixDisregard'}
                  render={() => <AvoidanceFixDisregardPage/>}
              />
              <Route
                exact
                path={'/app/products/manufacturer-mpn-stats'}
                render={() => <MPNStatsPage/>}
              />
              <Route
                  exact
                  path={'/app/products/history/:id'}
                  render={() => <ProductHistoryPage/>}
              />
              <Route
                exact
                path={'/app/tasks/add'}
                render={() => <AddTaskPage/>}
              />
              <Route
                exact
                path={'/app/tasks/list'}
                render={() => <TaskListPage/>}
              />
              <Route
                exact
                path={'/app/products/:id'}
                render={() => <CoreProductPage/>}
              />
              <Route
                exact
                path={'/app/product-imports'}
                render={() => <ProductImportsPage/>}
              />
              <Route
                exact
                path={'/app/product-imports/:id'}
                render={() => <ImportTaskExecutionPage/>}
              />
              <Route
                exact
                path={'/app/failures'}
                render={() => <FailureListPage/>}
              />
              <Route
                exact
                path={'/app/importer-failures/:id'}
                render={() => <ImportFailurePage/>}
              />
              <Route
                exact
                path={'/app/product-failures/:id'}
                render={() => <ProductFailurePage/>}
              />
              <Route
                exact
                path={'/app/settings'}
                render={() => <SettingsPage/>}
              />
              <Route
                exact
                path={'/app/settings/permissions-management'}
                render={() => <PermissionsManagementPage/>}
              />
              <Route
                exact
                path={'/app/settings/permissions-management/users/:id'}
                render={() => <UserPage/>}
              />
              <Route
                exact
                path={'/app/settings/permissions-management/groups/create'}
                render={() => <GroupPage/>}
              />
              <Route
                exact
                path={'/app/settings/permissions-management/groups/:id'}
                render={() => <GroupPage/>}
              />
              <Route
                exact
                path={'/app/settings/permissions-management/permissions/create'}
                render={() => <PermissionPage/>}
              />
              <Route
                exact
                path={'/app/settings/permissions-management/permissions/:id'}
                render={() => <PermissionPage/>}
              />
              <Route
                exact
                path={'/app/classification/gpc/add'}
                render={() => <ImportGpcClassificationPage/>}
              />
              <Route
                exact
                path={'/app/classification/gpc/list'}
                render={() => <ViewGpcClassificationPage/>}
              />
              <Route
                exact
                path={'/app/classification/gpc/:level/:code'}
                render={() => <ViewGpcClassificationItemPage/>}
              />
              <Route
                  exact
                  path={'/app/classification/productGroups/add'}
                  render={() => <ImportProductGroupClassificationPage/>}
              />
              <Route
                  exact
                  path={'/app/classification/productGroups/list'}
                  render={() => <ViewProductGroupClassificationPage/>}
              />
              <Route
                  exact
                  path={'/app/classification/productGroup/:level/:id/:language'}
                  render={() => <ViewProductGroupClassificationItemPage/>}
              />
              <Route
                  exact
                  path={'/app/maintenance/flat-id'}
                  render={() => <FlatIdPage/>}
              />
              <Route
                  exact
                  path={'/app/kind-of-product/list'}
                  render={() => <KindOfProductListPage/>}
              />
              <Route
                  exact
                  path={'/app/kind-of-product/add'}
                  render={() => <AddKindOfProductPage/>}
              />
              <Route
                  exact
                  path={'/app/kind-of-product/map/kop-to-mv'}
                  render={() => <KOPTOMVsMappingPage/>}
              />
              <Route
                  exact
                  path={'/app/kind-of-product/map/mv-to-kop'}
                  render={() => <MVTOKOPsMappingPage/>}
              />
              <Route
                  exact
                  path={'/app/kind-of-product/:code'}
                  render={() => <EditKindOfProductPage/>}
              />
              <Route
                  exact
                  path={'/app/kind-of-product/list/deprecated-kop'}
                  render={() => <DeprecatedKindOfProductListPage/>}
              />
              <Route
                  exact
                  path={'/app/kind-of-product/list/recalculation'}
                  render={() => <KindOfProductRecalculationPage/>}
              />
            </Switch>
          </div>
        </ConnectedRouter>
      </Provider>
    );
  }

}

export default App;
