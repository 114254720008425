import kindOfProductRecalculationService from "../../services/KindOfProductRecalculationService";

import {
    FETCH_KIND_OF_PRODUCT_RECALCULATIONS,
    APPLY_KIND_OF_PRODUCT_RECALCULATION,
    CREATE_KIND_OF_PRODUCT_RECALCULATION,
    DOWNLOAD_KIND_OF_PRODUCT_RECALCULATION_REPORT,
    UPDATE_KIND_OF_PRODUCT_RECALCULATION_REPORT,
    FetchKindOfProductRecalculationsAction,
    ApplyKindOfProductRecalculationAction,
    CreateKindOfProductRecalculationsAction,
    DownloadKindOfProductRecalculationReportAction,
    UpdateKindOfProductRecalculationReportAction,
    RejectKindOfProductRecalculationAction,
    REJECT_KIND_OF_PRODUCT_RECALCULATION
} from "./types"

import {all, call, put, takeLatest} from "redux-saga/effects";

import {message} from "antd";
import {
    applyKindOfProductRecalculationSucceeded,
    applyKindOfProductRecalculationFailed,
    createKindOfProductRecalculationSucceeded,
    createKindOfProductRecalculationFailed,
    downloadKindOfProductRecalculationReportSucceeded,
    downloadKindOfProductRecalculationReportFailed,
    fetchKindOfProductRecalculationsSucceeded,
    fetchKindOfProductRecalculationsFailed,
    rejectKindOfProductRecalculationSucceeded,
    rejectKindOfProductRecalculationFailed,
    updateKindOfProductRecalculationReportSucceeded,
    updateKindOfProductRecalculationReportFailed
} from "./actions";

export function* kindOfProductRecalculationSaga() {
    yield all([
        watchKindOfProductRecalculations(),
        watchCreateKindOfProductRecalculation(),
        watchDownloadKindOfProductRecalculationReport(),
        watchUpdateKindOfProductRecalculationReport(),
        watchApplyKindOfProductRecalculation(),
        watchRejectKindOfProductRecalculation()
    ])
}

function* watchKindOfProductRecalculations() {
    yield takeLatest(FETCH_KIND_OF_PRODUCT_RECALCULATIONS, getKopRecalculations)
}

function* getKopRecalculations(action: FetchKindOfProductRecalculationsAction) {
    try {
        const data = yield call([kindOfProductRecalculationService, kindOfProductRecalculationService.getKopRecalculations], action.pageRequest);
        yield put(fetchKindOfProductRecalculationsSucceeded(data))
    } catch (error) {
        message.error(error.message);
        yield put(fetchKindOfProductRecalculationsFailed(`${error.message}`))
    }
}

function* watchCreateKindOfProductRecalculation() {
    yield takeLatest(CREATE_KIND_OF_PRODUCT_RECALCULATION, createKopRecalculation)
}

function* createKopRecalculation(action: CreateKindOfProductRecalculationsAction) {
    try {
        const data = yield call([kindOfProductRecalculationService, kindOfProductRecalculationService.createKopRecalculation], action.request);
        message.success("Kind of Product Recalculation was triggered successfully with id: " + data.id);
        yield put(createKindOfProductRecalculationSucceeded(data))
    } catch (error) {
        message.error(error.message);
        yield put(createKindOfProductRecalculationFailed(`${error.message}`))
    }
}

function* watchDownloadKindOfProductRecalculationReport() {
    yield takeLatest(DOWNLOAD_KIND_OF_PRODUCT_RECALCULATION_REPORT, downloadKopRecalculationReport)
}

function* downloadKopRecalculationReport(action: DownloadKindOfProductRecalculationReportAction) {
    try {
        const data = yield call([kindOfProductRecalculationService, kindOfProductRecalculationService.downloadKopRecalculationReport], action.request);
        yield put(downloadKindOfProductRecalculationReportSucceeded(data))
    } catch (error) {
        message.error(error.message);
        yield put(downloadKindOfProductRecalculationReportFailed(`${error.message}`))
    }
}

function* watchUpdateKindOfProductRecalculationReport() {
    yield takeLatest(UPDATE_KIND_OF_PRODUCT_RECALCULATION_REPORT, updateKopRecalculationReport)
}

function* updateKopRecalculationReport(action: UpdateKindOfProductRecalculationReportAction) {
    try {
        const data = yield call([kindOfProductRecalculationService, kindOfProductRecalculationService.updateKopRecalculationReport], action.request);
        message.success(`Kind of Product Recalculation Report update succeeded.`)
        yield put(updateKindOfProductRecalculationReportSucceeded(data))
    } catch (error) {
        message.error(error.message);
        yield put(updateKindOfProductRecalculationReportFailed(`${error.message}`))
    }
}

function* watchApplyKindOfProductRecalculation() {
    yield takeLatest(APPLY_KIND_OF_PRODUCT_RECALCULATION, applyKindOfProductRecalculation)
}

function* applyKindOfProductRecalculation(action: ApplyKindOfProductRecalculationAction) {
    try {
        const data = yield call([kindOfProductRecalculationService, kindOfProductRecalculationService.applyKindOfProductRecalculation], action.request);
        message.success(`Started processing Kind of Product Recalculation with ID: ${action.request.kopRecalculationId}.`)
        yield put(applyKindOfProductRecalculationSucceeded(data))
    } catch (error) {
        message.error(error.message);
        yield put(applyKindOfProductRecalculationFailed(`${error.message}`))
    }
}

function* watchRejectKindOfProductRecalculation() {
    yield takeLatest(REJECT_KIND_OF_PRODUCT_RECALCULATION, rejectKindOfProductRecalculation)
}

function* rejectKindOfProductRecalculation(action: RejectKindOfProductRecalculationAction) {
    try {
        const data = yield call([kindOfProductRecalculationService, kindOfProductRecalculationService.rejectKindOfProductRecalculation], action.request);
        message.success(`Rejected Kind of Product Recalculation with ID: ${action.request}.`)
        yield put(rejectKindOfProductRecalculationSucceeded(data))
    } catch (error) {
        message.error(error.message);
        yield put(rejectKindOfProductRecalculationFailed(`${error.message}`))
    }
}
