import { all, call, put, takeLatest } from 'redux-saga/effects'

import {
  FETCH_GPC_ITEM,
  FETCH_GPC_ITEMS,
  FetchGpcItemAction,
  FetchGpcItemsAction,
  IMPORT_GPC_CLASSIFICATION,
  ImportClassificationAction,
} from './types'
import {
  fetchGpcLevelItemFailed,
  fetchGpcLevelItemsFailed,
  fetchGpcLevelItemsSucceeded,
  fetchGpcLevelItemSucceeded,
  importGpcClassificationFailed,
  importGpcClassificationSucceeded
} from './actions'

import classificationService from '../../../services/ClassificationService'
import { message } from "antd";
import {fetchImportProgress} from "../importProgress/actions";
import {ImportProgressResponse} from "../importProgress/types";

export function* classificationSaga() {
  yield all([
    watchFetchGpcClassificationLevelItem(),
    watchFetchGpcClassificationLevelItems(),
    watchImportGpcClassification()
  ])
}

function* watchFetchGpcClassificationLevelItems() {
  yield takeLatest(FETCH_GPC_ITEMS, handleFetchGpcItems)
}

function* watchFetchGpcClassificationLevelItem() {
  yield takeLatest(FETCH_GPC_ITEM, handleFetchGpcItem)
}

function* handleFetchGpcItems(action: FetchGpcItemsAction) {
  try {
    const data = yield call([classificationService, classificationService.getGpcItems], action.pageRequest);
    yield put(fetchGpcLevelItemsSucceeded(data))
  } catch (error) {
    message.error(error.message);
    yield put(fetchGpcLevelItemsFailed(`${error.message}`))
  }
}

function* handleFetchGpcItem(action: FetchGpcItemAction) {
  try {
    const data = yield call([classificationService, classificationService.getGpcItem], action.itemRequest);
    yield put(fetchGpcLevelItemSucceeded(data))
  } catch (error) {
    message.error(error.message);
    yield put(fetchGpcLevelItemFailed(`${error.message}`))
  }
}

function* watchImportGpcClassification() {
  yield takeLatest(IMPORT_GPC_CLASSIFICATION, handleImportGpcClassification)
}

function* handleImportGpcClassification(action: ImportClassificationAction) {
  try {
    const data: ImportProgressResponse = yield call([classificationService, classificationService.uploadGpcClassificationFile], action.request.file);
    yield put(importGpcClassificationSucceeded());
    yield put(fetchImportProgress({
      importId: data.importId,
      importType: data.importType
    }));
  } catch (error) {
    message.error(error.response.data.title);
    yield put(importGpcClassificationFailed(`${error.response.data.detail}`))
  }
}
