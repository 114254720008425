import * as React from "react";
import {RouteComponentProps, withRouter} from "react-router-dom";
import "./MVTOKOPsMappingPage.css";
import {AppState} from "../../store";
import {flowRight} from "lodash";
import {connect} from 'react-redux';
import {UserSession} from "../../store/userSession/types";
import PageWrapper from "../../components/Drawer/PageWrapper";
import {PageHeader} from 'antd';
import MappingMVToKOPsView from "../../components/MappingMVToKOPsView/MappingMVToKOPsView";

interface Props extends RouteComponentProps {
    userSession: UserSession;
    loading?: boolean;
    error?: string;
}

interface State {
}

class MVTOKOPsMappingPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
    }

    componentDidMount() {
    }

    public render() {

        return (
            <div className="mapping-page">
                <PageWrapper userSession={this.props.userSession}>
                    <PageHeader
                        ghost={false}
                        onBack={() => window.history.back()}
                        title="Kind of Product Mapping"
                        subTitle={"Map Product Group or Feature Frame To Kind of Products"}
                    >
                    </PageHeader>
                    <MappingMVToKOPsView
                        loading={this.props.loading}
                        error={this.props.error}
                    />
                </PageWrapper>
            </div>
        );
    }
}

const mapStateToProps = (state: AppState) => ({
    userSession: state.userSession.userSession,

    loading: state.mapping.mappedValuesLoading,
    error: state.mapping.mappedValuesError,
});

const mapDispatchToProps = () => ({});

const enhance = flowRight(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(MVTOKOPsMappingPage);
