import { Select } from "antd";
import * as React from "react";
import { productGroupLevelTypeList } from "../../../store/classifications/productGroup/types";

const { Option } = Select;

export interface Props {
  id: string
  value: any;
  mode?: 'multiple' | 'tags';
  onChange: (value: string) => void;
}

export default function ProductGroupLevelTypeSelect(props: Props) {

  const { id, mode, value, onChange } = props;

  return (
    <Select id={id}
            mode={mode}
            value={value}
            placeholder="Select language"
            onChange={(value) => onChange(value)}>
      {
        productGroupLevelTypeList.map(level => {
          return <Option value={level}>{level.replace(/_/gi, " ")}</Option>
        })
      }
    </Select>
  )
}
