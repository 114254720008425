import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import "./DuplicatedProductsPage.css";
import { Dispatch } from "redux";
import { flowRight } from "lodash";
import { AppState } from "../../store";
import { connect } from 'react-redux';
import { UserSession } from "../../store/userSession/types";
import { Order } from "../../components/EnhancedTable/EnhancedTable";
import PageWrapper from "../../components/Drawer/PageWrapper";
import { PageHeader } from 'antd';
import {
  FetchDuplicatedProductsPageRequest,
  FetchDuplicatedProductsPageResponse
} from "../../store/globalProducts/types";
import DuplicatedProductsView from "../../components/DuplicatedProducts/DuplicatedProductsView";
import { fetchDuplicatedProducts, generateDuplicatedProductsReport } from "../../store/globalProducts/actions";

interface Props extends RouteComponentProps {
  userSession: UserSession;
  pageRequest: FetchDuplicatedProductsPageRequest;
  pageResponse: FetchDuplicatedProductsPageResponse;
  fetchDuplicatedProducts: (pageRequest: FetchDuplicatedProductsPageRequest) => void;
  generateDuplicatedProductsReport: (pageRequest: FetchDuplicatedProductsPageRequest) => void;
  generateDuplicatedProductsReportLoading?: boolean;
  loading?: boolean;
  error?: string;
}

interface State {
}

class DuplicatedProductsPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.onPaginationChanged = this.onPaginationChanged.bind(this);
    this.onSortingChanged = this.onSortingChanged.bind(this);
    this.onFilterChanged = this.onFilterChanged.bind(this);
    this.onGenerateReport = this.onGenerateReport.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
  }

  componentDidMount () {
    this.props.fetchDuplicatedProducts(this.props.pageRequest)
  }

  private onRowClick(id: number, newTab?: boolean) {
    if (!!newTab) {
      const href = this.props.history.createHref({ pathname: '/app/products/' + id });
      window.open(href);
    } else {
      this.props.history.push("/app/products/" + id);
    }
  }

  private onPaginationChanged(page: number, size: number) {
    this.props.fetchDuplicatedProducts({
      ... this.props.pageRequest,
      page,
      size
    })
  }

  private onGenerateReport() {
    this.props.generateDuplicatedProductsReport({
      ... this.props.pageRequest
    });
  }

  private onFilterChanged(filterField: string, value: string) {
    console.log("Filter: " + filterField + ", value: " + value);

    this.props.fetchDuplicatedProducts({
      ...this.props.pageRequest,
      [filterField]: value
    });
  }

  private onSortingChanged(orderBy: string, order: Order) {
    console.log("Order by: " + orderBy + ", order: " + order);

    this.props.fetchDuplicatedProducts({
      ... this.props.pageRequest,
      sort: {
        field: orderBy,
        direction: order
      }
    });
  }

  public render() {
    return (
      <div className="duplicated-products-page">
        <PageWrapper userSession={this.props.userSession}>
          <PageHeader
            backIcon={null}
            ghost={false}
            onBack={() => window.history.back()}
            title="Core Product Duplicates"
            subTitle="Displays core products which have duplicated subsidiary products"
          >
          </PageHeader>
          <DuplicatedProductsView
            pageRequest={this.props.pageRequest}
            pageResponse={this.props.pageResponse}
            onPaginationChanged={this.onPaginationChanged}
            onSortingChanged={this.onSortingChanged}
            onFilterChanged={this.onFilterChanged}
            onGenerateReport={this.onGenerateReport}
            generateReportLoading={this.props.generateDuplicatedProductsReportLoading}
            loading={this.props.loading}
            error={this.props.error}
            onRowClick={this.onRowClick}
          />
        </PageWrapper>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  userSession: state.userSession.userSession,

  pageRequest: state.products.duplicatedProductsPageRequest,
  pageResponse: state.products.duplicatedProductsPageResponse,

  generateDuplicatedProductsReportLoading: state.products.generateDuplicatedProductsReportLoading,
  loading: state.products.loading,
  error: state.products.error,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchDuplicatedProducts: (pageRequest: FetchDuplicatedProductsPageRequest) => {
    dispatch(fetchDuplicatedProducts(pageRequest))
  },
  generateDuplicatedProductsReport: (pageRequest: FetchDuplicatedProductsPageRequest) => {
    dispatch(generateDuplicatedProductsReport(pageRequest))
  },
});

const enhance = flowRight(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(DuplicatedProductsPage);
