import { Subsidiary } from "../common/types";

export const START_FETCHING_STATISTICS = 'START_FETCHING_STATISTICS'
export const STOP_FETCHING_STATISTICS = 'STOP_FETCHING_STATISTICS'
export const FETCH_STATISTICS_SUCCEEDED = 'FETCH_STATISTICS_SUCCEEDED'
export const FETCH_STATISTICS_FAILED = 'FETCH_STATISTICS_FAILED'

export interface ProductsStatistics {
  coreProducts: number;
  coreProductsNoKoP: number;
  legacyProducts: number;
  legacyProductsBySubsidiary: { subsidiary: string; legacyProductsCount: number }[];
  duplicatedProductsBySubsidiary: { subsidiary: string; count: number }[];
  globalIdMappingIndex: number;
}

export interface FailuresStatistics {
  failures: number;
  failuresByType: { failureType: string; failuresCount: number }[];
  failuresByErrorType: { errorType: string; count: number }[];
}

export interface StatisticsState {
  subsidiaries: Subsidiary[];
  productsStatistics: ProductsStatistics;
  failuresStatistics: FailuresStatistics;
  importerFailuresCount: number;
  updateTime: number;
  loading: boolean;
  error: string;
}

export interface StartFetchingStatisticsAction {
  type: typeof START_FETCHING_STATISTICS;
  subsidiaries: Subsidiary[];
  excludeArchivedProducts: boolean;
}

export interface StopFetchingStatisticsAction {
  type: typeof STOP_FETCHING_STATISTICS;
}

export interface FetchStatisticsSucceededAction {
  type: typeof FETCH_STATISTICS_SUCCEEDED;
  productsStatistics: ProductsStatistics;
  failuresStatistics: FailuresStatistics;
  importerFailuresCount: number;
}

export interface FetchStatisticsFailedAction {
  type: typeof FETCH_STATISTICS_FAILED;
  error: string;
}

export type StatisticsActionTypes = StartFetchingStatisticsAction | StopFetchingStatisticsAction | FetchStatisticsSucceededAction | FetchStatisticsFailedAction;

