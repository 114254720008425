import * as React from "react";
import "./PermissionDetailsView.css"
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import { Alert, Button, Descriptions, Divider, Form, Input, Space, Switch, Tag, Typography } from 'antd';
import { Permission } from "../../store/permissionsManagement/types";

const { Text } = Typography;
const { TextArea } = Input;

export interface Props {
  permission: Permission;
  onPermissionCreate: (description: string, operation: string, domain: string, domainId: string, active: boolean) => void;
  onPermissionUpdate: (id: number, description: string, operation: string, domain: string, domainId: string, active: boolean) => void;
  loading: boolean;
  error: string;
}

export const PermissionDetailsView: React.FunctionComponent<Props> = (props) => {

  const { permission, onPermissionCreate, onPermissionUpdate, loading, error } = props;

  const [form] = Form.useForm();

  const formItemLayout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 10 },
  };

  const buttonItemLayout = {
    wrapperCol: { span: 10, offset: 3 },
  };

  const statusTag = (isActive: boolean) => {
    return (isActive ? (<Tag color="success">ACTIVE</Tag>) : (<Tag color="error">DISABLED</Tag>));
  };

  React.useEffect(() => {

    if (!!permission) {

      form.setFieldsValue({
        "description": permission.description,
        "operation": permission.operation,
        "domain": permission.domain,
        "domainId": permission.domainId,
        "active": permission.active
      })
    } else {

      form.setFieldsValue({
        "description": null,
        "operation": null,
        "domain": null,
        "domainId": null,
        "active": false
      })
    }

  }, [!!permission ? permission.id : 0]);

  const onSubmit = () => {

    const description = form.getFieldValue("description");
    const operation = form.getFieldValue("operation");
    const domain = form.getFieldValue("domain");
    const domainId = form.getFieldValue("domainId");
    const isActive = form.getFieldValue("active");

    if (!!permission) {
      onPermissionUpdate(permission.id, description, operation, domain, domainId, isActive);
    } else {
      onPermissionCreate(description, operation, domain, domainId, isActive);
    }
  };

  return <React.Fragment>

    {
      loading && (<div>
        <div className="view-loading"/>
        <CircularProgress className="view-loading-spinner"/>
      </div>)
    }

    {
      error && (<React.Fragment>
        <Alert
          message="Error"
          description={error}
          type="error"
        />
        <br/>
      </React.Fragment>)
    }

    {
      !!permission &&
      <Descriptions size="small" column={2} bordered>
          <Descriptions.Item label="ID">
              <a>{permission.id}</a>
          </Descriptions.Item>
          <Descriptions.Item label="Status">
              <Space>
                {statusTag(permission.active)}
              </Space>
          </Descriptions.Item>
          <Descriptions.Item label="Authority"><Text code>{permission.operation + "." + permission.domain + (!!permission.domainId ? ("." + permission.domainId) : "")}</Text>
          </Descriptions.Item>
          <Descriptions.Item label="Description">{permission.description}
          </Descriptions.Item>
          <Descriptions.Item
              label="Created">{new Date(permission.createdAt).toLocaleString()}
          </Descriptions.Item>
          <Descriptions.Item
              label="Created By">{permission.createdByFullName}
          </Descriptions.Item>
          <Descriptions.Item
              label="Modified">{new Date(permission.updatedAt).toLocaleString()}
          </Descriptions.Item>
          <Descriptions.Item
              label="Modified By">{permission.updatedByFullName}
          </Descriptions.Item>
      </Descriptions>
    }
    {
      !!permission && <React.Fragment><br/><Divider orientation={"left"}>
          Edit Permission
      </Divider><br/></React.Fragment>
    }
    <Form
      {...formItemLayout}
      layout={'horizontal'}
      form={form}
      onFinish={onSubmit}
    >

      <Form.Item label="Operation"
                 name={"operation"}
                 rules={[{ required: true, message: 'Please enter allowed operation (i.e. CREATE, UPDATE, READ, ...)' }]}>
        <Input
          placeholder="Enter allowed operation..."
        />
      </Form.Item>

      <Form.Item label="Domain"
                 name={"domain"}
                 rules={[{ required: true, message: 'Please enter allowed domain (i.e. PRODUCT, USER, GRANT, ...)' }]}>
        <Input
          placeholder="Enter allowed domain..."
        />
      </Form.Item>

      <Form.Item label="Domain ID"
                 name={"domainId"}
                 rules={[]}>
        <Input
          placeholder="Enter allowed domain id (i.e. specific product ID, user ID, ...)"
        />
      </Form.Item>

      <Form.Item label="Description"
                 name={"description"}
                 rules={[{ required: true, message: 'Please enter description' }]}>
        <TextArea
          rows={4}
          placeholder="Enter description..."
        />
      </Form.Item>

      <Form.Item label="Active"
                 name={"active"}
                 valuePropName="checked">
        <Switch />
      </Form.Item>

      <Form.Item {...buttonItemLayout}>
        <Button type="primary" htmlType="submit">
          {!!permission ? "Update" : "Create"}
        </Button>
      </Form.Item>
    </Form>

  </React.Fragment>
};

export default PermissionDetailsView;
