import * as React from "react";
import {RouteComponentProps, withRouter} from "react-router-dom";
import "./AddKindOfProductPage.css";
import {Dispatch} from "redux";
import {AppState, store} from "../../store";
import {flowRight} from "lodash";
import {connect} from 'react-redux';
import {UserSession} from "../../store/userSession/types";
import PageWrapper from "../../components/Drawer/PageWrapper";
import {PageHeader} from "antd";
import {closeErrorMessage} from "../../store/globalProducts/actions";
import AddKindOfProduct from "../../components/AddKindOfProduct/AddKindOfProduct";
import {AddKindOfItemRequest, AddKindOfItemResponse} from "../../store/kindOf/types";
import {addKindOfItem} from "../../store/kindOf/actions";
import {Store} from 'rc-field-form/lib/interface';
import EnhancedTable, {Column} from "../../components/EnhancedTable/EnhancedTable";

interface Props extends RouteComponentProps {
    userSession: UserSession;
    addKindOfRequest: AddKindOfItemRequest;
    addKindOfResponse: AddKindOfItemResponse;
    addKindOfItem: (addKindOfItemRequest: AddKindOfItemRequest) => AddKindOfItemResponse;
    loading?: boolean;
    error?: string;
}

interface State {
    items: AddKindOfItemResponse[]
}

class AddKindOfProductPage extends React.Component<Props, State> {

    columns: Column[] = [
        {
            label: 'Code',
            field: 'code',
            type: 'computed',
            computedValue: (item: AddKindOfItemResponse) => {
                return (<a onClick={() => this.onRowClick(item.code)}>{item.code}</a>)
            }
        },
        {
            label: 'Name',
            field: 'name',
            type: 'computed',
            computedValue: (item: AddKindOfItemResponse) => item.name
        },
        {
            label: 'Description',
            field: 'description',
            type: 'computed',
            computedValue: (item: AddKindOfItemResponse) => item.description
        }
    ];

    constructor(props: Props) {
        super(props);



        this.state = {
            items: []
        }

        AddKindOfProductPage.onMessageClose = AddKindOfProductPage.onMessageClose.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onRowClick = this.onRowClick.bind(this);
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (this.props.addKindOfResponse?.code && this.props.addKindOfResponse?.code !== prevProps.addKindOfResponse?.code) {
            this.setState({items: [...this.state.items, this.props.addKindOfResponse]})
        }
    }

    public render() {
        return (
            <div className="add-kind-of-page">
                <PageWrapper userSession={this.props.userSession}>
                    <PageHeader
                        backIcon={null}
                        ghost={false}
                        onBack={() => window.history.back()}
                        title="Add Kind Of Product"
                        subTitle="Creates a new 'Kind Of Product' item">
                    </PageHeader>
                    <AddKindOfProduct
                        onMessageClose={AddKindOfProductPage.onMessageClose}
                        onSubmit={this.onSubmit}
                        error={this.props.error}
                        loading={this.props.loading}/>

                    <EnhancedTable tableName={"Added items"}
                                   rowsSelectable={false}
                                   pagination={false}
                                   columns={this.columns}
                                   data={this.state.items}
                                   totalCount={this.state.items.length}
                                   countOnPage={this.state.items.length}
                                   currentPage={0}
                                   pageSize={this.state.items.length}
                                   sort={[{field: "id", direction: "ASC"}]}>
                        {{actionButtons: null}}
                    </EnhancedTable>
                </PageWrapper>
            </div>
        )
    }

    private static onMessageClose() {
        store.dispatch(closeErrorMessage());
    }

    private onSubmit(kindOf: Store) {
        this.props.addKindOfItem({
            code: kindOf.code,
            typographies: kindOf.typographies
        });
    }

    private onRowClick(id: string) {
        this.props.history.push("/app/kind-of-product/" + id);
    }
}

const mapStateToProps = (state: AppState) => ({
    userSession: state.userSession.userSession,
    addKindOfRequest: state.kindOf.addKindOfItemRequest,
    addKindOfResponse: state.kindOf.addKindOfItemResponse,
    loading: state.kindOf.loading,
    error: state.kindOf.error
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    addKindOfItem: (addKindOfItemRequest: AddKindOfItemRequest) => {
        dispatch(addKindOfItem(addKindOfItemRequest))
    }
});

const enhance = flowRight(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(AddKindOfProductPage);
