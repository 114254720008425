import * as React from "react";
import "./DuplicatedProductsView.css"
import EnhancedTable, { Column, Order } from "../EnhancedTable/EnhancedTable";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import {
  DateRange,
  DuplicatedProduct,
  FetchDuplicatedProductsPageRequest,
  FetchDuplicatedProductsPageResponse
} from "../../store/globalProducts/types";
import {Button, Checkbox, DatePicker, Form, Input, Space, Tooltip} from "antd";
import SubsidiarySelect from "../inputs/SubsidiarySelect/SubsidiarySelect";
import SourceSystemSelect from "../inputs/SourceSystemSelect/SourceSystemSelect";
import { ExportOutlined } from "@ant-design/icons/lib";
import {RangePickerProps} from "antd/es/date-picker";
import moment from 'moment';


export interface GlobalProductsViewProps {
  pageResponse: FetchDuplicatedProductsPageResponse;
  pageRequest: FetchDuplicatedProductsPageRequest;

  onPaginationChanged: (page: number, size: number) => void;
  onSortingChanged?: (orderBy: string, order: Order) => void;
  onFilterChanged?: (filterField: string, value: string | boolean | any) => void;

  onGenerateReport?: () => void;
  generateReportLoading?: boolean;

  onRowClick?: (id: number, newTab?: boolean) => void;
  loading: boolean;
  error: string;
}

export const DuplicatedProductsView: React.FunctionComponent<GlobalProductsViewProps> = (props) => {

  const [form] = Form.useForm();

  const formItemLayout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 8 },
  };

  const buttonItemLayout = {
    wrapperCol: { span: 8, offset: 2 },
  };

  const { pageRequest, pageResponse, onPaginationChanged, onSortingChanged, onFilterChanged, onGenerateReport, generateReportLoading, loading, onRowClick } = props;

  const columns: Column[] = [
    {
      label: 'Global Product Id',
      field: 'globalId',
      type: 'computed',
      computedValue: (product: DuplicatedProduct) => {
        return (<Space direction={"horizontal"}>
          <a onClick={event => onRowClick(product.globalId)}>{product.globalId}</a>
          <Button type={"link"} icon={<ExportOutlined />} onClick={() => onRowClick(product.globalId, true)}/>
        </Space>)
      },
    },
    {
      label: 'Duplicated Products',
      field: 'productIds',
      type: 'computed',
      computedValue: (product: DuplicatedProduct) => {
        return (product.productIds.join(', '))
      }
    },
    {
      label: 'Subsidiary',
      field: 'subsidiary',
      type: 'computed',
      computedValue: (product: DuplicatedProduct) => {
        return (product.subsidiary)
      }
    },
    {
      label: 'Source System',
      field: 'sourceSystem',
      type: 'computed',
      computedValue: (product: DuplicatedProduct) => {
        return (product.sourceSystem)
      }
    },
    { label: 'Duplicates Count', field: 'productsCount' }
  ];

  const handleDuplicatesCountChange = (e: any) => {
    const { value } = e.target;
    const reg = /^\d*(\.\d*)?$/;
    if ((!isNaN(value) && reg.test(value)) || value === '') {
      onFilterChanged('duplicatesCount', value)
    }
  };

  return props.error ?
    (
      <div className="view-error">{props.error}</div>
    )
    :
    (
      <React.Fragment>

        {
          loading && (<div>
            <div className="view-loading"/>
            <CircularProgress className="view-loading-spinner"/>
          </div>)
        }

        <Form
          {...formItemLayout}
          layout={'horizontal'}
          form={form}
        >

          <Form.Item label="Product Id">
            <Input id="product-id-input"
                   placeholder="Search by product id"
                   value={pageRequest.productId}
                   onChange={(value) => onFilterChanged('productId', value.target.value)}
            />
          </Form.Item>

          <Form.Item label="Subsidiary">
            <SubsidiarySelect id="subsidiary-select"
                              value={pageRequest.subsidiary}
                              onChange={(value) => onFilterChanged('subsidiary', value)}
            />
          </Form.Item>


          <Form.Item label="Source system">
            <SourceSystemSelect id="source-system-select"
                                notSelectedOption={true}
                                allEnabled={true}
                                value={pageRequest.sourceSystem}
                                onChange={(value) => onFilterChanged('sourceSystem', value)}/>
          </Form.Item>

          <Form.Item label="Duplicates count">
            <Input value={pageRequest.duplicatesCount}
                   onChange={handleDuplicatesCountChange}
                   placeholder="Input a number"
                   maxLength={2}
            />
          </Form.Item>

          <Form.Item label="Primary Assigned">
            <Checkbox
                indeterminate={pageRequest.anyPrimaryAssigned === null}
                onClick={(event) => onFilterChanged('anyPrimaryAssigned', pageRequest.anyPrimaryAssigned === null ? true : (pageRequest.anyPrimaryAssigned === true ? false : null))}
                checked={pageRequest.anyPrimaryAssigned !== null && pageRequest.anyPrimaryAssigned === true}
            />
          </Form.Item>

          <Form.Item label="Created At">
            <DatePicker.RangePicker
              showTime={{ format: 'HH' }}
              format={"DD-MM-YYYY HH:mm:ss"}
              onOk={(value: RangePickerProps['value']) => {}}
              onChange={(value: RangePickerProps['value']) => {onFilterChanged('createdAtDateRange', convertDateRange(value))}}
              value={convertDateToMoment(pageRequest.createdAtDateRange)}
            />
          </Form.Item>

          <Form.Item label="Updated At">
            <DatePicker.RangePicker
                showTime={{ format: 'HH' }}
                format={"DD-MM-YYYY HH:mm:ss"}
                onOk={(value: RangePickerProps['value']) => {}}
                onChange={(value: RangePickerProps['value']) => {onFilterChanged('updatedAtDateRange', convertDateRange(value))}}
                value={convertDateToMoment(pageRequest.updatedAtDateRange)}
            />
          </Form.Item>

          <Form.Item {...buttonItemLayout}>
            <Tooltip title="Limited to 25000 items">
              <Button type="primary" loading={generateReportLoading} size={"middle"} onClick={onGenerateReport}>Generate
                Report
              </Button>
            </Tooltip>
          </Form.Item>
        </Form>

        <EnhancedTable tableName={"Results"}
                       rowsSelectable={false}
                       columns={columns}
                       data={pageResponse.content}
                       totalCount={pageResponse.totalElements}
                       countOnPage={pageResponse.numberOfElements}
                       currentPage={pageRequest.page}
                       pageSize={pageRequest.size}
                       sort={[pageRequest.sort]}
                       onPaginationChanged={onPaginationChanged}
                       onSortingChanged={onSortingChanged}
        />
      </React.Fragment>
    );
};

const convertDateRange = (dateRange: any) : DateRange => {
  if (dateRange != null){
    const startDate = dateRange[0] != null ? new Date(dateRange[0].milliseconds(0)) : null;
    const endDate = dateRange[1] != null ? new Date(dateRange[1].milliseconds(0)) : null;
    const result : DateRange = {
      startDate: startDate,
      endDate: endDate
    }
    return result;
  }
  return null;
}

const convertDateToMoment = (dateRange : DateRange) : any => {
  if (dateRange == null) {
    return null;
  }
  const momentRange = [];
  momentRange[0] = moment(dateRange.startDate);
  momentRange[1] = moment(dateRange.endDate);
  return momentRange;
}

export default DuplicatedProductsView;
