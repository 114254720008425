import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import "./PermissionPage.css";
import { Dispatch } from "redux";
import { AppState, store } from "../../store";
import {flowRight} from "lodash";
import { connect } from 'react-redux';
import { UserSession } from "../../store/userSession/types";
import PageWrapper from "../../components/Drawer/PageWrapper";
import { PageHeader } from 'antd';
import { Permission } from "../../store/permissionsManagement/types";
import {
  createPermission,
  fetchPermission,
  fetchPermissionSucceeded,
  updatePermission
} from "../../store/permissionsManagement/actions";
import PermissionDetailsView from "../../components/PermissionDetails/PermissionDetailsView";

interface Props extends RouteComponentProps<{id: string}> {
  userSession: UserSession;
  permissionId: number;
  permissionDetails: Permission;
  fetchPermissionDetails: (id: number) => void;
  createPermission: (description: string, operation: string, domain: string, domainId: string, active: boolean) => void;
  updatePermission: (id: number, description: string, operation: string, domain: string, domainId: string, active: boolean) => void;
  loading?: boolean;
  error?: string;
}

interface State {
}

class PermissionPage extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.onPermissionCreate = this.onPermissionCreate.bind(this);
    this.onPermissionUpdate = this.onPermissionUpdate.bind(this);
  }

  componentDidMount() {

    if (this.props.match.params.id) {
      this.props.fetchPermissionDetails(parseInt(this.props.match.params.id))
    } else {
      store.dispatch(fetchPermissionSucceeded({
        permission: null
      }));
    }
  }

  private onPermissionCreate(description: string, operation: string, domain: string, domainId: string, active: boolean) {
    this.props.createPermission(description, operation, domain, domainId, active);
  }

  private onPermissionUpdate(id: number, description: string, operation: string, domain: string, domainId: string, active: boolean) {
    this.props.updatePermission(id, description, operation, domain, domainId, active);
  }

  public render() {

    return (
      <div className="permission-page">
        <PageWrapper userSession={this.props.userSession}>
          <PageHeader
            ghost={false}
            onBack={() => window.history.back()}
            title="Permission Details"
            subTitle={"General information about permission"}
          >
          </PageHeader>
          <PermissionDetailsView
            permission={this.props.permissionDetails}
            onPermissionCreate={this.onPermissionCreate}
            onPermissionUpdate={this.onPermissionUpdate}
            loading={this.props.loading}
            error={this.props.error}
          />
        </PageWrapper>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  userSession: state.userSession.userSession,

  permissionId: state.permissionsManagement.permissionId,
  permissionDetails: state.permissionsManagement.permissionDetails,

  loading: state.permissionsManagement.permissionDetailsLoading,
  error: state.permissionsManagement.permissionDetailsError
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchPermissionDetails: (id: number) => {
    dispatch(fetchPermission({id}))
  },
  updatePermission: (id: number, description: string, operation: string, domain: string, domainId: string, active: boolean) => {
    dispatch(updatePermission({
      id,
      description,
      operation,
      domain,
      domainId,
      active
    }))
  },
  createPermission: (description: string, operation: string, domain: string, domainId: string, active: boolean) => {
    dispatch(createPermission({
      description,
      operation,
      domain,
      domainId,
      active
    }))
  }
});

const enhance = flowRight(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(PermissionPage);
