import { UserSession } from "../../store/userSession/types";
import * as React from "react";
import { ReactNode } from "react";
import "./PageWrapper.css"
import { Layout, Menu } from 'antd';
import { BarcodeOutlined, HomeOutlined, ImportOutlined, SettingOutlined, ToolOutlined } from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import {
  BookOutlined,
  ClockCircleOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  QuestionCircleOutlined,
  ContainerOutlined
} from "@ant-design/icons/lib";
import UserIcon from "../UserIcon/UserIcon";

const { SubMenu } = Menu;

interface Props {
  userSession: UserSession;
  children: ReactNode;
}

const { Header, Sider, Content } = Layout;

const mainMenuItems: any[] = [
  {
    key: "1",
    name: "Home",
    url: "/",
    icon: <HomeOutlined/>
  },
  {
    key: "2",
    name: "Products",
    icon: <BarcodeOutlined/>,
    subItems: [
      {
        key: "2-1",
        name: "Add",
        url: "/app/products/add"
      },
      {
        key: "2-2",
        name: "List",
        url: "/app/products/list"
      },
      {
        key: "2-3",
        name: "Duplicates",
        url: "/app/products/duplicates"
      },
      {
        key: "2-4",
        name: "MPN statistics",
        url: "/app/products/manufacturer-mpn-stats"
      },
      {
        key: "2-5",
        name: "AvoidanceFix Disregards",
        url: "/app/products/avoidanceFixDisregard"
      }
    ]
  },
  {
    key: "6",
    name: "Tasks",
    icon: <ClockCircleOutlined/>,
    subItems: [
      {
        key: "6-1",
        name: "Add",
        url: "/app/tasks/add"
      },
      {
        key: "6-2",
        name: "List",
        url: "/app/tasks/list"
      }
    ]
  },
  {
    key: "7",
    name: "Classifications",
    icon: <BookOutlined/>,
    subItems: [
      {
        key: "7-1",
        name: "Import Product Groups",
        url: "/app/classification/productGroups/add"
      },
      {
        key: "7-2",
        name: "View Product Groups",
        url: "/app/classification/productGroups/list"
      }
    ]
  },
  {
    key: "8",
    name: "Maintenance",
    icon: <ToolOutlined/>,
    subItems: [
      {
        key: "8-1",
        name: "Flat Id",
        url: "/app/maintenance/flat-id"
      }
    ]
  },
  {
    key: "3",
    name: "Imports",
    url: "/app/product-imports",
    icon: <ImportOutlined/>
  },
  {
    key: "5",
    name: "Failures",
    url: "/app/failures",
    icon: <QuestionCircleOutlined/>
  },
  {
    key: "9",
    name: "Kind Of Product",
    icon: <ContainerOutlined/>,
    subItems: [
      {
        key: "9-1",
        name: "View Kind Of Product",
        url: "/app/kind-of-product/list"
      },
      {
        key: "9-2",
        name: "Add Kind Of Product",
        url: "/app/kind-of-product/add"
      },

      {
        key: "9-3",
        name: "Map Kind Of Product",
        url: "/app/kind-of-product/map/kop-to-mv"
      },
      {
        key: "9-4",
        name: "Map Mapped Values",
        url: "/app/kind-of-product/map/mv-to-kop"
      },
      {
        key: "9-5",
        name: "Deprecated KOPs",
        url: "/app/kind-of-product/list/deprecated-kop"
      },
      {
        key: "9-6",
        name: "Recalculate KOPs",
        url: "/app/kind-of-product/list/recalculation"
      }
    ]
  }
];

const secondaryMenuItems: any[] = [
  {
    key: "4",
    name: "Settings",
    icon: <SettingOutlined/>,
    subItems: [
      {
        key: "4-1",
        name: "Permissions",
        url: "/app/settings/permissions-management"
      }
    ]
  }
];

export default function PageWrapper(props: Props) {

  const [collapsed, setCollapsed] = React.useState(false);

  let location = useLocation();
  const mainItem = mainMenuItems.filter(item => {
    if (item.url === location.pathname) {
      return true;
    } else {
      const subItemFound = item.subItems && item.subItems.filter((subItem: any) => subItem.url === location.pathname)[0];
      return !!subItemFound;
    }
  })[0];

  const subItemFound = mainMenuItems.reduce((acc: [], item) => acc.concat(item.subItems || []), []).filter((subItem: any) => subItem.url === location.pathname)[0];

  const secondaryItem = secondaryMenuItems.filter(item => item.url === location.pathname)[0];
  const key = mainItem && mainItem.key || secondaryItem && secondaryItem.key;
  const subKey = subItemFound && subItemFound.key;

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  return (

    <Layout style={{
      height: '100vh'
    }}>
      <Sider trigger={null} collapsible collapsed={collapsed} onCollapse={toggle}>
        <div className="logo"/>
        <Menu theme="dark" mode="inline" selectedKeys={[key, subKey]} defaultOpenKeys={["2", "4", "8", "9"]}>
          {
            mainMenuItems.map((item) => {
              return item.subItems
                ?
                (
                    <SubMenu key={item.key} title={item.name} icon={item.icon}>
                      {item.subItems.map((subItem: any) => {
                        return (
                          <Menu.Item key={subItem.key}>
                            <Link to={subItem.url}>
                              <span>{subItem.name}</span>
                            </Link>
                          </Menu.Item>
                        )
                      })}
                    </SubMenu>
                )
                :
                (
                  <Menu.Item key={item.key}>
                    <Link to={item.url}>
                      {item.icon}
                      <span>{item.name}</span>
                    </Link>
                  </Menu.Item>
                )
            })
          }
          <Menu.Divider />
          {
            secondaryMenuItems.map((item) => {
              return item.subItems
                ?
                (
                  <SubMenu key={item.key} title={item.name} icon={item.icon}>
                    {item.subItems.map((subItem: any) => {
                      return (
                        <Menu.Item key={subItem.key}>
                          <Link to={subItem.url}>
                            <span>{subItem.name}</span>
                          </Link>
                        </Menu.Item>
                      )
                    })}
                  </SubMenu>
                )
                :
                (
                  <Menu.Item key={item.key}>
                    <Link to={item.url}>
                      {item.icon}
                      <span>{item.name}</span>
                    </Link>
                  </Menu.Item>
                )
            })
          }
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ padding: 0 }}>
          {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: 'trigger',
            onClick: toggle,
          })}
          <div style={{ display: 'inline-flex', fontSize: '18px', color: 'rgba(0, 0, 0, 0.85)' }}><strong>Core Product Control Panel</strong></div>
          <UserIcon style={{ float: 'right', paddingRight: '30px' }} userSession={props.userSession}/>
        </Header>
        <Content
          className="site-layout-background"
          style={{
            margin: '2px 2px',
            padding: 24,
            minHeight: 'unset',
            overflowY: 'auto'
          }}
        >
          {props.children}
        </Content>
      </Layout>
    </Layout>
  );
}
