import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import {
  FETCH_IMPORT_TASK_EXECUTION,
  FETCH_IMPORTS,
  FetchImportsAction,
  FetchImportTaskExecutionAction,
  ImportTaskExecution,
  RETRY_IMPORTS,
  RetryImportsAction
} from './types'
import {
  fetchImportTaskExecution,
  fetchImportTaskExecutionFailed,
  fetchImportTaskExecutionSucceeded,
  fetchProductImportsFailed,
  fetchProductImportsSucceeded,
  retryProductImportsFailed,
  retryProductImportsSucceeded
} from './actions'

import dataService from '../../services/DataService'
import { message } from "antd";
import { AppState } from "../index";

export function* productImportsSaga() {
  yield all([
    watchFetchImports(),
    watchFetchImportTaskExecution(),
  ])
}

function* watchFetchImports() {
  yield takeLatest(FETCH_IMPORTS, handleFetchImports)
}

function* watchFetchImportTaskExecution() {
  yield takeLatest(FETCH_IMPORT_TASK_EXECUTION, handleFetchImportTaskExecution)
}

function* handleFetchImports(action: FetchImportsAction) {
  try {
    const data = yield call([dataService, dataService.getProductImports], action.pageRequest);
    yield put(fetchProductImportsSucceeded(data))
  } catch (error) {
    message.error(error.message);
    yield put(fetchProductImportsFailed(`${error.message}`))
  }
}

function* handleFetchImportTaskExecution(action: FetchImportTaskExecutionAction) {
  try {
    const data = yield call([dataService, dataService.fetchImportTaskExecution], action.id);
    yield put(fetchImportTaskExecutionSucceeded(data))
  } catch (error) {
    message.error(error.message);
    yield put(fetchImportTaskExecutionFailed(`${error.message}`))
  }
}

export function* retryImportsSaga() {
  yield all([
    watchRetryImports()
  ])
}

function* watchRetryImports() {
  yield takeLatest(RETRY_IMPORTS, handleRetryImports)
}

function* handleRetryImports(action: RetryImportsAction) {
  try {
    const data = yield call([dataService, dataService.retryProductsImport], action.retryRequest);

    message.success("Reimport was triggered successfully");
    yield put(retryProductImportsSucceeded())

    const importTaskExecution: ImportTaskExecution = yield select((state: AppState) => state.productImports.importTaskExecution);
    if (!!importTaskExecution) {
      yield put(fetchImportTaskExecution(importTaskExecution.id));
    }
  } catch (error) {
    message.error(error.message);
    yield put(retryProductImportsFailed(`${error.message}`))
  }
}
