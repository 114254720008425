import * as React from "react";
import "./ProductImportsView.css"
import {
  FetchImportTaskExecutionPageRequest,
  FetchImportTaskExecutionPageResponse,
  ImportTaskExecution
} from "../../store/productImports/types";
import EnhancedTable, { Column, Order } from "../EnhancedTable/EnhancedTable";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { Button, Form, Select, Typography } from 'antd';

const { Text } = Typography;
const { Option } = Select;

export interface ProductImportsViewProps {
  pageResponse: FetchImportTaskExecutionPageResponse;
  pageRequest: FetchImportTaskExecutionPageRequest;

  onPaginationChanged: (page: number, size: number) => void;
  onSelectionChanged: (selectedIds: string[]) => void;
  onSortingChanged?: (orderBy: string, order: Order) => void;
  onFilterChanged?: (filterField: string, value: string) => void;
  onRowClick?: (id: string) => void;

  onRetry?: () => void;
  retryLoading: boolean;

  loading: boolean;
  error: string;
}

const useImportStatusColumnStyles = makeStyles((theme: Theme) => createStyles({
  failed: {
    color: 'red'
  },
  succeeded: {
    color: 'green'
  },
  running: {
    color: 'orange'
  },
}));

export const ProductImportsView: React.FunctionComponent<ProductImportsViewProps> = (props) => {

  const [form] = Form.useForm();

  const { pageRequest, pageResponse, onPaginationChanged, onSelectionChanged, onSortingChanged, onRowClick, onFilterChanged, onRetry, loading } = props;

  const importStatusColumnClasses = useImportStatusColumnStyles();
  const columns: Column[] = [
    {
      label: '',
      field: '',
      type: 'computed',
      computedValue: (info: ImportTaskExecution) => {

        return (<a onClick={() => onRowClick(info.id)}>Details</a>)
      }
    },
    {
      label: 'Source System',
      field: 'sourceSystem',
      type: 'computed',
      computedValue: (info: ImportTaskExecution) => {
        return (<Text code>{info.sourceSystem}</Text>)
      }
    },
    {
      label: 'Subsidiary',
      field: 'subsidiary',
      type: 'computed',
      computedValue: (info: ImportTaskExecution) => {
        return (<Text code>{info.subsidiary}</Text>)
      }
    },
    {
      label: 'From Date',
      field: 'fromDate',
      type: 'computed',
      sortable: false,
      computedValue: (info: ImportTaskExecution) => {
        return (<a>{new Date(info.fromDate).toLocaleString()}</a>)
      }
    },
    {
      label: 'To Date',
      field: 'toDate',
      type: 'computed',
      sortable: false,
      computedValue: (info: ImportTaskExecution) => {
        return (!!info.toDate && <a>{new Date(info.toDate).toLocaleString()}</a>)
      }
    },
    {
      label: 'Import Date',
      field: 'importStartedAt',
      type: 'computed',
      computedValue: (info: ImportTaskExecution) => {
        return (<a>{new Date(info.importStartedAt).toLocaleString()}</a>)
      }
    },
    { label: 'Imported Count', field: 'importedCount' },
    {
      label: 'Status',
      field: 'importStatus',
      className: (info: ImportTaskExecution) => {

        return clsx({
          [importStatusColumnClasses.failed]: info.importStatus === 'FAILED',
          [importStatusColumnClasses.succeeded]: info.importStatus === 'SUCCEEDED',
          [importStatusColumnClasses.running]: info.importStatus === 'RUNNING',
        })

      }
    }
  ];

  const handleImportStatusFilterChange = (value: string) => {
    onFilterChanged && onFilterChanged('importStatus', value);
  };

  const formItemLayout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 8 },
  };

  const buttonItemLayout = {
    wrapperCol: { span: 8, offset: 2 },
  };

  return props.error ?
    (
      <div className="view-error">{props.error}</div>
    )
    :
    (
      <React.Fragment>

        {
          loading && (<div>
            <div className="view-loading"/>
            <CircularProgress className="view-loading-spinner"/>
          </div>)
        }

        <Form
          {...formItemLayout}
          layout={'horizontal'}
          form={form}
        >
          <Form.Item label="Import status">
            <Select id="import-status-select"
                    value={pageRequest.importStatus}
                    onChange={handleImportStatusFilterChange}>

              <Option value="">None</Option>
              <Option value="RUNNING">Running</Option>
              <Option value="SUCCEEDED">Succeeded</Option>
              <Option value="FAILED">Failed</Option>
            </Select>
          </Form.Item>
          {/*<Form.Item {...buttonItemLayout}>*/}
          {/*  <Button type="primary">Submit</Button>*/}
          {/*</Form.Item>*/}
        </Form>

        <EnhancedTable tableName={"Results"}
                       rowsSelectable={true}
                       columns={columns}
                       data={pageResponse.content}
                       totalCount={pageResponse.totalElements}
                       countOnPage={pageResponse.numberOfElements}
                       currentPage={pageRequest.page}
                       pageSize={pageRequest.size}
                       sort={[pageRequest.sort]}
                       onPaginationChanged={onPaginationChanged}
                       onSelectionChanged={onSelectionChanged}
                       onSortingChanged={onSortingChanged}
                       onRowClick={onRowClick}
        >
          {{
            actionButtons: <Button type="primary" loading={props.retryLoading} size={"large"} onClick={onRetry}>Retry</Button>
          }}
        </EnhancedTable>
      </React.Fragment>
    );
};

export default ProductImportsView;
