import {Reducer} from "redux";
import {
    APPLY_KIND_OF_PRODUCT_RECALCULATION_SUCCEEDED,
    CREATE_KIND_OF_PRODUCT_RECALCULATION_SUCCEEDED,
    FETCH_KIND_OF_PRODUCT_RECALCULATIONS,
    FETCH_KIND_OF_PRODUCT_RECALCULATIONS_FAILED,
    FETCH_KIND_OF_PRODUCT_RECALCULATIONS_SUCCEEDED,
    KindOfProductRecalculationsActionTypes,
    KindOfProductRecalculationState, REJECT_KIND_OF_PRODUCT_RECALCULATION_SUCCEEDED,
    UPDATE_KIND_OF_PRODUCT_RECALCULATION_REPORT_SUCCEEDED
} from "./types";
import {pageResponse} from "../common/types";

const initialState: KindOfProductRecalculationState = {
    pageRequest: {
        page: 0,
        size: 10,
        sort: {
            field: "id",
            direction: 'DESC'
        },
    },
    pageResponse: {
        ...pageResponse(),
        content: []
    },
    loading: false,
    error: null
};

export const kindOfProductRecalculationReducer: Reducer<KindOfProductRecalculationState, KindOfProductRecalculationsActionTypes> = (state: KindOfProductRecalculationState = initialState, action: KindOfProductRecalculationsActionTypes): KindOfProductRecalculationState => {
    switch (action.type) {

        case FETCH_KIND_OF_PRODUCT_RECALCULATIONS: {
            return {
                ...state,
                pageRequest: {
                    ...action.pageRequest
                },
                loading: true,
                error: null
            }
        }

        case FETCH_KIND_OF_PRODUCT_RECALCULATIONS_SUCCEEDED: {
            return {
                ...state,
                pageResponse: action.payload,
                loading: false,
                error: null
            }
        }

        case FETCH_KIND_OF_PRODUCT_RECALCULATIONS_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.error
            }
        }

        case CREATE_KIND_OF_PRODUCT_RECALCULATION_SUCCEEDED: {
            state.globalProductIDs = ""; // TODO: clear input field after request succeeds

            if ('DESC' === state.pageRequest.sort.direction) {
                state.pageResponse.content.unshift(action.payload);
            } else {
                state.pageResponse.content.push(action.payload);
            }

            state.pageResponse.totalElements++; // total count of elements
            state.pageResponse.numberOfElements++; // total count of elements on current page

            return state;
        }

        case UPDATE_KIND_OF_PRODUCT_RECALCULATION_REPORT_SUCCEEDED:
        case APPLY_KIND_OF_PRODUCT_RECALCULATION_SUCCEEDED:
        case REJECT_KIND_OF_PRODUCT_RECALCULATION_SUCCEEDED:{
            const index = state.pageResponse.content.findIndex(value => value.id === action.payload.id);
            state.pageResponse.content[index] = action.payload;

            return state;
        }

        default:
            neverReached(action);
    }

    return state;
};


const neverReached = (never: never) => {
};
