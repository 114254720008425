import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import "./HomePage.css";
import { Dispatch } from "redux";
import { AppState } from "../../store";
import {flowRight} from "lodash";
import { connect } from 'react-redux';
import { UserSession } from "../../store/userSession/types";
import PageWrapper from "../../components/Drawer/PageWrapper";
import StatisticsView from "../../components/Statistics/StatisticsView";
import { FailuresStatistics, ProductsStatistics } from "../../store/statistics/types";
import { startFetchingStatistics, stopFetchingStatistics } from "../../store/statistics/actions";
import { Subsidiary } from "../../store/common/types";

interface Props extends RouteComponentProps {
  userSession: UserSession;

  subsidiaries: Subsidiary[];
  productsStatistics: ProductsStatistics;
  importsStatistics: FailuresStatistics;
  importerFailuresCount: number;

  startFetchingStatistics: (subsidiaries: Subsidiary[], excludeArchivedProducts: boolean) => void;
  stopFetchingStatistics: () => void;
  loading?: boolean;
  error?: string;
}

interface State {
  statisticsUpdateTime: number;
}

class HomePage extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    this.handleSubsidiariesChange = this.handleSubsidiariesChange.bind(this);
  }

  componentDidMount() {
    this.props.startFetchingStatistics([] as Subsidiary[], false)
  }

  componentWillUnmount() {
    this.props.stopFetchingStatistics()
  }

  private handleSubsidiariesChange(subsidiaries: Subsidiary[], excludeArchivedProducts: boolean) {
    this.props.startFetchingStatistics(subsidiaries, excludeArchivedProducts)
  }

  public render() {
    return (
      <div className="home-page">
        <PageWrapper userSession={this.props.userSession}>
          <StatisticsView
            subsidiaries={this.props.subsidiaries}
            productsStatistics={this.props.productsStatistics}
            failuresStatistics={this.props.importsStatistics}
            importerFailuresCount={this.props.importerFailuresCount}
            handleSubsidiariesChange={this.handleSubsidiariesChange}
            loading={this.props.loading}
            error={this.props.error}
          />
        </PageWrapper>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  userSession: state.userSession.userSession,

  subsidiaries: state.statistics.subsidiaries,
  productsStatistics: state.statistics.productsStatistics,
  importsStatistics: state.statistics.failuresStatistics,
  importerFailuresCount: state.statistics.importerFailuresCount,

  loading: state.statistics.loading,
  error: state.statistics.error
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  startFetchingStatistics: (subsidiaries: Subsidiary[], excludeArchivedProducts: boolean) => {
    dispatch(startFetchingStatistics(subsidiaries, excludeArchivedProducts))
  },
  stopFetchingStatistics: () => {
    dispatch(stopFetchingStatistics())
  }
});

const enhance = flowRight(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(HomePage);
