import * as React from "react";
import {RouteComponentProps, withRouter} from "react-router-dom";
import "./ViewProductGroupClassificationItemPage.css";
import {Dispatch} from "redux";
import {AppState} from "../../store";
import {flowRight} from "lodash";
import {connect} from 'react-redux';
import {UserSession} from "../../store/userSession/types";
import PageWrapper from "../../components/Drawer/PageWrapper";
import {PageHeader} from 'antd';
import {ProductGroupClassificationItem, ProductGroupLevelItemType} from "../../store/classifications/productGroup/types";
import {fetchProductGroupLevelItem} from "../../store/classifications/productGroup/actions";
import ProductGroupClassificationItemView
    from "../../components/ProductGroupClassificationItem/ProductGroupClassificationItemView";
import {LanguageType} from "../../store/common/types";

interface Props extends RouteComponentProps<{ id: string, level: string, language: string }> {
    userSession: UserSession;
    id: string;
    level: ProductGroupLevelItemType;
    language: LanguageType;
    productGroupClassificationItem: ProductGroupClassificationItem;
    fetchProductGroupClassificationItem: (id: string, level: string, language: string) => void;
    loading?: boolean;
    error?: string;
}

interface State {
}

class ViewProductGroupClassificationItemPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
    }

    componentDidMount() {
        this.props.fetchProductGroupClassificationItem(this.props.match.params.id, this.props.match.params.level, this.props.match.params.language)
    }

    public render() {

        return (
            <div className="view-productGroup-item-page">
                <PageWrapper userSession={this.props.userSession}>
                    <PageHeader
                        ghost={false}
                        onBack={() => window.history.back()}
                        title="Product Group classification item details"
                        subTitle={"Details of the Product Group classification item for the specified level and id"}
                    >
                    </PageHeader>
                    <ProductGroupClassificationItemView
                        id={this.props.id}
                        level={this.props.level}
                        language={this.props.language}
                        item={this.props.productGroupClassificationItem}
                        loading={this.props.loading}
                        error={this.props.error}
                    />
                </PageWrapper>
            </div>
        );
    }
}

const mapStateToProps = (state: AppState) => ({
    userSession: state.userSession.userSession,

    id: state.productGroupClassification.itemRequest.id,
    level: state.productGroupClassification.itemRequest.level,
    language: state.productGroupClassification.itemRequest.language,
    productGroupClassificationItem: state.productGroupClassification.itemResponse.item,

    loading: state.productGroupClassification.loading,
    error: state.productGroupClassification.error
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchProductGroupClassificationItem: (id: string, level: string, language: string) => {
        dispatch(fetchProductGroupLevelItem({
            id,
            level: level.toUpperCase() as ProductGroupLevelItemType,
            language: language as LanguageType
        }))
    }
});

const enhance = flowRight(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(ViewProductGroupClassificationItemPage);
