import * as React from 'react';
import { ReactNode } from 'react';
import clsx from 'clsx';
import { createStyles, lighten, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';

export type Order = 'ASC' | 'DESC';
export type Sort = { field: string, direction: Order }[];
export type ColumnType = 'string' | 'numeric' | 'datetime' | 'computed';

class EnhancedTableProps {
  tableName: string;
  rowsSelectable: boolean;

  pagination?: boolean;

  onRowClick?: (id: string) => void;
  onSortingChanged?: (orderBy: string, order: Order) => void;
  onSelectionChanged?: (selectedIds: string[]) => void;
  onPaginationChanged?: (page: number, size: number) => void;
  children?: { actionButtons?: ReactNode }

  sort: Sort;

  currentPage: number;
  pageSize: number;

  totalCount: number;
  countOnPage: number;

  data: any[];
  columns: Column[];
}

interface EnhancedTableHeadProps {
  classes: ReturnType<typeof useStyles>;

  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;

  sort: Sort;

  numSelected: number;
  countOnPage: number;
  columns: Column[];

  rowsSelectable: boolean;
}

export interface Column {
  field: string;
  label: string;
  type?: ColumnType;
  sortable?: boolean;
  className?: (row: any) => string;
  computedValue?: (row: any) => ReactNode;
}


function EnhancedTableHead(props: EnhancedTableHeadProps) {

  const { columns, numSelected, classes, onSelectAllClick, sort, countOnPage, onRequestSort, rowsSelectable } = props;

  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {rowsSelectable &&
        <TableCell padding="checkbox">
            <Checkbox
                indeterminate={numSelected > 0 && numSelected < countOnPage}
                checked={countOnPage > 0 && numSelected === countOnPage}
                onChange={onSelectAllClick}
                inputProps={{ 'aria-label': 'select all' }}
            />
        </TableCell>}
        {columns.map((headCell, index) => (
          <TableCell
            key={headCell.field}
            align={index == 0 && !rowsSelectable ? 'left' : 'right'}
            padding={'default'}
            sortDirection={(sort[0] && sort[0].field === headCell.field) ? ('DESC' === sort[0].direction ? 'desc' : 'asc') : false}
          >
            <TableSortLabel
              active={sort[0] && sort[0].field === headCell.field}
              direction={(sort[0] && sort[0].field === headCell.field) ? ('DESC' === sort[0].direction ? 'desc' : 'asc') : 'asc'}
              onClick={headCell.sortable !== undefined && !headCell.sortable ? (() => {}) : createSortHandler(headCell.field)}
            >
              {headCell.label}
              {(sort[0] && sort[0].field === headCell.field) ? (
                <span className={classes.visuallyHidden}>
                  {sort[0].direction === 'DESC' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
        : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
    title: {
      flex: '1 1 100%',
    },
  }),
);

interface EnhancedTableToolbarProps {
  numSelected: number;
  tableName: string;
  actionButtons?: ReactNode;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const classes = useToolbarStyles();
  const { numSelected, tableName, actionButtons } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle">
          {tableName}
        </Typography>
      )}
      {numSelected > 0 ?
        actionButtons
       : null}
    </Toolbar>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }),
);

export default function EnhancedTable(props: EnhancedTableProps) {
  const { data, onPaginationChanged, onSelectionChanged,
    sort, tableName, totalCount, countOnPage, columns,
    onSortingChanged, currentPage, pageSize, children, rowsSelectable, pagination } = props;

  const classes = useStyles();
  const [orderInternal, setOrder] = React.useState<Order>(sort[0] ? sort[0].direction : null);
  const [orderByInternal, setOrderBy] = React.useState<string>(sort[0] ? sort[0].field : null);

  const [selected, setSelected] = React.useState<string[]>([]);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderByInternal === property && orderInternal === 'ASC';
    setOrder(isAsc ? 'DESC' : 'ASC');
    setOrderBy(property);

    onSortingChanged(property, isAsc ? 'DESC' : 'ASC');
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newSelected: string[] = [];

    if (event.target.checked) {
      newSelected = data && data.map(n => n.id);
    }

    setSelected(newSelected);
    onSelectionChanged(newSelected);
  };

  const handleClick = (id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
    onSelectionChanged(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    onPaginationChanged(newPage, pageSize);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    onPaginationChanged(0, parseInt(event.target.value, 10));
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  const emptyRows = pageSize - countOnPage;

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar numSelected={selected.length} tableName={tableName} actionButtons={children && children.actionButtons}/>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              rowsSelectable={rowsSelectable}
              classes={classes}
              numSelected={selected.length}
              sort={sort}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              countOnPage={data && data.length}
              columns={columns}
            />
            <TableBody>
              {data && data
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      //onClick={event => props.onRowClick(row.id)}
                      // role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={index}
                      key={row.code}
                      selected={isItemSelected}
                    >
                      {rowsSelectable && <TableCell padding="checkbox">
                          <Checkbox
                              onClick={event => handleClick(row.id)}
                              checked={isItemSelected}
                              inputProps={{ 'aria-labelledby': labelId }}
                          />
                      </TableCell>}
                      {
                        columns.map((column, index) => {
                            if (index === 0) {
                              return (
                                <TableCell align={!rowsSelectable ? 'left' : 'right'} component="th" id={labelId} scope="row" padding={'default'}>
                                  {column.type === 'computed' ? column.computedValue(row) : row[column.field]}
                                </TableCell>
                              )
                            }
                            return (
                              <TableCell align="right" className={column.className && column.className(row)}>
                                {column.type === 'computed' ? column.computedValue(row) : row[column.field]}
                              </TableCell>
                            )
                          }
                        )
                      }
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={7} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {pagination &&
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 500]}
          component="div"
          count={totalCount}
          page={currentPage}
          rowsPerPage={pageSize}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />}
      </Paper>
    </div>
  );
}

EnhancedTable.defaultProps = {
  pagination: true,
  onRowClick: () => {},
  onSortingChanged: () => {},
  onSelectionChanged: () => {},
  onPaginationChanged: () => {}
}
