import {KindOfProductLanguageType, MappingSourceType, PageResponse} from "../common/types";

export const FETCH_KIND_OF_ITEMS = 'FETCH_KIND_OF_ITEMS'
export const FETCH_KIND_OF_ITEMS_SUCCEEDED = 'FETCH_KIND_OF_ITEMS_SUCCEEDED'
export const FETCH_KIND_OF_ITEMS_FAILED = 'FETCH_KIND_OF_ITEMS_FAILED'
export const ADD_KIND_OF_ITEM = "ADD_KIND_OF_ITEMS"
export const ADD_KIND_OF_ITEMS_FAILED = "ADD_KIND_OF_ITEMS_FAILED"
export const ADD_KIND_OF_ITEMS_SUCCEEDED = "ADD_KIND_OF_ITEMS_SUCCEEDED"

export const GET_KIND_OF_ITEM = "GET_KIND_OF_ITEM"
export const GET_KIND_OF_ITEM_FAILED = "GET_KIND_OF_ITEM_FAILED"
export const GET_KIND_OF_ITEM_SUCCEEDED = "GET_KIND_OF_ITEM_SUCCEEDED"

export const UPDATE_KIND_OF_ITEM = "UPDATE_KIND_OF_ITEM"
export const UPDATE_KIND_OF_ITEM_FAILED = "UPDATE_KIND_OF_ITEM_FAILED"
export const UPDATE_KIND_OF_ITEM_SUCCEEDED = "UPDATE_KIND_OF_ITEM_SUCCEEDED"

export const DELETE_KIND_OF_ITEM = "DELETE_KIND_OF_ITEM"
export const DELETE_KIND_OF_ITEM_FAILED = "DELETE_KIND_OF_ITEM_FAILED"
export const DELETE_KIND_OF_ITEM_SUCCEEDED = "DELETE_KIND_OF_ITEM_SUCCEEDED"

export const CLOSE_KIND_OF_ERROR_MESSAGE = 'CLOSE_KIND_OF_ERROR_MESSAGE'

export const GENERATE_MAPPING = 'GENERATE_MAPPING'
export const GENERATE_MAPPING_SUCCEEDED = 'GENERATE_MAPPING_SUCCEEDED'
export const GENERATE_MAPPING_FAILED = 'GENERATE_MAPPING_FAILED'

export const FETCH_DEPRECATED_KIND_OF_PRODUCT_ITEMS = 'FETCH_DEPRECATED_KIND_OF_PRODUCT_ITEMS'
export const FETCH_DEPRECATED_KIND_OF_PRODUCT_ITEMS_SUCCEEDED = 'FETCH_DEPRECATED_KIND_OF_PRODUCT_ITEMS_SUCCEEDED'
export const FETCH_DEPRECATED_KIND_OF_PRODUCT_ITEMS_FAILED = 'FETCH_DEPRECATED_KIND_OF_PRODUCT_ITEMS_FAILED'

export interface FetchKindOfLevelItemRequest {
    language: KindOfProductLanguageType;
}

export interface FetchKindOfLevelItemsPageRequest {
    page: number;
    size: number;
    sort: { field: string, direction: 'ASC' | 'DESC' }[];
    code: string;
    search: string;
    language: KindOfProductLanguageType;
}

export interface FetchKindOfLevelItemsPageResponse extends PageResponse {
    content: KindOfLevelItem[];
}

export class KindOfLevelItem {
    code: string;
    name: string;
    createdAt: string;
    updatedAt: string;
    description: string;
}

export interface ImportKindOf {
    file: File;
}

export interface AddKindOfItemRequest {
    code: string;
    typographies: [];
}

export interface AddKindOfItemResponse {
    code: string;
    name: string;
    description: string;
}

export interface GetKindOfItemResponse {
    code: string;
    typographies: [];
}

export interface UpdateKindOfItemRequest {
    code: string;
    typographies: [];
}

export interface KindOfState {
    importKindOf: ImportKindOf;

    pageRequest: FetchKindOfLevelItemsPageRequest;
    pageResponse: FetchKindOfLevelItemsPageResponse;

    itemRequest: FetchKindOfLevelItemRequest;

    addKindOfItemRequest: AddKindOfItemRequest;
    addKindOfItemResponse: AddKindOfItemResponse;

    updateKindOfItemRequest: UpdateKindOfItemRequest;

    getKindOfItemResponse: GetKindOfItemResponse;
    code: string;

    loading: boolean;
    error: string;

    generateKindOfProductMappingReportLoading: boolean;
}

export interface FetchKindOfItemsAction {
    type: typeof FETCH_KIND_OF_ITEMS
    pageRequest: FetchKindOfLevelItemsPageRequest
}

export interface FetchKindOfItemsSucceededAction {
    type: typeof FETCH_KIND_OF_ITEMS_SUCCEEDED
    payload: FetchKindOfLevelItemsPageResponse
}

export interface FetchKindOfItemsFailedAction {
    type: typeof FETCH_KIND_OF_ITEMS_FAILED
    error: string
}

export interface CloseKindOfErrorMessageAction {
    type: typeof CLOSE_KIND_OF_ERROR_MESSAGE
}

export interface AddKindOfItemAction {
    type: typeof ADD_KIND_OF_ITEM;
    addKindOfItemRequest: AddKindOfItemRequest;
}

export interface AddKindOfItemSucceededAction {
    type: typeof ADD_KIND_OF_ITEMS_SUCCEEDED;
    payload: AddKindOfItemResponse;
}

export interface AddKindOfItemFailedAction {
    type: typeof ADD_KIND_OF_ITEMS_FAILED;
    error: string;
}

export interface GetKindOfItemAction {
    type: typeof GET_KIND_OF_ITEM;
    code: string;
}

export interface GetKindOfItemSucceededAction {
    type: typeof GET_KIND_OF_ITEM_SUCCEEDED;
    payload: GetKindOfItemResponse;
}

export interface GetKindOfItemFailedAction {
    type: typeof GET_KIND_OF_ITEM_FAILED;
    error: string;
}

export interface UpdateKindOfItemAction {
    type: typeof UPDATE_KIND_OF_ITEM;
    payload: UpdateKindOfItemRequest;
}

export interface UpdateKindOfItemSucceededAction {
    type: typeof UPDATE_KIND_OF_ITEM_SUCCEEDED;
}

export interface UpdateKindOfItemFailedAction {
    type: typeof UPDATE_KIND_OF_ITEM_FAILED;
    error: string;
}

export interface DeleteKindOfItemAction {
    type: typeof DELETE_KIND_OF_ITEM;
    code: string;
}

export interface DeleteKindOfItemSucceededAction {
    type: typeof DELETE_KIND_OF_ITEM_SUCCEEDED;
}

export interface DeleteKindOfItemFailedAction {
    type: typeof DELETE_KIND_OF_ITEM_FAILED;
    error: string;
}

export interface generateKindOfProductMappingReportAction {
    type: typeof GENERATE_MAPPING
}

export interface generateKindOfProductMappingReportSucceededAction {
    type: typeof GENERATE_MAPPING_SUCCEEDED
}

export interface generateKindOfProductMappingReportFailedAction {
    type: typeof GENERATE_MAPPING_FAILED
    error: string
}

export interface MappedValue {
    id: string
}

export interface KindOfProductMappingResponse {
    content: MappedValue[];
}

export interface MappedValueMappingResponse {
    content: KindOfProduct[];
}

export interface KindOfProduct {
    id: string
}


export interface FetchDeprecatedKindOfProductItemsAction {
    type: typeof FETCH_DEPRECATED_KIND_OF_PRODUCT_ITEMS
    pageRequest: FetchKindOfLevelItemsPageRequest
}

export interface FetchDeprecatedKindOfProductItemsSucceededAction {
    type: typeof FETCH_DEPRECATED_KIND_OF_PRODUCT_ITEMS_SUCCEEDED
    payload: FetchKindOfLevelItemsPageResponse
}

export interface FetchDeprecatedKindOfProductItemsFailedAction {
    type: typeof FETCH_DEPRECATED_KIND_OF_PRODUCT_ITEMS_FAILED
    error: string
}

export type KindOfActionTypes = FetchKindOfItemsAction | FetchKindOfItemsSucceededAction | FetchKindOfItemsFailedAction
    | CloseKindOfErrorMessageAction | AddKindOfItemAction | AddKindOfItemSucceededAction
    | AddKindOfItemFailedAction | GetKindOfItemAction | GetKindOfItemSucceededAction | GetKindOfItemFailedAction
    | UpdateKindOfItemAction | UpdateKindOfItemSucceededAction | UpdateKindOfItemFailedAction
    | DeleteKindOfItemAction | DeleteKindOfItemSucceededAction | DeleteKindOfItemFailedAction
    | generateKindOfProductMappingReportAction | generateKindOfProductMappingReportSucceededAction | generateKindOfProductMappingReportFailedAction
    | FetchDeprecatedKindOfProductItemsAction | FetchDeprecatedKindOfProductItemsSucceededAction | FetchDeprecatedKindOfProductItemsFailedAction;
