import * as React from "react";
import "./GpcClassificationList.css"
import EnhancedTable, { Column, Order } from "../EnhancedTable/EnhancedTable";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import { Form, Typography } from 'antd';
import {
  FetchGpcLevelItemsPageRequest,
  FetchGpcLevelItemsPageResponse,
  GpcLevelItem
} from "../../store/classifications/gpc/types";
import GpcLevelTypeSelect from "../inputs/GpcLevelTypeSelect/GpcLevelTypeSelect";
import Search from "antd/es/input/Search";

const { Text } = Typography;

export interface Props {
  pageRequest: FetchGpcLevelItemsPageRequest;
  pageResponse: FetchGpcLevelItemsPageResponse;

  onPaginationChanged: (page: number, size: number) => void;
  onSortingChanged?: (orderBy: string, order: Order) => void;
  onFilterChanged?: (filterField: string, value: string) => void;
  onRowClick: (code: string) => void;

  loading: boolean;
  error: string;
}

export const GpcClassificationList: React.FunctionComponent<Props> = (props) => {

  const [form] = Form.useForm();

  const { pageRequest, pageResponse, onPaginationChanged, onSortingChanged, onRowClick, onFilterChanged, loading } = props;

  const columns: Column[] = [
    {
      label: 'Code',
      field: 'code',
      type: 'computed',
      computedValue: (item: GpcLevelItem) => {
        return (<a onClick={event => onRowClick(item.code)}>{item.code}</a>)
      }
    },
    {
      label: 'Title',
      field: 'text',
      type: 'computed',
      computedValue: (item: GpcLevelItem) => {
        return (item.text)
      }
    },
    {
      label: 'Description',
      field: 'definition',
      type: 'computed',
      computedValue: (item: GpcLevelItem) => {
        return (item.definition);
      }
    }
  ];

  const handleFilterChange = (field: string, value: string) => {
    onFilterChanged && onFilterChanged(field, value);
  };

  const formItemLayout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 8 },
  };

  const buttonItemLayout = {
    wrapperCol: { span: 8, offset: 2 },
  };

  return props.error ?
    (
      <div className="view-error">{props.error}</div>
    )
    :
    (
      <React.Fragment>

        {
          loading && (<div>
            <div className="view-loading"/>
            <CircularProgress className="view-loading-spinner"/>
          </div>)
        }

        <Form
          {...formItemLayout}
          layout={'horizontal'}
          form={form}
        >
          <Form.Item label="Level">
            <GpcLevelTypeSelect id={"gpc-level-select"}
                                value={pageRequest.level}
                                onChange={(value) => handleFilterChange('level', value)}
            />
          </Form.Item>
          <Form.Item label={'Search'}>
            <Search
              placeholder="Search by title"
              enterButton
              size="middle"
              defaultValue={pageRequest.search}
              onSearch={(value) => handleFilterChange('search', value)}
            />
          </Form.Item>
        </Form>

        <EnhancedTable tableName={"Results"}
                       rowsSelectable={false}
                       columns={columns}
                       data={pageResponse.content}
                       totalCount={pageResponse.totalElements}
                       countOnPage={pageResponse.numberOfElements}
                       currentPage={pageRequest.page}
                       pageSize={pageRequest.size}
                       sort={pageRequest.sort}
                       onPaginationChanged={onPaginationChanged}
                       onSortingChanged={onSortingChanged}
        >
          {{
            actionButtons: null
          }}
        </EnhancedTable>
      </React.Fragment>
    );
};

export default GpcClassificationList;
