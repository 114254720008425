import * as React from "react";
import {RouteComponentProps, withRouter} from "react-router-dom";
import "./EditKindOfProductPage.css";
import {Dispatch} from "redux";
import {AppState, store} from "../../store";
import {flowRight} from "lodash";
import {connect} from 'react-redux';
import {UserSession} from "../../store/userSession/types";
import PageWrapper from "../../components/Drawer/PageWrapper";
import {Modal, PageHeader} from "antd";
import {closeErrorMessage} from "../../store/globalProducts/actions";
import {GetKindOfItemResponse, UpdateKindOfItemRequest} from "../../store/kindOf/types";
import {deleteKindOfItem, getKindOfItem, updateKindOfItem} from "../../store/kindOf/actions";
import {Store} from 'rc-field-form/lib/interface';
import EditKindOfProduct from "../../components/EditKindOfProduct/EditKindOfProduct";
import KindOfProductService from "../../services/KindOfProductService";

interface Props extends RouteComponentProps<{ code: string }> {
    userSession: UserSession;
    updateKindOfItem: (updateKindOfItemRequest: UpdateKindOfItemRequest) => void;
    getKindOfItem: (id: string) => void;
    deleteKindOfItem: (id: string) => void;
    getKindOfItemResponse: GetKindOfItemResponse;
    loading?: boolean;
    error?: string;
}

interface State {
    deletingItemCode: string
}

class EditKindOfProductPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            deletingItemCode: null
        }

        EditKindOfProductPage.onMessageClose = EditKindOfProductPage.onMessageClose.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }

    componentDidMount() {
        this.props.getKindOfItem(this.props.match.params.code)
    }

    public render() {
        return (
            <div className="add-kind-of-page">
                <PageWrapper userSession={this.props.userSession}>
                    <PageHeader
                        backIcon={null}
                        ghost={false}
                        onBack={() => window.history.back()}
                        title="Edit Kind Of Product"
                        subTitle="Edit the 'Kind Of Product' item">
                    </PageHeader>
                    <EditKindOfProduct
                        item={this.props.getKindOfItemResponse}
                        onMessageClose={EditKindOfProductPage.onMessageClose}
                        onSubmit={this.onSubmit}
                        onDelete={this.onDelete}
                        error={this.props.error}
                        loading={this.props.loading}/>

                </PageWrapper>

                <Modal
                    title={'Delete'}
                    visible={this.state.deletingItemCode !== null}
                    onOk={() => {
                        const code = this.state.deletingItemCode
                        this.props.deleteKindOfItem(code)
                        this.props.history.push('/app/kind-of-product/list');
                    }}
                    onCancel={() => {
                        this.setState({deletingItemCode: null})
                    }}
                    okText={'Yes!'}
                    cancelText={'No, wait!'}
                    okButtonProps={{className: "bg-success"}}>
                    <h3>Are you sure?</h3>
                </Modal>
            </div>
        )
    }

    private static onMessageClose() {
        store.dispatch(closeErrorMessage());
    }

    private async onDelete(code: string) {

        const mappingListPG = this.checkMappings(code, 'PRODUCT_GROUP').then(values => {
            return values.map(value => value)
        })

        const mappingPG = async () => {
            return await mappingListPG;
        };

        const mappingListFF = this.checkMappings(code, 'FEATURE_FRAME').then(values => {
            return values.map(value => value)
        })

        const mappingFF = async () => {
            return await mappingListFF;
        };

        if ((await mappingPG()).length > 0) {
            return alert("Following Product Group Ids are mapped to kind-of-product: " + await mappingPG() +
                ". \n\nPlease delete Product Group Mappings before deleting Kind-Of-Product.")
        } else if ((await mappingFF()).length > 0) {
            return alert("Following Feature Frames are mapped to kind-of-product: " + await mappingFF() +
                ". \n\nPlease delete the Feature Frame Mappings before deleting Kind-Of-Product.")
        } else {
            this.setState({deletingItemCode: code})
        }
    }

    async checkMappings(code: string, mappingSource: string): Promise<string[]> {

        if (!!code && !!mappingSource) {

            const response = KindOfProductService.getKindOfProductMapping(code, mappingSource)
                .then(values => {
                    return values.content.map(value => value.id);
                }).catch(error => {
                    console.log(error)
                    return [] as string [];
                });
            const mappingList = async () => {
                return await response;
            };
            return await mappingList();
        }

        return [] as string []
    }

    private onSubmit(kindOf: Store) {
        this.props.updateKindOfItem({
            code: kindOf.code,
            typographies: kindOf.typographies
        })
        this.props.getKindOfItemResponse.code = kindOf.code;
        this.props.getKindOfItemResponse.typographies = kindOf.typographies;
    }
}

const mapStateToProps = (state: AppState) => ({
    userSession: state.userSession.userSession,
    getKindOfItemResponse: state.kindOf.getKindOfItemResponse,
    loading: state.kindOf.loading,
    error: state.kindOf.error
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    updateKindOfItem: (updateKindOfItemRequest: UpdateKindOfItemRequest) => {
        dispatch(updateKindOfItem(updateKindOfItemRequest))
    },
    getKindOfItem: (code: string) => {
        dispatch(getKindOfItem(code))
    },
    deleteKindOfItem: (code: string) => {
        dispatch(deleteKindOfItem(code))
    }
});

const enhance = flowRight(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(EditKindOfProductPage);
