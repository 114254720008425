export const FETCH_USER_SESSION = 'FETCH_USER_SESSION'
export const FETCH_USER_SESSION_SILENT = 'FETCH_USER_SESSION_SILENT'
export const FETCH_USER_SESSION_SUCCEEDED = 'FETCH_USER_SESSION_SUCCEEDED'
export const FETCH_USER_SESSION_FAILED = 'FETCH_USER_SESSION_FAILED'

// Interval time (milisec) that GUI calls backend to Reset the session timeout in the backend (every 5 mins)
export const USER_SESSION_REFRESH_INTERVAL = 300000

export interface UserSession {
  user: string;
  email: string;
  firstname: string;
  lastname: string;
  expiresIn: number;
  loggedIn: boolean;
}

export interface UserSessionState {
  userSession: UserSession;
  loading: boolean;
  error: string;
}

export interface FetchUserSessionAction {
  type: typeof FETCH_USER_SESSION
}

export interface FetchUserSessionSilentAction {
  type: typeof FETCH_USER_SESSION_SILENT
}

export interface FetchUserSessionSucceededAction {
  type: typeof FETCH_USER_SESSION_SUCCEEDED
  payload: UserSession
}

export interface FetchUserSessionFailedAction {
  type: typeof FETCH_USER_SESSION_FAILED
  error: string
}

export type UserSessionActionTypes = FetchUserSessionAction | FetchUserSessionSucceededAction | FetchUserSessionFailedAction | FetchUserSessionSilentAction;

