import * as React from "react";
import "./ImportTaskExecutionView.css"
import { ImportTaskExecution } from "../../store/productImports/types";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import { Button, Descriptions, Divider, List, Space, Tag, Typography } from 'antd';
import { ImportStatus } from "../../store/failures/types";

const { Text } = Typography;

export interface ImportTaskExecutionViewProps {

  importTaskExecution: ImportTaskExecution;

  onImportRetry?: (id: string) => void;
  onFailureRetry?: (id: number) => void;
  onProductFailureReject?: (id: number) => void;

  retryLoading: boolean;

  loading: boolean;
  error: string;
}

export const ImportTaskExecutionView: React.FunctionComponent<ImportTaskExecutionViewProps> = (props) => {

  const { importTaskExecution, onImportRetry, onFailureRetry, onProductFailureReject, loading } = props;

  const statusTag = (importStatus: ImportStatus | null) => {
    return (importStatus == 'FAILED' ?
        (<Tag color="error">FAILED</Tag>)
        : (importStatus == 'SUCCEEDED'
          ? (<Tag color="success">SUCCEEDED</Tag>)
          : ((importStatus == 'RUNNING' || importStatus == 'REJECTED') ? <Tag color="warning">{importStatus}</Tag> : null))
    );
  };

  return props.error ?
    (
      <div className="view-error">{props.error}</div>
    )
    :
    (
      <React.Fragment>

        {
          loading && (<div>
            <div className="view-loading"/>
            <CircularProgress className="view-loading-spinner"/>
          </div>)
        }

        <Descriptions size="small" column={2} bordered>
          <Descriptions.Item label="ID">
            <a>{importTaskExecution && importTaskExecution.id}</a>
          </Descriptions.Item>
          <Descriptions.Item label="Status">
            <Space>
              {statusTag((importTaskExecution && importTaskExecution.importStatus) || null)}
              {
                importTaskExecution && importTaskExecution.failures.length > 0 &&
                <Button type="primary" loading={props.retryLoading} size={"small"} onClick={() => onImportRetry(importTaskExecution && importTaskExecution.id)}>Retry</Button>
              }
            </Space>
          </Descriptions.Item>
          <Descriptions.Item label="Source system">{importTaskExecution && importTaskExecution.sourceSystem}</Descriptions.Item>
          <Descriptions.Item label="Subsidiary">{importTaskExecution && importTaskExecution.subsidiary}</Descriptions.Item>
          <Descriptions.Item label="From">{importTaskExecution && new Date(importTaskExecution.fromDate).toLocaleString()}</Descriptions.Item>
          <Descriptions.Item label="To">{importTaskExecution && importTaskExecution.toDate && new Date(importTaskExecution.toDate).toLocaleString()}</Descriptions.Item>
          <Descriptions.Item label="Import Started">{importTaskExecution && new Date(importTaskExecution.importStartedAt).toLocaleString()}</Descriptions.Item>
          <Descriptions.Item label="Import Finished">{importTaskExecution && importTaskExecution.importFinishedAt && new Date(importTaskExecution.importFinishedAt).toLocaleString()}</Descriptions.Item>
          <Descriptions.Item label="Imported products">{importTaskExecution && importTaskExecution.importedCount}</Descriptions.Item>
          <Descriptions.Item label="Failures">{importTaskExecution && importTaskExecution.failures.length}</Descriptions.Item>
          <Descriptions.Item label="Update time">{importTaskExecution && new Date(importTaskExecution.updatedAt).toLocaleString()}</Descriptions.Item>
          <Descriptions.Item label="Updated by">
            <a>{importTaskExecution && importTaskExecution.updatedByFullName}</a>
          </Descriptions.Item>
        </Descriptions>

        {
          importTaskExecution && importTaskExecution.failures.length > 0 &&
          (
            <div>
              <br/>
              <Divider orientation="center">
                <Text type="danger">Failures</Text>
              </Divider>
              <List
                itemLayout="horizontal"
                size="large"
                pagination={{
                  onChange: page => {
                    console.log(page);
                  },
                  hideOnSinglePage: true
                }}
                dataSource={(importTaskExecution && importTaskExecution.failures) || []}
                renderItem={item => (
                  <List.Item
                    key={item.id}
                    actions={[
                      statusTag(item.importStatus),
                      <Button type="primary" loading={props.retryLoading} size={"small"}
                              onClick={() => onFailureRetry(item.id)}>Retry</Button>,

                      <Button danger type="primary"  disabled={item.importStatus !== 'FAILED'}
                              loading={props.retryLoading} size={"small"} onClick={() => onProductFailureReject(item.id)}>Reject</Button>
                    ]}
                  >
                    <Space>
                      <List.Item.Meta
                        title={<Space>
                          <Text code>{item.failureType}</Text>
                        </Space>}
                        description={<Space direction={"vertical"}>
                          {item.productId && <Text>Product: <a>{item.productId}</a></Text>}
                          {item.fromDate && <Text>From: <a>{new Date(item.fromDate).toLocaleString()}</a></Text>}
                          {item.toDate && <Text>To: <a>{new Date(item.toDate).toLocaleString()}</a></Text>}
                          {item.page != null && <Text>Page: <a>{item.page}</a></Text>}
                          {item.size != null && <Text>Page size: <a>{item.size}</a></Text>}
                        </Space>}
                      />
                      <Text strong>{item.failureMessage}</Text>
                    </Space>
                  </List.Item>
                )}
              />
            </div>
          )
        }

      </React.Fragment>
    );
};

export default ImportTaskExecutionView;
