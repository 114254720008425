import {
  CLOSE_GPC_CLASSIFICATION_ERROR_MESSAGE,
  FETCH_GPC_ITEM,
  FETCH_GPC_ITEM_FAILED,
  FETCH_GPC_ITEM_SUCCEEDED,
  FETCH_GPC_ITEMS,
  FETCH_GPC_ITEMS_FAILED,
  FETCH_GPC_ITEMS_SUCCEEDED,
  FetchGpcLevelItemRequest,
  FetchGpcLevelItemResponse,
  FetchGpcLevelItemsPageRequest,
  FetchGpcLevelItemsPageResponse,
  GpcClassificationsActionTypes,
  IMPORT_GPC_CLASSIFICATION,
  IMPORT_GPC_CLASSIFICATION_FAILED,
  IMPORT_GPC_CLASSIFICATION_SUCCEEDED,
  ImportGpcClassification,

} from './types'

export function closeGpcClassificationErrorMessage(): GpcClassificationsActionTypes {
  return {
    type: CLOSE_GPC_CLASSIFICATION_ERROR_MESSAGE
  }
}

export function fetchGpcLevelItem(itemRequest: FetchGpcLevelItemRequest): GpcClassificationsActionTypes {
  return {
    type: FETCH_GPC_ITEM,
    itemRequest
  }
}

export function fetchGpcLevelItemSucceeded(payload: FetchGpcLevelItemResponse): GpcClassificationsActionTypes {
  return {
    type: FETCH_GPC_ITEM_SUCCEEDED,
    payload
  }
}

export function fetchGpcLevelItemFailed(error: string): GpcClassificationsActionTypes {
  return {
    type: FETCH_GPC_ITEM_FAILED,
    error
  }
}

export function fetchGpcLevelItems(pageRequest: FetchGpcLevelItemsPageRequest): GpcClassificationsActionTypes {
  return {
    type: FETCH_GPC_ITEMS,
    pageRequest
  }
}

export function fetchGpcLevelItemsSucceeded(payload: FetchGpcLevelItemsPageResponse): GpcClassificationsActionTypes {
  return {
    type: FETCH_GPC_ITEMS_SUCCEEDED,
    payload
  }
}

export function fetchGpcLevelItemsFailed(error: string): GpcClassificationsActionTypes {
  return {
    type: FETCH_GPC_ITEMS_FAILED,
    error
  }
}

export function importGpcClassification(request: ImportGpcClassification): GpcClassificationsActionTypes {
  return {
    type: IMPORT_GPC_CLASSIFICATION,
    request
  }
}

export function importGpcClassificationSucceeded(): GpcClassificationsActionTypes {
  return {
    type: IMPORT_GPC_CLASSIFICATION_SUCCEEDED
  }
}

export function importGpcClassificationFailed(error: string): GpcClassificationsActionTypes {
  return {
    type: IMPORT_GPC_CLASSIFICATION_FAILED,
    error
  }
}


