import * as React from "react";
import "./AddTask.css"
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import { Alert, Button, DatePicker, Form, Input, Switch } from 'antd';
import { ProductSourceSystem, Subsidiary } from "../../store/common/types";
import SubsidiarySelect from "../inputs/SubsidiarySelect/SubsidiarySelect";
import moment from 'moment';
import SourceSystemSelect from "../inputs/SourceSystemSelect/SourceSystemSelect";

export interface Props {
  subsidiary: Subsidiary;
  sourceSystem: ProductSourceSystem;
  fromDate: Date;
  toDate: Date;
  intervalMinutes: number;
  enabled: boolean;
  batchSize: number;
  queryCondition: string;

  onFormFieldChange: (field: string, value: any) => void;
  onSubmit: () => void;
  onMessageClose: () => void;

  loading: boolean;
  error: string;
}

export const AddTaskView: React.FunctionComponent<Props> = (props) => {

  const [form] = Form.useForm();

  const { fromDate, toDate, enabled, subsidiary, sourceSystem, intervalMinutes, onSubmit, onMessageClose, onFormFieldChange, loading, error } = props;

  const formItemLayout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 5 },
  };

  const buttonItemLayout = {
    wrapperCol: { span: 5, offset: 2 },
  };

  return (
    <React.Fragment>

      {
        loading && (<div>
          <div className="view-loading"/>
          <CircularProgress className="view-loading-spinner"/>
        </div>)
      }

      {
        error && (
          <React.Fragment>
            <Alert
              message="Error"
              description={error}
              type="error"
              closable
              onClose={onMessageClose}
            />
            <br/>
          </React.Fragment>
        )
      }

      <Form
        {...formItemLayout}
        initialValues={
          {
            subsidiary,
            sourceSystem,
            intervalMinutes,
            fromDate: fromDate != null ? moment(fromDate) : null,
            toDate: toDate != null ? moment(toDate) : null,
            enabled
          }
        }
        layout={'horizontal'}
        form={form}
        onFinish={onSubmit}
      >

        <Form.Item label="Subsidiary"
                   name={"subsidiary"}
                   rules={[{ required: true, message: 'Please input subsidiary' }]}
        >
          <SubsidiarySelect id="subsidiary-select"
                            value={subsidiary}
                            onChange={(value) => onFormFieldChange('subsidiary', value)}
          />
        </Form.Item>

        <Form.Item label="Source system"
                   name={"sourceSystem"}
                   rules={[{ required: true, message: 'Please input source system' }]}
        >
          <SourceSystemSelect id="source-system-select"
                              value={sourceSystem}
                              onChange={(value) => onFormFieldChange('sourceSystem', value)}
          />
        </Form.Item>

        <Form.Item label="Interval (minutes)"
                   name={"intervalMinutes"}
                   rules={[{ required: false, message: 'Please input interval in minutes' }]}
        >
          <Input
            type={"number"}
            placeholder="Please input interval in minutes"
            onChange={(value) => onFormFieldChange('intervalMinutes', value.target.value)}
          />
        </Form.Item>

        <Form.Item label="Batch size"
                   name={"batchSize"}
                   rules={[{ required: false, message: 'Please input batch size in minutes' }]}
        >
          <Input
              type={"number"}
              placeholder="Batch import of products. Max allowed is 2000 products per hour"
              onChange={(value) => onFormFieldChange('batchSize', value.target.value)}
          />
        </Form.Item>

        <Form.Item label="Query condition"
                   name={"queryCondition"}
                   rules={[{ required: false, message: 'Please input query condition' }]}
        >
          <Input
              placeholder="Batch import of products based on query condition"
              onChange={(value) => onFormFieldChange('queryCondition', value.target.value)}
          />
        </Form.Item>

        <Form.Item label="From Date"
                   name={"fromDate"}
                   rules={[{ required: true, message: 'Please input From Date' }]}
        >
          <DatePicker
            showTime={{ format: 'HH:mm:ss' }}
            format="DD-MM-YYYY HH:mm:ss"
            onOk={(value) => onFormFieldChange('fromDate', value != null ? value.milliseconds(0) : null)}
            onChange={(value) => onFormFieldChange('fromDate', value != null ? value.milliseconds(0) : null)}
          />
        </Form.Item>

        <Form.Item label="To Date"
                   name={"toDate"}
                   rules={[{ required: false, message: 'Please input To Date' }]}
        >
          <DatePicker
            showTime={{ format: 'HH:mm:ss' }}
            format="DD-MM-YYYY HH:mm:ss"
            onOk={(value) => onFormFieldChange('toDate', value != null ? value.milliseconds(0) : null)}
            onChange={(value) => onFormFieldChange('toDate', value != null ? value.milliseconds(0) : null)}
          />
        </Form.Item>

        <Form.Item label="Enabled"
                   name={"enabled"}
                   rules={[]}
        >
          <Switch defaultChecked={enabled} onChange={(value) => onFormFieldChange('enabled', value)}/>
        </Form.Item>
        <Form.Item {...buttonItemLayout}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>

    </React.Fragment>
  );
};

export default AddTaskView;
