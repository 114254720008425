import * as React from "react";
import { useEffect } from "react";
import { SelectProps } from "antd/es/select";
import { Select, Spin } from 'antd';
import debounce from 'lodash/debounce';

export interface LoadableSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType>, 'options' | 'children'> {
  fetchOptions: (search: string, page: number) => Promise<ValueType[]>;
  debounceTimeout?: number;
  loadOptionsTrigger?: number;
}

export interface LoadableSelectItem {
  key?: string;
  label: React.ReactNode;
  value: string | number
}

export function LoadableSelect<ValueType extends LoadableSelectItem = any>
({ fetchOptions, debounceTimeout = 800, ...props }: LoadableSelectProps<ValueType>) {

  const debounceFetcher = React.useMemo(() => {
    const loadOptions = (value: string) => {

      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      setPage(0);
      setSearch(value);

      fetchOptions(value, 0).then(newOptions => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions(newOptions);
        setFetching(false);

      }).catch(error => {
        console.error(error);
        setTimeout(() => {
          setFetching(false);
        }, 1000);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  const [search, setSearch] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [fetching, setFetching] = React.useState(false);
  const [options, setOptions] = React.useState<ValueType[]>(() => {
    debounceFetcher("");
    return [];
  });
  const fetchRef = React.useRef(0);

  useEffect(() => {
    debounceFetcher("");
  }, [props.loadOptionsTrigger]);

  const onScroll = (event: any) => {

    const target = event.target;
    if (!fetching && target.scrollTop + target.offsetHeight === target.scrollHeight) {

      setFetching(true);
      target.scrollTo(0, target.scrollHeight);

      fetchOptions(search, page + 1)
        .then(newOptions => {

          setTimeout(() => {

            if (newOptions.length > 0) {
              const children = [...options];
              newOptions.forEach(newOption => children.push(newOption));

              setOptions(children);
              setPage(page + 1);
            }
            setFetching(false);

          }, 1000);
        }).catch(error => {
        console.error(error);
        setTimeout(() => {
          setFetching(false);
        }, 1000);
      });
    }
  };

  return (
    <Select<ValueType>
      allowClear
      showSearch
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      onPopupScroll={onScroll}
      onDeselect={() => debounceFetcher("")}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={!fetching ? options : [...options, {label: <Spin size="small" />, value: -1}]}
    />
  );
}
