import {
  FailureGrantType,
  FETCH_IMPORTER_FAILURE,
  FETCH_IMPORTER_FAILURE_FAILED,
  FETCH_IMPORTER_FAILURE_LIST,
  FETCH_IMPORTER_FAILURE_LIST_FAILED,
  FETCH_IMPORTER_FAILURE_LIST_SUCCEEDED,
  FETCH_IMPORTER_FAILURE_SUCCEEDED,
  FETCH_LEGACY_PRODUCT,
  FETCH_LEGACY_PRODUCT_FAILED,
  FETCH_LEGACY_PRODUCT_SUCCEEDED,
  FETCH_PRODUCT_FAILURE,
  FETCH_PRODUCT_FAILURE_FAILED,
  FETCH_PRODUCT_FAILURE_LIST,
  FETCH_PRODUCT_FAILURE_LIST_FAILED,
  FETCH_PRODUCT_FAILURE_LIST_SUCCEEDED,
  FETCH_PRODUCT_FAILURE_SUCCEEDED,
  FetchFailuresActionTypes,
  FetchImporterFailuresPageRequest,
  FetchImporterFailuresPageResponse,
  FetchProductFailuresPageRequest,
  FetchProductFailuresPageResponse,
  GENERATE_REPORT,
  GENERATE_REPORT_FAILED,
  GENERATE_REPORT_SUCCEEDED,
  ImporterFailure,
  ProductFailure,
  REJECT_IMPORTER_FAILURE,
  REJECT_IMPORTER_FAILURE_FAILED,
  REJECT_IMPORTER_FAILURE_LIST, REJECT_IMPORTER_FAILURE_LIST_FAILED, REJECT_IMPORTER_FAILURE_LIST_SUCCEEDED,
  REJECT_IMPORTER_FAILURE_SUCCEEDED,
  REJECT_PRODUCT_FAILURE,
  REJECT_PRODUCT_FAILURE_FAILED,
  REJECT_PRODUCT_FAILURE_LIST,
  REJECT_PRODUCT_FAILURE_LIST_FAILED,
  REJECT_PRODUCT_FAILURE_LIST_SUCCEEDED,
  REJECT_PRODUCT_FAILURE_SUCCEEDED,
  RejectFailuresActionTypes,
  RejectImporterFailureListRequest,
  RejectProductFailureListRequest,
  RESOLVE_PRODUCT_FAILURE,
  RESOLVE_PRODUCT_FAILURE_FAILED,
  RESOLVE_PRODUCT_FAILURE_SUCCEEDED,
  ResolveFailuresActionTypes,
  RETRY_IMPORTER_FAILURE,
  RETRY_IMPORTER_FAILURE_FAILED,
  RETRY_IMPORTER_FAILURE_LIST,
  RETRY_IMPORTER_FAILURE_LIST_FAILED,
  RETRY_IMPORTER_FAILURE_LIST_SUCCEEDED,
  RETRY_IMPORTER_FAILURE_SUCCEEDED,
  RETRY_PRODUCT_FAILURE,
  RETRY_PRODUCT_FAILURE_FAILED,
  RETRY_PRODUCT_FAILURE_LIST,
  RETRY_PRODUCT_FAILURE_LIST_FAILED,
  RETRY_PRODUCT_FAILURE_LIST_SUCCEEDED,
  RETRY_PRODUCT_FAILURE_SUCCEEDED,
  RetryFailuresActionTypes,
  RetryImporterFailureListRequest,
  RetryProductFailureListRequest,
} from './types'
import {ProductSourceSystem, Subsidiary} from "../common/types";

export function fetchImporterFailureList(pageRequest: FetchImporterFailuresPageRequest): FetchFailuresActionTypes {
  return {
    type: FETCH_IMPORTER_FAILURE_LIST,
    pageRequest
  }
}

export function fetchImporterFailureListSucceeded(payload: FetchImporterFailuresPageResponse): FetchFailuresActionTypes {
  return {
    type: FETCH_IMPORTER_FAILURE_LIST_SUCCEEDED,
    payload
  }
}

export function fetchImporterFailureListFailed(error: string): FetchFailuresActionTypes {
  return {
    type: FETCH_IMPORTER_FAILURE_LIST_FAILED,
    error
  }
}

export function fetchProductFailureList(pageRequest: FetchProductFailuresPageRequest): FetchFailuresActionTypes {
  return {
    type: FETCH_PRODUCT_FAILURE_LIST,
    pageRequest
  }
}

export function fetchProductFailureListSucceeded(payload: FetchProductFailuresPageResponse): FetchFailuresActionTypes {
  return {
    type: FETCH_PRODUCT_FAILURE_LIST_SUCCEEDED,
    payload
  }
}

export function fetchProductFailureListFailed(error: string): FetchFailuresActionTypes {
  return {
    type: FETCH_PRODUCT_FAILURE_LIST_FAILED,
    error
  }
}

export function generateReport(pageRequest: FetchProductFailuresPageRequest): FetchFailuresActionTypes {
  return {
    type: GENERATE_REPORT,
    pageRequest
  }
}

export function generateReportSucceeded(): FetchFailuresActionTypes {
  return {
    type: GENERATE_REPORT_SUCCEEDED,
  }
}

export function generateReportFailed(error: string): FetchFailuresActionTypes {
  return {
    type: GENERATE_REPORT_FAILED,
    error
  }
}

export function fetchLegacyProduct(sourceSystem: ProductSourceSystem, subsidiary: Subsidiary, productId: string): FetchFailuresActionTypes {
  return {
    type: FETCH_LEGACY_PRODUCT,
    id: productId,
    subsidiary,
    sourceSystem
  }
}

export function fetchLegacyProductSucceeded(payload: string): FetchFailuresActionTypes {
  return {
    type: FETCH_LEGACY_PRODUCT_SUCCEEDED,
    payload
  }
}

export function fetchLegacyProductFailed(error: string): FetchFailuresActionTypes {
  return {
    type: FETCH_LEGACY_PRODUCT_FAILED,
    error
  }
}

export function fetchImporterFailure(id: number): FetchFailuresActionTypes {
  return {
    type: FETCH_IMPORTER_FAILURE,
    id
  }
}

export function fetchImporterFailureSucceeded(payload: ImporterFailure): FetchFailuresActionTypes {
  return {
    type: FETCH_IMPORTER_FAILURE_SUCCEEDED,
    payload
  }
}

export function fetchImporterFailureFailed(error: string): FetchFailuresActionTypes {
  return {
    type: FETCH_IMPORTER_FAILURE_FAILED,
    error
  }
}

export function fetchProductFailure(id: number): FetchFailuresActionTypes {
  return {
    type: FETCH_PRODUCT_FAILURE,
    id
  }
}

export function fetchProductFailureSucceeded(payload: ProductFailure): FetchFailuresActionTypes {
  return {
    type: FETCH_PRODUCT_FAILURE_SUCCEEDED,
    payload
  }
}

export function fetchProductFailureFailed(error: string): FetchFailuresActionTypes {
  return {
    type: FETCH_PRODUCT_FAILURE_FAILED,
    error
  }
}

export function retryImporterFailure(id: number): RetryFailuresActionTypes {
  return {
    type: RETRY_IMPORTER_FAILURE,
    retryFailureRequest: {
      id
    }
  }
}

export function retryImporterFailureSucceeded(): RetryFailuresActionTypes {
  return {
    type: RETRY_IMPORTER_FAILURE_SUCCEEDED
  }
}

export function retryImporterFailureFailed(error: string): RetryFailuresActionTypes {
  return {
    type: RETRY_IMPORTER_FAILURE_FAILED,
    error
  }
}

export function rejectImporterFailure(id: number): RejectFailuresActionTypes {
  return {
    type: REJECT_IMPORTER_FAILURE,
    rejectFailureRequest: {
      id
    }
  }
}
export function rejectImporterFailureSucceeded(): RejectFailuresActionTypes {
  return {
    type: REJECT_IMPORTER_FAILURE_SUCCEEDED
  }
}

export function rejectImporterFailureFailed(error: string): RejectFailuresActionTypes {
  return {
    type: REJECT_IMPORTER_FAILURE_FAILED,
    error
  }
}

export function retryImporterFailureList(retryFailuresRequest: RetryImporterFailureListRequest): RetryFailuresActionTypes {
  return {
    type: RETRY_IMPORTER_FAILURE_LIST,
    retryFailuresRequest
  }
}

export function retryImporterFailureListSucceeded(): RetryFailuresActionTypes {
  return {
    type: RETRY_IMPORTER_FAILURE_LIST_SUCCEEDED
  }
}

export function retryImporterFailureListFailed(error: string): RetryFailuresActionTypes {
  return {
    type: RETRY_IMPORTER_FAILURE_LIST_FAILED,
    error
  }
}

export function rejectImporterFailureList(rejectFailuresRequest: RejectImporterFailureListRequest): RejectFailuresActionTypes {
  return {
    type: REJECT_IMPORTER_FAILURE_LIST,
    rejectFailuresRequest
  }
}

export function rejectImporterFailureListSucceeded(): RejectFailuresActionTypes {
  return {
    type: REJECT_IMPORTER_FAILURE_LIST_SUCCEEDED
  }
}

export function rejectImporterFailureListFailed(error: string): RejectFailuresActionTypes {
  return {
    type: REJECT_IMPORTER_FAILURE_LIST_FAILED,
    error
  }
}

export function retryProductFailureList(retryFailuresRequest: RetryProductFailureListRequest): RetryFailuresActionTypes {
  return {
    type: RETRY_PRODUCT_FAILURE_LIST,
    retryFailuresRequest
  }
}

export function retryProductFailureListSucceeded(): RetryFailuresActionTypes {
  return {
    type: RETRY_PRODUCT_FAILURE_LIST_SUCCEEDED
  }
}

export function retryProductFailureListFailed(error: string): RetryFailuresActionTypes {
  return {
    type: RETRY_PRODUCT_FAILURE_LIST_FAILED,
    error
  }
}

export function rejectProductFailureList(rejectFailuresRequest: RejectProductFailureListRequest): RejectFailuresActionTypes {
  return {
    type: REJECT_PRODUCT_FAILURE_LIST,
    rejectFailuresRequest
  }
}

export function rejectProductFailureListSucceeded(): RejectFailuresActionTypes {
  return {
    type: REJECT_PRODUCT_FAILURE_LIST_SUCCEEDED
  }
}

export function rejectProductFailureListFailed(error: string): RejectFailuresActionTypes {
  return {
    type: REJECT_PRODUCT_FAILURE_LIST_FAILED,
    error
  }
}

export function retryProductFailure(id: number): RetryFailuresActionTypes {
  return {
    type: RETRY_PRODUCT_FAILURE,
    retryFailureRequest: {
      id
    }
  }
}

export function retryProductFailureSucceeded(): RetryFailuresActionTypes {
  return {
    type: RETRY_PRODUCT_FAILURE_SUCCEEDED
  }
}

export function retryProductFailureFailed(error: string): RetryFailuresActionTypes {
  return {
    type: RETRY_PRODUCT_FAILURE_FAILED,
    error
  }
}

export function rejectProductFailure(id: number): RejectFailuresActionTypes {
  return {
    type: REJECT_PRODUCT_FAILURE,
    rejectFailureRequest: {
      id
    }
  }
}

export function rejectProductFailureSucceeded(): RejectFailuresActionTypes {
  return {
    type: REJECT_PRODUCT_FAILURE_SUCCEEDED
  }
}

export function rejectProductFailureFailed(error: string): RejectFailuresActionTypes {
  return {
    type: REJECT_PRODUCT_FAILURE_FAILED,
    error
  }
}

export function resolveProductFailure(id: number, failureGrantType: FailureGrantType, errorTypeDetails: {}): ResolveFailuresActionTypes {
  return {
    type: RESOLVE_PRODUCT_FAILURE,
    resolveFailureRequest: {
      id,
      failureGrantType,
      errorTypeDetails
    }
  }
}

export function resolveProductFailureSucceeded(): ResolveFailuresActionTypes {
  return {
    type: RESOLVE_PRODUCT_FAILURE_SUCCEEDED
  }
}

export function resolveProductFailureFailed(error: string): ResolveFailuresActionTypes {
  return {
    type: RESOLVE_PRODUCT_FAILURE_FAILED,
    error
  }
}
