import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import "./FlatIdPage.css";
import { Dispatch } from "redux";
import { AppState } from "../../store";
import {flowRight} from "lodash";
import { connect } from 'react-redux';
import { UserSession } from "../../store/userSession/types";
import PageWrapper from "../../components/Drawer/PageWrapper";
import {Button, PageHeader, Row, Space, Modal} from "antd";
import {
  DownloadFlatIdFileRequest,
  ListFlatIdDirectoriesPageRequest,
  ListFlatIdDirectoriesPageResponse,
  ListFlatIdFilesPageRequest, ListFlatIdFilesPageResponse,
} from "../../store/maintenance/types";
import {listFlatIdFiles, listFlatIdDirectories, downloadFlatIdFile, publishFlatId} from "../../store/maintenance/actions";
import FlatIdDirectoriesView from "../../components/FlatIdMap/FlatIdDirectoriesView";
import FlatIdFilesView from "../../components/FlatIdMap/FlatIdFilesView";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";

interface Props extends RouteComponentProps {
  userSession: UserSession;

  listFlatIdFiles: (listFlatIdFilesPageRequest: ListFlatIdFilesPageRequest) => ListFlatIdFilesPageResponse;
  downloadFlatIdFile: (downloadFlatIdFileRequest: DownloadFlatIdFileRequest) => Boolean;
  publishFlatId: () => Boolean;
  listFlatIdFilesPageRequest: ListFlatIdFilesPageRequest;
  listFlatIdFilesPageResponse: ListFlatIdFilesPageResponse;

  listFlatIdDirectories: (listFlatIdDirectoriesPageRequest: ListFlatIdDirectoriesPageRequest) => ListFlatIdDirectoriesPageResponse;
  listFlatIdDirectoriesPageRequest: ListFlatIdDirectoriesPageRequest;
  listFlatIdDirectoriesPageResponse: ListFlatIdDirectoriesPageResponse;

  loading?: boolean;
  error?: string;
}

interface State {
  publishDialog: boolean
}

class FlatIdPage extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.onDirectoryClick = this.onDirectoryClick.bind(this);
    this.onDownloadClick = this.onDownloadClick.bind(this);
    this.onPublishClick = this.onPublishClick.bind(this);

    this.state = {
      publishDialog: false
    };
  }

  componentDidMount () {
    this.props.listFlatIdDirectories(this.props.listFlatIdDirectoriesPageRequest)
  }

  private onDirectoryClick(name: string) {
    this.props.listFlatIdFiles({
      name: name
    });
  }

  private onDownloadClick(file: string) {
    this.props.downloadFlatIdFile({
      directory: this.props.listFlatIdFilesPageRequest.name,
      file: file
    })
  }

  private onPublishClick() {
    this.props.publishFlatId()
  }

  private renderPublisher() {
    return <div style={{border: "1px solid lightgray", padding: 10}}>
      <Row>
        <p>{"Publish all Core Products to Pub/Sub topic (initial load)"}</p>
      </Row>
      <Row style={{marginTop: 10}}>
        <Button type="primary" size={"large"} onClick={() => this.setState({publishDialog: true})}>Publish</Button>
      </Row>
    </div>
  }

  public render() {
    return (
        <React.Fragment>
          {this.props.loading && <div>
              <div className="view-loading"/>
              <CircularProgress className="view-loading-spinner"/>
            </div>}
          <div className="push-product-page">
        <PageWrapper userSession={this.props.userSession}>
          <PageHeader
            ghost={false}
            onBack={() => window.history.back()}
            title="Flat-Id"
            subTitle="mapping of global and connected products id's"
          />

          {this.renderPublisher()}

          <Modal
              title={'Are you sure?'}
              visible={this.state.publishDialog}
              onOk={() => {
                this.setState({publishDialog: false})
                this.onPublishClick()
              }}
              onCancel={() => {
                this.setState({publishDialog: false})
              }}
              okText={'Yes!'}
              cancelText={'No, wait!'}
              okButtonProps={{ className: "bg-success" }}>
            <p><b>If you click to publish</b> Core Products will be published to <b>flat-id-map</b> Pub/Sub topic with the following JSON format</p>
              <pre>
                <code>
                  <p>&#123;</p>
                    <p style={{paddingLeft: 25}}>'global_id': number,</p>
                    <p style={{paddingLeft: 25}}>'modified_at': date,</p>
                    <p style={{paddingLeft: 25}}>'change_type': 'CREATED',</p>
                    <p style={{paddingLeft: 25}}>'target_markets': string[]</p>
                    <p style={{paddingLeft: 25}}>'core_product': core_product_object</p>
                  <p>&#10101;</p>
                </code>
              </pre>
            <p>Explore <b>core_product_object</b> <a target="_blank" href={'https://developer.mediamarktsaturn.com/docs/core-product/1/types/CoreProduct'}>here</a></p>
            <p><b>publisher-initial</b> GC function can be configured to push payloads to external Pub/Sub topics</p>
          </Modal>

          <h2 style={{margin: "30px 0 20px 20px"}}>Flat-Id last generated files</h2>
          <Space align={"start"} size={"large"}>
            <FlatIdDirectoriesView
                listFlatIdDirectoriesPageRequest={this.props.listFlatIdDirectoriesPageRequest}
                listFlatIdDirectoriesPageResponse={this.props.listFlatIdDirectoriesPageResponse}
                loading={this.props.loading}
                error={this.props.error}
                onDirectoryClick={this.onDirectoryClick}
            />

            <FlatIdFilesView
                listFlatIdFilesPageRequest={this.props.listFlatIdFilesPageRequest}
                listFlatIdFilesPageResponse={this.props.listFlatIdFilesPageResponse}
                loading={this.props.loading}
                error={this.props.error}
                onDownloadClick={this.onDownloadClick}
            />
          </Space>

        </PageWrapper>
      </div>
        </React.Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  userSession: state.userSession.userSession,
  listFlatIdDirectoriesPageRequest: state.maintenance.listFlatIdDirectoriesPageRequest,
  listFlatIdDirectoriesPageResponse: state.maintenance.listFlatIdDirectoriesPageResponse,
  listFlatIdFilesPageRequest: state.maintenance.listFlatIdFilesPageRequest,
  listFlatIdFilesPageResponse: state.maintenance.listFlatIdFilesPageResponse,
  loading: state.maintenance.loading,
  error: state.maintenance.error,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  listFlatIdFiles: (listFlatIdFilesPageRequest: ListFlatIdFilesPageRequest) => {
    dispatch(listFlatIdFiles(listFlatIdFilesPageRequest))
  },
  listFlatIdDirectories: (listFlatIdDirectoriesPageRequest: ListFlatIdDirectoriesPageRequest) => {
    dispatch(listFlatIdDirectories(listFlatIdDirectoriesPageRequest))
  },
  downloadFlatIdFile: (downloadFlatIdFileRequest: DownloadFlatIdFileRequest) => {
    dispatch(downloadFlatIdFile(downloadFlatIdFileRequest))
  },
  publishFlatId: () => {
    dispatch(publishFlatId())
  }
});

const enhance = flowRight(
  withRouter, connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(FlatIdPage);
