import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import "./ProductHistoryPage.css";
import { Dispatch } from "redux";
import { AppState } from "../../store";
import {flowRight} from "lodash";
import { connect } from 'react-redux';
import { UserSession } from "../../store/userSession/types";
import PageWrapper from "../../components/Drawer/PageWrapper";
import {Modal, PageHeader} from 'antd';

import ProductHistoryView from "../../components/ProductHistory/ProductHistoryView";
import {
  FetchCoreProductChangeRequest,
  FetchCoreProductChangeResponse,
  ProductChangeResponse
} from "../../store/prouctHistory/types";
import {fetchCoreProductChange} from "../../store/prouctHistory/actions";
import DataService from "../../services/DataService";
import Highlight from "../../components/Highlight/Highlight";

interface Props extends RouteComponentProps<{id: string}> {
  userSession: UserSession;

  coreProductChangeRequest: FetchCoreProductChangeRequest;
  coreProductChangeResponse: FetchCoreProductChangeResponse;

  fetchCoreProductChange: (pageRequest: FetchCoreProductChangeRequest) => FetchCoreProductChangeResponse;

  loading?: boolean;
  error?: string;
}

interface State {
  historyContent: string
  isHistoryVisible: boolean,
}

class ProductHistoryPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.onPaginationChanged = this.onPaginationChanged.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.onFilterChanged = this.onFilterChanged.bind(this);
    this.onHistoryOk = this.onHistoryOk.bind(this);

    this.state = {
      historyContent: undefined,
      isHistoryVisible: false,
    }
  }

  componentDidMount () {
    this.props.fetchCoreProductChange({
      ...this.props.coreProductChangeRequest,
      globalProductId: this.props.match.params.id as unknown as number
    })
  }

  private onPaginationChanged(page: number, size: number): void {
      this.props.fetchCoreProductChange({
        ...this.props.coreProductChangeRequest,
        page: page,
        size: size
      });
  }

  private onRowClick(productChangeResponse: ProductChangeResponse) {

    DataService.getCoreProductHistoryByPath(productChangeResponse.historyFilePath)
        .then(historyContent => {
          this.setState({isHistoryVisible: true, historyContent: historyContent})
        })
  }

  private onHistoryOk()  {
    this.setState({isHistoryVisible: false, historyContent: undefined})
  }

  private onFilterChanged (filterField: string, value: string) {

    if (filterField === 'globalProductId' && value.length !== 10) {
      return
    }

      this.props.fetchCoreProductChange({
        ... this.props.coreProductChangeRequest,
        [filterField]: value
      })
  }

  public render() {

    return (
      <div className="product-history-page">
        <PageWrapper userSession={this.props.userSession}>
          <PageHeader
            backIcon={null}
            ghost={false}
            onBack={() => window.history.back()}
            title="Core Product History"
            subTitle="Displays core product change history">
          </PageHeader>

          <ProductHistoryView
              pageRequest={this.props.coreProductChangeRequest}
              pageResponse={this.props.coreProductChangeResponse}
              onPaginationChanged={this.onPaginationChanged}
              loading={this.props.loading}
              error={this.props.error}
              onRowClick={this.onRowClick}
              onFilterChanged={this.onFilterChanged}
          />

          <Modal title="Detail" width={'80%'} visible={this.state.isHistoryVisible}
                 onCancel={this.onHistoryOk} onOk={this.onHistoryOk}>
            <Highlight language="json">
              {this.state.historyContent}
            </Highlight>
          </Modal>

        </PageWrapper>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  userSession: state.userSession.userSession,

  coreProductChangeRequest: state.coreProductChange.coreProductChangeRequest,
  coreProductChangeResponse: state.coreProductChange.coreProductChangeResponse,

  coreProductChangeDetailRequest: state.coreProductChange.coreProductChangeDetailRequest,
  coreProductChangeDetailResponse: state.coreProductChange.coreProductChangeDetailResponse,

  loading: state.coreProductChange.loading,
  error: state.coreProductChange.error
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchCoreProductChange: (request: FetchCoreProductChangeRequest) => {
    dispatch(fetchCoreProductChange(request))
  }
});

const enhance = flowRight(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(ProductHistoryPage);
