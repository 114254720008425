import * as React from "react";
import { Dropdown, Menu, message } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { UserSession } from "../../store/userSession/types";
import { resolveApiBaseUrl } from "../../environment";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  userSession: UserSession;
}

export default function UserIcon(props: Props) {

  const handleLogout = () => {
    window.location.href = resolveApiBaseUrl() + '/logout';
  };

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={handleMenuClick}>
        Profile
      </Menu.Item>
      <Menu.Item key="2" onClick={handleMenuClick}>
        Settings
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="3" onClick={handleLogout}>
        <LogoutOutlined /> Logout
      </Menu.Item>
    </Menu>
  );

  function handleMenuClick(e: any) {
    message.info('Click on menu item.');
    console.log('click', e);
  }

  return (
    <Dropdown overlay={menu} >
      <div {...props}>
        <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
          {props.userSession && (props.userSession.lastname)}, {props.userSession && props.userSession.firstname}
        </a>
      </div>
    </Dropdown>
  );
}
