import { Select } from "antd";
import * as React from "react";
import { subsidiariesList } from "../../../store/common/types";

const { Option } = Select;

export interface SubsidiarySelectProps {
  id: string
  value: any;
  mode?: 'multiple' | 'tags';
  onChange: (value: string) => void;
}

export default function SubsidiarySelect(props: SubsidiarySelectProps) {

  const { id, mode, value, onChange } = props;

  return (
    <Select id={id}
            mode={mode}
            value={value}
            placeholder="Select subsidiary"
            onChange={(value) => onChange(value)}>
      <Option value="">Not Selected</Option>
      {
        subsidiariesList.map(subsidiary => {
          return <Option value={subsidiary}>{subsidiary}</Option>
        })
      }
    </Select>
  )
}
