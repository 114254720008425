import {
  ADD_PRODUCT,
  ADD_PRODUCT_FAILED,
  ADD_PRODUCT_SUCCEEDED,
  ADD_PRODUCTS,
  ADD_PRODUCTS_FAILED,
  ADD_PRODUCTS_SUCCEEDED,
  AddProductRequest,
  AddProductResponse,
  AddProductsRequest,
  CLOSE_ERROR_MESSAGE,
  FETCH_AMBIGUOUS_MPN_STATS,
  FETCH_AMBIGUOUS_MPN_STATS_FAILED,
  FETCH_AMBIGUOUS_MPN_STATS_SUCCEEDED,
  FETCH_CORE_PRODUCT,
  FETCH_CORE_PRODUCT_FAILED,
  FETCH_CORE_PRODUCT_SUCCEEDED,
  FETCH_DUPLICATED_PRODUCTS,
  FETCH_DUPLICATED_PRODUCTS_FAILED,
  FETCH_DUPLICATED_PRODUCTS_SUCCEEDED,
  FETCH_MANUFACTURER_MPN_STATS,
  FETCH_MANUFACTURER_MPN_STATS_FAILED,
  FETCH_MANUFACTURER_MPN_STATS_SUCCEEDED,
  FETCH_PRODUCTS,
  FETCH_PRODUCTS_FAILED,
  FETCH_PRODUCTS_SUCCEEDED,
  FetchAmbiguousMPNStatsAction,
  FetchAmbiguousMPNStatsPageRequest,
  FetchAmbiguousMPNStatsPageResponse,
  FetchDuplicatedProductsPageRequest,
  FetchDuplicatedProductsPageResponse,
  FetchManufacturerMPNStatsAction,
  FetchManufacturerMPNStatsPageRequest,
  FetchManufacturerMPNStatsPageResponse,
  FetchProductsActionTypes,
  FetchProductsPageRequest,
  FetchProductsPageResponse,
  GENERATE_DUPLICATED_PRODUCTS_REPORT,
  GENERATE_DUPLICATED_PRODUCTS_REPORT_FAILED,
  GENERATE_DUPLICATED_PRODUCTS_REPORT_SUCCEEDED,
  GENERATE_MPN_STATS_REPORT,
  GENERATE_MPN_STATS_REPORT_FAILED,
  GENERATE_MPN_STATS_REPORT_SUCCEEDED,
  GlobalProduct,
  UPDATE_PRIMARY_PRODUCT,
  UPDATE_PRIMARY_PRODUCT_FAILED,
  UPDATE_PRIMARY_PRODUCT_SUCCEEDED,
  UpdatePrimaryProductRequest,
} from './types'

export function closeErrorMessage(): FetchProductsActionTypes {
  return {
    type: CLOSE_ERROR_MESSAGE,
  }
}

export function addProduct(addProductRequest: AddProductRequest): FetchProductsActionTypes {
  return {
    type: ADD_PRODUCT,
    addProductRequest
  }
}

export function addProductSucceeded(payload: AddProductResponse): FetchProductsActionTypes {
  return {
    type: ADD_PRODUCT_SUCCEEDED,
    payload
  }
}

export function addProductFailed(error: string): FetchProductsActionTypes {
  return {
    type: ADD_PRODUCTS_FAILED,
    error
  }
}

export function addProducts(addProductsRequest: AddProductsRequest): FetchProductsActionTypes {
  return {
    type: ADD_PRODUCTS,
    addProductsRequest
  }
}

export function addProductsSucceeded(): FetchProductsActionTypes {
  return {
    type: ADD_PRODUCTS_SUCCEEDED
  }
}

export function addProductsFailed(error: string): FetchProductsActionTypes {
  return {
    type: ADD_PRODUCT_FAILED,
    error
  }
}

export function fetchProducts(pageRequest: FetchProductsPageRequest): FetchProductsActionTypes {
  return {
    type: FETCH_PRODUCTS,
    pageRequest
  }
}

export function fetchProductsSucceeded(payload: FetchProductsPageResponse): FetchProductsActionTypes {
  return {
    type: FETCH_PRODUCTS_SUCCEEDED,
    payload
  }
}

export function fetchProductsFailed(error: string): FetchProductsActionTypes {
  return {
    type: FETCH_PRODUCTS_FAILED,
    error
  }
}

export function fetchDuplicatedProducts(pageRequest: FetchDuplicatedProductsPageRequest): FetchProductsActionTypes {
  return {
    type: FETCH_DUPLICATED_PRODUCTS,
    pageRequest
  }
}

export function fetchDuplicatedProductsSucceeded(payload: FetchDuplicatedProductsPageResponse): FetchProductsActionTypes {
  return {
    type: FETCH_DUPLICATED_PRODUCTS_SUCCEEDED,
    payload
  }
}

export function fetchDuplicatedProductsFailed(error: string): FetchProductsActionTypes {
  return {
    type: FETCH_DUPLICATED_PRODUCTS_FAILED,
    error
  }
}

export function generateDuplicatedProductsReport(pageRequest: FetchDuplicatedProductsPageRequest): FetchProductsActionTypes {
  return {
    type: GENERATE_DUPLICATED_PRODUCTS_REPORT,
    pageRequest
  }
}

export function generateDuplicatedProductsReportSucceeded(): FetchProductsActionTypes {
  return {
    type: GENERATE_DUPLICATED_PRODUCTS_REPORT_SUCCEEDED,
  }
}

export function generateDuplicatedProductsReportFailed(error: string): FetchProductsActionTypes {
  return {
    type: GENERATE_DUPLICATED_PRODUCTS_REPORT_FAILED,
    error
  }
}

export function fetchManufacturerMPNStats(manufacturerMPNStatsPageRequest: FetchManufacturerMPNStatsPageRequest): FetchManufacturerMPNStatsAction {
  return {
    type: FETCH_MANUFACTURER_MPN_STATS,
    manufacturerMPNStatsPageRequest
  }
}

export function fetchManufacturerMPNStatsSucceeded(payload: FetchManufacturerMPNStatsPageResponse): FetchProductsActionTypes {
  return {
    type: FETCH_MANUFACTURER_MPN_STATS_SUCCEEDED,
    payload
  }
}

export function fetchManufacturerMPNStatsFailed(error: string): FetchProductsActionTypes {
  return {
    type: FETCH_MANUFACTURER_MPN_STATS_FAILED,
    error
  }
}

export function fetchAmbiguousMPNStats(ambiguousMPNStatsPageRequest: FetchAmbiguousMPNStatsPageRequest): FetchAmbiguousMPNStatsAction {
  return {
    type: FETCH_AMBIGUOUS_MPN_STATS,
    ambiguousMPNStatsPageRequest
  }
}

export function fetchAmbiguousMPNStatsSucceeded(payload: FetchAmbiguousMPNStatsPageResponse): FetchProductsActionTypes {
  return {
    type: FETCH_AMBIGUOUS_MPN_STATS_SUCCEEDED,
    payload
  }
}

export function fetchAmbiguousMPNStatsFailed(error: string): FetchProductsActionTypes {
  return {
    type: FETCH_AMBIGUOUS_MPN_STATS_FAILED,
    error
  }
}

export function generateMPNStatsReport(manufacturerMPNStatsPageRequest: FetchManufacturerMPNStatsPageRequest): FetchProductsActionTypes {
  return {
    type: GENERATE_MPN_STATS_REPORT,
    manufacturerMPNStatsPageRequest
  }
}

export function generateMPNStatsReportSucceeded(): FetchProductsActionTypes {
  return {
    type: GENERATE_MPN_STATS_REPORT_SUCCEEDED,
  }
}

export function generateMPNStatsReportFailed(error: string): FetchProductsActionTypes {
  return {
    type: GENERATE_MPN_STATS_REPORT_FAILED,
    error
  }
}

export function fetchCoreProduct(id: string): FetchProductsActionTypes {
  return {
    type: FETCH_CORE_PRODUCT,
    id
  }
}

export function fetchCoreProductSucceeded(payload: GlobalProduct): FetchProductsActionTypes {
  return {
    type: FETCH_CORE_PRODUCT_SUCCEEDED,
    payload
  }
}

export function fetchCoreProductFailed(error: string): FetchProductsActionTypes {
  return {
    type: FETCH_CORE_PRODUCT_FAILED,
    error
  }
}

export function updatePrimaryProduct(updatePrimaryProductRequest: UpdatePrimaryProductRequest): FetchProductsActionTypes {
  return {
    type: UPDATE_PRIMARY_PRODUCT,
    updatePrimaryProductRequest
  }
}

export function updatePrimaryProductSucceeded(): FetchProductsActionTypes {
  return {
    type: UPDATE_PRIMARY_PRODUCT_SUCCEEDED
  }
}

export function updatePrimaryProductFailed(error: string): FetchProductsActionTypes {
  return {
    type: UPDATE_PRIMARY_PRODUCT_FAILED,
    error
  }
}
