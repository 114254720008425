import { Reducer } from "redux";
import {
  FETCH_IMPORT_TASK_EXECUTION,
  FETCH_IMPORT_TASK_EXECUTION_FAILED,
  FETCH_IMPORT_TASK_EXECUTION_SUCCEEDED,
  FETCH_IMPORTS,
  FETCH_IMPORTS_FAILED,
  FETCH_IMPORTS_SUCCEEDED,
  ImportsActionTypes,
  ImportTaskExecutionState,
  RETRY_IMPORTS,
  RETRY_IMPORTS_FAILED,
  RETRY_IMPORTS_SUCCEEDED,
  RetryActionTypes,
  RetryImportState
} from "./types";
import { pageResponse } from "../common/types";

const initialState: ImportTaskExecutionState = {
  importTaskExecutionId: null,
  importTaskExecution: null,
  pageRequest: {
    page: 0,
    size: 10,
    importStatus: null,
    sort: {
      field: "importStartedAt",
      direction: 'DESC'
    }
  },
  pageResponse: {
    ...pageResponse(),
    content: []
  },
  loading: false,
  error: null
};

export const productImportsReducer: Reducer<ImportTaskExecutionState, ImportsActionTypes> = (state: ImportTaskExecutionState = initialState, action: ImportsActionTypes): ImportTaskExecutionState => {
  switch (action.type) {

    case FETCH_IMPORTS: {
      return {
        ... state,
        pageRequest: {
          ... action.pageRequest
        },
        loading: true,
        error: null
      }
    }

    case FETCH_IMPORTS_SUCCEEDED: {
      return {
        ...state,
        pageResponse: action.payload,
        loading: false,
        error: null
      }
    }

    case FETCH_IMPORTS_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.error
      }
    }

    case FETCH_IMPORT_TASK_EXECUTION: {
      return {
        ... state,
        importTaskExecutionId: action.id,
        loading: true,
        error: null
      }
    }

    case FETCH_IMPORT_TASK_EXECUTION_SUCCEEDED: {
      return {
        ...state,
        importTaskExecution: action.payload,
        loading: false,
        error: null
      }
    }

    case FETCH_IMPORT_TASK_EXECUTION_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.error
      }
    }

    default:
      neverReached(action);
  }

  return state;
};

const retryInitialState: RetryImportState = {
  retryRequest: {
    ids: []
  },
  retryLoading: false,
  retryError: null
};

export const retryImportsReducer: Reducer<RetryImportState, RetryActionTypes> = (state: RetryImportState = retryInitialState, action: RetryActionTypes): RetryImportState => {
  switch (action.type) {

    case RETRY_IMPORTS: {
      return {
        ... state,
        retryRequest: {
          ... action.retryRequest
        },
        retryLoading: true,
        retryError: null
      }
    }

    case RETRY_IMPORTS_SUCCEEDED: {
      return {
        ...state,
        retryLoading: false,
        retryError: null
      }
    }

    case RETRY_IMPORTS_FAILED: {
      return {
        ...state,
        retryLoading: false,
        retryError: action.error
      }
    }

    default:
      neverReached(action);
  }

  return state;
};


const neverReached = (never: never) => {};
