import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import "./AddTaskPage.css";
import { Dispatch } from "redux";
import { AppState, store } from "../../store";
import {flowRight} from "lodash";
import { connect } from 'react-redux';
import { UserSession } from "../../store/userSession/types";
import PageWrapper from "../../components/Drawer/PageWrapper";
import { PageHeader } from "antd";
import AddTaskView from "../../components/AddTask/AddTask";
import { CreateImportTask } from "../../store/tasks/types";
import { closeErrorMessage, createTask } from "../../store/tasks/actions";
import { ProductSourceSystem, Subsidiary } from "../../store/common/types";

interface Props extends RouteComponentProps {
  userSession: UserSession;
  createTaskRequest: CreateImportTask;
  createTask: (createTask: CreateImportTask) => void;
  loading?: boolean;
  error?: string;
}

interface State {
  subsidiary: Subsidiary;
  sourceSystem: ProductSourceSystem;
  fromDate: Date;
  toDate: Date;
  intervalMinutes: number;
  enabled: boolean;
  queryCondition: string;
  batchSize: number;
}

class AddTaskPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.onFormFieldChanged = this.onFormFieldChanged.bind(this);
    this.onMessageClose = this.onMessageClose.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      subsidiary: props.createTaskRequest.subsidiary,
      sourceSystem: props.createTaskRequest.sourceSystem,
      fromDate: props.createTaskRequest.fromDate,
      toDate: props.createTaskRequest.toDate,
      intervalMinutes: props.createTaskRequest.intervalMinutes,
      enabled: props.createTaskRequest.enabled,
      queryCondition: props.createTaskRequest.queryCondition,
      batchSize: props.createTaskRequest.batchSize
    };
  }

  private onMessageClose() {
    store.dispatch(closeErrorMessage());
  }

  private onFormFieldChanged(filterField: string, value: any) {

    this.setState({
      ... this.state,
      [filterField]: value
    }, () => {
      console.log("Filter: " + filterField + ", value: " + value);
    })
  }

  private onSubmit() {
    this.props.createTask({
      subsidiary: this.state.subsidiary,
      sourceSystem: this.state.sourceSystem,
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
      intervalMinutes: this.state.intervalMinutes,
      enabled: this.state.enabled,
      queryCondition: this.state.queryCondition,
      batchSize: this.state.batchSize
    });
  }

  public render() {
    return (
      <div className="add-task-page">
        <PageWrapper userSession={this.props.userSession}>
          <PageHeader
            backIcon={null}
            ghost={false}
            onBack={() => window.history.back()}
            title="Add task"
            subTitle="Creates task for importing products from various source systems and subsidiaries for specified time range"
          >
          </PageHeader>
          <AddTaskView
            subsidiary={this.state.subsidiary}
            sourceSystem={this.state.sourceSystem}
            fromDate={this.state.fromDate}
            toDate={this.state.toDate}
            intervalMinutes={this.state.intervalMinutes}
            enabled={this.state.enabled}
            batchSize={this.state.batchSize}
            queryCondition={this.state.queryCondition}
            onFormFieldChange={this.onFormFieldChanged}
            onMessageClose={this.onMessageClose}
            onSubmit={this.onSubmit}
            error={this.props.error}
            loading={this.props.loading}
          />
        </PageWrapper>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  userSession: state.userSession.userSession,
  createTaskRequest: state.tasks.createImportTask,
  loading: state.tasks.loading,
  error: state.tasks.error,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  createTask: (createTaskRequest: CreateImportTask) => {
    dispatch(createTask(createTaskRequest))
  },
});

const enhance = flowRight(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(AddTaskPage);
