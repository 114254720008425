import axios, {AxiosInstance} from 'axios'

const options = {
    headers: {
        'accept': 'application/json'
    },
    withCredentials: true
};

const axiosInstance: AxiosInstance = axios.create(options)

axiosInstance.interceptors.response.use((response) => response, (error) => {
    if (error.response) { // server side error
        console.log("ErrorCode: " + error.response.status);
        if (error.response.status == 502) { // Gateway error
            error.message = 'The server encountered a temporary error and could not complete your request. Please try later';
        } else if (error.response.data?.error) {
            error.message = error.response.data.error;
        }
    }
    throw error;
});

export default axiosInstance
