import React from "react";
import "./EditKindOfProduct.css"
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import {Alert, Button, Col, Form, Input, Row, Select} from 'antd';
import {GetKindOfItemResponse} from "../../store/kindOf/types";

const {Option} = Select;
import {Store} from 'rc-field-form/lib/interface';
import {kindOfProductLanguageTypeList} from "../../store/common/types";

export interface Props {
    onSubmit: (store: Store) => void;
    onDelete: (code: string) => void;
    onMessageClose: () => void;
    item: GetKindOfItemResponse;
    loading: boolean;
    error: string;
}

export const EditKindOfProduct: React.FunctionComponent<Props> = (props) => {

    const {onSubmit, onDelete, onMessageClose, item, loading, error} = props;

    if (loading) {
        return <div>
            <div className="view-loading"/>
            <CircularProgress className="view-loading-spinner"/>
        </div>
    }

    return (
        <React.Fragment>
            {loading && <div>
                <div className="view-loading"/>
                <CircularProgress className="view-loading-spinner"/>
            </div>}

            {error &&
            <React.Fragment>
                <Alert
                    message="Error"
                    description={error}
                    type="error"
                    closable
                    onClose={onMessageClose}
                />
                <br/>
            </React.Fragment>}
            <Form
                layout={'horizontal'}
                initialValues={item}
                onFinish={(store) => onSubmit(store)}>
                <Row gutter={24}>
                    <Col span={10}>
                        <Form.Item labelCol={{span: 4}} label="Code" name={"code"}>
                            <Input disabled id="kind-of-code-input" placeholder="Input unique code"/>
                        </Form.Item>
                    </Col>
                </Row>

                <Form.List name="typographies">
                    {(fields, {add, remove}) => {
                        return <>
                            {fields.map((field, index) => (
                                <Row key={field.key} gutter={8}>
                                    <Col span={4}>
                                        <Form.Item {...field} labelCol={{span: 10}} label="Language"
                                                   name={[field.name, 'language']}
                                                   rules={[{required: true, message: 'Language is mandatory'}]}>
                                            <Select id={"language-select"} placeholder="Select language">
                                                {kindOfProductLanguageTypeList.map(language => <Option mode={"tags"}
                                                                                          value={language}>{language}</Option>)}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item {...field} label="Name" name={[field.name, "name"]}
                                                   rules={[{whitespace: false, required: true, message: 'Name is mandatory'}]}>
                                            <Input id="kind-of-name-input" placeholder="Input name"/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item {...field} label="Description" name={[field.name, "description"]}
                                                   rules={[{ whitespace: false, required: false, message: 'Description is mandatory' }]}>
                                            <Input id="kind-of-description-input" placeholder="Input description"/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={2}>
                                        <Button type="primary" style={{backgroundColor: "orange", borderColor: "orange"}}
                                                htmlType="button" onClick={() => remove(index)}>
                                            Remove
                                        </Button>
                                    </Col>
                                </Row>
                            ))}

                            <Row gutter={8}>
                                <Col span={4}>
                                    <Form.Item labelCol={{span: 10}} label="Actions:">
                                        <Button type="ghost" htmlType="button" onClick={() => add()}>
                                            Add name
                                        </Button>
                                    </Form.Item>
                                </Col>
                                <Col span={2}>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit">
                                            Update
                                        </Button>
                                    </Form.Item>
                                </Col>
                                <Col span={2}>
                                    <Form.Item>
                                        <Button type="primary" style={{backgroundColor: "red", borderColor: "red"}}
                                                htmlType="button" onClick={() => onDelete(item.code)}>
                                            Delete
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                    }}
                </Form.List>
            </Form>

        </React.Fragment>
    );
};

export default EditKindOfProduct;
