import {
    AvoidanceFixDisregardRuleCreateRequest,
    AvoidanceFixDisregardRuleCreateResponse,
    AvoidanceFixDisregardRulesActionTypes,
    AvoidanceFixDisregardRulesPageRequest,
    AvoidanceFixDisregardRulesPageResponse,
    CLOSE_ERROR_MESSAGE,
    CREATE_AVOIDANCE_FIX_DISREGARD,
    CREATE_AVOIDANCE_FIX_DISREGARD_SUCCEEDED,
    DELETE_AVOIDANCE_FIX_DISREGARD,
    DELETE_AVOIDANCE_FIX_DISREGARD_SUCCEEDED,
    FETCH_AVOIDANCE_FIX_DISREGARD,
    FETCH_AVOIDANCE_FIX_DISREGARD_FAILED,
    FETCH_AVOIDANCE_FIX_DISREGARD_SUCCEEDED,
} from './types'

export function closeErrorMessage(): AvoidanceFixDisregardRulesActionTypes {
    return {
        type: CLOSE_ERROR_MESSAGE,
    }
}

export function fetchAvoidanceFixDisregardRules(pageRequest: AvoidanceFixDisregardRulesPageRequest): AvoidanceFixDisregardRulesActionTypes {
    return {
        type: FETCH_AVOIDANCE_FIX_DISREGARD,
        pageRequest
    }
}

export function avoidanceFixDisregardSucceeded(payload: AvoidanceFixDisregardRulesPageResponse): AvoidanceFixDisregardRulesActionTypes {
    return {
        type: FETCH_AVOIDANCE_FIX_DISREGARD_SUCCEEDED,
        payload
    }
}

export function createAvoidanceFixDisregardRule(request: AvoidanceFixDisregardRuleCreateRequest): AvoidanceFixDisregardRulesActionTypes {
    return {
        type: CREATE_AVOIDANCE_FIX_DISREGARD,
        request
    }
}

export function createAvoidanceFixDisregardRuleSucceeded(payload: AvoidanceFixDisregardRuleCreateResponse): AvoidanceFixDisregardRulesActionTypes {
    return {
        type: CREATE_AVOIDANCE_FIX_DISREGARD_SUCCEEDED,
        payload
    }
}

export function deleteAvoidanceFixDisregardRule(request: string[]): AvoidanceFixDisregardRulesActionTypes {
    return {
        type: DELETE_AVOIDANCE_FIX_DISREGARD,
        request
    }
}

export function deleteAvoidanceFixDisregardRuleSucceeded(): AvoidanceFixDisregardRulesActionTypes {
    return {
        type: DELETE_AVOIDANCE_FIX_DISREGARD_SUCCEEDED
    }
}

export function avoidanceFixDisregardFailed(error: string): AvoidanceFixDisregardRulesActionTypes {
    return {
        type: FETCH_AVOIDANCE_FIX_DISREGARD_FAILED,
        error
    }
}
