import { all, call, put, takeLatest } from 'redux-saga/effects'

import {
  FETCH_USER_SESSION, FETCH_USER_SESSION_SILENT, FetchUserSessionAction,
} from './types'
import {
  fetchUserSessionFailed,
  fetchUserSessionSucceeded
} from './actions'

import userService from '../../services/UserService'

export default function* userSessionSaga() {
  yield all([
    watchFetchUserSession(),
    watchFetchUserSessionSilent()
  ])
}

export function* watchFetchUserSession() {
  yield takeLatest(FETCH_USER_SESSION, handleFetchUserSession)
}

export function* watchFetchUserSessionSilent() {
  yield takeLatest(FETCH_USER_SESSION_SILENT, handleFetchUserSessionSilent)
}

export function* handleFetchUserSession(action: FetchUserSessionAction) {
  try {
    const data = yield call([userService, userService.getUserSession])
    yield put(fetchUserSessionSucceeded(data))
  } catch (error) {
    yield put(fetchUserSessionFailed(`${error.message}`))
  }
}

// Reset the userSession timeout in the backend
export function* handleFetchUserSessionSilent(action: FetchUserSessionAction) {
  try {
    yield call([userService, userService.getUserSession])
  } catch (error) {
    yield put(fetchUserSessionFailed("Session Closed!"))
  }
}
