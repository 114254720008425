import { PageResponse, ProductSourceSystem, Subsidiary } from "../common/types";

export const RETRY_PRODUCT_FAILURE = 'RETRY_PRODUCT_FAILURE'
export const RETRY_PRODUCT_FAILURE_SUCCEEDED = 'RETRY_PRODUCT_FAILURE_SUCCEEDED'
export const RETRY_PRODUCT_FAILURE_FAILED = 'RETRY_PRODUCT_FAILURE_FAILED'

export const REJECT_PRODUCT_FAILURE = 'REJECT_PRODUCT_FAILURE'
export const REJECT_PRODUCT_FAILURE_SUCCEEDED = 'REJECT_PRODUCT_FAILURE_SUCCEEDED'
export const REJECT_PRODUCT_FAILURE_FAILED = 'REJECT_PRODUCT_FAILURE_FAILED'

export const REJECT_PRODUCT_FAILURE_LIST = 'REJECT_PRODUCT_FAILURE_LIST'
export const REJECT_PRODUCT_FAILURE_LIST_SUCCEEDED = 'REJECT_PRODUCT_FAILURE_LIST_SUCCEEDED'
export const REJECT_PRODUCT_FAILURE_LIST_FAILED = 'REJECT_PRODUCT_FAILURE_LIST_FAILED'

export const REJECT_IMPORTER_FAILURE = 'REJECT_IMPORTER_FAILURE'
export const REJECT_IMPORTER_FAILURE_SUCCEEDED = 'REJECT_IMPORTER_FAILURE_SUCCEEDED'
export const REJECT_IMPORTER_FAILURE_FAILED = 'REJECT_IMPORTER_FAILURE_FAILED'

export const REJECT_IMPORTER_FAILURE_LIST = 'REJECT_IMPORTER_FAILURE_LIST'
export const REJECT_IMPORTER_FAILURE_LIST_SUCCEEDED = 'REJECT_IMPORTER_FAILURE_LIST_SUCCEEDED'
export const REJECT_IMPORTER_FAILURE_LIST_FAILED = 'REJECT_IMPORTER_FAILURE_LIST_FAILED'

export const RESOLVE_PRODUCT_FAILURE = 'RESOLVE_PRODUCT_FAILURE'
export const RESOLVE_PRODUCT_FAILURE_SUCCEEDED = 'RESOLVE_PRODUCT_FAILURE_SUCCEEDED'
export const RESOLVE_PRODUCT_FAILURE_FAILED = 'RESOLVE_PRODUCT_FAILURE_FAILED'

export const RETRY_IMPORTER_FAILURE = 'RETRY_IMPORTER_FAILURE'
export const RETRY_IMPORTER_FAILURE_SUCCEEDED = 'RETRY_IMPORTER_FAILURE_SUCCEEDED'
export const RETRY_IMPORTER_FAILURE_FAILED = 'RETRY_IMPORTER_FAILURE_FAILED'

export const RETRY_IMPORTER_FAILURE_LIST = 'RETRY_IMPORTER_FAILURE_LIST'
export const RETRY_IMPORTER_FAILURE_LIST_SUCCEEDED = 'RETRY_IMPORTER_FAILURE_LIST_SUCCEEDED'
export const RETRY_IMPORTER_FAILURE_LIST_FAILED = 'RETRY_IMPORTER_FAILURE_LIST_FAILED'

export const RETRY_PRODUCT_FAILURE_LIST = 'RETRY_PRODUCT_FAILURE_LIST'
export const RETRY_PRODUCT_FAILURE_LIST_SUCCEEDED = 'RETRY_PRODUCT_FAILURE_LIST_SUCCEEDED'
export const RETRY_PRODUCT_FAILURE_LIST_FAILED = 'RETRY_PRODUCT_FAILURE_LIST_FAILED'

export const FETCH_IMPORTER_FAILURE_LIST = 'FETCH_IMPORTER_FAILURE_LIST'
export const FETCH_IMPORTER_FAILURE_LIST_SUCCEEDED = 'FETCH_IMPORTER_FAILURE_LIST_SUCCEEDED'
export const FETCH_IMPORTER_FAILURE_LIST_FAILED = 'FETCH_IMPORTER_FAILURE_LIST_FAILED'

export const FETCH_PRODUCT_FAILURE_LIST = 'FETCH_PRODUCT_FAILURE_LIST'
export const FETCH_PRODUCT_FAILURE_LIST_SUCCEEDED = 'FETCH_PRODUCT_FAILURE_LIST_SUCCEEDED'
export const FETCH_PRODUCT_FAILURE_LIST_FAILED = 'FETCH_PRODUCT_FAILURE_LIST_FAILED'

export const GENERATE_REPORT = 'GENERATE_REPORT'
export const GENERATE_REPORT_SUCCEEDED = 'GENERATE_REPORT_SUCCEEDED'
export const GENERATE_REPORT_FAILED = 'GENERATE_REPORT_FAILED'

export const FETCH_IMPORTER_FAILURE = 'FETCH_IMPORTER_FAILURE'
export const FETCH_IMPORTER_FAILURE_SUCCEEDED = 'FETCH_IMPORTER_FAILURE_SUCCEEDED'
export const FETCH_IMPORTER_FAILURE_FAILED = 'FETCH_IMPORTER_FAILURE_FAILED'

export const FETCH_PRODUCT_FAILURE = 'FETCH_PRODUCT_FAILURE'
export const FETCH_PRODUCT_FAILURE_SUCCEEDED = 'FETCH_PRODUCT_FAILURE_SUCCEEDED'
export const FETCH_PRODUCT_FAILURE_FAILED = 'FETCH_PRODUCT_FAILURE_FAILED'

export const FETCH_LEGACY_PRODUCT = 'FETCH_LEGACY_PRODUCT'
export const FETCH_LEGACY_PRODUCT_SUCCEEDED = 'FETCH_LEGACY_PRODUCT_SUCCEEDED'
export const FETCH_LEGACY_PRODUCT_FAILED = 'FETCH_LEGACY_PRODUCT_FAILED'

export type ImportStatus = 'FAILED' | 'SUCCEEDED' | 'RUNNING' | 'REJECTED';
export type FailureStatus = 'UNRESOLVED' | 'RESOLVED' | 'REJECTED' | 'RUNNING';

export type FailureType = 'PRODUCT_FAILURE' | 'RANGE_IMPORT_FAILURE';

export type ErrorType = 'GENERAL' | 'GTINS_MISSING' | 'INVALID_GTINS' | 'ONLY_INTERNAL_GTINS_PROVIDED'
    | 'MORE_THAN_1_GLOBAL_ID_RESOLVED' | 'GLOBAL_ID_CONFLICT' | 'PRIMARY_GTIN_CONFLICT'
    | 'KIND_OF_PRODUCT_CONFLICT' | 'MULTIPLE_KIND_OF_PRODUCT_FOUND' | 'KIND_OF_PRODUCT_NOT_FOUND' | 'DUPLICATE_COUNTRY_FOUND'
    | 'SERVICE_AND_SUBSCRIPTION_ERROR' | 'AMBIGUOUS_PRODUCT_GROUP_FOUND';

export type FailureGrantType = 'GLOBAL_ID_CONFLICT_RESOLVE_GRANT' | 'MERGE_GLOBAL_PRODUCTS_GRANT' | 'DUPLICATE_COUNTRY_RESOLVE_GRANT' | 'KIND_OF_PRODUCT_RESOLVE_GRANT';

export interface FetchImporterFailuresPageRequest {
  page: number;
  size: number;
  sort: {field: string, direction: 'ASC' | 'DESC'};
  productId: string;
  sourceSystem: ProductSourceSystem;
  subsidiary: Subsidiary;
  importStatus: ImportStatus;
  failureType: FailureType;
}

export interface FetchImporterFailuresPageResponse extends PageResponse {
  content: ImporterFailure[];
}

export interface FetchProductFailuresPageRequest {
  page: number;
  size: number;
  sort: {field: string, direction: 'ASC' | 'DESC'};
  productId: string;
  subsidiary: Subsidiary;
  failureStatus: FailureStatus;
  errorType: ErrorType;
  activeProduct: boolean;
}

export interface FetchProductFailuresPageResponse extends PageResponse {
  content: ProductFailure[];
}

export interface ImporterFailure {
  id: number;
  failureType: FailureType;
  failureMessage: string;
  importStatus: ImportStatus;
  importedProductsCount: number;

  fromDate: Date;
  toDate: Date;
  page: number;
  size: number;

  productId: string;
  sourcePayload: string;
  subsidiary: Subsidiary;
  productSourceSystem: ProductSourceSystem

  createdAt: Date;
  updatedAt: Date;
  createdByFullName: string;
  updatedByFullName: string;
}

export interface ProductFailure {
  id: number;
  globalId: number;
  productId: string;
  subsidiary: Subsidiary;
  sourceSystem: ProductSourceSystem;
  activeProduct: boolean;

  message: string;
  errorType: ErrorType;
  status: FailureStatus;
  productDetails: { V1: string };
  errorTypeDetails: { globalProductId: string, proposedGlobalProductId: string, } &
      { mergeId: string, proposedGlobalIds: number[], importedProductGtins: string[] } &
      { importedProductId: string, importedProductSourceSystem: string, importedProductSubsidiary: string, duplicatedProductIds: string[] } &
      { globalProductId: string, kindOfProduct: string, kindOfProducts: string[], proposedKindOfProduct: string, importedProductSourceSystem: ProductSourceSystem, importedProductSubsidiary: Subsidiary, importedProductId: string};

  createdAt: Date;
  updatedAt: Date;
  createdByFullName: string;
  updatedByFullName: string;
}

export interface FailuresState {
  importerFailureId: number;
  productFailureId: number;
  importerFailure: ImporterFailure;
  productFailure: ProductFailure;
  importerFailuresPageRequest: FetchImporterFailuresPageRequest;
  importerFailuresPageResponse: FetchImporterFailuresPageResponse;
  productFailuresPageRequest: FetchProductFailuresPageRequest;
  productFailuresPageResponse: FetchProductFailuresPageResponse;
  legacyProduct: string;
  loading: boolean;
  generateReportLoading: boolean;
  error: string;
}

export interface RetryImporterFailureListRequest {
  ids: number[];
}

export interface RetryImporterFailureRequest {
  id: number;
}

export interface RetryProductFailureListRequest {
  ids: number[];
}

export interface RetryProductFailureRequest {
  id: number;
}

export interface RejectProductFailureRequest {
  id: number;
}

export interface RejectProductFailureListRequest {
  ids: number[];
}

export interface RejectImporterFailureRequest {
  id: number;
}

export interface RejectImporterFailureListRequest {
  ids: number[];
}

export interface ResolveProductFailureRequest {
  id: number;
  failureGrantType: FailureGrantType;
  errorTypeDetails: {}
}

export interface RetryFailuresState {
  retryImporterFailureListRequest: RetryImporterFailureListRequest;
  retryImporterFailureRequest: RetryImporterFailureRequest;
  retryProductFailureListRequest: RetryProductFailureListRequest;
  retryProductFailureRequest: RetryProductFailureRequest;
  retryLoading: boolean;
  retryError: string;
}

export interface RejectFailuresState {
  rejectImporterFailureListRequest: RejectImporterFailureListRequest;
  rejectProductFailureListRequest: RejectProductFailureListRequest;
  rejectProductFailureRequest: RejectProductFailureRequest;
  rejectImporterFailureRequest: RejectImporterFailureRequest;
  rejectLoading: boolean;
  rejectError: string;
}

export interface ResolveFailuresState {
  resolveProductFailureRequest: ResolveProductFailureRequest;
  resolveLoading: boolean;
  resolveError: string;
}

export interface FetchImporterFailureListAction {
  type: typeof FETCH_IMPORTER_FAILURE_LIST
  pageRequest: FetchImporterFailuresPageRequest
}

export interface FetchImporterFailureListSucceededAction {
  type: typeof FETCH_IMPORTER_FAILURE_LIST_SUCCEEDED
  payload: FetchImporterFailuresPageResponse
}

export interface FetchImporterFailureListFailedAction {
  type: typeof FETCH_IMPORTER_FAILURE_LIST_FAILED
  error: string
}

export interface FetchProductFailureListAction {
  type: typeof FETCH_PRODUCT_FAILURE_LIST
  pageRequest: FetchProductFailuresPageRequest
}

export interface FetchProductFailureListSucceededAction {
  type: typeof FETCH_PRODUCT_FAILURE_LIST_SUCCEEDED
  payload: FetchProductFailuresPageResponse
}

export interface FetchProductFailureListFailedAction {
  type: typeof FETCH_PRODUCT_FAILURE_LIST_FAILED
  error: string
}

export interface GenerateReportAction {
  type: typeof GENERATE_REPORT
  pageRequest: FetchProductFailuresPageRequest
}

export interface GenerateReportSucceededAction {
  type: typeof GENERATE_REPORT_SUCCEEDED
}

export interface GenerateReportFailedAction {
  type: typeof GENERATE_REPORT_FAILED
  error: string
}

export interface FetchImporterFailureAction {
  type: typeof FETCH_IMPORTER_FAILURE
  id: number
}

export interface FetchImporterFailureSucceededAction {
  type: typeof FETCH_IMPORTER_FAILURE_SUCCEEDED;
  payload: ImporterFailure;
}

export interface FetchImporterFailureFailedAction {
  type: typeof FETCH_IMPORTER_FAILURE_FAILED
  error: string
}

export interface FetchProductFailureAction {
  type: typeof FETCH_PRODUCT_FAILURE
  id: number
}

export interface FetchProductFailureSucceededAction {
  type: typeof FETCH_PRODUCT_FAILURE_SUCCEEDED;
  payload: ProductFailure;
}

export interface FetchProductFailureFailedAction {
  type: typeof FETCH_PRODUCT_FAILURE_FAILED
  error: string
}

export interface RetryImporterFailureListAction {
  type: typeof RETRY_IMPORTER_FAILURE_LIST
  retryFailuresRequest: RetryImporterFailureListRequest
}

export interface RetryImporterFailureListSucceededAction {
  type: typeof RETRY_IMPORTER_FAILURE_LIST_SUCCEEDED
}

export interface RetryImporterFailureListFailedAction {
  type: typeof RETRY_IMPORTER_FAILURE_LIST_FAILED
  error: string
}

export interface RetryProductFailureListAction {
  type: typeof RETRY_PRODUCT_FAILURE_LIST
  retryFailuresRequest: RetryProductFailureListRequest
}

export interface RetryProductFailureListSucceededAction {
  type: typeof RETRY_PRODUCT_FAILURE_LIST_SUCCEEDED
}

export interface RetryProductFailureListFailedAction {
  type: typeof RETRY_PRODUCT_FAILURE_LIST_FAILED
  error: string
}

export interface RejectProductFailureAction {
  type: typeof REJECT_PRODUCT_FAILURE
  rejectFailureRequest: RejectProductFailureRequest
}

export interface RejectProductFailureSucceededAction {
  type: typeof REJECT_PRODUCT_FAILURE_SUCCEEDED
}

export interface RejectProductFailureFailedAction {
  type: typeof REJECT_PRODUCT_FAILURE_FAILED
  error: string
}

export interface RejectProductFailureListAction {
  type: typeof REJECT_PRODUCT_FAILURE_LIST
  rejectFailuresRequest: RejectProductFailureListRequest
}

export interface RejectProductFailureListSucceededAction {
  type: typeof REJECT_PRODUCT_FAILURE_LIST_SUCCEEDED
}

export interface RejectProductFailureListFailedAction {
  type: typeof REJECT_PRODUCT_FAILURE_LIST_FAILED
  error: string
}

export interface RejectImporterFailureAction {
  type: typeof REJECT_IMPORTER_FAILURE
  rejectFailureRequest: RejectImporterFailureRequest
}

export interface RejectImporterFailureSucceededAction {
  type: typeof REJECT_IMPORTER_FAILURE_SUCCEEDED
}

export interface RejectImporterFailureFailedAction {
  type: typeof REJECT_IMPORTER_FAILURE_FAILED
  error: string
}

export interface RejectImporterFailureListAction {
  type: typeof REJECT_IMPORTER_FAILURE_LIST
  rejectFailuresRequest: RejectImporterFailureListRequest
}

export interface RejectImporterFailureListSucceededAction {
  type: typeof REJECT_IMPORTER_FAILURE_LIST_SUCCEEDED
}

export interface RejectImporterFailureListFailedAction {
  type: typeof REJECT_IMPORTER_FAILURE_LIST_FAILED
  error: string
}

export interface ResolveProductFailureAction {
  type: typeof RESOLVE_PRODUCT_FAILURE
  resolveFailureRequest: ResolveProductFailureRequest
}

export interface ResolveProductFailureSucceededAction {
  type: typeof RESOLVE_PRODUCT_FAILURE_SUCCEEDED
}

export interface ResolveProductFailureFailedAction {
  type: typeof RESOLVE_PRODUCT_FAILURE_FAILED
  error: string
}

export interface RetryProductFailureAction {
  type: typeof RETRY_PRODUCT_FAILURE
  retryFailureRequest: RetryProductFailureRequest
}

export interface RetryProductFailureSucceededAction {
  type: typeof RETRY_PRODUCT_FAILURE_SUCCEEDED
}

export interface RetryProductFailureFailedAction {
  type: typeof RETRY_PRODUCT_FAILURE_FAILED
  error: string
}

export interface RetryImporterFailureAction {
  type: typeof RETRY_IMPORTER_FAILURE
  retryFailureRequest: RetryImporterFailureRequest
}

export interface RetryImporterFailureSucceededAction {
  type: typeof RETRY_IMPORTER_FAILURE_SUCCEEDED
}

export interface RetryImporterFailureFailedAction {
  type: typeof RETRY_IMPORTER_FAILURE_FAILED
  error: string
}

export interface FetchLegacyProductAction {
  type: typeof FETCH_LEGACY_PRODUCT
  id: string
  subsidiary: Subsidiary
  sourceSystem: ProductSourceSystem
}

export interface FetchLegacyProductSucceededAction {
  type: typeof FETCH_LEGACY_PRODUCT_SUCCEEDED;
  payload: string;
}

export interface FetchLegacyProductFailedAction {
  type: typeof FETCH_LEGACY_PRODUCT_FAILED
  error: string
}

export type FetchFailuresActionTypes =  FetchImporterFailureAction | FetchImporterFailureSucceededAction | FetchImporterFailureFailedAction
  | FetchProductFailureAction | FetchProductFailureSucceededAction | FetchProductFailureFailedAction
  | FetchImporterFailureListAction | FetchImporterFailureListSucceededAction | FetchImporterFailureListFailedAction
  | FetchLegacyProductAction | FetchLegacyProductSucceededAction | FetchLegacyProductFailedAction
  | FetchProductFailureListAction | FetchProductFailureListSucceededAction | FetchProductFailureListFailedAction
  | GenerateReportAction | GenerateReportSucceededAction | GenerateReportFailedAction;

export type RetryFailuresActionTypes = RetryImporterFailureListAction | RetryImporterFailureListSucceededAction | RetryImporterFailureListFailedAction
  | RetryProductFailureListAction | RetryProductFailureListSucceededAction | RetryProductFailureListFailedAction
  | RetryImporterFailureAction | RetryImporterFailureSucceededAction | RetryImporterFailureFailedAction
  | RetryProductFailureAction | RetryProductFailureSucceededAction | RetryProductFailureFailedAction;

export type RejectFailuresActionTypes = RejectProductFailureAction | RejectProductFailureSucceededAction | RejectProductFailureFailedAction
    | RejectImporterFailureAction | RejectImporterFailureSucceededAction | RejectImporterFailureFailedAction
    | RejectImporterFailureListAction | RejectImporterFailureListSucceededAction | RejectImporterFailureListFailedAction
    | RejectProductFailureListAction | RejectProductFailureListSucceededAction | RejectProductFailureListFailedAction;

export type ResolveFailuresActionTypes = ResolveProductFailureAction | ResolveProductFailureSucceededAction | ResolveProductFailureFailedAction;
