import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import {
  FETCH_IMPORTER_FAILURE,
  FETCH_IMPORTER_FAILURE_LIST,
  FETCH_LEGACY_PRODUCT,
  FETCH_PRODUCT_FAILURE,
  FETCH_PRODUCT_FAILURE_LIST,
  FetchImporterFailureAction,
  FetchImporterFailureListAction,
  FetchLegacyProductAction,
  FetchProductFailureAction,
  FetchProductFailureListAction,
  GENERATE_REPORT,
  GenerateReportAction,
  REJECT_IMPORTER_FAILURE, REJECT_IMPORTER_FAILURE_LIST,
  REJECT_PRODUCT_FAILURE, REJECT_PRODUCT_FAILURE_LIST,
  RejectImporterFailureAction, RejectImporterFailureListAction, RejectImporterFailureRequest,
  RejectProductFailureAction, RejectProductFailureListAction,
  RESOLVE_PRODUCT_FAILURE,
  ResolveProductFailureAction,
  RETRY_IMPORTER_FAILURE,
  RETRY_IMPORTER_FAILURE_LIST,
  RETRY_PRODUCT_FAILURE,
  RETRY_PRODUCT_FAILURE_LIST,
  RetryImporterFailureAction,
  RetryImporterFailureListAction,
  RetryProductFailureAction,
  RetryProductFailureListAction
} from './types'
import {
  fetchImporterFailure,
  fetchImporterFailureFailed,
  fetchImporterFailureListFailed,
  fetchImporterFailureListSucceeded,
  fetchImporterFailureSucceeded,
  fetchLegacyProductFailed,
  fetchLegacyProductSucceeded,
  fetchProductFailure,
  fetchProductFailureFailed,
  fetchProductFailureListFailed,
  fetchProductFailureListSucceeded,
  fetchProductFailureSucceeded,
  generateReportFailed,
  generateReportSucceeded,
  rejectImporterFailureFailed, rejectImporterFailureListFailed, rejectImporterFailureListSucceeded,
  rejectImporterFailureSucceeded,
  rejectProductFailureFailed, rejectProductFailureListFailed, rejectProductFailureListSucceeded,
  rejectProductFailureSucceeded,
  resolveProductFailureFailed, resolveProductFailureSucceeded,
  retryImporterFailureFailed,
  retryImporterFailureListFailed,
  retryImporterFailureListSucceeded,
  retryImporterFailureSucceeded,
  retryProductFailureFailed,
  retryProductFailureListFailed,
  retryProductFailureListSucceeded,
  retryProductFailureSucceeded
} from './actions'

import dataService from '../../services/DataService'
import { message } from "antd";
import { fetchImportTaskExecution } from "../productImports/actions";
import { AppState } from "../index";

export function* failuresSaga() {
  yield all([
    watchFetchImporterFailureList(),
    watchFetchProductFailureList(),
    watchFetchImporterFailure(),
    watchFetchProductFailure(),
    watchFetchLegacyProduct(),
    watchGenerateReport(),
  ])
}

function* watchFetchImporterFailureList() {
  yield takeLatest(FETCH_IMPORTER_FAILURE_LIST, handleFetchImporterFailureList)
}

function* watchFetchProductFailureList() {
  yield takeLatest(FETCH_PRODUCT_FAILURE_LIST, handleFetchProductFailureList)
}

function* watchGenerateReport() {
  yield takeLatest(GENERATE_REPORT, handleGenerateReport)
}

function* watchFetchImporterFailure() {
  yield takeLatest(FETCH_IMPORTER_FAILURE, handleFetchImporterFailure)
}

function* watchFetchProductFailure() {
  yield takeLatest(FETCH_PRODUCT_FAILURE, handleFetchProductFailure)
}

function* watchFetchLegacyProduct() {
  yield takeLatest(FETCH_LEGACY_PRODUCT, handleFetchLegacyProduct)
}

function* handleFetchImporterFailureList(action: FetchImporterFailureListAction) {
  try {
    const data = yield call([dataService, dataService.getImporterFailureList], action.pageRequest);
    yield put(fetchImporterFailureListSucceeded(data))
  } catch (error) {
    message.error(error.message);
    yield put(fetchImporterFailureListFailed(`${error.message}`))
  }
}

function* handleFetchProductFailureList(action: FetchProductFailureListAction) {
  try {
    const data = yield call([dataService, dataService.getProductFailureList], action.pageRequest);
    yield put(fetchProductFailureListSucceeded(data))
  } catch (error) {
    message.error(error.message);
    yield put(fetchProductFailureListFailed(`${error.message}`))
  }
}

function* handleGenerateReport(action: GenerateReportAction) {
  try {
    yield call([dataService, dataService.generateReport], action.pageRequest);
    yield put(generateReportSucceeded())
  } catch (error) {
    message.error(error.message);
    yield put(generateReportFailed(`${error.message}`))
  }
}

function* handleFetchImporterFailure(action: FetchImporterFailureAction) {
  try {
    const data = yield call([dataService, dataService.fetchImporterFailure], action.id);
    yield put(fetchImporterFailureSucceeded(data))
  } catch (error) {
    message.error(error.message);
    yield put(fetchImporterFailureFailed(`${error.message}`))
  }
}

function* handleFetchProductFailure(action: FetchProductFailureAction) {
  try {
    const data = yield call([dataService, dataService.fetchProductFailure], action.id);
    yield put(fetchProductFailureSucceeded(data))
  } catch (error) {
    message.error(error.message);
    yield put(fetchProductFailureFailed(`${error.message}`))
  }
}

function* handleFetchLegacyProduct(action: FetchLegacyProductAction) {
  try {
    const data: string = yield call([dataService, dataService.fetchLegacyProduct], action.id, action.subsidiary, action.sourceSystem);
    yield put(fetchLegacyProductSucceeded(data))
  } catch (error) {
    message.error(error.message);
    yield put(fetchLegacyProductFailed(`${error.message}`))
  }
}

export function* rejectFailuresSaga() {
  yield all([
    watchRejectProductFailure(),
    watchRejectImporterFailure(),
    watchRejectProductFailureList(),
    watchRejectImporterFailureList()
  ])
}

export function* resolveFailuresSaga() {
  yield all([
    watchResolveProductFailure()
  ])
}

export function* retryFailuresSaga() {
  yield all([
    watchRetryImporterFailureList(),
    watchRetryImporterFailure(),
    watchRetryProductFailureList(),
    watchRetryProductFailure()
  ])
}

function* watchRetryImporterFailure() {
  yield takeLatest(RETRY_IMPORTER_FAILURE, handleRetryImporterFailure)
}

function* watchRetryImporterFailureList() {
  yield takeLatest(RETRY_IMPORTER_FAILURE_LIST, handleRetryImporterFailureList)
}

function* watchRejectProductFailure() {
  yield takeLatest(REJECT_PRODUCT_FAILURE, handleRejectProductFailure)
}

function* watchRejectProductFailureList() {
  yield takeLatest(REJECT_PRODUCT_FAILURE_LIST, handleRejectProductFailureList)
}

function* watchRejectImporterFailure() {
  yield takeLatest(REJECT_IMPORTER_FAILURE, handleRejectImporterFailure)
}

function* watchRejectImporterFailureList() {
  yield takeLatest(REJECT_IMPORTER_FAILURE_LIST, handleRejectImporterFailureList)
}

function* watchResolveProductFailure() {
  yield takeLatest(RESOLVE_PRODUCT_FAILURE, handleResolveProductFailure)
}

function* watchRetryProductFailure() {
  yield takeLatest(RETRY_PRODUCT_FAILURE, handleRetryProductFailure)
}

function* watchRetryProductFailureList() {
  yield takeLatest(RETRY_PRODUCT_FAILURE_LIST, handleRetryProductFailureList)
}

function* handleRetryProductFailureList(action: RetryProductFailureListAction) {
  try {
    const data = yield call([dataService, dataService.retryProductFailureList], action.retryFailuresRequest);

    message.success("Failures retry was triggered successfully");
    yield put(retryProductFailureListSucceeded());
  } catch (error) {
    message.error(error.message);
    yield put(retryProductFailureListFailed(`${error.message}`))
  }
}

function* handleRejectProductFailureList(action: RejectProductFailureListAction) {
  try {
    const data = yield call([dataService, dataService.rejectProductFailureList], action.rejectFailuresRequest);

    message.success("Failures reject was triggered successfully");
    yield put(rejectProductFailureListSucceeded());
  } catch (error) {
    message.error(error.message);
    yield put(rejectProductFailureListFailed(`${error.message}`))
  }
}

function* handleRetryProductFailure(action: RetryProductFailureAction) {
  try {
    const data = yield call([dataService, dataService.retryProductFailure], action.retryFailureRequest);

    message.success("Failure retry was triggered successfully");
    yield put(retryProductFailureSucceeded());
    yield put(fetchProductFailure(action.retryFailureRequest.id));

  } catch (error) {
    message.error(error.message);
    yield put(retryProductFailureFailed(`${error.message}`))
  }
}

function* handleRejectProductFailure(action: RejectProductFailureAction) {
  try {
    const data = yield call([dataService, dataService.rejectProductFailure], action.rejectFailureRequest);

    message.success("Failure reject was triggered successfully");
    yield put(rejectProductFailureSucceeded());
    yield put(fetchProductFailure(action.rejectFailureRequest.id));

  } catch (error) {
    message.error(error.message);
    yield put(rejectProductFailureFailed(`${error.message}`))
  }
}

function* handleRejectImporterFailure(action: RejectImporterFailureAction) {
  try {
    const data = yield call([dataService, dataService.rejectImporterFailure], action.rejectFailureRequest);

    message.success("Failure reject was triggered successfully");
    yield put(rejectImporterFailureSucceeded());
    yield put(fetchImporterFailure(action.rejectFailureRequest.id));

  } catch (error) {
    message.error(error.message);
    yield put(rejectImporterFailureFailed(`${error.message}`))
  }
}

function* handleResolveProductFailure(action: ResolveProductFailureAction) {
  try {
    const addGrantResult = yield call([dataService, dataService.addProductFailureGrant], action.resolveFailureRequest);
    const retryResult = yield call([dataService, dataService.retryProductFailure], {id: action.resolveFailureRequest.id});

     message.success("Failure resolve was successful");
     yield put(resolveProductFailureSucceeded());
     yield put(fetchProductFailure(action.resolveFailureRequest.id));

  } catch (error) {
    message.error(error.message);
    yield put(resolveProductFailureFailed(`${error.message}`))
  }
}

function* handleRetryImporterFailureList(action: RetryImporterFailureListAction) {
  try {
    const data = yield call([dataService, dataService.retryImporterFailureList], action.retryFailuresRequest);

    message.success("Importer failures retry was triggered successfully");
    yield put(retryImporterFailureListSucceeded());
  } catch (error) {
    message.error(error.message);
    yield put(retryImporterFailureListFailed(`${error.message}`))
  }
}

function* handleRejectImporterFailureList(action: RejectImporterFailureListAction) {
  try {
    const data = yield call([dataService, dataService.rejectImporterFailureList], action.rejectFailuresRequest);

    message.success("Importer failures reject was triggered successfully");
    yield put(rejectImporterFailureListSucceeded());
  } catch (error) {
    message.error(error.message);
    yield put(rejectImporterFailureListFailed(`${error.message}`))
  }
}

function* handleRetryImporterFailure(action: RetryImporterFailureAction) {
  try {
    const data = yield call([dataService, dataService.retryImporterFailure], action.retryFailureRequest);

    message.success("Failure retry was triggered successfully");
    yield put(retryImporterFailureSucceeded());
    yield put(fetchImporterFailure(action.retryFailureRequest.id));

    const importTaskExecutionId = yield select((state: AppState) => state.productImports.importTaskExecutionId);
    if (!!importTaskExecutionId) {
      yield put(fetchImportTaskExecution(importTaskExecutionId));
    }
  } catch (error) {
    message.error(error.message);
    yield put(retryImporterFailureFailed(`${error.message}`))
  }
}
